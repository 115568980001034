import React, {useState} from 'react';
import {IonActionSheet} from "@ionic/react";
import {useAppStateDispatch} from '../../AppState';

export var presentMenuWord:any;

var mWordsTableView:any,
    mClickingList:any,
    mClickingWords:any,
    mClickingWord:any,
    mClickingRow:any;

export function setClicking(WordsTableView:any, List:any, Words:any,Word:any, Row:any){
  mWordsTableView = WordsTableView;
  mClickingList = List;
  mClickingWords = Words;
  mClickingWord = Word;
  mClickingRow = Row;
}

export const MenuWord:React.FC = () => {
  const dispatch = useAppStateDispatch();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [actions, setActions] = useState([]);

  //@export:
  presentMenuWord = setShowActionSheet;
  (window as any).top.presentMenuWord = presentMenuWord;

  return (
    <IonActionSheet
      isOpen={showActionSheet}
      onDidDismiss={() => setShowActionSheet(false)}
      //header={Object(window).mClickingWord.word_string}
      //subHeader={Object(window).mClickingWord.word_key}
      onWillPresent={() => {

        var options = [
          
          //.卡片学习
          {
            text: '卡片学习',
            handler: () => {
              //console.log("🐞mClickingListRow:",mClickingList,mClickingRow);
              //setTimeout(function () {
                 Object(window).top.mCardsViewController.showUP(mClickingList, mClickingWords,mClickingRow);
              //},1500);
            }
          },

          //.循环播放
          {
            text: '播放',
            handler: () => {
              mWordsTableView.startLoopPlayBy(mClickingRow);
            }
          },

          //.取消
          {
            text: '取消',
            role: 'cancel',
            handler: () => {}
          }
        ];
        
        setActions(options as any);
      }}
      cssClass='menu-word'
      buttons={actions}
    ></IonActionSheet>
  )
}
