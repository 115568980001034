import React, {useCallback, useEffect, useRef} from 'react';
import { Redirect, Route } from 'react-router-dom';
import {AppStateProvider} from './AppState.jsx';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle } from 'ionicons/icons';

import {FileController} from "./tools/FileController";

//global setup
import {setupConfig} from '@ionic/react'

//elements
import IframeNav from "./tools/uikit/IframeNav";
import {MenuApp} from "./pages/elements/MenuApp";

//pages
import TabStudy from './pages/TabStudy.jsx';
import TabDiscover from './pages/TabDiscover';
import TabTools from './pages/TabTools';
import LoginPage from './pages/LoginPage';
import PayPage from './pages/PayPage.jsx';

import WordsViewController from "./pages/studyplan/WordsViewController";
import CardsViewController from "./pages/studyplan/CardsViewController/CardsViewController";
import BookViewController,{MenuBook} from "./pages/studyplan/Books/BookViewController";
import StudyPlanSetting from "./pages/studyplan/StudyPlanSetting";
import SearchDetail from "./pages/dictionary/SearchDetail";
import MdxFileManager from "./pages/dictionary/MdxFileManager";

import WordsLibraryManager from "./pages/dashboard/WordsLibraryManager";

import NativeTools from "./tools/NativeTools.js";


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css';

import CardsSetting from './pages/studyplan/CardsViewController/CardsSetting.jsx';
import {MenuAddList} from "./pages/elements/MenuAddList";
import {MenuList} from "./pages/elements/MenuList";


//🍉.global nav events
const AppNavDidChange = (e:any) => {
  //console.log("🐞IonNavDidChange:",e);

  const lastChild = e.target.lastChild;
  const lastChildName = lastChild.nodeName.toLowerCase();
  const lastChildID = lastChild.id;
  //console.log("🐞lastChild:",lastChild,lastChildName,lastChildID);

  //root
  if (lastChildName == "div" && lastChildID == "home-wrapper"){
    if(mTabsRef != null){
      let tab = mTabsRef.ionTabContextState.activeTab;
      if (tab == "tab_study"){
        (window as any).top.TabStudy.viewDidAppear();
      }
      if (tab == "tab_discover"){
        (window as any).top.TabDiscover.viewDidAppear();
      }
    }
  }

  //nav-page
  if (lastChildName == "iframe-nav-detail"){

  }

  //listviewcontroller
  if (lastChildID == "custom-listviewcontroller"){
    if ((window as any).top.ListViewController){
      (window as any).top.ListViewController.delegate.pageDidAppear();
    }
  }

  //wordsviewcontroller
  if (lastChildID == "custom-wordsviewcontroller"){
    if ((window as any).top.WordsViewController){
      (window as any).top.WordsViewController.viewDidAppear();
    }
  }

  //studyplansetting
  if (lastChildID == "custom-studyplansetting"){
    if ((window as any).top.StudyPlanSetting){
      (window as any).top.StudyPlanSetting.viewDidAppear();
    }
  }
}
(window as any).top.AppNavDidChange = AppNavDidChange;

//🍉.init
setupConfig({mode: 'ios'})

NativeTools.init();

//🍉.view
var mTabsRef:any = null;
const App: React.FC = () => {

  useEffect(()=>{
    //console.log("🐞mTabsRef:",mTabsRef)
  });

  const tabsRef = useCallback(function (ref) {
    mTabsRef = ref;
  },[]);

  return (
    <AppStateProvider>
      <IonApp>
        <IframeNav>
          <IonReactRouter>
            <IonTabs ref={tabsRef}>
              <IonRouterOutlet animated={true} mode="ios">

                {/*Pages.StudyPlan*/}
                <Route path="/page/BookViewController" component={BookViewController} exact={true}/>
                <Route path="/page/WordsViewController" component={WordsViewController} exact={true}/>
                <Route path="/page/CardsViewController" component={CardsViewController} exact={true}/>
                <Route path="/page/StudyPlanSetting" component={StudyPlanSetting} exact={true}/>
                {/*@Depracated*/}
                {/*<Route path="/page/ListViewController" component={ListViewController} exact={true}/>*/}

                {/*Pages.Dictionary*/}
                <Route path="/page/SearchDetail" component={SearchDetail} exact={true}/>

                {/*🚫test*/}
                {/*<Route path="/page/SearchDetail" render={props => <SearchDetail values={Object({WordString:"parlare", MeanString:"说话"})}/>} exact={true}/>*/}
                <Route path="/page/CardsSetting" component={CardsSetting} exact={true}/>

                <Route path="/page/MdxFileManager" component={MdxFileManager} exact={true}/>

                <Route exact path="/tab_study">
                  <TabStudy />
                </Route>
                <Route exact path="/tab_discover">
                  <TabDiscover />
                </Route>
                <Route path="/tab_tools">
                  <TabTools />
                </Route>
                <Route exact path="/">
                  <Redirect to="/tab_study" />
                </Route>
              </IonRouterOutlet>
              <IonTabBar slot="bottom">
                <IonTabButton tab="tab_study" href="/tab_study">
                  <IonIcon icon={triangle} />
                  <IonLabel>背单词</IonLabel>
                </IonTabButton>
                <IonTabButton tab="tab_discover" href="/tab_discover">
                  <IonIcon icon={ellipse} />
                  <IonLabel>大发现</IonLabel>
                </IonTabButton>
                <IonTabButton tab="tab_tools" href="/tab_tools">
                  <IonIcon icon={square} />
                  <IonLabel>工具箱</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>

            <Route path="/dashboard" component={WordsLibraryManager} exact={true}/>

          </IonReactRouter>

          {/*LoginPage*/}
          <LoginPage/>

          {/*PayPage*/}
          <PayPage/>

          {/*MenuApp*/}
          <MenuApp/>

          {/*MenuBook*/}
          <MenuBook/>

          {/*MenuAddList*/}
          <MenuAddList/>

          {/*MenuList*/}
          <MenuList/>

        </IframeNav>
      </IonApp>
    </AppStateProvider>
  )
};

export default App;
