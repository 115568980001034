import $ from  'jquery';
import {useEffect} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import {IframeNavPresentPage} from '../../tools/uikit/IframeNav'
import './WordsLibraryManager.css';
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react/dist/index";
import {ellipsisHorizontal} from "ionicons/icons/index";
import WordsRichTable from "./WordsRichTable";
import * as Tools from "../../tools/Tools";
import Keys from "../../tools/Keys";
import SupportTools from "../../tools/SupportTools";
import NativeTools from "../../tools/NativeTools";

var mMaxWordsNum = false           //要验证的单词数 | false=不限制
var mMaxWordsRange = 2000          /*分段进行的数量*/*2;
var mStartFrom = 0               //起始单词

//🍉instance
var instance = {
  showUP : function () {
    IframeNavPresentPage(WordsLibraryManager, "WordsLibraryManager", false);
  }
};
Object(window.top).CurveView = instance;

const WordsLibraryManager = () => {
  var mDataWords = [],
      mSpeakWords = [];

  //🍉init
  useEffect(() => {
    //console.log("🐞WordsLibraryManager ready!");
    setTimeout(()=>{
      getAllWordsThenReload(true);
    },3000);
  });
  
  function getAllWordsThenReload(FromCache) {
    getAllWords(FromCache, function () {

      //prepare words
      if (mMaxWordsNum != false){
        mDataWords = mDataWords.slice(0,mMaxWordsNum);
      }

      //prepare preloader
      mSpeakWords = NativeTools.mTTSPreloader.makeSpeakWordsByWords(mDataWords, true);

      //save cache
      localStorage.setItem("TEMP-WORDS",JSON.stringify(mDataWords));

      //reload
      //console.log("🐞mDataWords:",mDataWords.length);
      mWordsTableViewRef.reloadRichTable(mDataWords, mSpeakWords);
    });
  }
  
  function getAllWords(FromLocalstorage, OnFinish) {
    if (FromLocalstorage){
      mDataWords = JSON.parse(localStorage.getItem("TEMP-WORDS"));
      OnFinish();
    }else{
      SupportTools.ajaxGet(Keys.Url.api_coverlist_data, function (data) {
        let results = JSON.parse(data);
        mDataWords = results['dataWords'];
        OnFinish();
      });
    }
  }

  //🍉methods

  //🍋检查发音
  function startCheckWords() {
    NativeTools.mTTSPreloader.setPreloaderPreferences({CheckOnly:true, UploadOnly:true, HasInterFace:false});

    updateStartFrom();

    var totalLoaded = 0;

    function loopCheckWords(start, length) {
      let sliceWords = mSpeakWords.slice(start, start+length);

      mWordsTableViewRef.markSpeakWords(sliceWords,'loading', true);

      NativeTools.mTTSPreloader.preloadSpeakWords(
        sliceWords,
        //OnLoad
        function ( loadedaudio ) {
          mWordsTableViewRef.markSpeakWords([loadedaudio.speakword], 'loaded', true);
        },
        //OnPrepared
        function () {

        },
        //OnFinish
        function (num) {
          totalLoaded += num;
          //console.log("🐞check:", start, "=>", num, "=", totalLoaded);

          if (start+length <= mSpeakWords.length){
            loopCheckWords(start+length, length);
          }else{
            //console.log("🐞finish check:", totalLoaded);
          }
        });
    }

    if (mStartFrom > 0){
      let loadedWords = mSpeakWords.slice(0, mStartFrom-1);
      mWordsTableViewRef.markSpeakWords(loadedWords, 'loaded', true);
    }

    loopCheckWords(mStartFrom, mMaxWordsRange);
  }

  //🍋下载缺失发音
  function startDownloadWords() {

    NativeTools.mTTSPreloader.setPreloaderPreferences({CheckOnly:false, UploadOnly:true, HasInterFace:false});

    updateStartFrom();

    //console.log("🐞mStartFrom:",mStartFrom, (new Date()).toString());

    //NativeTools.mTTSPreloader.showPreloaderContext();

    function loopCheckWords(start, length) {
      let sliceWords = mSpeakWords.slice(start, start+length);
      //console.log("🐞----------> start loop:",[start, start+length],sliceWords,sliceWords[0].row, sliceWords[sliceWords.length-1].row);

      mWordsTableViewRef.markSpeakWords(sliceWords,'loading', true);

      NativeTools.mTTSPreloader.preloadSpeakWords(
        sliceWords,
        //OnLoad
        function ( loadedaudio ) {
          mWordsTableViewRef.markSpeakWords([loadedaudio.speakword], 'loaded', true);
        },
        //OnPrepared
        function () {

        },
        //OnFinish
        function () {
          if (start+length <= mSpeakWords.length){
              loopCheckWords(start+length, length);
          }
        });
    }

    if (mStartFrom > 0){
      let loadedWords = mSpeakWords.slice(0, mStartFrom-1);
      mWordsTableViewRef.markSpeakWords(loadedWords, 'loaded', true);
    }

    loopCheckWords(mStartFrom, mMaxWordsRange);
  }

  function updateStartFrom() {
    let getStartFrom = $("#start-from").val();
    if (getStartFrom != '0'){
      getStartFrom = parseInt(getStartFrom);
      if (getStartFrom != NaN){
        mStartFrom = getStartFrom*2;
      }
    }
  }

  //🍉view
  var mWordsTableViewRef, setWordsTableViewRef = function(tableview){mWordsTableViewRef = tableview;};
  return (
    <IonPage id="PageTemplate">
      <IonContent className="PageTemplateContent">
        <IonButtons size="small" className="actions-wrapper p-2">
          <IonButton size="small" onclick={()=>{getAllWordsThenReload(false);}}>重新加载</IonButton>
          <IonButton size="small" onClick={()=>{startCheckWords();}}>检查发音</IonButton>
          <IonButton size="small" onClick={()=>{startDownloadWords();}}>下载缺失发音</IonButton>
          <input id="start-from" defaultValue="0"/>
        </IonButtons>

        <WordsRichTable onRef={setWordsTableViewRef}/>
      </IonContent>
    </IonPage>
  );
};

export default WordsLibraryManager;
