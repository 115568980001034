import React from 'react';
import {IonNav, IonButton, IonIcon} from '@ionic/react';
import {render} from "react-dom";
import {IframeWrapper} from "./IframeWrapper.jsx";
import "./IframeNav.css";
import {shareOutline} from "ionicons/icons";
import NativeTools from "../NativeTools.js";

//🍉Methods

//🍋IframeNavPresent: for src based iframe page
export const IframeNavPresent = (values:any) => {
  //console.log("🐞IframeNavPresent:',values);

  class NavDetail extends HTMLElement {
    values:any;
    connectedCallback() {

      // const mountPoint = document.createElement('div');
      // this.attachShadow({ mode: 'open' }).appendChild(mountPoint);
      // render(<Page2/>, mountPoint);

      // const mountPoint = document.createElement('div');
      // render(<Page2/>, mountPoint);
      // this.innerHTML = mountPoint.innerHTML;

      //render(<Page2/>, this.querySelector('#content'));

      this.innerHTML =
        `
          <ion-header translucent>
            <ion-toolbar>
              <ion-buttons slot="start">
                <ion-back-button defaultHref="/" text=""></ion-back-button>
              </ion-buttons>
              <ion-title>${this.values.title}</ion-title>
              <ion-buttons slot="end" class="right-buttons">
               
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-content id="content" fullscreen class="">
            <div id="frame-content"></div>
            <div id="gesture-handlr"></div>
          </ion-content>
        `;

      render(
        <IframeWrapper src={this.values.url} delegate={this.values.delegate}/>,
        this.querySelector('#frame-content')
      );

      //@share
      if (this.values.share){
          var title = this.values.title,
              url = this.values.url;
        render(<IonButton className="share-button" onClick={()=>{
            NativeTools.share(title, url);
          }} color="dark">
            <IonIcon icon={shareOutline}/>
          </IonButton>,
          this.querySelector('.right-buttons')
        )
      }
    }
  }
  if (!isRegistered("iframe-nav-detail")){
    customElements.define('iframe-nav-detail', NavDetail);
  }

  const navEl = (window as any).top.nav;
  if (navEl) {
    //@Depracated!
    //navEl.popToRoot();
    //navEl.push(<Login/>);
    //navEl.push(ReactDOM.render(Login));

    navEl.push('iframe-nav-detail', { values });
  }
}

//🍋IframeNavPresentPage: for component based page
var mCacheElements = {};
export const IframeNavPresentPage = (Component:any, ComponentName:any, Values:any, Cache=false) => {

  //@Depracated: webcomponent 的 connectedCallback 只认第一次数据
  // const WebComponentName = "custom-"+ComponentName.toLowerCase()//Component.name
  // if (!isRegistered(WebComponentName)){
  //
  //   customElements.define(WebComponentName, class NavDetail extends HTMLElement {
  //     connectedCallback() {
  //       //@Depracated: IonNavBUG
  //       //render(<Component/>, this);
  //
  //       //@Need: mount then push child nodes to new element
  //       const mountPoint = document.createElement('div');
  //       render(<Component values={Values}/>, mountPoint);
  //
  //       const detailNodes: ChildNode[] = []
  //       mountPoint.childNodes.forEach((node) => {
  //         detailNodes.push(node)
  //       })
  //
  //       this.append(...detailNodes)
  //     }
  //   });
  // }

  //@Depracated: 这种方式只有innerHTML, Component 里面的方法失效
  //const WebComponentName = "custom-"+ComponentName.toLowerCase()//Component.name
  // if (!isRegistered(WebComponentName)){
  //   const mountPoint = document.createElement('div');
  //   render(<Component />, mountPoint);
  //   const contents = mountPoint.querySelector('.ion-page');
  //
  //   customElements.define(WebComponentName, class NavDetail extends HTMLElement {
  //     connectedCallback() {
  //       this.innerHTML = (contents as any).innerHTML;
  //     }
  //   });
  // }
  //var element = document.createElement(WebComponentName);

  const WebComponentName = "custom-"+ComponentName.toLowerCase()//Component.name
  var element:any = null;
  //Cache
  if (Cache && Object(mCacheElements)[WebComponentName]){
    element = Object(mCacheElements)[WebComponentName];
    element.style.display = 'block';
    //console.log("🐞🍋...CACHE:", element);
  }
  //New
  else{
    element = document.createElement('div');
    element.id = WebComponentName;
    render(<Component values={Values}/>, element);

    // const mountPoint = document.createElement('div');
    // render(<Component values={Values}/>, mountPoint);
    //
    // element = document.createElement(WebComponentName);
    // const detailNodes: ChildNode[] = []
    // mountPoint.childNodes.forEach((node) => {
    //   detailNodes.push(node)
    // })
    // element.append(...detailNodes);

    if (Cache){
      Object(mCacheElements)[WebComponentName] = element;

      // @Depracated: save cache by interval
      // setInterval(function () {
      //   //console.log("🐞🍋+',element.style.display, $(element)[0]);
      //   Object(mCacheElements)[WebComponentName] = $(element)[0];
      // },2000);
    }
    //console.log("🐞🍋...NEW:", element);
  }

  //@depracated: save cache by dom change event
  // $(element).on('DOMSubtreeModified', function (e) {
  //   Object(mCacheElements)[WebComponentName] = $(element)[0];
  //   //console.log("🐞+++++',element);
  // });

  const navEl = (window as any).top.nav;
  if (navEl) {
    navEl.push(element);
  }
}

//🍋IframeNavPresentFragment: for component based fragment
export const IframeNavPresentFragment = (Context:any, Component:any, ComponentName:any, Values:any) => {
  const WebComponentName = "fragment-"+ComponentName.toLowerCase()//Component.name

  const mountPoint = document.createElement('div');
  render(<Component values={Values}/>, mountPoint);

  var element = document.createElement(WebComponentName);
  const detailNodes: ChildNode[] = []
  mountPoint.childNodes.forEach((node) => {
    detailNodes.push(node)
  })
  element.append(...detailNodes);

  Context.append(element);

  // var element:any = null;
  // //Cache
  // if (Cache && Object(mCacheElements)[WebComponentName]){
  //   element = Object(mCacheElements)[WebComponentName];
  //   element.style.display = 'block';
  //   //console.log("🐞🍋...CACHE:", element);
  // }
  // //New
  // else{
  //
  //
  //   if (Cache){
  //     Object(mCacheElements)[WebComponentName] = element;
  //
  //     // @Depracated: save cache by interval
  //     // setInterval(function () {
  //     //   //console.log("🐞🍋+',element.style.display, $(element)[0]);
  //     //   Object(mCacheElements)[WebComponentName] = $(element)[0];
  //     // },2000);
  //   }
  //   //console.log("🐞🍋...NEW:", element);
  // }
  //
  // //@depracated: save cache by dom change event
  // // $(element).on('DOMSubtreeModified', function (e) {
  // //   Object(mCacheElements)[WebComponentName] = $(element)[0];
  // //   //console.log("🐞+++++',element);
  // // });
  //
  // const navEl = (window as any).top.nav;
  // if (navEl) {
  //   navEl.push(element);
  // }
}


//🍋IframeNavBack: dismiss current page
export const IframeNavBack = () => {
  const navEl = (window as any).top.nav;
  //console.log("🐞BACK:",navEl,navEl.children.length);
  if (navEl.children.length > 1){
      navEl.pop();
  }
}

//🍉Helper
function isRegistered(name:string) {
  return document.createElement(name).constructor !== HTMLElement;
}


//🍉NavView
const IframeNav: React.FC = ({ children }) => {

  const handleNavWillChange = async (navEl: HTMLIonNavElement) => {
    const rootView = await navEl.getByIndex(0)
    //init
    if (rootView === undefined) {
      (window as any).top.nav = navEl;

      //@Depracated!
      // if (!homeEl || !detailEl) {
      // const detailEl = navEl.querySelector('#detail-wrapper') as HTMLDivElement

      //set home
      const homeEl = navEl.querySelector('#home-wrapper') as HTMLDivElement
      if (!homeEl){
        throw new Error('Missing home or detail wrapper elements')
      }
      navEl.setRoot(homeEl)

      //@Depracated!
      //set detail
      // if (customElements.get('nav-detail') === undefined) {
      //     const detailNodes: ChildNode[] = []
      //     detailEl.childNodes.forEach(node => {
      //         detailNodes.push(node)
      //     })
      //     customElements.define('nav-detail', class NavDetail extends HTMLElement {
      //         connectedCallback() {
      //             this.append(...detailNodes)
      //         }
      //     })
      // }

      //@Depracated!
      //set click
      // navEl.querySelectorAll('.ButtonDeeper').forEach(btn => {
      //     btn.addEventListener('click', function (e) {
      //         IframeNavPresent(e);
      //         //navEl.push('nav-detail')
      //     })
      // })
    }
  }

  const handleNavDidChange = async (e:any) => {
    (window as any).top.AppNavDidChange(e);
  }

  return (
    <IonNav
      onIonNavWillChange={(e) => handleNavWillChange(e.target as HTMLIonNavElement)}
      onIonNavDidChange={(e) => handleNavDidChange(e as any)}
      root="nav-home">
      <div id="home-wrapper" >{children}</div>

      {/*@Depracated*/}
      {/*<div id="detail-wrapper" style={{ display: 'none' }}></div>*/}
    </IonNav>
  )
}
export default IframeNav;