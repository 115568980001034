import $ from "jquery";
import React, {useEffect, useState} from 'react';
import {AutoSizer, List} from "react-virtualized";
import './UITableView.css';

export function UITableViewDataSource(
  numberOfSections,
  numberOfRows,
  heightForRowAtIndexPath,
  cellForRowAtIndexPath,
  // function numberOfSections() {},
  // function numberOfRows() {},
  // function heightForRowAtIndexPath(row) {}
  // function cellForRowAtIndexPath(section, row) {}
){
  return {
    numberOfSections:numberOfSections,
    numberOfRows:numberOfRows,
    heightForRowAtIndexPath:heightForRowAtIndexPath,
    cellForRowAtIndexPath:cellForRowAtIndexPath,
  };
}

export  function UITableViewDelegate(
  didFinishLoad,
  didSelectRow,
  didSelectColumn,
  didHoverCell,
  didLeaveCell,
  // function didFinishLoad(Instace){},
  // function didSelectRow(Row){},
  // function didSelectColumn(Row, ColumnKey, RowData){},
  // function didHoverCell(Row, Cell, RowData){},
  // function didLeaveCell(Row, Cell, RowData){},
){
  return {
    didFinishLoad:didFinishLoad,
    didSelectRow:didSelectRow,
    didSelectColumn:didSelectColumn,
    didHoverCell:didHoverCell,
    didLeaveCell:didLeaveCell,
  }
}

const UITableViewInfinite = ({datasource, onRef}) => {
  function init(
    Context,
    Delegate,
    DataSource
  ){
    let instance = {
      mContext : Context,
      mDelegate: Delegate,
      mDataSource: DataSource,
    }

    //🍓methods
    function pluckDictsByItem(Item){
      let this_cell = Item.closest('.ui-tableview-cell');
      let dicts = {};
      $.each(this_cell.find('.ui-tableview-cell-item'),function () {
        let key = $(this).attr('key');
        let value = $(this).text();
        if ($(this).find('.content').length > 0){
          value = key.startsWith('rich_') ?
            $(this).find('.content').html():$(this).find('.content').text();
        }
        dicts[key] = value;
      });
      return dicts;
    }

    //🍓outlets

    //reload
    instance.reload = function (Context=null) {

      //
      if (Context != null){
        instance.mContext = Context;
      }

      //
      instance.mContext.empty();

      //tableview
      let tableview_elm = $("<table class='ui-tableview'></table>");

      //🦞DataSource:

      //numberOfRows
      let rows = instance.mDataSource.numberOfRows();

      //cellForRow
      for (var row = 0; row < rows; row++){
        //cell
        let cell_elm = $("<div class='ui-tableview-cell'></div>"); cell_elm.attr('row',row);
        cell_elm.append(instance.mDataSource.cellForRowAtIndexPath(0, row));
        tableview_elm.append(cell_elm);
      }

      let tableview_wrapper_elm = $("<div class='ui-tableview-wrapper scroller-hidden'></div>");
      tableview_wrapper_elm.append(tableview_elm);
      instance.mContext.append(tableview_wrapper_elm);

      //🦀 Delegate
      if (instance.mDelegate != null){
        instance.mDelegate.didFinishLoad(instance);
      }
    }

    //add action
    instance.addActionToCell = function(Cell, Action){
      Cell.find('.actions-wrapper').append(Action);
    }
    instance.removeActions = function(){
      instance.mContext.find('.cell-action').remove();
    }


    //🍓events

    //:click
    instance.mContext.off('click','.ui-tableview-cell');
    instance.mContext.on('click','.ui-tableview-cell', function () {
      //console.log("🐞click cell");
      if (instance.mDelegate != null){
        let row = parseInt($(this).attr('row'));
        instance.mDelegate.didSelectRow(row);
      }
    });

    //init
    instance.reload();
    return instance;
  }

  function IndexPath(section, row){
    return {
      section: section,
      row: row,
    }
  }

  //...............................
  const [mouted, setMounted] = useState(true);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [scrollToIndex, setScrollToIndex] = useState(0);

  useEffect(()=>{
    onRef({
      reload: reload,
      scrollToRow: scrollToRow,
    });
  },[mouted]);

  
  function reload(fast=false) {
    setNumberOfRows(datasource.numberOfRows());

    //todo: 搞明白重新加载的机制, 是不是只需要 setNumberOfRows + setScrollToIndex
    //setScrollToIndex(0);
    // if (fast){
    //   mListRef.forceUpdate();
    // }else{
    //   mListRef.forceUpdateGrid();
    // }
  }

  function scrollToRow(Row) {
    setScrollToIndex(Row);
  }

  function rowRenderer(
    {
      key, // Unique key within array of rows
      index, // Index of row within collection
      isScrolling, // The List is currently being scrolled
      isVisible, // This row is visible within the List (eg it is not an overscanned row)
      style, // Style object to be applied to row (to position it)
    }
  ){
    let cell = datasource.cellForRowAtIndexPath(0, index);
    return (
      <div className="ui-tableview-cell" row={index} key={key} style={style}>
        {cell}
      </div>
    );
  }

  var mListRef, setListRef = function(list){
    if (list){
      mListRef = list;
    }
  };
  return (
    <AutoSizer>
      {({width, height}) => <List
        width={width}
        height={height}
        rowCount={numberOfRows}
        rowHeight={({index}) => datasource.heightForRowAtIndexPath(index)}
        rowRenderer={rowRenderer}
        scrollToIndex={scrollToIndex}
        ref={setListRef}
      />}
    </AutoSizer>
  );

};
export default UITableViewInfinite;