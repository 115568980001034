const TimeController = {
  /**
   * 获取当前时间
   * @reutrn: Date
   */
  getCurrentDate:function (){
    return new Date();
  },

  /*获取当前时间戳
  * @return: "2019-05-12 14:10"
  */
  getTimeStringBySeconds:function(){
    let date = this.getCurrentDate();
    let timestring =
      date.getFullYear()+ "-"+
      addZero((date.getMonth()+1))+ "-"+
      addZero(date.getDate())+" "+
      addZero(date.getHours())+":"+
      addZero(date.getMinutes())+":"+
      addZero(date.getSeconds());

    function addZero(m) {
      return m < 10 ? '0' + m : m;
    }

    return timestring;
  },
  getTimeStringByMinites:function(){
    let date = this.getCurrentDate();
    let timestring =
      date.getFullYear()+ "-"+
      addZero((date.getMonth()+1))+ "-"+
      addZero(date.getDate())+" "+
      addZero(date.getHours())+":"+
      addZero(date.getMinutes());

    function addZero(m) {
      return m < 10 ? '0' + m : m;
    }

    return timestring;
  },
  getTimeStringByDay:function(){
    let date = this.getCurrentDate();
    let timestring =
      date.getFullYear()+ "-"+
      addZero((date.getMonth()+1))+ "-"+
      addZero(date.getDate());
    function addZero(m) {
      return m < 10 ? '0' + m : m;
    }
    return timestring;
  },

  /**
   * 编码时间
   * @param date
   * @returns {string}
   */
  encodeDateToTimeStringByMinites(date) {
    let timestring =
      date.getFullYear()+ "-"+
      addZero((date.getMonth()+1))+ "-"+
      addZero(date.getDate())+" "+
      addZero(date.getHours())+":"+
      addZero(date.getMinutes());

    function addZero(m) {
      return m < 10 ? '0' + m : m;
    }

    return timestring;
  },
  encodeDateToTimeStringBySeconds(date) {
    let timestring =
      date.getFullYear()+ "-"+
      addZero((date.getMonth()+1))+ "-"+
      addZero(date.getDate())+" "+
      addZero(date.getHours())+":"+
      addZero(date.getMinutes())+":"+
      addZero(date.getSeconds());

    function addZero(m) {
      return m < 10 ? '0' + m : m;
    }

    return timestring;
  },

  /*解码时间戳为 Date Object
  * @param: timestring
  * @return: date object
  */
  decodeTimeStringOfMinites:function(TimeString) {
    let date = new Date(TimeString.replace(/\-/g, "/"));
    return date;
  },
  decodeTimeString:function(TimeString) {
    let date = new Date(TimeString.replace(/\-/g, "/"));
    return date;
  },

  /*计算当前与时间的差距天数
  * @params: timestring
  * @return: float
  * */
  countDistanceOfTodayTo:function(TimeString){
    let date = this.getCurrentDate();
    let start = this.decodeTimeStringOfMinites(TimeString);

    let miles = date-start;
    let diffDays = (miles / 86400000).toFixed(2); // days

    //var diffHrs = (miles % 86400000) / 3600000; // hours
    //var diffMins = Math.round(((miles % 86400000) % 3600000) / 60000); // minutes

    return parseFloat(diffDays);
  },
  countDistanceFromTo:function(CurrentTimeString,StartTimeString){
    let date = this.decodeTimeStringOfMinites(CurrentTimeString);
    let start = this.decodeTimeStringOfMinites(StartTimeString);

    let miles = date-start;
    let diffDays = (miles / 86400000).toFixed(2); // days

    //var diffHrs = (miles % 86400000) / 3600000; // hours
    //var diffMins = Math.round(((miles % 86400000) % 3600000) / 60000); // minutes

    return parseFloat(diffDays);
  },
  countDistanceFromDateToDate:function(date,start){

    let miles = date-start;
    let diffDays = (miles / 86400000).toFixed(2); // days

    //var diffHrs = (miles % 86400000) / 3600000; // hours
    //var diffMins = Math.round(((miles % 86400000) % 3600000) / 60000); // minutes

    return parseFloat(diffDays);
  },

  /**
   * Laravel优化
   */
  // optimizeForLaravel(TimeString){
  //   let date;
  //   if (Tools.isEmpty(TimeString)){
  //     date = new Date();
  //   }else{
  //     date = TimeController.decodeTimeString(TimeString);
  //   }
  //   return TimeController.encodeDateToTimeStringBySeconds(date);
  // },

  /**
   * AddHours
   * @param date, hours
   * @returns {Date}
   */
  addHours(date, hours){
    let new_data = new Date(date.toDateString());
    new_data.setTime(new_data.getTime() + (hours*60*60*1000));
    return new_data;
  },

  // 获取UUID
  makeUUID(){
    return 'xxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16).toUpperCase();
    });
  },
  getUnicoIDByTimeString:function () {
    return TimeController.getTimeStringByDay()+"/"+TimeController.makeUUID();
  }
};
Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
};

export default TimeController;