/* eslint-disable */
/* 👆 this is required for disabling eslint for amd. */

//@usage:
//import * as Tools from "./Tools";

(function (global) {
  let Tools = {

    /**
     * 🍋Call Device
     * @params: name:string, contents:string or json stringfyed dictionary or array
     * @Context.onCallDevice(CallBackName,Objects)
     */
    CallDeviceContext:null,

    triger_call_device : function (CallBackName,Objects=null){

      if (this.CallDeviceContext == null && typeof window.CallDeviceContext != "undefined"){
        this.CallDeviceContext = window.CallDeviceContext;
      }

      //console.log("🐞Tools.call:',CallBackName,Objects, 'Context:',this.CallDeviceContext, window);

      //iframe
      if (this.CallDeviceContext != null){
        this.CallDeviceContext.onCallDevice(CallBackName,Objects);
        return;
      }

      //iOS
      if (window.webkit !== undefined && window.webkit.messageHandlers.call_device !== undefined){
        if (Objects === undefined || Objects === null){
          window.webkit.messageHandlers.call_device.postMessage({name:CallBackName});
        }else{
          window.webkit.messageHandlers.call_device.postMessage({name:CallBackName, contents:Objects});
        }
      }

      //Android
      else if (window.call_device !== undefined){
        if (Objects === undefined || Objects === null){
          window.call_device.postMessage(JSON.stringify({name:CallBackName}));
        }else{
          window.call_device.postMessage(JSON.stringify({name:CallBackName, contents: Objects}));
        }
      }

      //Web
      else{
        //console.log("🐞",CallBackName,Objects);

        if(Objects == undefined || Objects.length <= 0){
          $('#definition').prepend("<div>无结果</div>");
          return;
        }

        if (CallBackName === "mdxFetchMainpartCallBack"){
          let mainpart_elm = $.parseHTML(Objects[0].result_mainpart);
          $('#definition').prepend($(mainpart_elm));
        }
        else if (CallBackName === "mdxDidMapWordsCallBack"){

          $('#definition').empty();

          var words_string = "";
          $.each(Objects, function (index, object) {
            let word_string = object.result_index;
            let word_mean_pretty = object.result_mean != undefined  ? object.result_mean : "";

            //分隔符 "/"
            //let word_means = word_mean_pretty.split(" / ");
            //分隔符 ","
            let word_means = word_mean_pretty
              .replace(/；/g,',')
              .replace(/\s\/\s/,',')
              .split(",");

            let word_means_pure = [];
            $.each(word_means, function (index, word_mean) {
              let first_mean = word_mean.split(", ")[0];
              if (first_mean !== null && !word_means_pure.includes(first_mean)){
                word_means_pure.push(first_mean)
              }
            });
            word_means_pure = word_means_pure.filter(function (value, index) {
              return value.includes('常用') === false && value.includes('音标') === false;
            });

            //显示所有释义
            // if (word_means_pure.length > 0){
            //     word_mean_pretty = word_means_pure.join(", ");
            // }
            //显示1个释义
            if (word_means_pure.length > 0){
              word_mean_pretty = word_means_pure[0];
            }

            let line = (word_string+"---"+word_mean_pretty).replace(/\n/g,'');
            words_string = words_string + line + "\n";

          });

          let pre_elm = $("<pre class='preview'></pre>");
          $('#definition').prepend(pre_elm.append(words_string));
        }
        else{
          let json_string = JSON.stringify(Objects,null,2);
          let highlighted_json_string = Tools.syntaxHighlight(json_string);
          let pre_elm = $("<pre class='preview'></pre>");
          $('#definition').prepend(pre_elm.append(highlighted_json_string));
        }
      }
    },

    syntaxHighlight: function (json) {
      if (typeof json != 'string') {
        json = JSON.stringify(json, undefined, 2);
      }
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      });
    },

    //🍋Object
    isEmpty : function (e) {
      switch (e) {
        case "":
        case null:
        case NaN:
        case undefined:
          return true;
        default:
          if(typeof this === "undefined"){
            return true;
          }
          if (Array.isArray(e)){
            return e.length <= 0;
          }
          return false;
      }
      //@Depracated!
      // switch (e) {
      //     case "":
      //     case 0:
      //     case "0":
      //     case null:
      //     case undefined:
      //     case false:
      //     case typeof this === "undefined":
      //         return true;
      //     default:
      //         if (typeof e === "object"){
      //             return e.length <= 0;
      //         }
      //         return false;
      // }
    },

    //🍋File
    //根据字符串创建文件
    createFile: function (urlData){
      var bytes=window.atob(urlData),
        n=bytes.length,
        u8arr=new Uint8Array(n);
      while(n--){
        u8arr[n]=bytes.charCodeAt(n);
      }
      return new Blob([u8arr],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    },
    createFileFromBinary: function (bytes){
      var n=bytes.length,
        u8arr=new Uint8Array(n);
      while(n--){
        u8arr[n]=bytes.charCodeAt(n);
      }
      return new Blob([u8arr],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    },

    //加载文件(promise.ajax)
    promiseAjax: function (url) {
      return new Promise(function (resolve, reject) {
        let breaks = decodeURIComponent(url).split("/");
        let file_name = breaks[breaks.length-1];

        // $.ajax({
        //   url: url,
        //   type: "get",
        //   dataType: "blob", //扩展出了blob类型
        // }).done(function(data, status, jqXHR){
        //   var blob = data;
        //   blob.lastModifiedDate = new Date();
        //   blob.name = file_name;
        //   let file = blob;
        //   resolve(file);
        //   console.warn("🐞ajax success!");
        // }).fail(function(jqXHR, textStatus) {
        //   console.warn("🐞ajax error:",url,textStatus);
        // });

        var oReq = new XMLHttpRequest();
        oReq.open("GET", url, true);
        oReq.responseType = "blob";
        oReq.onload = function(oEvent) {
            var blob = oReq.response;
            blob.lastModifiedDate = new Date();
            blob.name = file_name;
            let file = blob;
            resolve(file);
          //console.log("🐞ajax success!");
        };
        oReq.onerror = function(error){
            console.warn("🔥ajax error:",error,url);
            resolve(null);
        };
        oReq.send();
      });
    },

    //🍋String
    hasChinese: function (Text) {
      let reg = new RegExp(/[^\x00-\xff]+/g);
      return reg.test(Text);
    },
    pureChinese: function(Text){
      let reg = new RegExp(/[^\x00-\xff]+/g);
      let matches = Text.match(reg);
      if (matches != null){
        return matches.join(",");
      }
      return Text;
    },

    removeAllMarks:function (Text) {
      var punctuationRegEx = /[!-/:-@[-`{-~¡-©«-¬®-±´¶-¸»¿×÷˂-˅˒-˟˥-˫˭˯-˿͵;΄-΅·϶҂՚-՟։-֊־׀׃׆׳-״؆-؏؛؞-؟٪-٭۔۩۽-۾܀-܍߶-߹।-॥॰৲-৳৺૱୰௳-௺౿ೱ-ೲ൹෴฿๏๚-๛༁-༗༚-༟༴༶༸༺-༽྅྾-࿅࿇-࿌࿎-࿔၊-၏႞-႟჻፠-፨᎐-᎙᙭-᙮᚛-᚜᛫-᛭᜵-᜶។-៖៘-៛᠀-᠊᥀᥄-᥅᧞-᧿᨞-᨟᭚-᭪᭴-᭼᰻-᰿᱾-᱿᾽᾿-῁῍-῏῝-῟῭-`´-῾\u2000-\u206e⁺-⁾₊-₎₠-₵℀-℁℃-℆℈-℉℔№-℘℞-℣℥℧℩℮℺-℻⅀-⅄⅊-⅍⅏←-⏧␀-␦⑀-⑊⒜-ⓩ─-⚝⚠-⚼⛀-⛃✁-✄✆-✉✌-✧✩-❋❍❏-❒❖❘-❞❡-❵➔➘-➯➱-➾⟀-⟊⟌⟐-⭌⭐-⭔⳥-⳪⳹-⳼⳾-⳿⸀-\u2e7e⺀-⺙⺛-⻳⼀-⿕⿰-⿻\u3000-〿゛-゜゠・㆐-㆑㆖-㆟㇀-㇣㈀-㈞㈪-㉃㉐㉠-㉿㊊-㊰㋀-㋾㌀-㏿䷀-䷿꒐-꓆꘍-꘏꙳꙾꜀-꜖꜠-꜡꞉-꞊꠨-꠫꡴-꡷꣎-꣏꤮-꤯꥟꩜-꩟﬩﴾-﴿﷼-﷽︐-︙︰-﹒﹔-﹦﹨-﹫！-／：-＠［-｀｛-･￠-￦￨-￮￼-�]|\ud800[\udd00-\udd02\udd37-\udd3f\udd79-\udd89\udd90-\udd9b\uddd0-\uddfc\udf9f\udfd0]|\ud802[\udd1f\udd3f\ude50-\ude58]|\ud809[\udc00-\udc7e]|\ud834[\udc00-\udcf5\udd00-\udd26\udd29-\udd64\udd6a-\udd6c\udd83-\udd84\udd8c-\udda9\uddae-\udddd\ude00-\ude41\ude45\udf00-\udf56]|\ud835[\udec1\udedb\udefb\udf15\udf35\udf4f\udf6f\udf89\udfa9\udfc3]|\ud83c[\udc00-\udc2b\udc30-\udc93]/g;
      var newString = Text.replace(punctuationRegEx, '').replace(/(\s){2,}/g, '$1');
      return newString;
    },

    //🍋Version
    versionCompare: function (v1, v2, options) {
      var lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1.split('.'),
        v2parts = v2.split('.');

      function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
      }

      if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return null;
      }

      if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
      }

      if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
      }

      for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length == i) {
          return 1;
        }

        if (v1parts[i] == v2parts[i]) {
          continue;
        }
        else if (v1parts[i] > v2parts[i]) {
          return 1;
        }
        else {
          return -1;
        }
      }

      if (v1parts.length != v2parts.length) {
        return -1;
      }

      return 0;
    },

    //🍋LocalStorage
    setLocalStorageObject:function (key, object) {
      localStorage.setItem(key, JSON.stringify(object));
    },
    getLocalStorageObject:function (key) {
      return JSON.parse(localStorage.getItem(key));
    },

    //🍋URL (window.location)
    getUrlParamByKey(key){
      var url_string = window.location.href;
      var url = new URL(url_string);
      var value = url.searchParams.get(key);
      return value;
    },

    //🍋Device
    /**
     * https://juejin.cn/post/6844903794736627720
     * 判断是不是移动端
     * @returns {boolean}
     */
    isMobile() {
      var flag = true;
      var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");
      var userAgentInfo = navigator.userAgent;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return !flag;
    },

    /**
     * 判断是不是微信浏览器
     * @returns {boolean}
     */
    isWechatProgram(){
      if (typeof wx == 'object') {//是否成功引入
        return true;
      }
      return false;
    },
    isWechatBrowser() {
      if (!navigator || !navigator.userAgent){
        return false;
      }

      var ua = navigator.userAgent.toLowerCase();
      if(ua.match(/MicroMessenger/i)=="micromessenger"){
        return true;
      }
      else{
        return false;
      }
    }
  };

  //window
  window.Tools = Tools;

  //AMD
  if (typeof define === 'function' && define.amd) {
    define([],function (){
      return Tools;
    });
  }
  //ES5
  else if (typeof module === 'object' && module.exports) {
    module.exports = Tools;
  }
  //Global
  else {
    global.Tools = Tools;
  }
})(this);