import {ellipsisHorizontal, ellipsisVertical, heart} from "ionicons/icons/index";
import {useEffect} from "react/cjs/react.production.min";
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react/dist/index";

const NavBar = () => {
  // useEffect(() => {
  //   //console.log("🐞NavBar ready!");
  // });

  // return(
  //   <ion-header translucent>
  //     <ion-toolbar>
  //       <ion-buttons slot="start">
  //         <ion-back-button defaultHref="/">返回</ion-back-button>
  //       </ion-buttons>
  //       <ion-title></ion-title>
  //       {/*<ion-title>${this.values.title}</ion-title>*/}
  //       <ion-buttons slot="end" className="right-buttons">
  //
  //       </ion-buttons>
  //     </ion-toolbar>
  //   </ion-header>
  // );

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton text="" defaultHref="/" onClick={(e)=>{
            //for IonNav
            //为 IonBackButton 添加一个 onClick 事件, 可以防止 IonBackButton 默认的 Router 导航 (e.preventDefault 可选)
            e.preventDefault();
          }}/>
        </IonButtons>
        <IonTitle></IonTitle>
        <IonButtons slot="end">
          {/*<IonButton className="menu-button">*/}
            {/*<IonIcon className="color-dark" slot="icon-only" icon={ellipsisHorizontal}/>*/}
          {/*</IonButton>*/}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default NavBar;