import $ from "jquery";
import {useEffect, useRef, useState} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import {IframeNavPresentPage} from '../../../tools/uikit/IframeNav'
import './CurveView.css';
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react/dist/index";
import {ellipsisHorizontal, chevronForward, lockClosed} from "ionicons/icons/index";
import * as echarts from 'echarts';
import * as Tools from "../../../tools/Tools";
import * as ColorSuits from "../../../theme/ColorSuits.js";
import Curve from "./Curve";
import * as Extension from "../../../tools/Extension.js";
import StudyDataController from "../../../tools/StudyDataController";
import Alert from "../../../tools/Alert";
import TimeController from "../../../tools/TimeController";

//🍉instance
var instance = {
  showUP : function () {
    IframeNavPresentPage(CurveView, "CurveView", false);
  }
};
Object(window.top).CurveView = instance;

function initCharts(DOM) {

  var mDom = DOM;
  let stack_titles = {
    "memo_untracked":"未记",
    "memo_start":"新记",
    "memo_known":"巩固",
    "memo_familiar":"熟悉",
    "memo_danger":"需要复习",
  };
  let stack_colors = ColorSuits["standard-red"];

  var mChartWords = [];
  function tryRefreshChartsData() {
    tryInitChart();

    //charts
    mChartWords = StudyDataController.allNewAddWordsUnique.filter(function (word) {
      return word.MemoModel.MemoType != 'memo_untracked';
    });
    let word_curve_strings = mChartWords.map(function (word) {
      return word.curve_points;
    })

    let options = {
      // "color_suit" : "standard-dark",
      // "list_name": window.top.WordsVCor.getCurrentList().list_name,
      // "option_order":Settings.mOptionWordsOrder,
      // "option_start_time":Settings.mOptionStartTime,
      // "option_repeat_time":Settings.mOptionRepeatTime,
      // "option_memo": Settings.mOptionMemos.length == 1 ? Settings.mOptionMemos[0] : "memo_all",
    }

    setupCharts(options, word_curve_strings);

    //库存
    // let infos = {
    //   "memo_stack":mTempMemoStack,
    //   "memo_behaviour":mTempBehavior
    // }
    // if (mChartsNav.getIframeWindow().updateInfo != undefined){
    //   mChartsNav.getIframeWindow().updateInfo(infos);
    // }
  }

  //🍋初始化记忆图
  var mCurveChartDom;
  var mCurveChartOption;
  var mCurveChart = null;

  function tryInitChart() {
    if (mCurveChart == null){
      mCurveChartDom = mDom.find("#charts-container");
      mCurveChartOption = {};
      mCurveChart = echarts.init(mCurveChartDom[0],
        {
          "width":mCurveChartDom.width(),
          "height":mCurveChartDom.height(),
          renderer: 'canvas'
        }
      );

      //@点击事件
      mCurveChart.on('click', function (params) {

        //console.log("🐞点击Chart:",params['seriesType']);

        //🍓点击Bar
        if (params['seriesType'] === "bar"){

          //刷新显示 points
          let serie_index = params['dataIndex'];

          setupPointsBy(serie_index);

          applyChartsOption();

          //自动隐藏 Tooltip
          setTimeout(function () {
            mCurveChart.dispatchAction({
              type: 'hideTip'
            });
          },2000);
        }

        //🍓点击日历
        if (params['seriesType'] === 'heatmap'){
          //刷新显示 points
          let serie_index = params['dataIndex'];
          setupPointsBy(serie_index);
          applyChartsOption();
        }

        //🍓点击散点
        if (params['seriesType'] === "scatter"){

          highlightByPoint(params['data']);

          //显示总量
          let points = params['data'][3];
          onCallDevice("didClickPoints", points);
        }
      });

      //@Brush
      mCurveChart.on('brush', function (params) {
        let command = params.command;
        //console.log("🐞click brush:",command);
        if (command !== undefined && command === 'clear'){
          //console.log("🐞onBrushClear");

          clearHighlight();

          //🍓模拟点击散点 -> 清除
          onCallDevice("didClickPoints", []);
        }
      });
      var selected_points = [];
      mCurveChart.on('brushselected', function (params) {
        selected_points = [];

        let selected_indexs = params['batch'][0]['selected'][0]['dataIndex'];
        $.each(selected_indexs, function (i, si) {
          let group = mRandomPoints[si];
          if (group !== undefined && group.length >= 4){
            let group_points = group[3];
            if (group_points !== undefined){
              selected_points = selected_points.concat(group_points);
            }
          }
        });

        //console.log("🐞onBrushSelect:",selected_indexs,selected_points,mRandomPoints);
      });

      mCurveChart.on('brushend', function (params) {
        //🍓模拟点击散点
        setTimeout(function () {
          //console.log("🐞onBrushend:",params);
          if (selected_points.length > 0){
            onCallDevice("didClickPoints", selected_points);
          }
        },500);
      });



      //@DataZoom
      mCurveChart.on('datazoom',function (parmas) {
        //@Depracated!
        // let zoom_id = parmas['dataZoomId'];
        // if (zoom_id !== undefined && zoom_id === 'points_zoom'){
        //     setDataZoom(parmas);
        // }

        //拖动datazoom inside 触发的 parmas 包含在 batch 里
        if (parmas['batch'] !== undefined){ parmas = parmas['batch'][0]; }

        setDataZoom(parmas);
      });

    }
  }

  //🍋渲染记忆图
  var mGroupedWords;

  //🍓渲染网格
  function setupBaseGrid() {
    mCurveChartOption = {
      dataZoom : [],
      grid: [],
      xAxis: [],
      yAxis: [],
      series: [],

      // //性能优化
      // animation:true,
      // animationDuration:300,
      // animationDurationUpdate:300,
      // animationEasingUpdate:300,
      // animationEasing:'linear',
      // animationDelay:0,

      animation:false,
      animationDurationUpdate:0,

      toolbox: {
        top:8,
        right:10,
        itemSize:25,
        itemGap:5,
        iconStyle: {
          normal: {
            opacity:0.2,
          },
          emphasis: {
            opacity:1,
          }
        },
        feature:{
          brush:{
            show: true,
            type:[
              'polygon',
              'clear',
            ],
            icon:{
              polygon:"image://assets/icons/charts/icon_select.png",
              clear:"image://assets/icons/charts/icon_clear.png",
            },
            onclick: function (){
              alert('myToolHandler1')
            }
          },
          // dataView:{
          //     show:true,
          // },
        },
        showTitle:false,
      },

      brush: {
        brushMode:'multiple',
        removeOnClick:false,

        brushStyle: {
          borderWidth: 2,
          color: 'RGBA(22, 129, 251, 0.20)',
          borderColor: '#1681FB',
          borderType:'dashed',
        },
        inBrush: {
          colorAlpha:1,
        },
        outOfBrush: {
          colorAlpha:3,
        },

        seriesIndex: [0],
        geoIndex: 0,
        throttleType: 'debounce',
        throttleDelay: 300,
      },
    };

    mCurveChartOption.title = [];

    //toolTip
    // mCurveChartOption.tooltip = {
    //     trigger: 'axis',            // 坐标轴指示器，坐标轴触发有效
    //     axisPointer : {
    //         type : 'none'           // 默认为直线，可选为：'line' | 'shadow'
    //     },
    //     position:[10, '63%'],
    //     triggerOn:'click'
    // };

    // //legend
    // mCurveChartOption.legend = {
    //     data:['新记','巩固','熟悉','需要复习','未记'],
    //     top:'5',
    //     left:'10',
    //     itemWidth:10,
    //     itemHeight:10,
    //     textStyle:{
    //         fontSize:10
    //     }
    // };

  }

  //🍓渲染遗忘曲线
  function setupDeadlineAndSafeArea(isShowAllLines) {

    var max = 0;
    if (mGroupedWords !== undefined && mGroupedWords['valid_words'] !== undefined){
      $.each(mGroupedWords['valid_words'], function (index, one_group) {
        if (one_group['max_points_distance'] !== undefined && one_group['max_points_distance'] > max){
          max = one_group['max_points_distance']+1;
        }
      });
    }
    if (max !== 0){
      max = max+30;//修复无法渲染完整
    }


    var data_a = [];
    var data_b = [];
    var data_c = [];
    var data_d = [];
    var data_e = [];
    var data_f = [];
    var data_g = [];
    for (var i=0; i<=max*3; i++){
      data_a.push([i/3,1/Math.pow(5,i/3)*(100-21)+21]);//0-1
      data_b.push([i/3+1,1/Math.pow(5,i/(3*1))*(100-25)+25]);//1-2
      data_c.push([i/3+2,1/Math.pow(5,i/(3*2))*(100-29)+29]);//2-4
      data_d.push([i/3+4,1/Math.pow(5,i/(3*3))*(100-34)+34]);//4-7
      data_e.push([i/3+7,1/Math.pow(5,i/(3*7.3))*(100-38)+38]);//7-15
      data_f.push([i/3+15,1/Math.pow(5,i/(3*13))*(100-40)+40]);//15-30
      data_g.push([i/3+30,1/Math.pow(5,i/(3*30.3))*(100-55)+55]);//30 - ∞
    }

    mCurveChartOption.series = mCurveChartOption.series.concat([
      {
        id: 'safe_area',
        data: [[0,50],[max,50]],
        type: 'line',
        lineStyle:{
          color:"transparent"
        },
        areaStyle: {
          //纹理填充
          color: "#33e092",
          origin:'end',
          opacity:0.1,
        },
        symbolSize: 0,
        silent:true,  //忽略点击事件
      }
    ]);

    if (isShowAllLines){
      mCurveChartOption.series = mCurveChartOption.series.concat([
        {
          id: 'deadline',
          type: 'line',
          smooth: true,
          smoothMonotone:"none",
          lineStyle:{
            width: 1.5,
            color:"gray",
            type:"dotted",
            opacity:0.3
          },
          symbolSize: 0,
          // areaStyle: {
          //     color: '#404040',
          //     opacity:0.1
          // },
          data: data_a,
          silent:true,  //忽略点击事件
        },
        {
          id: 'deadline_a',
          type: 'line',
          smooth: true,
          smoothMonotone:"none",
          lineStyle:{
            width:1.5,
            color:"gray",
            type:"dotted",
            opacity:0.3,
          },
          symbolSize: 0,
          data: data_b,
          silent:true,  //忽略点击事件
        },
        {
          id: 'deadline_aa',
          type: 'line',
          smooth: true,
          smoothMonotone:"none",
          lineStyle:{
            width:1.5,
            color:"gray",
            type:"dotted",
            opacity:0.3,
          },
          symbolSize: 0,
          data: data_c,
          silent:true,  //忽略点击事件
        },
        {
          id: 'deadline_b',
          type: 'line',
          smooth: true,
          smoothMonotone:"none",
          lineStyle:{
            width:1.5,
            color:"gray",
            type:"dotted",
            opacity:0.3,
          },
          symbolSize: 0,
          data: data_d,
          silent:true,  //忽略点击事件
        },
        {
          id: 'deadline_c',
          type: 'line',
          smooth: true,
          smoothMonotone:"none",
          lineStyle:{
            width:1.5,
            color:"gray",
            type:"dotted",
            opacity:0.3,
          },
          symbolSize: 0,
          data: data_e,
          silent:true,  //忽略点击事件
        },
        {
          id: 'deadline_d',
          type: 'line',
          smooth: true,
          smoothMonotone:"none",
          lineStyle:{
            width:1.5,
            color:"gray",
            type:"dotted",
            opacity:0.3,
          },
          symbolSize: 0,
          data: data_f,
          silent:true,  //忽略点击事件
        },
        {
          id: 'deadline_e',
          type: 'line',
          smooth: true,
          smoothMonotone:"none",
          lineStyle:{
            width:1.5,
            color:"gray",
            type:"dotted",
            opacity:0.3,
          },
          symbolSize: 0,
          data: data_g,
          silent:true,  //忽略点击事件
        },
      ]);
    }

  }

  //🍓渲染日历
  function setupCalendar() {

    let all_valid_words = Object.clone(mGroupedWords['valid_words']);

    var data = [];

    var last_day_learned_words_num = 0;

    $.each(all_valid_words.reverse(), function (index, day_points) {
      let day_string = day_points.time_string.split(" ")[0];
      let stack_infos = day_points.stack;

      let this_day_learned_words_num = stack_infos.memo_start + stack_infos.memo_known + stack_infos.memo_familiar;
      let diff = this_day_learned_words_num - last_day_learned_words_num;
      data.push([day_string, diff]);

      last_day_learned_words_num = this_day_learned_words_num;
    });

    mCurveChartOption.calendar = [
      {
        bottom: 20,
        left: 25,
        right: 20,
        height:62,

        range: ['2019-06-01', '2019-12-30'],
        splitLine: {
          show: true,
          lineStyle: {
            color: 'white',
            width: 3,
            type: 'solid'
          }
        },
        monthLabel: {
          nameMap: 'cn',
          margin: 2,
          fontSize:7,
          color:'#C1C1C1',
        },
        dayLabel:{
          firstDay: 1, // 从周一开始
          nameMap: 'cn',
          fontSize:6,
          color:'#C1C1C1',
        },
        itemStyle: {
          normal: {
            color: '#e5e5e5',
            borderWidth: 1,
            borderColor: '#ffffff'
          }
        }
      }
    ];
    mCurveChartOption.visualMap = {
      type:'piecewise',
      seriesIndex: mCurveChartOption.series.length,
      //selectedMode:'single',
      bottom:0,
      left:'center',
      height:80,
      orient:'horizontal',
      itemWidth:8,
      itemHeight:8,
      textStyle:{
        //color:'RGBA(240, 240, 240, 1.00)',
        fontSize:8,
      },
      textGap:2,
      itemGap:5,

      pieces: [
        {min: 700, label: '过度记忆', color:'#752843'},
        {min: 300, max: 700, label: '大量记忆', color: '#142729'},
        {min: 100, max: 300, label: '适量记忆', color: '#159962'},
        {min: 0, max: 100, label: '轻度记忆', color: '#90D5A0'},
        {min:-100, max: 0, label: '轻度遗忘', color: '#FECF56'},
        {max: -100, label: '遗忘过多', color: '#EB573E'}
      ],
      show: true,

    };
    mCurveChartOption.series = mCurveChartOption.series.concat([
      {
        name: '',
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: data,
        symbolSize: function (serie) {
          return 6;
        },
        // itemStyle: {
        //     normal: {
        //         color: '#ddb926'
        //     }
        // }
      },
    ]);
  }

  //🍓渲染Bars
  function setupBars() {
    //console.log("🐞mGroupedWords:",mGroupedWords);

    let all_valid_words = mGroupedWords['valid_words'];
    let all_valid_behaviours = mGroupedWords['valid_behaviours'];
    let display_days = mGroupedWords['all_days'];
    let bar_width = (window.innerWidth / (display_days.length-1)) - 5;
    if (bar_width > 25){
      bar_width = 25;
    }

    //grid
    let bars_top_position = 150;
    let bars_height = 55;
    mCurveChartOption.grid = mCurveChartOption.grid.concat([
      {
        left: '25px',
        right: '15px',
        top: bars_top_position,
        height:bars_height,
      }
    ]);

    //title
    mCurveChartOption.title = mCurveChartOption.title.concat([{
      text:(function () {
        let today_string = "暂无记忆数据";
        if (display_days.length > 0){
          today_string = "每日遗忘和复习总量";
        }
        return today_string;
      })(),
      textStyle:{
        color:"#1d1d1d",
        fontSize:11,
        fontWeight:600,
      },
      top:bars_top_position + bars_height + 20,
      left:10,
    }]);

    //xAxis
    mCurveChartOption.xAxis = mCurveChartOption.xAxis.concat([
      {
        id:'dateAxis',
        gridIndex: 1,
        type : 'category',
        data: display_days,

        boundaryGap: false,//从0开始

        min: 'dataMin',
        max: display_days.length,
        axisTick:{
          show:false,
        },
        axisLabel: {
          fontSize:8,
          color:"gray",
          minInterval:0,
          margin:5,
          formatter: function (value, index) {
            if (value !== undefined && value.includes(" ")){
              let breaks = value.split(" ")[0].split("-");
              return breaks[1]+"."+breaks[2];
            }
            return "";
          }
        },
        axisLine:{
          onZero: false,
          lineStyle:{
            color:"transparent"
          },
        },
        z:100
      },

    ]);

    //yAxis
    mCurveChartOption.yAxis = mCurveChartOption.yAxis.concat([
      {
        gridIndex: 1,
        //name:"记忆总量",
        min: 0,
        max: (function () {
          var max_total = 0;
          all_valid_words.map(function (group) {
            let stack = group['stack'];
            let total = stack['total'];
            if (total > max_total){
              max_total = total;
            }
          });
          return max_total+10;
        })(),
        splitLine:{
          show:true,
          lineStyle:{
            color:"whitesmoke",
            type:"dotted",
          }
        },

        type: 'value',
        nameTextStyle:{
          fontSize:8,
        },
        axisTick:{
          show:false
        },
        axisLabel:{
          fontSize:8,
          color:"transparent",
          inside:true,
          margin:-5,
        },
        axisLine:{
          onZero: false,
          lineStyle:{
            color:"transparent"
          }
        },
      }
    ]);

    let bar_stacks = {
      "memo_familiar":[],
      "memo_known":[],
      "memo_start":[],
      "memo_danger":[],
      "memo_untracked":[],
    };
    let line_stacks = {
      "memo_known":[],
    }
    let totals = [];

    //学习量
    all_valid_behaviours.map(function (group) {
      line_stacks['memo_known'].push(group['start_memo'] + group['repeat_memo']);
    })
    //每日单词分布
    all_valid_words.map(function (group) {
      let stack = group['stack'];

      bar_stacks['memo_danger'].push(stack['memo_danger']);
      bar_stacks['memo_familiar'].push(stack['memo_familiar']);
      bar_stacks['memo_known'].push(stack['memo_known'] + stack['memo_start']);

      // @Depracated!
      // stacks['memo_known'].push(stack['memo_known']);
      // stacks['memo_start'].push(stack['memo_start']);

      totals.push(stack['memo_start'] + stack['memo_known'] + stack['memo_familiar'] + stack['memo_danger']);
    });

    var line_index = 10;
    $.each(bar_stacks, function (stack_index, stack_data) {
      //bar
      let this_bar_option = {
        name: stack_titles[stack_index],
        type:'bar',
        stack:'memo_bars',
        data:stack_data,
        xAxisIndex: 1,
        yAxisIndex: 1,
        barWidth:bar_width,
        itemStyle:{
          color:stack_colors[stack_index],
          opacity:0.65,
          borderWidth:1,
          borderColor:"transparent",
          borderRadius: (function () {
            // if(stack_index == "memo_danger"){
            //   return [3, 3, 1, 1];
            // }else{
            //   return [1, 1, 1, 1];
            // }
            return [2, 2, 2, 2];
          })()
        },
        z:1,
      };

      //label
      if (stack_index == "memo_danger"){

        this_bar_option.label = {
          show:true,
          position:'top',
          fontSize:8,
          color:"#5f5f5f",
          //backgroundColor:"white",
          //padding: 5,
          borderRadius: 10,
          formatter:function (point, data) {
            //过窄
            let point_index = point['dataIndex'];
            if (bar_width < 20 ){
              if (point_index == 0 || point_index == totals.length-1){
                return totals[point_index]+"词";
              }else{
                return "";
              }
            }

            return totals[point_index]+"词";
          }
        };
        this_bar_option.symbol = 'circle';
        this_bar_option.symbolSize = 5;
        this_bar_option.symbolOffset = [0,'-120%'];
      }
      mCurveChartOption.series = mCurveChartOption.series.concat([this_bar_option]);

      //line
      let line_leval = {
        "memo_start":1,
        "memo_known":2,
        "memo_familiar":3
      }
      var symbol_index = 0;
      if (stack_index == "memo_known"){
        let this_line_option = {
          name: stack_titles[stack_index],
          type:'line',
          //stack:'memo_lines',
          data:line_stacks["memo_known"],
          xAxisIndex: 1,
          yAxisIndex: 1,
          lineStyle:{
            width:2,
            opacity:1,
            type:'solid',
            color:stack_colors[stack_index],

            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: line_leval[stack_index] * 1,
            shadowOffsetY: line_leval[stack_index] * 1,
          },

          symbol:'circle',
          symbolSize: function (serie) {
            symbol_index ++;
            if (symbol_index % display_days.length == 1 || symbol_index % display_days.length == 0){
              return 4;
            }else{
              return 0;
            }
          },
          itemStyle:{
            borderType:'dotted',
            color:stack_colors[stack_index],

            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: line_leval[stack_index] * 1,
            shadowOffsetY: line_leval[stack_index] * 1,
          },

          // areaStyle: {
          //     color:stack_colors[stack_index],
          //     opacity:0.15,
          // },

          smooth: true,
          smoothMonotone:'x',

          z:line_index,
        };
        line_index = line_index-1;
        // if (stack_index == "memo_start"){
        //     this_line_option.label = {
        //         show:true,
        //         position:'top',
        //         fontSize:8,
        //         color:"#C1C1C1",
        //         //backgroundColor:"white",
        //         //padding: 5,
        //         borderRadius: 10,
        //         formatter:function (point, data) {
        //             let point_index = point['dataIndex'];
        //             return totals[point_index];
        //         }
        //     };
        //     this_line_option.symbol = 'circle';
        //     this_line_option.symbolSize = 5;
        //     this_line_option.symbolOffset = [0,'-120%'];
        // }
        mCurveChartOption.series = mCurveChartOption.series.concat([this_line_option]);
      }
    });
  }

  //🍓渲染记忆点
  var mRandomPoints;
  function setupPointsBy(GroupIndex) {

    if (mGroupedWords === undefined){
      return;
    }

    let all_valid_words = mGroupedWords['valid_words'];
    let display_days = mGroupedWords['all_days'];

    //分组
    let this_group_points = [];
    let this_group_max_points_distance = 0;

    let this_day_string = display_days[GroupIndex];

    all_valid_words.filter(function (group) {
      return group['time_string'] === this_day_string;
    }).map(function (Group) {
      let points = Group['points'];
      this_group_max_points_distance = Group['max_points_distance'];
      this_group_points = this_group_points.concat(points);
    });

    //散布
    let random_positions = [];
//console.log("🐞this_group_points:",this_group_points);
    //位置相近->归组
    $.each(this_group_points, function (point_index, point) {

      let x = point['distance'] + point['start_distance'];
      let y = point['value'];
      let memo_type = point['memo_type'];
      //@TODO
      //let repeat_times = point['repeat_times'];

      let existed_points = random_positions.filter(function (one_point) {
        //横向距离 纵向距离 复习次数
        return Math.abs(one_point[0] - x) < 0.5 && Math.abs(one_point[1] - y) < 10 && one_point[4] == memo_type;
        //@TODO
        //&& one_point[4] == repeat_times;
      });

      //random_position
      //  0 => x
      //  1 => y
      //  2 => size
      //  3 => point

      if (existed_points.length > 0){
        let max_existed_point = existed_points[0];
        let index = random_positions.indexOf(max_existed_point);
        random_positions[index][2] = random_positions[index][2]+1; //size
        random_positions[index][3].push(point);                    //points
      }
      else{
        let random_position = [x, y];   //position
        random_position[2] = 1;         //size
        random_position[3] = [point];   //points
        random_position[4] = memo_type; //memo_type
        random_positions.push(random_position);
        //@TODO
        //random_position[4] = [repeat_times];
      }
    });

    // //重新散布过少的分组
    // let randon_points_for_loop = random_positions;
    // $.each(randon_points_for_loop, function (index, random_point) {
    //     let size = random_point[2];
    //     if (size < 5){
    //         let points = random_point[3];
    //
    //         //remove
    //         let random_point_index = random_positions.indexOf(random_point);
    //         random_positions.splice(random_point_index,1);
    //
    //         //add
    //         $.each(points, function (index, point) {
    //
    //             let random_position = [point['distance']+point['start_distance'], point['value']];
    //
    //             random_position[0] += (Math.random(0, 1)-0.5)/2;
    //             random_position[1] -= (Math.random(0, 1)-0.5)*10;
    //             random_position[2] = 1;
    //             random_position[3] = [point];
    //
    //             random_positions.push(random_position);
    //         });
    //     }
    // });

    {
      var ordered_points = [];
      for (let memo_type of ["memo_danger","memo_start","memo_known","memo_familiar"].reverse()){
        for (let points of random_positions){
          if (points[4] == memo_type){
            ordered_points.push(points);
          }
        }
      }
      random_positions =  ordered_points;
    }
    mRandomPoints = random_positions;

    //开始渲染
    //grid
    mCurveChartOption.grid[0] = {
      left:'16px',
      width: $("#container").width()-32,
      top:'10px',
      height:'90',
    };

    //title
    let existed_title_index;
    $.each(mCurveChartOption.title, function (title_index, title) {
      if (title['id'] === "this_day_title"){
        existed_title_index = title_index;
      }
    });
    if (existed_title_index !== undefined){
      mCurveChartOption.title.splice(existed_title_index,1);
    }

    mCurveChartOption.title = mCurveChartOption.title.concat([{
      id:"this_day_title",
      text:(function () {
        let info = "暂无记忆数据";
        if (this_day_string !== undefined){
          let day_string = this_day_string.split(" ")[0];
          let month = day_string.split("-")[1];
          let day = day_string.split("-")[2];
          if (TimeController.getTimeStringByDay().includes(day)){
            info = "今日遗忘详情";
          }else{
            info = month+"."+day+"日 遗忘详情";
          }
        }
        return info;
      })(),
      textStyle:{
        color:"#1d1d1d",
        fontSize:11,
        fontWeight:600,
      },
      top:105,
      left:12,
    }]);

    //xZoom dataZoom
    mCurveChartOption.dataZoom[0] = {
      type: 'inside',
      xAxisIndex: 0,
      filterMode: 'none',

      //性能优化
      throttle:50,
      //zoomOnMouseWheel:false,
      //moveOnMouseWheel:false,
      //* moveOnMouseMove:false,
      //* preventDefaultMouseMove:true,

      startValue:0,
      endValue:8,
    };

    applyDataZoom();

    mCurveChartOption.dataZoom[1] = {
      id:'points_zoom',
      type: 'slider',
      xAxisIndex: 0,
      filterMode: 'none',

      show:false,

      left:180,
      right:16,
      top:$("#viewport_filter").position().top+12,
      height:20,

      showDataShadow:false,
      backgroundColor:'whitesmoke',
      fillerColor:"#60d89a",
      borderColor:'transparent',
      handleIcon: 'M 200 100 A 50 50 0 1 1 400 100 A 50 50 0 1 1 200 100 Z',
      handleSize: '110%',
      handleStyle: {
        color: 'white',
        shadowBlur: 3,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        shadowOffsetX: 0,
        shadowOffsetY: 1,
        // borderWidth:1,
        // borderColor:"whitesmoke",
      },
      showDetail:false,

      //性能优化
      throttle:200,
    };

    //xAxis
    mCurveChartOption.xAxis[0] = {

      gridIndex: 0,
      min: -0.01,
      max: (function () {
        if (this_group_max_points_distance < 30){
          return 30;
        }else{
          return this_group_max_points_distance;
        }
      })(),
      splitLine:{
        show:true,
        lineStyle:{
          color:"whitesmoke"
        }
      },


      minInterval:1.0,
      //maxInterval:1.0,

      boundaryGap: false,

      type: 'value',
      axisTick:{
        show:false,
      },
      axisLabel:{
        fontSize:8,
        color:"gray",
        formatter: function (value, index) {
          if (value === 0){
            return "今天";
          }
          else if (value === 1){
            return "昨天";
          }
          else if (value === 2){
            return "前天";
          }
          else{
            return (value)+"天前";
          }
        },
        inside:true,
        margin:2,
        padding:[0,0,0,20],
      },
      axisLine:{
        lineStyle:{
          color:"whitesmoke"
        },
      },
      //隐藏ToolTip
      axisPointer:{
        show:false
      }
    };

    //yAxis
    mCurveChartOption.yAxis[0] = {
      gridIndex: 0,
      //name:"记忆程度",
      min: 0,
      max: 100.01,
      //splitNumber:10,
      minInterval:25,
      splitLine:{
        show:true,
        lineStyle:{
          //color:"whitesmoke"
          color:"transparent"
        }
      },
      type: 'value',
      nameTextStyle:{
        fontSize:8,
      },
      axisTick:{
        show:false
      },
      axisLabel:{
        fontSize:8,
        color:"gray",
        inside:true,
        margin:2,
        padding:[12,0,0,0],
        formatter:function (value, index) {
          if(value === 0){
            return '';
          }else{
            return value+" %";
          }
        }
      },
      axisLine:{
        onZero: false,
        lineStyle:{
          color:"whitesmoke"
        }
      },
    };

    //scatter
    let existed_scatter_index;
    $.each(mCurveChartOption.series, function (serie_index, serie) {
      if (serie['name'] === "当日记忆点"){
        existed_scatter_index = serie_index;
      }
    });
    if (existed_scatter_index !== undefined){mCurveChartOption.series.splice(existed_scatter_index,1);}
//console.log("🐞random_positions:",random_positions);


    mCurveChartOption.series = [
      {
        name:'当日记忆点',
        type:'scatter',
        data: random_positions,
        itemStyle:{
          color:function (serie) {
            //@Depracated!
            // let distance = serie.data[0];
            // let value = serie.data[1];
            // let this_color;
            // if (value <= 50){
            //     this_color = stack_colors['memo_danger'];
            // }else{
            //     if (distance<1){
            //         this_color = stack_colors['memo_start'];
            //     }else if (distance < 7){
            //         this_color = stack_colors['memo_known'];
            //     }else{
            //         this_color = stack_colors['memo_familiar'];
            //     }
            // }

            let memo_type = serie.data[4];
            return stack_colors[memo_type];

            //TODO
            // return new echarts.graphic.RadialGradient(0.4, 0.3, 1, [{
            //     offset: 0,
            //     color: this_color
            // }, {
            //     offset: 1,
            //     color: this_color
            // }])
          },
          opacity:0.85,
          shadowColor: 'rgba(0, 0, 0, 0.3)',
          shadowBlur: 5,
          shadowOffsetY:3,
        },
        label:{
          show:true,
          fontSize:9,
          formatter:function (point) {
            let size = point.data[2];
            if (size > 1){
              return size+'词';
            }
            return '';
          },
          color:"white",
          borderColor:'transparent',
          shadowColor:'transparent',
          textBorderColor:'transparent',
          textShadowColor:'transparent'
        },
        symbolSize:function (serie) {
          let size = serie[2];
          if (size === undefined || size <= 1){
            return 15;
          }else if (size < 10){
            return 20;
          }else if (size < 30){
            return 25;
          }else if (size < 50){
            return 30;
          }else if (size < 70){
            return 35;
          }else if (size < 90){
            return 40;
          }else{
            return 50;
          }
        },
      },
    ].concat(mCurveChartOption.series);
  }

  //🍓渲染高亮点
  function setupPointHighlighted(){
    mCurveChartOption.series = mCurveChartOption.series.concat([
      {
        id:"highlighted_points",
        type: 'effectScatter',
        silent: true,
        data: [
          //[1.2, 80]
        ],
        symbolSize:20,
        itemStyle:{
          opacity:0.7,
          color:function (serie) {
            let point = serie['data'];
            let distance = point[0];
            let value = point[1];

            if (distance<1){
              return stack_colors['memo_start'];
            }else if (value < 50){
              return stack_colors['memo_danger'];
            }else if (distance < 7){
              return stack_colors['memo_known'];
            }else{
              return stack_colors['memo_familiar'];
            }
          },
          shadowColor: 'rgba(0, 0, 0, 0.2)',
          shadowBlur: 3,
          shadowOffsetY:2,
        },
      }
    ]);
  }


  /*🍓开始渲染
   * @params:isAll, words, infos
  */
  var mWordCurves;
  function setupCharts(Options, WordCurves){

    Tools.triger_call_device('stopLoading');

    let color_suit = Options['color_suit'];
    let list_name = Options['list_name'];
    let option_order = Options['option_order'];
    let option_start_time = Options['option_start_time'];
    let option_repeat_time = Options['option_repeat_time'];
    let option_memo = Options['option_memo'];

    //颜色
    //stack_colors = ColorSuits[color_suit];
    // $("body").append($('<style>#chart_wrapper .infos_div .info .sub_info.memo_untracked:after{background-color:'+stack_colors['memo_untracked']+';}</style>'));
    // $("body").append($('<style>#chart_wrapper .infos_div .info .sub_info.memo_start:after{background-color:'+stack_colors['memo_start']+';}</style>'));
    // $("body").append($('<style>#chart_wrapper .infos_div .info .sub_info.memo_known:after{background-color:'+stack_colors['memo_known']+';}</style>'));
    // $("body").append($('<style>#chart_wrapper .infos_div .info .sub_info.memo_familiar:after{background-color:'+stack_colors['memo_familiar']+';}</style>'));
    // $("body").append($('<style>#chart_wrapper .infos_div .info .sub_info.memo_danger:after{background-color:'+stack_colors['memo_danger']+';}</style>'));

    //单词本名称
    $("#viewport_filter .title").text(list_name);

    //刷新选项
    $(".item").removeClass('active');
    $(".item[title='"+option_start_time+"']").addClass('active');
    $(".item[title='"+option_repeat_time+"']").addClass('active');
    $(".item[title='"+option_memo+"']").addClass('active');

    //显示总量
    mDom.find("#enter_filter").addClass("empty");
    // if (WordCurves.length > 0){
    //   mDom.find("#enter_filter").removeClass("empty");
    //   mDom.find("#enter_filter .action").text("已选择"+WordCurves.length+"个单词")
    // }else{
    //   mDom.find("#enter_filter").addClass("empty");
    //   mDom.find("#enter_filter .action").text("没有单词")
    // }

    //渲染图标
    let curve_points = [];
    $.each(WordCurves, function (index, WordCurve) {
      if (WordCurve !== ""){
        //显示模拟遗忘后的结果
        curve_points.push(
          JSON.parse(WordCurve)
        );
      }
    });
    mWordCurves = curve_points;

    regroupWordsThenRefreshCharts(30);
  }
  window.setupCharts = setupCharts;

  //🍓刷新显示
  function regroupWordsThenRefreshCharts(Day) {


    //make Grouped Words
    mGroupedWords = Curve.makeGroupedWords(mWordCurves, Day);

    //ui
    $(".unlock_days").text(3-mGroupedWords.all_days.length);
    if (mGroupedWords.all_days.length < 3){
      $("#expand_container_button").removeClass('hidden');
      updateLayout(1);
    }else{
      $("#expand_container_button").addClass('hidden');
      updateLayout(Day);
    }


    //console.log("🐞mGroupedWords:",mGroupedWords);

    //开始渲染
    setupBaseGrid();
    setupDeadlineAndSafeArea(true);
    setupPointHighlighted();

    //1.
    setupPointsBy(0);

    //2.
    if (Day > 1){
      setupBars();
    }


    //3.
    //setupCalendar();

    applyChartsOption();
  }
  function applyChartsOption() {
    applyDataZoom();
    mCurveChart.setOption(mCurveChartOption, true, true);
  }
  function updateLayout(Day) {
    if (Day > 1){
      $("#container_card").css({'height':'245px'});
      $("#expand_container_button").css({'display':'none'});
      $("#today_info").css({'display':'none'});
    }else{
      $("#container_card").css({'height':'138px'});
      $("#expand_container_button").css({'display':'inline-block'});
      $("#today_info").css({'display':'block'});
    }
  }

  $("#expand_container_button").click(function () {
    Tools.triger_call_device('startLoading');//🔥start loading
    setTimeout(function () {
      regroupWordsThenRefreshCharts(30);
      Tools.triger_call_device('stopLoading');//🔥stop loading
    },50);
  });


  //🍋高亮标记点
  function highlightByPoint(Point) {
    $.each(mCurveChartOption.series, function (serie_index, serie) {
      if (serie.id === "highlighted_points"){
        serie.data = [[Point[0],Point[1]]];

        mCurveChartOption.dataZoom[0].startValue = 0;
        mCurveChartOption.dataZoom[0].endValue = Point[0] + 1;
        mCurveChart.setOption(mCurveChartOption, true, true);
      }
    });
  }
  function highlightByWord(WordString,ParentListUnicoID){
    let find_random_point;

    $.each(mRandomPoints, function (i,random_point) {
      let points = random_point[3];
      if (points !== undefined){
        $.each(points, function (o, point) {
          if (point['word'] === WordString && point['parent_list_unico_id'] === ParentListUnicoID){
            find_random_point = random_point;
          }
        });
      }
    });

    if (find_random_point !== undefined){
      highlightByPoint(find_random_point);
    }
  }
  function clearHighlight(Point) {
    $.each(mCurveChartOption.series, function (serie_index, serie) {
      if (serie.id === "highlighted_points"){
        serie.data = [];
        applyChartsOption();
      }
    });
  }

  //🍉点击控件

  //调整显示范围
  $("#viewport_filter .item").click(function () {

    //highlight
    $("#viewport_filter .item").removeClass('active');
    $(this).addClass('active');

    //handle
    let type = $(this).attr('title');
    if (type == "all"){
      mCurveChart.dispatchAction({
        type: 'dataZoom',
        dataZoomIndex: 0,
        start:0,
        end:100
      });
    }else{
      let days = parseInt(type);
      mCurveChart.dispatchAction({
        type: 'dataZoom',
        dataZoomIndex: 0,
        startValue:0,
        endValue:days
      });
    }
  });

  //查看单词
  $("#enter_filter").click(function () {
    if (mSelectedWordsStrings.length <= 0){
      Alert.showWarningAlert("暂时无法打开","没有找到选中的单词");
    }else{
      if (window.top.WordsViewController){
        let points_words = mChartWords.filter(function (word) {
          return mSelectedWordsStrings.includes(word['word_string']);
        });
        StudyDataController.mSelectedWords = points_words;

        window.top.WordsViewController.showUP(-5,0);
      }
    }
  });


  //🍉Setting
  //DataZoom范围
  var mZoom = null;
  function setDataZoom(paramas){
    mZoom = paramas;
    setStorage('DATAZOOM-POINTS', JSON.stringify(paramas));
  }
  function getDataZoom(){
    if (mZoom != null){
      return mZoom;
    }

    let zoom_string = getStorageBy('DATAZOOM-POINTS');
    if (zoom_string === undefined || zoom_string === null){
      return null;
    }else{
      let zoom = JSON.parse(zoom_string);
      if (zoom === undefined || zoom === null){
        return null;
      }else{
        return zoom;
      }
    }
  }
  function applyDataZoom(){
    //applay option
    $("#viewport_filter .item").removeClass('active');
    $("#viewport_filter .item[title='all']").addClass('active');

    let saved_zoom = getDataZoom();
    if (saved_zoom !== null){
      let start = saved_zoom['start'];
      if (start != undefined){
        let end = saved_zoom['end'];
        mCurveChartOption.dataZoom[0].start = start;
        mCurveChartOption.dataZoom[0].end = end;
      }else{
        let start_value = saved_zoom['startValue'];
        if (start_value != undefined){
          let end_value = saved_zoom['endValue'];

          //applay option
          $("#viewport_filter .item").removeClass('active');
          $("#viewport_filter .item[title='"+end_value+"']").addClass('active');

          //apply viewport
          end_value = end_value + 1;
          mCurveChartOption.dataZoom[0].startValue = start_value;
          mCurveChartOption.dataZoom[0].endValue = end_value;
        }
      }
    }
  }

  //设置选项
  function updateOptions(Object){
    let start_time = Object["start-time"];
    let repeat_time = Object["repeat-time"];
    let memo = Object["memo"];

    $(".item").removeClass('active');
    $(".item[title='"+memo+"']").addClass('active');
    $(".item[title='"+start_time+"']").addClass('active');
    $(".item[title='"+repeat_time+"']").addClass('active');
  }
  function resetOptions(){
    updateOptions({
      "memo":"memo_all",
      "repeat-time":"repeat_time_all",
      "start-time":"start_time_all",
    });
  }
  resetOptions();//初始化

  //🍉Helper
  function scaleViewport(device) {
    if (device === "osx"){
      document.body.style.zoom = 0.8;
    }
  }

  //🍋Storage
  function setStorage(key, value) {
    localStorage.setItem(key, value);
  }
  function getStorageBy(key) {
    return localStorage.getItem(key);
  }

  return {
    tryRefreshChartsData:tryRefreshChartsData,
  }
}

var mSelectedWordsStrings = [];
function onCallDevice(Action, Result) {
  if (Action == "didClickPoints"){
    if (Result.length > 0){
      $("#enter_filter").removeClass("empty");
      $("#enter_filter .action").text("已选择"+Result.length+"个单词");
    }else{
      $("#enter_filter").addClass("empty");
    }

    mSelectedWordsStrings = [];
    if (Result.length > 0){
      let points_words_strings = Result.map(function (point) {
        return point['word'];
      });
      mSelectedWordsStrings = points_words_strings;
    }
  }
}

//🍉init
const CurveView = (props) => {

  const [mouted, setMounted] = useState(true);
  useEffect(() => {
    //console.log("🐞CurveView ready!");
    var mDom = $(contentRef.current);
    if(props.onRef){
      let charts = initCharts(mDom);
      props.onRef(charts);
    }
  },[mouted]);

  const contentRef = useRef(this);
  return (
    <div id="curve_wrapper" ref={contentRef}>

      <div className="sections">

        <section id="enter_filter" className="empty">
          <div className="action">已选择对应单词</div>
          <IonIcon icon={chevronForward}/>
        </section>

        <section className="section-card" id="viewport_filter">
          {/*style="margin: 0px 16px 5px 16px;"*/}
          {/*<div className="title">当前单词本</div>*/}

          <div className="row UISegmentedControl">
            <div className="col col-3 px-0">
              <div className="items">
                <div className="item" title="3">最近三天</div>
              </div>
            </div>

            <div className="col col-3 px-0">
              <div className="items">
                <div className="item" title="7">最近一周</div>
              </div>
            </div>

            <div className="col col-3 px-0">
              <div className="items">
                <div className="item" title="30">最近一月</div>
              </div>
            </div>

            <div className="col col-3 px-0">
              <div className="items">
                <div className="item" title="all">所有</div>
              </div>
            </div>

          </div>

          <div id="container_card">
            <div id="charts-container"></div>
          </div>

          {/*<div id="today_info" className="behaviour">今天学习了<span className="start">0</span>个新单词，复习了<span*/}
            {/*className="repeat">0</span>个单词*/}
          {/*</div>*/}
          <div id="expand_container_button" className="hidden">
            <IonIcon icon={lockClosed}/>
            <span className="unlock_days">3</span>天后解锁每日单词总量统计
          </div>

        </section>

        <section className="section-card" id="selection_filter">
          <div className="title">按条件筛选显示</div>
          <div id="clear_filter">重置条件</div>

          <div className="row">
            <div id="filter_start_time" className="col col-4">
              <div className="sub_title">按开始时间</div>
              <div className="items">
                <div className="item" title="start_time_all">所有</div>
                <div className="item" title="start_time_0">今天</div>
                <div className="item" title="start_time_1">昨天</div>
                <div className="item" title="start_time_2">昨天∼前天</div>
                <div className="item" title="start_time_4">前天∼4天</div>
                <div className="item" title="start_time_7">4天∼7天</div>
                <div className="item" title="start_time_15">7天∼15天</div>
                <div className="item" title="start_time_30">15天∼30天</div>
                <div className="item" title="start_time_30+">30天∼</div>
              </div>
            </div>

            <div id="filter_repeat_time" className="col col-4">
              <div className="sub_title">按复习次数</div>
              <div className="items">
                <div className="item" title="repeat_time_all">所有</div>
                <div className="item" title="repeat_time_1">1次</div>
                <div className="item" title="repeat_time_2">2次</div>
                <div className="item" title="repeat_time_3">3次</div>
                <div className="item" title="repeat_time_4">4次</div>
                <div className="item" title="repeat_time_5">5次</div>
                <div className="item" title="repeat_time_6">6次</div>
                <div className="item" title="repeat_time_7">6+次</div>
              </div>
            </div>

            <div id="filter_memo" className="col col-4">
              <div className="sub_title">按记忆情况</div>
              <div className="items">
                <div className="item" title="memo_all">所有</div>
                <div className="item" title="memo_start">新记</div>
                <div className="item" title="memo_known">巩固</div>
                <div className="item" title="memo_familiar">熟悉</div>
                <div className="item" title="memo_danger">需要复习</div>

              </div>
            </div>
          </div>
        </section>

        <section className="section-card" id="selection_total">
          <div className="title">库存单词总量</div>
          <div id="chart_wrapper">
            <div className="info total"><span className="num">0</span></div>
            <div className="infos_div">
              <div className="info">
                <span className="sub_info memo_start"><span className="num">0</span> <span
                  className="mode">新记</span><img src="assets/icons/icon/standard_arrow_right.png"/></span>
                <span className="sub_info memo_known"><span className="num">0</span> <span
                  className="mode">巩固</span><img src="assets/icons/icon/standard_arrow_right.png"/></span>
                <span className="sub_info memo_familiar"><span className="num">0</span> <span className="mode">熟悉</span><img
                  src="assets/icons/icon/standard_arrow_right.png"/></span>
                <span className="sub_info memo_danger"><span className="num">0</span> <span className="mode">需要复习</span><img
                  src="assets/icons/icon/standard_arrow_right.png"/></span>
              </div>
            </div>
            <div id="rose_div" className="background_secondary"></div>
          </div>
        </section>
      </div>

    </div>
  );
};

export default CurveView;
