/* eslint-disable */
/* 👆 this is required for disabling eslint for amd. */

import {Settings} from "./Settings";

var root = 'https://iofange.com'; //https://iofange.com = http://120.78.156.138

//🚫test: 本地测试
//root = 'http://home.com';

const Keys = {
  Action:{
    // global_show_app_menu:           "global_show_app_menu",
    // global_show_page_login:         "global_show_page_login",
    // global_show_page_book_viewer:   "global_show_page_book_viewer",
    // global_show_page_note_viewer:   "global_show_page_note_viewer",
    // global_show_media:              "global_show_media",
  },

  Storage:{
    kDownloadLink   : "STUDY-DATA-DOWNLOAD-LINK-1.0",
    kRecordWords    : "STUDY-DATA-RECORD-WORDS-1.0",
    kRecordLists    : "STUDY-DATA-RECORD-LISTS-1.0",
    kRecordAppuntis : "STUDY-DATA-RECORD-APPUNTIS-1.0",
  },

  Path:{
    page_tabs:                      "./CoCoWebPages/Controllers/TabsController.html",
    page_practice_home:             "./CoCoWebPages/Controllers/Practice_Home.html",
    page_practice_cards:            "./CoCoWebPages/Controllers/Practice_CardsController.html",
    page_tree_verbo:                "./CoCoWebPages/Controllers/Tree_Verbo.html",

    page_wordsmaker:                "/pages/WordsMaker/WordsMaker.html",

    // page_app_center:            "./appSandBox/Apps/Editor/Pages/AppPage/aAppCenter/aAppCenter.html",
    //
    // page_book_controller:       "./appSandBox/Apps/Editor/Pages/AppPage/aBookController/aBookController.html",
    // page_book_viewer:           "./appSandBox/Apps/Editor/Pages/AppPage/aBookViewer/aBookViewer.html",
    // page_note_viewer:           "./appSandBox/Apps/Editor/Pages/AppPage/aNoteViewer/aNoteViewer.html",
    //
    // page_settings_viewer :      "./appSandBox/Apps/Editor/Pages/AppPage/aSettingViewer/aSettingViewer.html",
    // page_discover :             "./appSandBox/Apps/Editor/Pages/AppPage/aSocialDiscover/aSocialDiscover.html",
  },

  Url:{
    // cloud_url :                 "https://public.iofange.com",
    // page_note_viewer :          server_root + "/Apps/Editor/Pages/AppPage/aNoteViewer/aNoteViewer.html",
    api_coverlist_data:             root + "/coverlist_v3/coverlists_data?language=all",

    studydata_download_link:            root + '/studydata/v2/download/cache',
    studydata_upload_link:              root + '/studydata/v2/upload/records',

    study_modify_upload_link:           root + '/modify',
    study_modify_download_link:         root + '/modify',

    page_login:                     root + "/login?device="+Settings.AppDevice+"&app_name="+Settings.AppName+"&version="+Settings.AppVersion,
    page_pay:                       root + "/app/pay/mobile?device="+Settings.AppDevice+"&app_name="+Settings.AppName+"&version="+Settings.AppVersion,
    page_join_beta:                 root + "/beta",

    page_book:                      function () {
      return root + "/coverlist_v3/home?device="+Settings.AppDevice+"&app_name="+Settings.AppName+"&version="+Settings.AppVersion+"&user_email="+Settings.getUserEmail()+"&language="+Settings.getLanguage();
    },
    page_singlelist:                function(id){
      return root + "/coverlist_v3/singlelist/"+id+"?device="+Settings.AppDevice+"&app_name="+Settings.AppName+"&version="+Settings.AppVersion+"&user_email="+Settings.getUserEmail()+"&language="+Settings.getLanguage();
    },

    page_pronounce_fr:                 root + "/page/pronounce_fr_new?device="+Settings.AppDevice+"&app_name="+Settings.AppName+"&version="+Settings.AppVersion,

    token_link:                         root + "/box/token",
    page_tts_preloader:                 root + "/audio/preloader_too",
    audio_records_fetch_link:           root + "/audio/audio_preloader/fetch_records",
    audio_records_save_link:            root + "/audio/audio_preloader/save_records",

    token_link:                         root + '/box/token',
    mdx_check_link:                     root + '/dizio/mdx/check',
    mdx_store_link:                     root + '/dizio/mdx/store',
    mdx_list_link:                      root + '/dizio/mdx/list',

    version_check:                      root + "/app/version_check",
  }
}

export default Keys;

// (function (global) {
//
//   //window.top
//   window.top.Keys = Keys;
//
//   //AMD
//   if (typeof define === 'function' && define.amd) {
//     define(function () {
//       return Keys;
//     });
//   }
//   //ES5
//   else if (typeof module === 'object' && module.exports) {
//     module.exports = Keys;
//   }
//   //Global
//   else {
//     global.Keys = Keys;
//   }
// })(this);