import { Capacitor, Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
import $ from 'jquery';
const { Filesystem } = Plugins;

//async: http://www.ruanyifeng.com/blog/2015/05/async.html

export const FileController = {
    home_dir: null,//=documents
    cloud_url : 'http://public.iofange.com',

    init:function init() {
        Filesystem.getUri({path: '', directory: FilesystemDirectory.Documents}).then(function (result) {
            FileController.home_dir = result.uri;
        });
    },

    //@Path Tools
    tryRemoveHomeDir(path){
        path = decodeURIComponent(path);
        if (path.includes('Documents/')){
            path = path.split('Documents/')[1];
        }
        return path;
    },
    tryReplaceHomeDir(path){
        return FileController.home_dir + FileController.tryRemoveHomeDir(path);
    },
    tryReplaceToCloudUrl(path){
        if (path.includes('UserNotes/')){
            return FileController.cloud_url + '/UserNotes/' + path.split('UserNotes/')[1];
        }
        return path;
    },
    tryReplaceFileSrc(path){
        return Capacitor.convertFileSrc(path);
    },

    //@Download

    /**
     * 下载 home_dir media
     * @param path like
     */
    tryDownloadHomeDirMediaFiles(paths, callback){
        Promise.each(paths, function (filepath) {
            //console.log("🐞download start",filepath);
            return FileController.tryDownloadHomeDirMediaFile(filepath);
        }).then(function () {
           callback();
        });
    },
    tryDownloadHomeDirMediaFile(path){
        let file_url = FileController.tryReplaceToCloudUrl(path);
        //console.log("🐞ajax start",file_url);
        return FileController.promiseAjax(file_url).then(function (data) {
            if (data != null){
                //console.log("🐞ajax success, save to local...");
                return FileController.tryWriteFileToDocuments(
                    FileController.tryRemoveHomeDir(path),
                    data
                )
            }else{
                //console.log("🐞ajax null:",path);
                return;
            }
        });
    },
    //加载文件(promise.ajax)
    promiseAjax(url){
        return new Promise(function (resolve, reject) {
            let breaks = decodeURIComponent(url).split("/");
            let file_name = breaks[breaks.length-1];

            // $.ajax({
            //     url: url,
            //     type: "get",
            //     //@depracated: cause parsererror
            //     //dataType: "blob", //扩展出了blob类型
            // }).done(function(data, status, jqXHR){
            //     resolve(data);
            //
            //     //@Deparacated: make as file
            //     // var blob = data;
            //     // blob.lastModifiedDate = new Date();
            //     // blob.name = file_name;
            //     // let file = blob;
            //     // resolve(file);
            // }).fail(function(jqXHR, textStatus) {
            //     console.warn("🐞ajax error 001:",textStatus);
            // });

            var oReq = new XMLHttpRequest();
            oReq.open("GET", url, true);
            oReq.responseType = "blob";
            oReq.onload = function(oEvent) {
                var blob = oReq.response;

                //@Depracated!
                // blob.lastModifiedDate = new Date();
                // blob.name = file_name;
                // let file = blob;
                // resolve(file);

                FileController.getBase64(blob).then(function (base64) {
                    resolve(base64);
                });
            };
            oReq.onerror = function(error){
                //console.log("🐞ajax error:",error,url);
                resolve(null);
            };
            oReq.send();
        });
    },
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    },

    //@Local filesystem

    /**
     * 写入本地文件
     */
    tryWriteFileToDocuments: async function(path, data){
        //console.log("🐞tryWriteFileToDocuments:',path);
        // let folder = path.replace(path.split('/').pop(), '');
        // await Filesystem.mkdir({
        //     path: folder,
        //     directory: FilesystemDirectory.Documents,
        //     recursive:true,
        // });

        try {
            const result = await Filesystem.writeFile({
                path: path,
                data: data,
                directory: FilesystemDirectory.Documents,
                //.
                //encoding: FilesystemEncoding.UTF8,
                recursive:true,
            })
            //console.log("🐞tryWriteFileToDocuments success:', result);
        } catch(e) {
            console.error('🔥tryWriteFileToDocuments error:', e, FilesystemDirectory.Documents);
        }
    },

    validPaths: function(paths, callback){
        var invalid_paths = [];
        Promise.each(paths, function (filepath) {
            return FileController.existFileAt(filepath).then(function (exist) {
                //console.log("🐞stat:',filepath,exist);
                if (!exist){
                    invalid_paths.push(filepath);
                }
            });

        }).then(function () {
            callback(invalid_paths);
        });
    },
    existFileAt: async function(path){
        path = FileController.tryRemoveHomeDir(path);
        try{
            let result = await Filesystem.stat({
                path: path,
                directory: FilesystemDirectory.Documents
            });
            return true;
        }catch (e) {
            //console.log("🐞stat error:',e);
            return false;
        }
    }
};

Promise.each = function(arr, fn) { // take an array and a function
    if(!Array.isArray(arr)) return Promise.reject(new Error("Non array passed to each"));
    if(arr.length === 0) return Promise.resolve();
    return arr.reduce(function(prev, cur) {
        return prev.then(() => fn(cur))
    }, Promise.resolve());
}


export async function fileWrite() {
    try {
        const result = await Filesystem.writeFile({
            path: 'text.txt',
            data: "This is a test",
            directory: FilesystemDirectory.Documents,
            encoding: FilesystemEncoding.UTF8
        })
        //console.log("🐞Wrote file', result);
    } catch(e) {
        console.error('🔥Unable to write file', e, FilesystemDirectory.Documents);
    }
}

// async fileRead() {
//     let contents = await Filesystem.readFile({
//         path: 'secrets/text.txt',
//         directory: FilesystemDirectory.Documents,
//         encoding: FilesystemEncoding.UTF8
//     });
//     console.log(contents);
// }
//
// async fileAppend() {
//     await Filesystem.appendFile({
//         path: 'secrets/text.txt',
//         data: "MORE TESTS",
//         directory: FilesystemDirectory.Documents,
//         encoding: FilesystemEncoding.UTF8
//     });
// }
//
// async fileDelete() {
//     await Filesystem.deleteFile({
//         path: 'secrets/text.txt',
//         directory: FilesystemDirectory.Documents
//     });
// }
//
// async mkdir() {
//     try {
//         let ret = await Filesystem.mkdir({
//             path: 'secrets',
//             directory: FilesystemDirectory.Documents,
//             recursive: false // like mkdir -p
//         });
//     } catch(e) {
//         console.error('Unable to make directory', e);
//     }
// }
//
// async rmdir() {
//     try {
//         let ret = await Filesystem.rmdir({
//             path: 'secrets',
//             directory: FilesystemDirectory.Documents,
//             recursive: false,
//         });
//     } catch(e) {
//         console.error('Unable to remove directory', e);
//     }
// }
//
// async readdir() {
//     try {
//         let ret = await Filesystem.readdir({
//             path: 'secrets',
//             directory: FilesystemDirectory.Documents
//         });
//     } catch(e) {
//         console.error('Unable to read dir', e);
//     }
// }
//
// async stat() {
//     try {
//         let ret = await Filesystem.stat({
//             path: 'secrets/text.txt',
//             directory: FilesystemDirectory.Documents
//         });
//     } catch(e) {
//         console.error('Unable to stat file', e);
//     }
// }
//
// async readFilePath() {
//     // Here's an example of reading a file with a full file path. Use this to
//     // read binary data (base64 encoded) from plugins that return File URIs, such as
//     // the Camera.
//     try {
//         let data = await Filesystem.readFile({
//             path: 'file:///var/mobile/Containers/Data/Application/22A433FD-D82D-4989-8BE6-9FC49DEA20BB/Documents/text.txt'
//         })
//     }
// }
//
// async rename() {
//     try {
//         // This example moves the file within the same 'directory'
//         let ret = await Filesystem.rename({
//             from: 'text.txt',
//             to: 'text2.txt',
//             directory: FilesystemDirectory.Documents
//         });
//     } catch(e) {
//         console.error('Unable to rename file', e);
//     }
// }
//
// async copy() {
//     try {
//         // This example copies a file within the documents directory
//         let ret = await Filesystem.copy({
//             from: 'text.txt',
//             to: 'text2.txt',
//             directory: FilesystemDirectory.Documents
//         });
//     } catch(e) {
//         console.error('Unable to copy file', e);
//     }
// }