// //.for InAppBrowser
// import { Plugins } from '@capacitor/core';
//
// //.instances
// const {  Browser } = Plugins;
//
//
// //NativeTools
// const NativeTools = {
//   openUrl(url){
//     Browser.open({ url: url });
//   }
// }
// export default NativeTools;


//.for Platform
import { isPlatform } from '@ionic/react';
//.for Settings
import {Settings, UpdateController} from "./Settings";
//.for InAppBrowser
import { Plugins } from '@capacitor/core';
//.for TTS
import { TextToSpeech } from '@ionic-native/text-to-speech';
import TTSHelper from "./TTSHelper";
import {TTSPreloader} from "./TTSPreloader";
//.for Vibration
import { Vibration } from '@ionic-native/vibration';
//*
import Alert from "./Alert";
import * as Tools from "./Tools";
//.
import $ from 'jquery';
import Keys from "./Keys";
//.
import {IframeNavBack} from  "./uikit/IframeNav";
//.
import MdxService from "./mdict/MdxService";

window.$ = $;
window.top.Tools = Tools;
window.mTokenLink = Keys.Url.token_link;
window.mRecordsFetchLink = Keys.Url.audio_records_fetch_link;
window.mRecordsSaveLink = Keys.Url.audio_records_save_link;
console.log("🐞Tools:",window.top.Tools);

//.instances
const {  Browser, Share, CustomNativePlugin, iOSCustomPlugin, Clipboard } = Plugins;

//NativeTools
const NativeTools = {

  init(){

    //.
    window.top.speak = NativeTools.speak;

    NativeTools.listenBackButton();

    setTimeout(()=>{
      if (localStorage.getItem('SETTING-LANGUAGE') == null){
        Alert.showLanguageAlert(()=>{});
      }
    },2000);

    setTimeout(()=>{
      //.
      NativeTools.mTTSPreloader = (new TTSPreloader());
      //.
      NativeTools.mTTSHelper = (new TTSHelper());
      //.
      MdxService.init();
      window.MdxService = MdxService;
    },3000);

    setTimeout(()=>{
      UpdateController.autoCheckUpdate();
    },5000);
  },

  //Browser.OpenUrl
  openUrl(url){
    Browser.open({ url: url });
  },

  //BackButton
  listenBackButton(){
    if (NativeTools.isAndroid){
      document.addEventListener('ionBackButton', (ev) => {
        ev.preventDefault();

        ev.detail.register(10, (processNextHandler) => {
          //console.log("🐞BackButton Handler was called!");

          //call default event handlr:
          //processNextHandler();

          IframeNavBack();
        });
      });
    }
  },

  //TTS.Speak
  isMobile:(isPlatform('ios') || isPlatform('android')),
  isAndroid:isPlatform('android'),
  isIOS:isPlatform('ios'),
  mTTSHelper:null,
  mTTSPreloader:null,
  tryRequireOfflineTTS(){
    if (Settings.getVoiceOffline() && NativeTools.isAndroid){
      CustomNativePlugin.requireGoogleTTS().then((result)=>{
        //console.log("🐞requireGoogleTTS:",result);
      });
    }
  },
  trySetRequireBackgroundAudio(bool){
    if (NativeTools.isIOS){
      iOSCustomPlugin.setRequireBackgroundAudio({RequireBackgroundAudio: bool});
    }
  },

  speakWord(word){
    //console.log("🐞speakWord:",word);

    let mWordString = word.word_string;
    let mMeanString = word.word_key;

    let word_string = Tools.isEmpty(mWordString) ? "":mWordString;
    let mean_string = Tools.isEmpty(mMeanString) ? "":mMeanString;

    var speak_string;
    if (word_string.hasChinese()==false){
      speak_string = word_string;
    }else if (mean_string.hasChinese()==false){
      speak_string = mean_string;
    }else{
      speak_string = word_string+" "+mean_string;
    }

    NativeTools.speak(speak_string, word.preferred_language, null);
  },


  /**
   * 为单词预加载读音, 30个/次
   */
  mVoicePreloading:false,
  mVoiceLoadQueues:[],

  mFromIndex : 0,
  mToIndex : 0,
  preloadWordsVoicesBy(
    Words,
    WithMean,
    CurrentIndex,
    Force,
    HasUI,
    OnFinish
  ){

    //离线模式
    if (Settings.getVoiceOffline()){
      OnFinish();
    }
    //在线模式
    else{

      if (HasUI){
          Alert.showLoadingAlert("加载发音数据",null,true);
      }

      var range = 10;

      if (
        Force ||                          //初始化进入
        CurrentIndex % range == 0 ||      //自然预加载
        (CurrentIndex < NativeTools.mFromIndex || CurrentIndex > NativeTools.mToIndex)  //跳转进度
      ){
        //prepare
        var speakwords = [];
        if (Force){
          NativeTools.mFromIndex = CurrentIndex;
          NativeTools.mToIndex = CurrentIndex;
          speakwords = NativeTools.mTTSPreloader.makeSpeakWordsByWords(Words.slice(NativeTools.mFromIndex, NativeTools.mFromIndex+5), WithMean);
        }else{
          NativeTools.mFromIndex = CurrentIndex;
          NativeTools.mToIndex = CurrentIndex + range + 5;
          speakwords = NativeTools.mTTSPreloader.makeSpeakWordsByWords(Words.slice(NativeTools.mFromIndex, NativeTools.mToIndex), WithMean);
        }

        const queue = () => {

          //start preload
          NativeTools.mVoicePreloading = true;

          //max wait
          let wait = setTimeout(()=>{
            Alert.clearAlerts();
            NativeTools.mVoicePreloading = false;
            OnFinish();

            NativeTools.tryContinueVoiceQueue();
          },20*1000);

          NativeTools.mTTSPreloader.preloadSpeakWords(
            //SpeakWords
            speakwords,
            //OnLoad
            function () {

            },
            //OnPrepared
            function () {
              clearTimeout(wait);

              Alert.clearAlerts();
              NativeTools.mVoicePreloading = false;
              OnFinish();

              NativeTools.tryContinueVoiceQueue();
            },

            //OnFinish
            function () {

            });
        }

        //等待加载
        if(NativeTools.mVoicePreloading){
          NativeTools.mVoiceLoadQueues.unshift(queue);
          console.info("🐞queue is loading. wait:", NativeTools.mVoiceLoadQueues.length);
        }
        //开始加载
        else{
          queue();
        }

      }else{
        //console.log("🐞skip load index:", CurrentIndex);
      }

      // @Depracated!
      // Alert.showLoadingAlert("加载学习数据");
      //
      // let wait = setTimeout(()=>{
      //   Alert.clearAlerts();
      //   OnFinish();
      // },10*1000);
      //
      // let speakwords = NativeTools.mTTSPreloader.makeSpeakWordsByWords(Words, WithMean);
      // NativeTools.mTTSPreloader.preloadSpeakWords(
      //   speakwords,
      //   function () {
      //
      //   },
      //   function () {
      //     clearTimeout(wait);
      //     Alert.clearAlerts();
      //     OnFinish();
      //   },
      //   function () {
      //
      //   });
    }
  },
  tryContinueVoiceQueue(){
    if (NativeTools.mVoiceLoadQueues.length > 0){
      console.log("🐞continue queue:", NativeTools.mVoiceLoadQueues.length);
      const queue = NativeTools.mVoiceLoadQueues[0];
      NativeTools.mVoiceLoadQueues.splice(0,1);

      console.log("🐞excuting queue:", NativeTools.mVoiceLoadQueues.length);
      queue();
    }else{
      console.log("🐞finish all voice queues!");
    }
  },

  speak(Text,Language=null,OnFinish=null,Rate=0.5){

    //.validate
    if (Text == null){
      OnFinish(true);
      return;
    }

    //@Depracated: if (Language == null){Language=Settings.getVoiceLanguage();}
    if (Language == null){Language=Settings.getLanguage();}

    //speak by audio
    if (Settings.getVoiceOffline() == false){
      NativeTools.speakByAudio(Text, Language, OnFinish, Rate);
    }
    //speak by native
    else{
      NativeTools.speakByNative(Text, Language, OnFinish,Rate);
    }
  },
  speakByAudio(Text, Language=null, OnFinish=null, Rate=0.5){

    //@Depracated: if (Language == null){Language=Settings.getVoiceLanguage();}
    if (Language == null){Language=Settings.getLanguage();}

    let speakword = NativeTools.mTTSPreloader.SpeakWord(
      NativeTools.mTTSPreloader.hasChinese(Text) ? Tools.pureChinese(Text) : Text,
      NativeTools.mTTSPreloader.hasChinese(Text) ? "zh":Language,
      null,
      null,
    )
    //console.log("🐞speakByAudio:",Text,'=>',speakword);

    NativeTools.mTTSPreloader.speak(speakword, function (success) {
      if (success){
        console.log('🔥Audio Success!');
        if (OnFinish){
          OnFinish();
        }
      }else{
        console.log('🔥Audio fail:');
        NativeTools.speakByNative(Text, Language, OnFinish, Rate);
      }
    }, Rate);
  },
  speakByNative(Text, Language=null, OnFinish=null, Rate=0.5){

    //@Depracated: if (Language == null){Language=Settings.getVoiceLanguage();}
    if (Language == null){Language=Settings.getLanguage();}

    //console.log("🐞speakBy Native:",Text, "mobile:", NativeTools.isMobile);

    if (NativeTools.mTTSPreloader.hasChinese(Text)){
      Language = "zh";
      Text = Tools.pureChinese(Text);
    }

    //Speak By Native
    if (NativeTools.isMobile){

      let voices = {
        "it":{
          //text:"italiano modo di dire",
          locale:"it-IT",
          pitch: 1.2,
          rate:Rate,
        },
        "fr":{
          //text:"ouvvrez la porte et voyez la montagne.",
          locale: 'fr-FR',
          pitch: 1.2,
          rate:Rate,
        },
        "en":{
          //text:"assigned a value but never used",
          locale:"en-US",
          pitch: 1.25,
          rate:Rate,
        },
        "zh":{
          //text:"最有趣的意大利语故事",
          locale:"zh-TW",
          pitch: 1.0,
          rate:Rate,
        },

        "all":{
          //text:"assigned a value but never used",
          locale:"en-US",
          pitch: 1.25,
          rate:Rate,
        },
      }

      if (NativeTools.isAndroid){
        for(let lan of Object.keys(voices)){
          voices[lan].rate = Rate*2;
        }
      }

      let voice = voices[Language]; voice.text = Text;

      TextToSpeech.speak(voice)
        .then(() => {
          console.log('🔥Native TTS Success!');
          if(OnFinish){
            OnFinish();
          }
        })
        .catch((reason) => {
          console.warn("🔥Native TTS Error:",reason);
          if(OnFinish){
            OnFinish();
          }
        });
    }
    //Speak By Web
    else{
      NativeTools.mTTSHelper.speakByString(Text, Language, OnFinish, Rate);
    }
  },

  //Shared.Link
  share(title, url){

    //remove parmas
    if (url.includes("?")){
        url = url.split("?")[0];
    }

    //add language
    url += "?language="+Settings.getLanguage();

    //encode for ios
    url = encodeURI(url);

    //share
    //console.log("🐞share:',url);
    Share.share({
      title: title,
      url: url,
    }).then(function (result) {
      //console.log("🐞share success:',result);
    }).catch((e)=>{
      console.warn("🔥share fail:",e);
      Alert.showConfirmAlert("分享错误啦","貌似当前环境不支持分享<br>"+JSON.stringify(e),false,"浏览器打开",function () {
        NativeTools.openUrl(url);
      });
    });
  },

  //Clipboard / Pasteboard
  async getPasteboardValue(){
    let result = await Clipboard.read();
    return result.value;
  },

  //Vibration
  vibrate(){
    //todo: 真机测试
    //Vibration.vibrate(1000);
  },


}
export default NativeTools;