import $ from "jquery";
import swal from 'sweetalert2';
import * as Tools from "./Tools";
import "./Alert.css"
import {Settings} from "./Settings";


const Alert = {
  icons : {
    success:'success',
    error:'error',
    warning:'warning',
    info:'info',
    question:'question',
  },

  //clear
  clearAlerts:function(){
    swal.close();
  },
  hideAlerts:function(){
    swal.close();
  },

  castHtmlMessage(HtmlMessage){
    if (Tools.isEmpty(HtmlMessage)){
      return '';
    }
    if (HtmlMessage != null){
      if(typeof (HtmlMessage) == "object"){
        if(HtmlMessage['responseText'] != undefined){
          HtmlMessage = HtmlMessage['responseText'];
        }
      }
      if(typeof (HtmlMessage) != "string"){
        HtmlMessage = JSON.stringify(HtmlMessage);
      }
    }
    return HtmlMessage;
  },

  //🍋General

  //loading
  showLoadingAlert:function (Title, HtmlMessage, InlineMode=false){
    swal.fire({
      title: Title,
      html: HtmlMessage,
      heightAuto:false,
      showClass: {
        popup: InlineMode ? 'swal2-noanimation':'',
        backdrop: InlineMode ? 'swal2-noanimation':'',
      },
      hideClass: {
        popup: '',
        backdrop: ''
      },
      customClass:{
        container:InlineMode ? "inline-mode":""
      },
      onBeforeOpen: () => {
        swal.showLoading();
      },
    });
  },

  //warning
  showWarningAlert:function(Title, HtmlMessage, ActionText){
    if (ActionText == undefined || ActionText == null){
      ActionText = "好的";
    }

    let config = {
      title: Title,
      html: Alert.castHtmlMessage(HtmlMessage),
      heightAuto:false,
      showConfirmButton: false,

      showCancelButton: true,
      cancelButtonText: ActionText,
      cancelButtonColor: '#DBDCDC'
    };
    swal.fire(config);
  },

  //success
  showSuccessAlert:function(Title, HtmlMessage='', ActionText='好的', AutoClose=true){
    if (ActionText == undefined ||ActionText == null){
      ActionText = "好的";
    }
    if(typeof (AutoClose)=="undefined"){
      AutoClose = false;
    }

    let config = {
      icon: 'success',
      title: Title,
      html: Alert.castHtmlMessage(HtmlMessage),
      heightAuto:false,

      showConfirmButton: false,

      showCancelButton: true,
      cancelButtonText: ActionText,
      cancelButtonColor: '#DBDCDC'
    };

    if (AutoClose){
      config.timer = 1500;
      config.showCancelButton = false;
    }

    swal.fire(config);
  },

  //confirm
  showConfirmAlert:function(
    Title,
    HtmlMessage,
    isDangerous,
    ActionText,
    OnConfirm,
    CancelText="取消"
  ){
    let confirm_button_color = isDangerous ? '#FF6358':'#1586FF';
    let config = {
      title: Title,
      html: Alert.castHtmlMessage(HtmlMessage),
      heightAuto:false,
      reverseButtons:true,
      showCancelButton: true,

      confirmButtonText: ActionText,
      confirmButtonColor: confirm_button_color,

      cancelButtonText: CancelText,
      cancelButtonColor: '#DBDCDC'
    };

    swal.fire(config).then((result) => {
      //comfirm
      if (result.value) {
        OnConfirm();
      }
    })
  },

  //input
  showInputAlert:function(
    Title,
    HtmlMessage,
    DefaultValue,
    ActionText,
    OnFinish
  ){
    swal.fire({
      title: Title,
      html: HtmlMessage,
      heightAuto:false,
      input: 'text',
      inputValue: DefaultValue != null ? DefaultValue : "",
      showCancelButton: true,
      confirmButtonText: ActionText,
      cancelButtonText: "取消",
      inputValidator: (value) => {
        OnFinish(value);
      }
    })
  },

  //actions
  showActionsAlert:function(Title,HtmlMessage,Actions,ActionText="取消"){
    let content = $("<div></div>");
    //.
    let message = $("<div class='message'>"+HtmlMessage+"</div>");
    //.
    let buttons = $("<div class='alert-buttons'></div>");
    $.each(Actions, function (text, action) {
      let button = $("<div class='alert-button'>"+text+"</div>");
      button.on('click',function () {
        action();
        Alert.clearAlerts();
      });
      buttons.append(button);
    });
    //.
    content.append(message);
    content.append(buttons);

    let config = {
      title: Title,
      customClass:{
        popup:"alert-actions"
      },
      html: content[0],
      heightAuto:false,

      showConfirmButton: false,

      showCancelButton: true,
      cancelButtonText: ActionText,
      cancelButtonColor: '#DBDCDC'
    };
    swal.fire(config);
  },

  //notification
  showNotification:function(Title, Icon='success'){
    const Toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer:2000
    })

    Toast.fire({
      icon: Icon,
      title: Title
    })
  },

  //正在上传
  showUploadingAlert:function (Title, HtmlMessage) {
    swal.fire({
      title: Title,
      html: HtmlMessage+'<br><span class="percentage"></span>',
      heightAuto:false,
      onBeforeOpen: () => {
        swal.showLoading();
      },
    });
  },
  //上传进度
  updateUploadingProgress:function (PrecentString) {
    const content = swal.getContent()
    if (content) {
      const percentage = content.querySelector('.percentage')
      if (percentage) {
        percentage.innerHTML = PrecentString;
      }
    }
  },


  //🍋Pages

  //选择语言
  showLanguageAlert(OnFinish){
    function setLanguage(language){
      //设置语言
      Settings.setLanguage(language);
      //设置词典
      window.top.MdxService.init();

      OnFinish();
    }
    Alert.showActionsAlert("设置学习语言","选择您正在学习的语言作为词典和背单词偏好",{
      "🏳️‍🌈 多国语":function () {
        setLanguage("all");
      },
      "🇬🇧 英语":function () {
        setLanguage("en");
      },
      "🇫🇷 法语":function () {
        setLanguage("fr");
      },
      "🇮🇹 意语":function () {
        setLanguage("it");
      },
    });
  },

  //刷新页面
  showReloadAlert:function () {
    swal.fire({
      title: '正在应用设置',
      heightAuto:false,
      onBeforeOpen: () => {
        swal.showLoading();
        window.top.MdxService.init();
        setTimeout(function () {
          Alert.clearAlerts();

          //@Depracated!
          //window.top.location.reload();

        },2000);
      },
    });
  },

  //正在下载
  showDownloadingMDXAlert:function () {
    swal.fire({
      title: '正在缓存词典包',
      html: '这可能需要一些时间，但是值得等待<br>缓存完成后，您将可以随时查词而无需再次等待<br><span class="percentage"></span>',
      heightAuto:false,
      onBeforeOpen: () => {
        swal.showLoading();
      },
    });
  },
  showDownloadingStudydataAlert:function(){
    swal.fire({
      title: '正在下载学习数据',
      html: '这可能需要一些时间，但是值得等待',
      heightAuto:false,
      onBeforeOpen: () => {
        swal.showLoading();
      },
    });
  },
  //下载完成
  showDownloadFinishAlert:function () {
    swal.fire({
      title: '缓存完成',
      html: '正在打开词典包',
      heightAuto:false,
      onBeforeOpen: () => {
        swal.showLoading();
      },
    });
  },

  //下载词典
  showSetupGuide:function() {

    setTimeout(function () {
      swal.mixin({
        progressSteps: ['1', '2']
      }).queue([
        // {
        //   title: '设置词典',
        //   html: '<p class="text-sandy">选择一门语言作为词典语言<br>使用时也可以随时修改</p>',
        //   input: 'select',
        //   inputOptions: {
        //     en: '英语',
        //     fr: '法语',
        //     it: '意大利语',
        //     all:'多国语',
        //   },
        //   inputPlaceholder: '选择语言',
        //   inputValidator: (value) => {
        //     return new Promise((resolve) => {
        //       if (value === '') {
        //         resolve('请先选择语言哦')
        //       } else {
        //         Settings.setLanguage(value);
        //         resolve()
        //       }
        //     })
        //   },
        //   confirmButtonText:"下一步"
        // },
        {
          title: '下载依赖词典包',
          html:
            '<p class="text-sandy">这需要30秒左右的时间<br>取决于您的网络状况</p>',
          confirmButtonText: '开始下载',
          showLoaderOnConfirm: true,
          preConfirm: (value) => {
            //Promise: AutoDownloadFiles
            return window.top.MdxService.tryAutoDownloadFilesByLanguage(function () {
              //下载完成
              //console.log("🐞下载完成");
            });
          }
        },
        {
          title: '缓存完成 可以使用词典啦',
          html:
            '<p class="text-sandy">📗 您还可以在设置界面添加更多词典包</p>',
          confirmButtonText: '完成',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            Alert.showReloadAlert();
            return "finish";
          }
        },
      ]);
    },1000);
  },
  showEmptyDizioAlert:function () {
    swal.fire({
      title: '没有可用的词典',
      html: '<p class="">当前语言没有可用的词典包<br>您可以通过右上角的词典按钮来安装词典包 📗<br>也可以尝试让App自动下载词典</p>',
      heightAuto:false,
      showCancelButton: true,

      cancelButtonText: '知道啦',
      cancelButtonColor: '#DBDCDC',
      confirmButtonText: '尝试自动下载',
      confirmButtonColor: '#1586FF',
    }).then((result) => {
      //comfirm
      if (result.value) {
        Alert.showDownloadingMDXAlert();
        window.top.MdxService.tryAutoDownloadFilesByLanguage(function () {
          Alert.showReloadAlert();
        });
      }
    })
  },

};
export default Alert;