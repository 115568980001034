/**
 * 🍋 生成WordShell
 * @params: IndexString, MainPartString
 * @return: WordShell Object
 * */
import $ from "jquery";
import * as Tools from "../Tools";

function Shell(IndexString, PreferredRawValue) {

  //IndexString, AliasString, Keys, Propertys, PreferredRawValue
  this.index_srting = IndexString;
  this.alias_string = null;
  this.keys = [];
  this.propertys = [];
  this.preferred_raw_value = PreferredRawValue;
  this.preferred_origin_tables_string = null;
  this.preferred_related_words_string = null;

  //Propertys
  this.makeProperty = function (PropertyKey, PropertyNote) {
    return {
      property_key:PropertyKey,
      property_note:PropertyNote,
      means: [],

      //Means
      makeMean:function (MeanKey) {
        return {
          mean_key:MeanKey,
          frases:[],

          //Frases
          makeFrase:function (Frase, Mean, PreferredRawString) {
            return {
              preferred_raw_string:PreferredRawString,
              frase_body: Frase,
              frase_mean: Mean
            };
          },
          addFrase:function (Frase) {
            this.frases.push(Frase);
          }
        };
      },
      addMean:function (Mean) {
        this.means.push(Mean);
      }
    };
  };
  this.addProperty = function (Property) {
    this.propertys.push(Property);
  };


  //Renderer
  this.toHtmlString = function (CustomKey=null) {

    var MainParteHTMLString = "";

    if (this.propertys.length === 0){
      //防止主要内容和Keys相同(重复)
      if (this.keys.length === 1 && $($.parseHTML(this.preferred_raw_value)).text() == this.keys[0]){
        MainParteHTMLString = "";
      }else{
        MainParteHTMLString = "<div id='Raw' class='TouchAction'>"+this.preferred_raw_value+"</div>";
      }
    }else{
      var sommario = "";
      var frasiNumber = 0;
      var meansNumber = 0;

      var PP = 0;
      sommario += "<ul class='sommario'>";
      MainParteHTMLString += "<div id='Main'>";
      MainParteHTMLString += "<div class='functionButton'>详细释义和例句</div><div class='checkerDIV TouchAction'>";

      $.each(this.propertys, function (property_index, property) {

        MainParteHTMLString+="<div class='property'>"+property.property_key+"</div>";
        sommario += "<div class='sommarioProperty'>"+property.property_key;

        if (property.property_note !== null){
          if (property.property_note.includes("自反")){
            MainParteHTMLString += "<div class='property-note riflessivo'>"+property.property_note+"</div>"
            sommario += "<br><div class='property-note-indicator riflessivo'>"+property.property_note+"</div>"
          }
          else if (property.property_note.includes("副词")){
            MainParteHTMLString += "<div class='property-note avv'>"+property.property_note+"</div>"
            sommario += "<br><div class='property-note-indicator avv'>"+property.property_note+"</div>"
          }
          else{
            MainParteHTMLString += "<div class='property-note'>"+property.property_note+"</div>"
            sommario += "<br><div class='property-note-indicator'>"+property.property_note+"</div>"
          }
        }


        var KK = 0;
        $.each(property.means, function (mean_index, mean) {

          meansNumber += 1;

          //MeanKey
          MainParteHTMLString+="<span class=\"oneMean\" id='"+String(PP)+String(KK)+"'><span>"+mean.mean_key+"</span></span>";
          sommario+="<li><a href='#"+String(PP)+String(KK)+"'>"+mean.mean_key+"</a></li>";

          //Frases
          $.each(mean.frases, function (frase_index, frase) {
            MainParteHTMLString += "<div class='oneFrase'>";

            MainParteHTMLString += frase.preferred_raw_string;

            // if (frase.frase_body != null){
            //     MainParteHTMLString+="<span class='oneFrase frase-body'>"+frase.frase_body+"<br></span>"
            // }
            // if (frase.frase_mean != null){
            //     MainParteHTMLString+="<span class='oneFrase frase-mean'>"+frase.frase_mean+"<br></span>"
            // }

            MainParteHTMLString += "</div>";

            frasiNumber ++;
          });

          KK+=1
        });

        sommario+="</div>"
        PP+=1
      });
      sommario += "</ul>"
      MainParteHTMLString+="</div></div>"

      //当词条行数大于6时, 附加目录sommario
      if (frasiNumber > 6){
        MainParteHTMLString = sommario+MainParteHTMLString
      }
    }

    //添加alias
    if (this.alias_string != null && this.alias_string.replace(/\s/g,'') !== ''){
      MainParteHTMLString = "<p class='alias "+window.mLanguage+"'>"+this.alias_string+"</p>" + MainParteHTMLString
    }

    //添加 index + keys
    var html_TitleString = "<p id='theIndice' class='TouchAction'>"+this.index_srting+"</p>";
    if (CustomKey != null){
      html_TitleString = html_TitleString+"<p id='theKeys' class='TouchAction'>"+CustomKey+"</p>";
    }else{
      if (this.keys.length > 0){
        html_TitleString = html_TitleString+"<p id='theKeys' class='TouchAction'>"+this.keys.join(", ")+"</p>";
      }
    }
    MainParteHTMLString = "<div class='Preview expanding'>"+html_TitleString+MainParteHTMLString+"</div>";

    //添加近义词
    //SemiDiv = "<div class='SemDiv'><input class='functionButton' value='近义词' type='button'/><div class='checkerDIV TouchAction'>"+theNearParolesString+"</div></div>"
    if (this.preferred_related_words_string !== null && this.preferred_related_words_string !== ""){
      let related_words_htmlstring = "<div class='SemDiv'><div class='functionButton'>释义相近单词</div><div class='checkerDIV TouchAction'>"+this.preferred_related_words_string+"</div></div>";
      MainParteHTMLString += related_words_htmlstring;
    }

    //添加变位表
    if (this.preferred_origin_tables_string !== null && this.preferred_origin_tables_string !== ""){
      let origin_tables_htmlstring = "<div id='OriDiv'><div class='functionButton'>变位表</div><div class='checkerDIV TouchAction'>"+this.preferred_origin_tables_string+"</div></div>";
      MainParteHTMLString += origin_tables_htmlstring;
    }

    return MainParteHTMLString;
  };

  this.toHighlightSummaryHtmlString = function () {
    return "nothing";
  };
}

//IT
function makeWordShell_IT(IndexString,MainPartString) {
  var mainPartString = MainPartString
    .replace(new RegExp("★★","gi"),",");

  //开始解析
  var wordshell = new Shell(IndexString, mainPartString);

  //🍋提取释义
  var Keys = [];
  var KeyBreaks = [mainPartString];
  if (mainPartString.includes("------------------------------<br>★《意汉词典》★")){
    KeyBreaks = mainPartString.split("------------------------------<br>★《意汉词典》★");

    let KeysString = KeyBreaks[0]
      .replace(/\"/g,'')
      .replace(/<br>/g,'')
      .replace(/\s/g,'');

    Keys = KeysString.split("★★");
  }

  mainPartString = KeyBreaks[KeyBreaks.length-1];

  //🍋替换 [P] 和 (N)
  var newMainPartString = ""
  $.each(mainPartString.split("<br>"),function (i, one) {
    if (one != ""){
      var oneLine = one
      oneLine = oneLine.replace(/<br>/g,'');

      //------- Block
      let pattern_property = "([\\[])([IVX]+)(\\.\\])"//parlamentare
      let regular = new RegExp(pattern_property,'gi');
      oneLine = oneLine.replace(regular, "[P]");
      oneLine = oneLine.replace(new RegExp("★　自反动词与副词："), "[P][R]");
      oneLine = oneLine.replace(new RegExp("------------------------------"), "");

      //* 按照词形进行分块. 有的单词比如 parlare 包含自反动词, 也作为一个块处理, 后面加一个[R]作为标记, 以便后面特殊处理

      //------- Mean
      let pattern_mean = "(\\()([0-9]+)(\\))"//parlamentare
      let regular_mean = new RegExp(pattern_mean,'gi');
      oneLine = oneLine.replace(regular_mean, "(N)");
      oneLine = oneLine.replace(new RegExp("★　常用短语："), "(N)常用短语：")

      //* 按照含义进行区分, 通常格式为 "释义: <br> only one frase 只有一条例句"
      //* 常用短语部分, 格式为 "常用短语: <br> fist frase 第一条例句 <br> second frase 第二条例句"

      newMainPartString += (oneLine+"<br>")
    }
  })
  mainPartString = newMainPartString


  //🍋提取 property
  let find_propertys = [];
  let find_keys = Keys.concat();

  var blocks = mainPartString.split("[P]")

  //将自反动词放到最后
  let find_index = null;
  $.each(blocks, function (block_index, block) {
    if (block.includes("[R]")){
      find_index = block_index;
    }
  });
  if (find_index != null){
    let find_block = blocks[find_index];
    blocks.splice(find_index, 1);
    blocks.push(find_block);
  }

  //包含注音

  //(cosa)
  let match_alias_blocks = blocks.filter(function (block) {
    return block.includes("[R]")==false && block.replace(/^<br>/g,'').startsWith("[");
  });
  if (match_alias_blocks.length > 0){
    let Alias_Block = match_alias_blocks[0];

    var alias_string = ""

    //(poco)
    if (Alias_Block.replace(/^<br>/g,'').split("<br>").length == 1){
      alias_string = Alias_Block.replace(/^<br>/g,'');
      blocks.splice(blocks.indexOf(Alias_Block), 1);
    }else{
      //(abaca)
      if (new RegExp('\\]\\s*\\(或','gi').test(Alias_Block.split("<br>")[0])){
        alias_string = Alias_Block.split("<br>")[0];
      }else{
        let regular_c = new RegExp("(\\[)([\\w]+)(\\])", 'gi');
        let results_c = Alias_Block.match(regular_c);
        if (Tools.isEmpty(results_c)){//能匹配更多, 但是仅用于上一个reg未取到结果的情况
          regular_c = new RegExp("(\\[)([^\"\\]]*)(\\])",'gi');
          results_c = Alias_Block.match(regular_c);
        }
        if (Tools.isEmpty(results_c)==false){
          alias_string = results_c[0];
        }
      }
      if (!Tools.isEmpty(alias_string)){
        let alias_block_index = blocks.indexOf(Alias_Block);

        blocks.splice(alias_block_index, 1);

        let rest_alias_block = Alias_Block.replace(new RegExp(alias_string.escapeRegExp()), "").replace(/^<br>/g,'');
        if (!Tools.isEmpty(rest_alias_block.replace(/\s/g,''))){
          //insert rest_alias_block into blocks at alias_block_index
          blocks.splice(alias_block_index, 0, rest_alias_block);
        }
      }
    }

    if (Tools.isEmpty(alias_string) == false){
      wordshell.alias_string = alias_string
        .replace(/^\s*/g,'')
        .replace(/\s*$/g,'');
    }
  }

  $.each(blocks, function (block_index, block) {
    if ((block !== "")){
      let blockLines = block.replace(/^<br>/g,'').split("<br>").filter(function (line) {
        return Tools.isEmpty(line)  == false && Tools.isEmpty(line.replace(/\s/g,'')) == false;
      })

      //只有一行, 不提取 Property, 直接添加到Keys
      if (blockLines.length == 1){
        wordshell.keys.push(
          blockLines[0]
            .replace(new RegExp(" ★★ "), ", ")
            .replace(new RegExp("★★ "), ", ")
            .replace(new RegExp(" ★★"), ", ")
            .replace(new RegExp("★★"), ", ")
            .replace(new RegExp("★"), " ")
        )
      }
      //有多行, 开始提取 Property
      else if (blockLines.length > 1){

        //🍓提取 Property
        var find_property_key = null;
        var find_propery_note = null;
        var restBlock = "";

        //普通 property
        if (block.includes("[R]") == false){

          find_property_key = blockLines[0];

          //如果包含多个 (N)                    *(代表这个 (N) 不是 "常用短语" 的 (N))
          //而且 property_key 的下一行 不是 (N)
          //那么表示 (N) 和 property_key 之间, 包含了一段 property_note

          //* andare, quello
          if (block.includes("(N)") && block.split("(N)").length >= 3 &&
            blockLines.length > 1 && blockLines[1].includes("(N)")==false){

            let block_breaks = block.split("(N)")

            let property_note = block_breaks[0].split(find_property_key).last().replace(/^<br>/g,'');
            find_propery_note = property_note.replace(new RegExp("<br>"), "")

            restBlock = block.split(block_breaks[0]).last()
          }
          else{

            //* 如果 part_betweean_propertykey_n 只有一行(io)
            let block_n_breaks = block.split("(N)")

            let part_betweean_propertykey_n = block_n_breaks[0].split(find_property_key).last().replace(/^<br>/g,'')
            let part_betweean_propertykey_n_lines = part_betweean_propertykey_n.split("<br>").filter(function (part) {
              return Tools.isEmpty(part) == false;
            })

            if (part_betweean_propertykey_n_lines.length==1 && part_betweean_propertykey_n_lines[0].includes(".")){
              find_propery_note = part_betweean_propertykey_n_lines[0]
              restBlock = block.split(block_n_breaks[0]).last()
            }
            //其它
            else{
              restBlock = block.split(find_property_key).last()
            }
          }
        }
        //自反动词 property
        else{
          let r_block = block.replace(new RegExp("[R]".escapeRegExp()), "").replace(/^<br>/g,'')
          let r_block_lines = r_block.split("<br>")
          if (r_block_lines.length > 1){
            find_property_key = r_block_lines[0]

            if (Tools.isEmpty(r_block_lines[1])){
              restBlock = r_block.split(r_block_lines[0]).last()
            }else{
              restBlock = r_block.split(r_block_lines[1]).last()
            }

            if (r_block_lines[1].includes("rifl")){
              find_propery_note = r_block_lines[1]+"(自反动词)"
            }else if (r_block_lines[1].includes("avv")){
              find_propery_note = r_block_lines[1]+"(副词)"
            }

            if (find_property_key.includes("avv.")){
              find_property_key = find_property_key.replace(new RegExp("avv.".escapeRegExp()), "").replace(/\s*$/g,'')
              find_propery_note = "avv.(副词)"
            }
            if (find_property_key.includes("v.rifl.")){
              find_property_key = find_property_key.replace(new RegExp("v.rifl.".escapeRegExp()), "").replace(/\s*$/g,'')
              find_propery_note = "v.rifl.(自反动词)"
            }

          }else{
            find_property_key = r_block
            if (find_property_key.includes("avv.")){
              find_property_key = find_property_key.replace(new RegExp("avv.".escapeRegExp()), "").replace(/\s*$/g,'')
              find_propery_note = "avv.(副词)"
            }
            if (find_property_key.includes("v.rifl.")){
              find_property_key = find_property_key.replace(new RegExp("v.rifl.".escapeRegExp()), "").replace(/\s*$/g,'')
              find_propery_note = "v.rifl.(自反动词)"
            }
            restBlock = ""
          }
        }

        restBlock = restBlock.replace(/^<br>/g,'')

        //矫正 property_key 包含 ":"
        //* avere
        if (find_property_key.includes(":")){
          let property_key_breaks = find_property_key.split(":").filter(function (k_break) {
            return Tools.isEmpty(k_break) == false;
          })
          if (property_key_breaks.length == 2){
            find_property_key = property_key_breaks[0]
            restBlock = property_key_breaks[1] + "<br>" + restBlock
          }
        }

        //矫正 property_key 包含 "[...]"
        //* che, parlamentare, avere
        var regular_c = new RegExp("(\\[)([\\w]+)(\\])", 'gi');//(che)
        var results_c = find_property_key.match(regular_c);
        if (Tools.isEmpty(results_c)){//能匹配更多, 但是仅用于上一个reg未取到结果的情况
          regular_c = new RegExp("(\\[)([^\"\\]]*)(\\])",'gi');////(avere)
          results_c = find_property_key.match(regular_c);
        }

        if (Tools.isEmpty(results_c)==false){
          var rects_string = "";
          $.each(results_c, function (i, one_result) {
            rects_string += " "+one_result;
            find_property_key = find_property_key.replace(new RegExp(one_result.escapeRegExp()), "")
          });

          if (find_propery_note != null){
            find_propery_note = find_propery_note + "<br>" + rects_string
          }else{
            find_propery_note = rects_string
          }
        }

        //矫正词性包含汉字(poco)
        if (find_property_key.hasChinese() && (find_property_key.endsWith(":") || find_property_key.endsWith("："))){
          let property_key_breaks = autoSlice(find_property_key);
          find_property_key = property_key_breaks[0].toLowerCase();
          restBlock = property_key_breaks[1]+"<br>"+restBlock
        }

        //矫正 词性的下一行
        //检查例句部分第一行, 包含 ".]" (第一行通常是 MeanKey, 而 MeanKey 是不包含 ".]" 的)
        //检查例句部分第一行, 包含 []
        //检查例句部分第一行, 包含一个点号 (第一行通常是 MeanKey, 而 MeanKey 是不包含点号的),  + 且不包含配对的中文句点(potere)

        restBlock = restBlock.replace(/^<br>/g,'')
        let first_line = restBlock.split("<br>").first()
        var overflow_string = ""

        //(che)
        if (first_line.includes(".]")){
          let first_line_breaks = first_line.split(".]")
          overflow_string = first_line_breaks[0] + ".]"
          restBlock = restBlock.replace(new RegExp(overflow_string.escapeRegExp()), "")
        }
        //词性下一行包含 "[]" (frutta)
        else if (first_line.includes("[") && first_line.includes("]")
          && (first_line.startsWith("[")==false || first_line.endsWith("]")==false)
          && first_line.includes("(N)") == false //(avere)
          && Tools.isEmpty(find_propery_note)
        ){
          var regular_cx = new RegExp("(\\[)([\\w]+)(\\])", 'gi');
          var results_cx = first_line.match(regular_cx);
          if (Tools.isEmpty(results_cx)){
            regular_cx = new RegExp("(\\[)([^\"\\]]*)(\\])",'gi');
            results_cx = first_line.match(regular_cx);
          }
          if (Tools.isEmpty(results_cx)==false){
            $.each(results_cx, function (i, one_result) {
              let matchString = one_result;
              overflow_string += " "+matchString
              restBlock = restBlock.replace(new RegExp(matchString.escapeRegExp()), "")
            })
          }

        }
        //词性下一行包含句点
        else{
          let first_line_breaks = first_line.split(".")

          if (
            first_line_breaks.filter(function (line_break) {
              return Tools.isEmpty(line_break) == false
            }).length == 2 && first_line.includes("。")==false
          ){
            //做一个约束, 如果不包含 "[" , overflow部分不能是由单词组成的句子
            if (first_line_breaks[0].includes("[") || first_line_breaks[0].split(" ").length < 2){
              overflow_string = first_line_breaks[0] + "."
              restBlock = restBlock.replace(new RegExp(overflow_string.escapeRegExp()), "")
            }
          }
        }

        //-
        if (Tools.isEmpty(overflow_string) == false){
          if (find_propery_note != null){
            find_propery_note = find_propery_note + "<br>" + overflow_string
          }else{
            find_propery_note = overflow_string
          }
        }

        //+one_property
        let one_property = wordshell.makeProperty(find_property_key, find_propery_note);
        find_propertys.push(one_property);


        //🍓提取 Means
        let find_means = [];
        let means = restBlock.split("(N)");
        $.each(means, function (i, mean) {
          if (mean != "" && mean != "<br>"){

            //提取 mean_key
            let find_mean_key = null;

            var meanLines = mean.replace(/^<br>/g,'').split("<br>")
            meanLines = meanLines.filter(function (meanLine) {
              return meanLine != "";
            })
            find_mean_key = meanLines.first()
              .replace(/^\s/g,'')
              .replace(new RegExp("："), "");

            //矫正 Mean_Key 包含冒号和一条句子的情况, 这种情况下, 冒号为半角
            if (find_mean_key.includes(":")){
              let meankey_breaks = find_mean_key.split(":")
              find_mean_key = meankey_breaks.first()
              meanLines.splice(1, 0, meankey_breaks.last());//=insert(meankey_breaks.last(), at: 1)
            }

            //矫正 MeanKey 包含分号 ,通常MeanKey不包含分号, 代表包含了一条句子, 此时句子在多个分号的最末
            //* immaginare
            if (find_mean_key.includes("；") && find_mean_key.endsWith("；")==false){
              let meankey_breaks = find_mean_key.split("；")

              let frase_string = meankey_breaks.last()

              //如果frase_string去除标点后不包含英文, 则不成立 (avere)
              var has_en = false
              let pure_string = Tools.removeAllMarks(frase_string);
              if (pure_string.hasChinese() == false){
                has_en = true;
              }
              if (has_en){
                meanLines.splice(1, 0, frase_string); //=insert(frase_string, at: 1)
                find_mean_key = find_mean_key.replace(frase_string, "");
              }
            }

            //+one_mean
            var one_mean = one_property.makeMean(find_mean_key);
            find_means.push(one_mean)
            find_keys.push(find_mean_key);////为没有Keys的提取Key

            if (find_mean_key.startsWith("[")==false || find_mean_key.endsWith("]")==false){

              //🍓提取 frases
              let find_frases = [];

              meanLines.splice(0,1);
              $.each(meanLines, function (i, one) {
                if (one != ""){
                  let find_en = null;
                  let find_zh = null;

                  let frase = autoSlice(one);
                  if (Tools.isEmpty(frase[0]) == false && Tools.isEmpty(frase[frase.length-1]) == false){
                    find_en = frase[0]
                    find_zh = frase[frase.length-1]

                    //+one_frase
                    var one_frase = one_mean.makeFrase(find_en, find_zh, one);
                    find_frases.push(one_frase)
                  }
                  else if (Tools.isEmpty(frase[0]) == false || Tools.isEmpty(frase[frase.length-1]) == false){

                    if (Tools.isEmpty(frase[0])){
                      find_en = frase[frase.length-1]
                    }else{
                      find_en = frase[0]
                    }

                    //+one_frase
                    var one_frase = one_mean.makeFrase(find_en, find_zh, one);
                    find_frases.push(one_frase)
                  }
                }
              })

              //完成提取 frases
              one_mean.frases = find_frases;
            }
          }
        })

        //完成提取 means
        one_property.means = find_means
      }
    }
  })

  //完成提取 propertys
  wordshell.propertys = find_propertys;

  //为没有Keys的提取key
  if (Tools.isEmpty(Keys)){
    wordshell.keys = find_keys
  }else{
    wordshell.keys = Keys
  }

  // console.log("🍉Keys:",Keys,wordshell)
  // $.each(wordshell.propertys, function (i, property) {
  //     console.log("\n\n🍋"+property.property_key)
  //     $.each(property.means, function (ii, mean) {
  //         console.log("\n\n🍓"+mean.mean_key)
  //         $.each(mean.frases, function (iii, frase) {
  //             console.log("\n-",frase);
  //         })
  //     })
  // });

  return wordshell

}

//FR
function makeWordShell_FR(IndexString,MainPartString){

  var wordshell = new Shell(IndexString, MainPartString);

  let find_keys = [];

  //1.分割 变位表 | 主内容 | 近义词
  var origin_tables_string = "";
  var related_words_string = "";
  var mainparts_string = "";

  let origin_tables_reg = /\(v\.\s\d\)<BR>/g;
  let origin_tables_res_x = /pluriel/g;

  if (MainPartString.includes("<hr>")){
    let parts = MainPartString.split("<hr>");

    if (origin_tables_reg.test(parts[0]) || origin_tables_res_x.test(parts[0])){
      origin_tables_string = parts[0];
      mainparts_string = parts[1];
    }else{
      mainparts_string = parts[0];
      origin_tables_string = parts[1];
    }
  }
  else{
    if (origin_tables_reg.test(MainPartString) || origin_tables_res_x.test(MainPartString)){
      origin_tables_string = MainPartString;
    }else{
      mainparts_string = MainPartString;
    }
  }

  let related_words_reg = /<br>近义词/g;
  if (related_words_reg.test(mainparts_string)){
    let breaks = mainparts_string.split("<br>近义词");
    if (breaks.length == 2){
      mainparts_string = breaks[0];
      related_words_string = breaks[1];
    }
  }

  //去掉index
  let index_reg = new RegExp('(<font color="#FF0000">)'+IndexString+'(\\n|\\r)*(<\\/font>)*(<br>)*(\\s)*','g');
  mainparts_string = mainparts_string.replace(index_reg, "");
  origin_tables_string = origin_tables_string.replace(index_reg,"");
  related_words_string = related_words_string.replace(index_reg,"");

  wordshell.preferred_raw_value = mainparts_string;
  wordshell.preferred_origin_tables_string = origin_tables_string;
  wordshell.preferred_related_words_string = related_words_string;

  //2.分割 第一部分(包含音标)和词性部分
  var first_part = mainparts_string;
  var property_blocks = [];

  //获取音标
  let alias_reg = /<font color="#FB8C42" face="Kingsoft Phonetic Plain, Tahoma">[^<]*<\/font>/g;
  var alias_matches = first_part.match(alias_reg);
  if (alias_matches !== null){

    let alias_string = alias_matches.map(function (alias_match) {
      return alias_match.replace(/color="#FB8C42" /g,"");
    }).join(" / ");

    wordshell.alias_string = alias_string;
  }

  //获取词性
  let property_key_reg = /<br>(\s)*(\(复数~s\))*(<font color="#0000FF">)*(I|V)*(\s)*((s'(\S)*)|(se\s(\S)*))*(\s)*(\d+\.{1})*(\s)*((v|i|f|m|n|adj|adv|prép|prep|pers|imper|impers|interj|loc|pron|neut|pleut){1}(\s|<br>|,|\.)*)+(v|i|t|f|m|n|adj|adv)*(\s|<br>|,|\.)*(in|dir|pr|pl)*(\s|\.|<br>|v|t|adv|adj|m|n|f){1}/g;
  let property_keys = mainparts_string.match(property_key_reg);
  if (property_keys != null){
    let property_blocks_string = mainparts_string.replace(property_key_reg, "<break>");

    property_blocks = property_blocks_string.split("<break>");
    first_part = property_blocks[0];
    property_blocks.splice(0,1);
  }
  //没有词性块, 直接返回
  else{
    return wordshell;
  }


  //3.开始生成Wordshell

  //- propertys
  $.each(property_keys, function (property_index, property_key) {
    var pure_property_key = property_key.replace(/^<br>(I|V)*(\s)*(\d+\.{1})*(\s)*/g, "");    //去掉前面的<br>和序号;
    var property_key_note = null;

    let rifl_reg = /((s'(\S)*)|(se\s(\S)*)){1}(\s)*/g;
    let rifls = pure_property_key.match(rifl_reg);
    if (rifls !== null){
      property_key_note = rifls[0]+"(自反动词)";
      pure_property_key = pure_property_key.replace(rifl_reg, "");
    }

    let one_property = wordshell.makeProperty(pure_property_key, property_key_note);

    //- means
    var means_string = property_blocks[property_index];
    let mean_blocks = [means_string];
    let means_reg = /(\d\.\s)+/g;
    if (means_reg.test(means_string)){
      means_string = means_string.replace(means_reg, "<break>");
      mean_blocks = means_string.split("<break>").filter(function (mean_block) {
        return mean_block.replace(/\s/g,"") !== "";
      });
    }

    $.each(mean_blocks, function (mean_index, mean_block) {
      let mean_key_reg = /^(([^：:])*(：|:)){1}/g;
      let one_mean = one_property.makeMean("常用例句");
      if (mean_key_reg.test(mean_block)){
        one_mean.mean_key = mean_block.match(mean_key_reg)[0].replace("/<br>/g","");
        mean_block = mean_block.replace(mean_key_reg, "");
      }
      mean_block = mean_block.replace(" / ", "<br>");//@TODO: 未批量测试

      //-frases
      var frase_blocks = mean_block.split("<br>").filter(function (frase_block) {
        return frase_block.replace(/\s/g, "") !== "";
      });

      //mean key in first frase
      if (frase_blocks.length > 0){
        let start_with_en_reg = /^([a-z]|[A-Z])*/g;
        let start_with_en = frase_blocks[0].match(start_with_en_reg);
        if (start_with_en != null){
          start_with_en = start_with_en.filter(function (one) {
            return one.replace(/\s/g,"") !== "";
          });
        }
        if (Tools.isEmpty(start_with_en)){
          if (one_mean.mean_key === "常用例句"){
            one_mean.mean_key = frase_blocks[0];
          }else{
            one_mean.mean_key = one_mean.mean_key+", "+frase_blocks[0];
          }
          frase_blocks.splice(0,1); //remove first
        }
      }
      one_mean.mean_key = one_mean.mean_key
        .replace(/[:：～]/g, "")
        .replace(/[，]/g, ",")
        .replace(/<br>/g,"")
        .replace(/{/g,"")
        .replace(/}/g,"")
        .replace(/【/g,"[")
        .replace(/】/g,"]");

      var double_line_mode = false;
      if (frase_blocks.length >= 2){
        if (!Tools.hasChinese(frase_blocks[0]) && Tools.hasChinese(frase_blocks[1])){
          double_line_mode = true;
        }
      }

      if (double_line_mode){
        var one_frase = null;
        $.each(frase_blocks, function (frase_index, frase_block) {
          if (one_frase == null){
            one_frase = {frase_body:frase_block};
          }else{
            one_frase.frase_mean = frase_block;
            one_frase.preferred_raw_string = one_frase.frase_body + " " + one_frase.frase_mean;
            one_mean.addFrase(one_frase);
          }
        });
      }else{
        $.each(frase_blocks, function (frase_index, frase_block) {
          let slices = autoSlice(frase_block);
          let one_frase = one_mean.makeFrase(slices[0], slices[1], frase_block);
          one_mean.addFrase(one_frase);
        });
      }

      one_property.addMean(one_mean);

      //push keys
      let start_with_en_reg = /^([a-z]|[A-Z])*/g;
      let start_with_ens = one_mean.mean_key.match(start_with_en_reg);
      if (start_with_ens != null){
        start_with_ens = start_with_ens.filter(function (one) {
          return one.replace(/\s/g,"") !== "";
        });
      }
      if (
        Tools.isEmpty(start_with_ens)==true &&
        Tools.isEmpty(one_mean.mean_key)==false &&
        one_mean.mean_key.includes("常用例句")==false
      ){
        if (one_mean.mean_key.length < 10){
          wordshell.keys.push(one_mean.mean_key);
        }else{
          wordshell.keys.push(one_mean.mean_key.substr(0,10)+"..");
        }
      }
    });
    wordshell.addProperty(one_property);
  });

  return wordshell;
}

//EN
function makeWordShell_EN(IndexString,MainPartString){
  //1.找到音标并根据音标分割后半部分获取
  //2.替换加粗 property_key
  //3.找到简单释义

  MainPartString = MainPartString.replace(/\r\n/g,'');

  //去掉多个index 情况下的 index ('ok')
  MainPartString = MainPartString.replace(/<font color="#FF0000" size=4>((?!<\/font>).)*<\/font>\s*/g,'');

  //去掉<hr>
  MainPartString = MainPartString.replace(/<hr>/g,'');

  //1.
  var alias_string;
  let alias_reg = /<font  color="#FB8C42" size=4 face="Kingsoft Phonetic Plain, Tahoma">((?!<\/font>).)*<\/font>/g;
  var alias_matches = MainPartString.match(alias_reg);
  if (alias_matches !== null){
    //获取 alias_string, 并分割获取后半部分作为 MainPartString
    alias_string = alias_matches.map(function (alias_match) {

      //let alias_breaks = MainPartString.split(alias_match);
      //MainPartString = alias_breaks[alias_breaks.length-1];
      MainPartString = MainPartString.replace(alias_match,'');

      //let new_match = alias_match.replace(/color="#FB8C42"/g,"");

      return $(alias_match).text();

    }).filter(function (alias_txt) {
      return alias_txt.replace(/\s/g, '') !== "";
    }).join(" / ");
  }

  //2.
  //3.
  let keys = [];
  let mainparts_string = MainPartString;
  let property_key_reg = /(a|ad|v|vt|vi|f|m|n|adj|adv|prép|int|pers|imper|impers|interj|loc|pron|neut|pleut|art|prep|conj|abbr|aux|in|dir|pr|pl|vbl){1}(\.){1}(\s)*(<br>)*/g;
  let property_keys = mainparts_string.match(property_key_reg);
  if (property_keys != null){
    //加粗
    $.each(property_keys, function (property_key_index, property_key) {
      MainPartString = MainPartString.replace(property_key, "<div class='property_key'>"+property_key.replace(/<br>/g,'')+"</div>");
    });

    //找到释义
    let property_blocks_string = mainparts_string.replace(property_key_reg, "<break>");
    let property_blocks = property_blocks_string.split("<break>").filter(function (block) {
      return block.replace(/\s/g,'') !== '';
    });
    $.each(property_blocks, function(block_index, property_block) {

      property_block = "<div>"+property_block+"</div>";

      let block_txt = $(property_block).text().replace(/\s*\d+\.{1}\s*/g,', ');
      let get_keys = block_txt.split(", ").filter(function (key) {
        return key.replace(/\s/g,'') !== "";
      });

      if (get_keys.length >= 2){
        keys.push(get_keys[0]);
        keys.push(get_keys[1]);
      }else if (get_keys.length >= 1){
        keys.push(get_keys[0]);
      }
    });
  }

  MainPartString = MainPartString.replace(/^(\s|<br>|\n|\r)*/g,'');

  let wordshell = new Shell(IndexString,MainPartString);
  if (alias_string !== undefined){
    wordshell.alias_string = alias_string;
  }
  if (keys.length > 0){
    wordshell.keys = keys;
  }
  return wordshell;
}


//Tools
function autoSlice(frase_block) {

  let zh_reg = /[^\x00-\xff]+/g;

  let cicles_reg = /(\(\d\))|(（\d）)/g;
  let cicles = frase_block.match(cicles_reg);
  if (cicles !== null){
    $.each(cicles, function (circle_index, circle) {
      let circle_nums = circle.match(/\d/g);
      if (circle_nums !== null){
        frase_block = frase_block.replace(circle, "<br><span class='sub-frase'></span>"+circle_nums[0]+". ");
      }
    });
  }

  let rects_reg = /\[.\]/g;
  frase_block = frase_block.replace(rects_reg, "");

  var zh_string = "";
  var en_string = frase_block;

  let zh_matches = frase_block.match(zh_reg);
  if (zh_matches != null){
    zh_matches = zh_matches.filter(function (zh_match) {
      return zh_match.replace(/\s/g,"") !== "";
    });

    zh_string = zh_matches.join("");
    en_string = frase_block.replace(zh_reg, "");
  }

  return [en_string, zh_string];
}


const WordShell = {
  Shell:Shell,
  makeWordShell_IT:makeWordShell_IT,
  makeWordShell_FR:makeWordShell_FR,
  makeWordShell_EN:makeWordShell_EN
};
export default  WordShell;