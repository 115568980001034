import {useEffect} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import {IframeNavPresentPage, IframeNavBack} from '../../../tools/uikit/IframeNav'
import './WordsPicker.css';
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react/dist/index";
import {ellipsisHorizontal} from "ionicons/icons/index";
import {IframeWrapper, IframeWrapperDelegate} from "../../../tools/uikit/IframeWrapper";
import {Settings} from "../../../tools/Settings";
import Keys from "../../../tools/Keys";
import {MenuSelectList, presentMenuLists} from "../../elements/MenuSelectList";
import StudyDataController from "../../../tools/StudyDataController";
import Alert from "../../../tools/Alert";

//🍉instance
// var instance = {
//   showUP : function () {
//     IframeNavPresentPage(WordsPicker, "WordsPicker", false);
//   }
// };
// Object(window.top).WordsPicker = instance;

var mList = null;

export const presentWordsPicker = (list=null) => {
  mList = list;
  IframeNavPresentPage(WordsPicker, "WordsPicker", false);
}

//🍉init
const WordsPicker = () => {
  useEffect(() => {
    //console.log("🐞WordsPicker ready!");
  });

  var mPairs = [];

  const delegate = IframeWrapperDelegate(
    function (){
      //console.log("🐞IframeJSX.dom receive delegate!');
    },
    function (){

    },
    function (){

    },
    function (){

    },
    function (CallBackName, Objects){
      //console.log("🐞WordsPicker receive calldevice:", CallBackName, Objects);
      if (CallBackName == "FINISH-MAKE-WORDS"){
        mPairs = Objects;

        if (mList == null){
          presentMenuLists(true);
        }else{
          onClickList(mList);
        }
      }
    }
  );

  const onClickList = (list) => {
    let words = mPairs.map((pair)=>{
      return StudyDataController.WordModel.initFromValues(
        pair.word,
        pair.mean,
        list.unico_id,
        'NewAdd',
        999,
        Settings.getUserEmail(),
        list.preferred_language,
        null,
        true
      );
    });

    //copy
    StudyDataController.copyWordsBy(list.unico_id, words, function (skiped_num) {
      let html_message = skiped_num > 0 ?
        "总共"+words.length+"个单词, 跳过了"+skiped_num+"个重复单词":"";
      Alert.showSuccessAlert(
        "已添加到"+list.list_name,
        html_message,
        '好的',
        true
      );

      setTimeout(()=>{
        IframeNavBack();
      },2000);
    });
  };

  return (
    <IonPage id="WordsPicker">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="返回" defaultHref="/" onClick={(e)=>{e.preventDefault();}}/>
          </IonButtons>
          <IonTitle>{mList != null ? mList.list_name : ""}</IonTitle>
          <IonButtons slot="end">
            {/*<IonButton className="menu-button">*/}
              {/*<IonIcon className="color-dark" slot="icon-only" icon={ellipsisHorizontal}/>*/}
            {/*</IonButton>*/}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="WordsPickerContent">
        <IframeWrapper src={Keys.Path.page_wordsmaker} delegate={delegate}/>
      </IonContent>

      <MenuSelectList onClickList={onClickList}/>

    </IonPage>
  );
};

export default WordsPicker;
