import {useEffect, useRef} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import {IframeNavPresentPage, IframeNavPresentFragment} from '../../tools/uikit/IframeNav'
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react/dist/index";
import {ellipsisHorizontal} from "ionicons/icons/index";
import $ from "jquery";
import Alert from "../../tools/Alert";
import TimeController from "../../tools/TimeController";
import * as Tools from "../../tools/Tools";
import * as mark from "../../tools/sdk/jquery-plugins/jquery.mark";
import './SearchDetail.css';
import Keys from "../../tools/Keys";
import {Settings} from "../../tools/Settings";
import StudyDataController from "../../tools/StudyDataController";
import NativeTools from "../../tools/NativeTools";
import SearchDetailNavBar from "./SearchDetailNavBar";


//🍉instance
var instance = {
  showUP : function (WordString, MeanString) {
    //console.log("🐞SearchDetail ShowUP:",WordString);

    //🔋mdx require
    if (window.top.MdxService.validateDictionarys() != true){
        return;
    }

    IframeNavPresentPage(
      SearchDetail, "SearchDetail",
      {WordString:WordString, MeanString:MeanString},
      false
    );
  },
  showFragment : function (Context, WordString, MeanString) {
    //console.log("🐞SearchDetail showFragment:",WordString);
    IframeNavPresentFragment(
      Context,
      SearchDetail, "SearchDetail",
      {WordString:WordString, MeanString:MeanString}
    );
  }
};
Object(window.top).SearchDetail = instance;

//🍉setup
function setup(Context, Word, WordString, MeanString) {
  //🍋init
  var mDom = Context;
  var mWord = Word;
  var mWordString = WordString;
  var mMeanString = MeanString;
  tryFetchMainpartThenRedisplay();

  /*************************************************
   * 🍋显示内容
   *************************************************/
  function tryFetchMainpartThenRedisplay(){
    if (mDom == null){
      //console.log("🐞SearchDetail dom not found");
      return;
    }

    if (Tools.isEmpty(mWordString) != true){
      window.top.mdxFetchMainpart(mWordString, mMeanString, function (mainparts) {
        if (mainparts != null){
          tryRedisplayDetail(mainparts);
        }
      });
    }else{
      //....
      //console.log("🐞SearchDetail word not found");
      return;
    }
  }
  function tryRedisplayDetail(Results){

    //empty
    if (Results.length <= 0){
      //console.log("🐞无结果显示");
      return;
    }

    // todo:
    // //current_word
    // SearchDetail.displayResultIndex(mNavSearchDetail.getIframeContents(), Results[0]);

    //contents
    let mainparts = [];
    $.each(Results, function (i, result) {
      let result_mainpart = result.result_mainpart;
      if (!Tools.isEmpty(result_mainpart)){
        mainparts.push(result_mainpart);
      }
    });
    renderContent([mainparts]);
  }
  //todo:
  //🍓Headers
  // var SearchDetail = {
  //   mCurrentResult:null,
  //   renderContent: renderContent,
  //   displayResultIndex:displayResultIndex,
  // }
  //
  // //apply Context To window
  // Object(window.top).SearchDetail = SearchDetail;
  
  var mJsonArray;

  //🍓渲染内容
  function renderContent(JsonArray){

    mJsonArray = Object.clone(JsonArray);

    //+ empty mask
    // if (SearchDetail.mCurrentResult != null && Tools.isEmpty(mWordString) == false){
    //     $("#empty-mask").fadeOut(0);
    // }else{
    //     $("#empty-mask").fadeIn(200);
    //     return;
    // }
    $("#empty-mask").fadeOut(100);
    if (Tools.isEmpty(mDom)==false){ mDom.find("#empty-mask").fadeOut(100); }

    //todo:
    //+ appunto
    // if (window.isFrame && SearchDetail.mCurrentResult){
    //   let match_appuntis = window.top.StudyDataController.allAppuntis.filter(function (appunto) {
    //     return appunto.parent_word == mWordString;
    //   });
    //   if (match_appuntis.length > 0){
    //     if (Tools.isEmpty(match_appuntis[0].content)==false && match_appuntis[0].content.trim() != ''){
    //       let lines_br = match_appuntis[0].content.replace(/\n/,'<br>');
    //       JsonArray[0] = ["<div id='appuntoDiv' class='TouchAction'>"+lines_br+"</div>"].concat(JsonArray[0]);
    //     }
    //   }
    // }

    //+ contents
    const content_div = mDom.find('#content');;
    content_div.empty();
    content_div.append(JsonArray[0]);

    //todo:
    //隐藏图片
    // $('img').error(function(){
    //   $(this).fadeOut(0);
    // });

    //优化变位表
    refineOrigintable();

    //优化修改内容
    debounce_for_apply_modify(function () {
      tryDownloadModifysThenApply(true);
    }, 1000);
  }
  
  //🍓刷新渲染
  function refreshContent(){
    renderContent(mJsonArray);
  }
  
  //🍓展开释义
  mDom.on('click',".functionButton", function () {
    let parent_dizio_div = $(this).closest('.one-dizio');

    //显示
    if (parent_dizio_div.attr("class").includes("expanding") == false){
      parent_dizio_div.addClass('expanding');
      //$(this).parent().css({"boxShadow":"0 5px 15px #a5a5a5"});//外部DIV
      //$(this).parent().children(".checkerDIV").fadeIn(100);//释义DIV
      //$(this).css({"backgroundColor":"#f9f9f9"});//头部

      let findFrame = parent_dizio_div.find('iframe');
      if (findFrame.length > 0){
        expandIframe(findFrame[0]);
      }
    }
    //隐藏
    else{
      parent_dizio_div.removeClass('expanding');
      //$(this).parent().css({"boxShadow":"0 1px 2px #cecece"});
      //$(this).parent().children(".checkerDIV").fadeOut(100);
      //$(this).css({"backgroundColor":"white"});//头部
    }
  });

  function expandIframe(obj) {
    //if (obj.contentWindow && obj.contentWindow.documentElement){
    if (window.env && window.env.platform != 'ios'){
      obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
    }
  }

  //🍓显示例句
  mDom.on('click',".sommarioProperty li", function () {
    //显示释义
    mDom.find("#Main").closest('.one-dizio').addClass('expanding');

    //获取当前点击
    var theID = $(this).find("a").attr("href");
    theID = theID.replace("#", "");
    var ScrollDistance = $("#"+theID).offset().top-100;

    //console.log("🐞ScrollDistance:",ScrollDistance);

    //滑动到当前点击
    mDom.find('#searchdetail-content').animate({
      scrollTop: ScrollDistance
    }, 300);
    mDom.find('#searchdetail-content').scrollTop(ScrollDistance);

    //高亮
    $(".Targeted").removeClass("Targeted");
    $("#"+theID).addClass("Targeted");
  });

  //🍓发音
  mDom.on('click','.alias',function (e) {
    e.preventDefault();
    e.stopPropagation();
    NativeTools.tryRequireOfflineTTS();
    NativeTools.speakWord(mWord);
  });

  //🍓翻译
  mDom.on('click','#translate_button', function (e) {
    e.stopPropagation();
    window.location.href = "action://translate";
  });

  //🍓激活
  mDom.on('touchstart',".purchase_button",function (e) {
    e.preventDefault();
    e.stopPropagation();
    Tools.triger_call_device("GLOBAL:PAYMENT-OPEN",null);
  });

  //🍓优化IT:Origintable
  function refineOrigintable(){
    //console.log("🐞refineOrigintable:", mDom.find(".Origintable.it"));
    if (mDom.find(".Origintable.it").length <= 0){
      return;
    }

    //color
    $(".Origintable.it font").each(function(){
      let thisBackcolor = HexString($(this).css("color"));
      if (thisBackcolor == "#ffffff"){
        $(this).addClass("SectionTitle");
      }else if(thisBackcolor == "#009900"){
        var theText = $(this).html().replace(":", "") ;
        $(this).html(theText);
        $(this).addClass("IndiceTitle");
      }else if(thisBackcolor == "#0000ff"){
        $(this).css({color:"#00a5ff"});
      }
    });

    //table
    let content_html = $(".Origintable.it").html();

    //@new row: starts with "\s io" , ends with not "new tempo | new modo"
    let row_reg = /\s+(mi|io)((?!(<BR>|<font style="color: #FFFFFF; background-color: #800000;)).)*(<BR>)*/gi;

    let row_matchs = content_html.match(row_reg);
    if (row_matchs != null){
      for (let row_match of row_matchs){
        //@Depracated!
        // let row_match_replaced = row_match;
        // let person_reg = /(io|tu|lui|noi|voi|loro|mi|ti|ci|vi|si)((?!(\sio|\stu|\slui|\snoi|\svoi|\sloro|\smi|\sti|\sci|\svi|\ssi|,|<BR>)).)*,*\s*/gi; ///(io|tu|lui|noi|voi|loro)(\s[^,<]+)+,*\s*/g;
        // let person_matchs = row_match.match(person_reg);
        // if (person_matchs != null){
        //     for (let person_match of person_matchs){
        //         row_match_replaced = row_match_replaced.replace(person_match, "<span class='person'>"+person_match.replace(',' ,'')+"</span>")
        //     }
        // }

        let row_match_replaced = row_match;

        let persons = row_match.split(",");
        if (persons.length == 6){
          persons = persons.map(function (person) {
            return "<span class='person'>"+person.replace(',' ,'')+"</span>";
          });
          row_match_replaced =
            "<span class='group'>" +
            persons[0] +
            persons[1] +
            persons[2] +
            "</span>" +
            "<span class='group'>" +
            persons[3] +
            persons[4] +
            persons[5] +
            "</span>";
        }

        content_html = content_html.replace(row_match, "<span class='row'>"+row_match_replaced+"</span>");
      }
    }

    $(".Origintable.it").html(content_html);
  }
  function HexString(rgb){
    rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? "#" +
      ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
      ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
      ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
  }


  /*************************************************
   * 🍋NavBar: 控制NavBar的显示
   *************************************************/
  //@Depracated!
  //frame
  // if( window.isFrame ){
  //   $("#navbar").fadeIn(0);
  // }

  //🍓bookmark-button.click
  mDom.click(function () {
    mDom.find('.lists').addClass('hidden');
  });
  mDom.on('click',".bookmark-button > .btn", function (e) {
    e.stopPropagation();
    e.preventDefault();

    setupBookMarkButton();
    mDom.find('.lists').toggleClass('hidden');
  });
  function setupBookMarkButton() {

    //BookMark
    //ui
    if (Context.find('.lists').length <= 0){
      Context.append($('<div class="lists dropdown-menu scroller-hidden"></div>'));
    }
    Context.find('.lists').empty();

    var isSaved = false;

    if (StudyDataController.allNewAddLists.length > 0){
      for (let list of StudyDataController.allNewAddLists){
        let list_elm = $('<a class="dropdown-item list"></a>');
        list_elm.text(list.list_name);
        list_elm.attr('unico_id', list.unico_id);

        if (
          StudyDataController.allNewAddWords.filter(function (word) {
            return word.word_string == mWordString && word.parent_list_unico_id == list.unico_id;
          }).length > 0
        ){
          list_elm.addClass('action_remove_word');
          list_elm.append('<span class="check">✓</span>');
          isSaved = true;
        }else{
          list_elm.addClass('action_add_word');
        }
        Context.find('.lists').append(list_elm);
      }
    }else if (StudyDataController.isFetching){
      let list_elm = $('<a class="dropdown-item list disabled">正在获取列表, 请稍后重试</a>');
      Context.find('.lists').append(list_elm);
    }else{
      let list_elm = $('<a class="dropdown-item list disabled">您还没有单词本哦</a>');
      Context.find('.lists').append(list_elm);
    }

    if (isSaved){
      Context.find(".bookmark-button .icon").attr('src','assets/icons/search-detail/SDetail%20-%20InBook@30X30.png');
    }else{
      Context.find(".bookmark-button .icon").attr('src','assets/icons/search-detail/SDetail - UnBook@30X30.png');
    }

    //actions
    Context.find(".lists .list").click(function () {
      let unico_id = $(this).attr('unico_id');
      let class_name = $(this).attr('class');

      //remove
      if (class_name.includes('action_remove_word')){
        StudyDataController.archiveWordsBy([mWordString], unico_id);
        StudyDataController.regroupStudyData();
      }
      //add
      else{
        StudyDataController.insertWordBy(unico_id, mWordString, mMeanString);
        StudyDataController.regroupStudyData();
      }

      //reload
      setupBookMarkButton();
    });
  }

  //🍓voice-button.click
  mDom.on('click','#voice-button',function () {
    //console.log("🐞speak:",mWordString);
    NativeTools.tryRequireOfflineTTS();
    NativeTools.speakWord(mWord);
  });

  //🍓todo: edit-button.click
  // mDom.on('click',"#note-button", function () {
  //   window.top.NoteEditor.showEditorWithWord(
  //     window.top.$('body'),
  //     SearchDetailNavBar.mCurrentResult.result_index,
  //     SearchDetailNavBar.mCurrentResult.result_mean,
  //     function () {
  //       window.refreshContent();
  //     });
  // });


  /*************************************************
   * 🍋图片:
   *************************************************/

  $("#cover_image_div").click(function () {
    window.location.href = "action://image_selector";
  });

  //🍓图片加载失败: Niubility Mdx Image / FangeWords ImageLoader)
  $(document).ready(function () {
    //todo: 验证有效性
    $(document).on('error','img',function () {
      $(this).fadeOut(0);
    });

    // $('img').error(function(){
    //   $(this).fadeOut(0);
    // });
  });

  
  /*************************************************
   * 🍋纠错/添加释义
   *************************************************/

  const kModifyDownloadLink = "STUDY-MODIFY-CACHE-DOWNLOAD-LINK-1.0";
  const kModifyUpdatedAt = "STUDY-MODIFY-UPDATE-DATE-1.0";

  let menu = $('#highlight_menu');

  let dizio_dom;
  let selected_dizio_dom;
  let selected_dizio_name;
  let selected_text;
  let selected_html;
  let selected_range;

  let modify_editor = $("#modify-editor-wrapper");

  //showMenu
  $('body').on('mouseup touchend contextmenu', '.one-dizio', function (evt) {
    if (evt.type == "contextmenu"){
      evt.preventDefault();
    }

    dizio_dom = $(this)[0];
    selected_dizio_name = $(this).attr('data-dizio-name');

    //console.log("🐞selected_dizio_name",selected_dizio_name);

    debounce_for_text_selection(function (evt) {

      var selection = document.getSelection(),
        range = selection.rangeCount > 0 ? selection.getRangeAt(0) : undefined;

      selected_dizio_dom = getSelectionHtmlContent(dizio_dom);

      if (range && selection.toString()) {
        updateSelectedContent();
        //console.log("🐞selected_text",selected_text);

        //show Menu
        if (window.top.env.platform == 'windows' || window.top.env.platform == 'web'){
          var position = range.getBoundingClientRect();
          if (position.left || position.top) {
            menu.css({
              left: (position.left + (position.width / 2)) - (menu.width() / 2),
              top: (position.top - menu.height() - 10),
              display: 'block',
              opacity: 0
            })
              .animate({
                opacity:1
              }, 100);

            setTimeout(function() {
              menu.addClass('highlight_menu_animate');
            }, 10);
          }
        }

        return;
      }

      //hide Menu
      clearSelection();
      hideMenu();

    }, 500);
  });
  function updateSelectedContent(){
    var selection = document.getSelection();
    selected_dizio_dom = getSelectionHtmlContent(dizio_dom);
    selected_text = selection.toString();
    selected_range = getSelectionRangeIn(dizio_dom);
    selected_html = getSelectionHtmlContent(dizio_dom);
  }
  window.updateSelectedContent = updateSelectedContent;
  
  //hideMenu
  menu.on('click', function () {
    hideMenu();
  });

// $('#searchdetail-content').on('scroll', function () {
//     hideMenu();
// });

  function hideMenu(){
    menu.animate({ opacity:0 }, 100, function () {
      menu.hide().removeClass('highlight_menu_animate');
    });
  }

//🍓modify-editor
  menu.on('click', '.item-modify', function () {
    trigerModifySelection(false);
  });
  menu.on('click', '.item-translate', function () {
    Alert.showNotification('翻译功能正在维护<br>现在请通过查词框翻译哦', Alert.icons.info);
  });

  function trigerModifySelection(UpdateSelection=true){

    if (UpdateSelection){
      updateSelectedContent();
    }

    if (selected_dizio_dom.includes('modified-content')){
      alert('目前不支持在包含修改的段落重复修改');
      return;
    }

    //console.log("🐞selected_text",selected_text);

    if (selected_text.trim().length < 3){
      alert('暂不支持过少的修改内容');
      return;
    }

    //showEditor
    showEditorWith(selected_text);
  }
  window.trigerModifySelection = trigerModifySelection;

//show/hide/upload
  function showEditorWith(Content){
    modify_editor.find('textarea').val(Content);
    modify_editor.fadeIn(0);
    modify_editor.find('textarea').focus();
  }
  modify_editor.on('click', '.cancel-button', function () {
    modify_editor.fadeOut(0);
  });
  modify_editor.on('click', '.confirm-button', function () {
    modify_editor.fadeOut(0);
    let new_content = modify_editor.find('textarea').val();
    tryUploadModify(new_content);
  });

  function getSelectionRangeIn(element) {
    var startOffset = 0, endOffset = 0;

    var range = document.getSelection().getRangeAt(0);
    var preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.startContainer, range.startOffset);
    startOffset = preCaretRange.toString().length;
    endOffset = startOffset + range.toString().length;

    return { start: startOffset, end: endOffset };
  }
  function getSelectionRangeParentContainer ( range ) {
    return range.parentElement ? range.parentElement() : range.commonAncestorContainer;
  }
  function getSelectionHtmlContent(Node) {
    var html = "";
    if (typeof document.getSelection != "undefined") {
      var sel = document.getSelection();
      if (sel.rangeCount) {
        var container = document.createElement("div");
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
          container.appendChild(sel.getRangeAt(i).cloneContents());
        }
        html = container.innerHTML;
      }
    } else if (typeof document.selection != "undefined") {
      if (document.selection.type == "Text") {
        html = document.selection.createRange().htmlText;
      }
    }
    return html;
  }
  function setSelectionRangeIn(el, start, end) {
    if (document.createRange && document.getSelection) {
      var range = document.createRange();
      range.selectNodeContents(el);
      var textNodes = getTextNodesIn(el);
      var foundStart = false;
      var charCount = 0, endCharCount;

      for (var i = 0, textNode; textNode = textNodes[i++]; ) {
        endCharCount = charCount + textNode.length;
        if (!foundStart && start >= charCount
          && (start < endCharCount ||
            (start == endCharCount && i <= textNodes.length))) {
          range.setStart(textNode, start - charCount);
          foundStart = true;
        }
        if (foundStart && end <= endCharCount) {
          range.setEnd(textNode, end - charCount);
          break;
        }
        charCount = endCharCount;
      }

      var sel = document.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (document.selection && document.body.createTextRange) {
      var textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(true);
      textRange.moveEnd("character", end);
      textRange.moveStart("character", start);
      textRange.select();
    }

    function getTextNodesIn(node) {
      var textNodes = [];
      if (node.nodeType == 3) {
        textNodes.push(node);
      } else {
        var children = node.childNodes;
        for (var i = 0, len = children.length; i < len; ++i) {
          textNodes.push.apply(textNodes, getTextNodesIn(children[i]));
        }
      }
      return textNodes;
    }
  }
  function replaceSelectionWithHtml(html) {
    var range;
    if (window.getSelection && window.getSelection().getRangeAt) {
      range = window.getSelection().getRangeAt(0);

      var div = document.createElement("div");
      div.innerHTML = html;
      var frag = document.createDocumentFragment(), child;
      while ( (child = div.firstChild) ) {
        frag.appendChild(child);
      }

      range.deleteContents();
      range.insertNode(frag);
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      range.pasteHTML(html);
    }
  }
  function clearSelection() {
    if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {  // IE?
      document.selection.empty();
    }
  }

  function trySetContentOfRangeIn(DizioName, Range, OrigContent, NewContent){
    //补充笔记
    if (Range==null){
      let addtion = $('<pre class="addition"></pre>');
      addtion.html(NewContent);
      $("#content").prepend(addtion);
      return;
    }

    //替换笔记
    let blocks = $(".one-dizio[data-dizio-name='"+DizioName+"']");
    $.each(blocks, function () {
      let block_elm = $(this)[0];
      setSelectionRangeIn(block_elm, Range.start, Range.end);
      let selected_dom = getSelectionHtmlContent(block_elm);
      if (selected_dom.includes('modified-content') == false){
        let new_content = $("<span class='modified-content'></span>");
        new_content.append(NewContent);
        new_content.append("<span class='original-content' style='display: none;'>"+OrigContent+"</span>");
        let replaced_html = $(this).html().replaceAll(OrigContent, new_content[0].outerHTML);
        $(this).html(replaced_html);

        // let current_text = document.getSelection().toString();
        // //console.log("🐞compare:',current_text.localeCompare(OrigContent), current_text, OrigContent);
        // if (current_text.localeCompare(OrigContent)==0 || current_text == OrigContent){
        //     let new_content = $("<span class='modified-content'></span>");
        //     new_content.append(NewContent);
        //     new_content.append("<span class='original-content' style='display: none;'>"+OrigContent+"</span>");
        //     replaceSelectionWithHtml(new_content[0].outerHTML);
        // }
        // clearSelection();
      }

      // let block_elm = $(this)[0];
      // setSelectionRangeIn(block_elm, Range.start, Range.end);
      // let current_text = document.getSelection().toString();
      // //console.log("🐞compare:',current_text.localeCompare(OrigContent), current_text, OrigContent);
      // if (current_text.localeCompare(OrigContent)==0 || current_text == OrigContent){
      //     let new_content = $("<span class='modified-content'></span>");
      //     new_content.append(NewContent);
      //     new_content.append("<span class='original-content' style='display: none;'>"+OrigContent+"</span>");
      //     replaceSelectionWithHtml(new_content[0].outerHTML);
      // }
      // clearSelection();
    });
  }
  String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
  };

  $('body').on('click', '.modified-content', function (e) {
    e.stopPropagation();
    if ($(this).find('.original-content').attr('style').includes('none')){
      $(this).find('.original-content').fadeIn(0);
    }else{
      $(this).find('.original-content').fadeOut(0);
    }

  });
  $('body').on('click', function () {
    $('.original-content').fadeOut(0);
  });

  let mModifys;
  function tryDownloadModifysThenApply(WithCache) {
    hideMenu();

    //@update cache
    let today_timestring = TimeController.getTimeStringByDay();
    let update_date = localStorage.getItem(kModifyUpdatedAt);
    if (update_date == null || update_date != today_timestring){
      WithCache = false;
      localStorage.setItem(kModifyUpdatedAt, today_timestring);
    }

    //@cache control:
    let download_link;
    let cached_url = localStorage.getItem(kModifyDownloadLink);
    if (cached_url != null && WithCache){
      download_link = cached_url;
    }else{
      download_link = Keys.Url.study_modify_download_link
        + "?timestamp=" + TimeController.getTimeStringByMinites().replace(/\s/,"_");
      localStorage.setItem(kModifyDownloadLink, download_link);
    }

    //download
    $.get(download_link, function(data, status){
      let Modifys = JSON.parse(data);
      if (Modifys != null){
        mModifys = Modifys;
      }
      let matchModifys = mModifys.filter(function (modify) {
        return modify['parent_word'] == mWordString;
      });
      //console.log("🐞modify", Modifys, matchModifys);
      for (let modify of matchModifys){
        trySetContentOfRangeIn(
          modify['parent_dizio_name'],
          modify['range'],
          modify['orig_content'],
          modify['new_content'],
        );
      }
    });
  }
  window.tryDownloadModifysThenApply = tryDownloadModifysThenApply;

  function tryUploadModify(NewContent) {
    let upload_link = Keys.Url.study_modify_upload_link;
    let data_string = JSON.stringify({
      parent_user_email: Settings.getUserEmail(),
      parent_word:mWordString,
      parent_dizio_name:selected_dizio_name,
      range:selected_range,
      orig_content:selected_html,
      new_content:NewContent,
    });
    //console.log("🐞upload:", upload_link, data_string);
    Alert.showLoadingAlert('正在提交修改','感谢您对词典质量的提升');
    $.ajax({
      type: "POST",
      url: upload_link,
      data: data_string,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      success: function (data) {
        //console.log("🐞提交结果:',data);
        let results = data;
        if (results != null){
          let reuslt_code = results['result_code'];
          if (reuslt_code === 'success'){
            tryDownloadModifysThenApply(false);
            Alert.showSuccessAlert('提交成功',null,null,true);
            return;
          }
        }
        Alert.showWarningAlert('😨 似乎遇到一些错误',data,"好的");
      }
    });
  }
  window.tryUploadModify = tryUploadModify;

  //debounce
  var timeout = null;
  function debounce_for_text_selection(fn, wait) {
    if(timeout !== null)
      clearTimeout(timeout);
    timeout = setTimeout(fn, wait);
  }

  var timeout_x = null;
  function debounce_for_apply_modify(fn, wait) {
    if(timeout_x !== null)
      clearTimeout(timeout_x);
    timeout_x = setTimeout(fn, wait);
  }

  /*************************************************
   * 🍋Settings
   *************************************************/

  localStorage.settings = {
    cookiePrefix : 'iofange.com/search_detail_settings', // Prefix for the Local Storage substitution cookies
    cookieOptions : {
      expires: 365 // Days left for cookie expiring
    }
  };

  //开启关闭
  mDom.on('click', function () {
    mDom.find("#settings_div").addClass('hidden');
  });
  mDom.off('click', '#setting-button');
  mDom.on('click', '#setting-button', function (e) {
    e.stopPropagation();
    //console.log("🐞click setting-button");
    clickFontOptions();
  });
  $(".close").click(function (e) {
    e.stopPropagation();
    mDom.find("#settings_div").addClass('hidden');
  });
  function clickFontOptions() {
    mDom.find("#settings_div").toggleClass('hidden');
  }

//storage
  function setStorage(key, value) {
    localStorage.setItem(key, value);
  }
  function getStorageBy(key) {
    return localStorage.getItem(key);
  }
//大小
  function getOptionFontSize() {
    let size = getStorageBy('OPTION-FONT-SIZE');
    if (size === null){
      size = '12px';
    }
    return size;
  }
//粗细
  function getOptionFontWeight() {
    let font = getStorageBy('OPTION-FONT-WEIGHT');
    if (font === null){
      font = '400';
    }
    return font;
  }
//字体
  function setOptionFontFamily(FONT) {
    setStorage('OPTION-FONT-FAMILY',FONT);
  }
  function getOptionFontFamily() {
    let font = getStorageBy('OPTION-FONT-FAMILY');
    if (font === null){
      font = 'sans-serif';
    }
    return font;
  }
//标记
  function getOptionFontUderline() {
    let underline = getStorageBy('OPTION-FONT-UNDERLINE');
    if (underline === null){
      underline = 'shown';
    }
    return underline;
  }

//开始渲染
  function refetchOptionsThenReload(){
    let font_size = getOptionFontSize();
    let font_weight = getOptionFontWeight();
    let font_family = getOptionFontFamily();
    let font_underline = getOptionFontUderline();

    $(".option").removeClass('active');
    $(".option[title="+font_size+"]").addClass('active');
    $(".option[title="+font_weight+"]").addClass('active');
    $(".option[title="+font_family+"]").addClass('active');
    $(".option[title="+font_underline+"]").addClass('active');

    //cast font fantasy
    //if (font_family === "fantasy"){
    //    font_family = "'SnellRoundhand', fantasy";
    //}
    mDom.attr('font_size',font_size);
    mDom.attr('font_weight',font_weight);
    mDom.attr('font_family',font_family);
    $('html')[0].style.setProperty('font-size',font_size,'important');
    mDom[0].style.setProperty('font-weight',font_weight,'important');
    mDom[0].style.setProperty('font-family',font_family,'important');

    if (font_underline === 'shown'){
      mDom.addClass('underline');
    }else{
      mDom.removeClass('underline');
    }
  }

//事件刷新
  $(document).ready(function () {
    refetchOptionsThenReload();
  });
  $(".option").click(function (e) {

    //e.stopPropagation();

    let option_value = $(this).attr('title');
    let option_key = $(this).parent().attr('title');

    setStorage(option_key, option_value);

    refetchOptionsThenReload();
  });


//🍓选择图片
  $(document).on('click',"#settings_div section.image",function () {
    window.location.href = "action://image_selector";
  });
  $(document).ready(function () {
    let ua = window.navigator.userAgent;
    if (ua.includes("android") || ua.includes("/osx/")){
      $("#select_image_button").css({'display':'none'});
    }
  });

//🍓添加释义
  $(document).on('click',"#add_definition_button",function () {

    selected_dizio_name = null;
    selected_range = null;
    selected_html = null;

    if (window.env && window.env.platform == 'ios'){
      Tools.triger_call_device('SHOW-MODIFY-EDITOR');
    }else{
      showEditorWith('');
    }
  });


  //🍋其它
  //listenKeyboard
  $(document).keydown(function (e) {
    //find
    if(e.which == 70 && (e.ctrlKey || e.metaKey)){
      e.preventDefault();
      window.top.focusSearchTextFiled();
    };
  });
}


//🍉init
const SearchDetail = (props) => {

  useEffect(() => {
    setup(
      //context
      $(contentRef.current),
      //word
      props.values ? props.values.Word : null,
      //wordstring
      props.values ? props.values.WordString : null,
      //meanstring
      props.values ? props.values.MeanString : null
    )
  });

  const contentRef = useRef(this);
  return (
    <IonPage className="SearchDetail" ref={contentRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons>
            {/*back*/}
            <IonBackButton text="" defaultHref="/" onClick={(e)=>{e.preventDefault();}}/>

            {/*bookmark*/}
            <div className="nav-item bookmark-button">
              <div className="btn btn-link" type="button" data-toggle="dropdown">
                <img className="icon" src="assets/icons/search-detail/SDetail%20-%20InBook@30X30.png"/>
              </div>
            </div>

            {/*voice*/}
            <div className="nav-item" id="voice-button">
              <img className="icon" src="assets/icons/search-detail/SDetail%20-%20VoiceOutput@30X30.png"/>
            </div>

            {/*todo: note*/}
            {/*<div className="nav-item" id="note-button">*/}
              {/*<img className="icon" src="assets/icons/search-detail/SDetail%20-%20Note.png"/>*/}
            {/*</div>*/}

            {/*setting*/}
            <IonButton className="menu-button" id="setting-button">
              <IonIcon className="color-dark" slot="icon-only" icon={ellipsisHorizontal}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="SearchDetailContent">
        <div className="flex-wrapper safe-area-top">

          {/*content*/}
          <div id="searchdetail-content" className="scroller-hidden">

            {/*@图片*/}
            <div id="cover_image_div" className=""><img id="cover_img" className="hidden"/></div>

            {/* @单词显示 */}
            <div id="content"></div>

          </div>

          {/*empty-mask*/}
          <div id="empty-mask">
            <img src="assets/background/empty_img_searchdetail.png"/>
          </div>

          {/*modify-pop-menu*/}
          <div id="highlight_menu" className="hidden">
            <ul className="items-wrapper">
              <li className="item-translate"><i className="fa fa-language"></i>翻译</li>
              <li className="item-modify"><i className="fa fa-quote-left"></i>修改</li>
            </ul>
            <div className="caret">
            </div>
          </div>

          {/*modify-editor*/}
          <div id="modify-editor-wrapper" className="hidden">
            <div className="nav-bar">
              <div className="cancel-button">取消</div>
              <div className="confirm-button">确认</div>
            </div>
            <textarea placeholder="输入内容"></textarea>
          </div>

          {/*mark-list*/}
          <div className="lists dropdown-menu scroller-hidden hidden"></div>

          {/*SettingsPanel*/}
          <div id="settings_div" className="hidden">
            <div className="blur-effect-panel dark">

              {/*todo*/}
              {/*<section id="add_definition_button" className="panel">*/}
              {/*<div className="title-button">补充词条</div>*/}
              {/*</section>*/}

              {/*todo*/}
              {/*<section id="select_image_button" className="panel image disabled">*/}
              {/*<div className="title-button">选择图片</div>*/}
              {/*</section>*/}

              <section className="size" title="OPTION-FONT-SIZE">
                <div className="title">文字大小</div>
                <div className="option" title="10px">10px</div>
                <div className="option" title="12px">12px</div>
                <div className="option active" title="14px">14px</div>
                <div className="option" title="16px">16px</div>
                <div className="option" title="18px">18px</div>
              </section>

              <section className="weight" title="OPTION-FONT-WEIGHT">
                <div className="title">文字粗细</div>
                <div className="option" title="200">纤细</div>
                <div className="option active" title="400">普通</div>
                <div className="option" title="800">加粗</div>
              </section>

              <section className="font" title="OPTION-FONT-FAMILY">
                <div className="title">字体</div>
                <div className="option active" title="serif">衬线字体 serif</div>
                <div className="option" title="sans-serif">无衬线字体 sans-serif</div>
                <div className="option" title="monospace">复古打字机 monospace</div>
                <div className="option" title="fantasy">手写体 fantasy</div>
              </section>

              <section className="underline" title="OPTION-FONT-UNDERLINE">
                <div className="title onemean"><span>主释义高亮标记</span></div>
                <div className="option active" title="shown">显示</div>
                <div className="option" title="hidden">隐藏</div>
              </section>

            </div>
          </div>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default SearchDetail;
