import {useEffect, useRef} from 'react';
import $ from "jquery";
import Tools from '../../../tools/Tools';
import "./CardsSetting.css"

//🍋attach settings panel
// $(document).ready(function () {
//   $("#cards-setting-wrapper").load('Apps/StudyPlan/CardsVCor/CardsSetting.html', function () {
//     //update options
//     refetchThenUpdateOptions();
//
//     //bind calldevice
//     Tools.CallDeviceContext = window;
//   });
// });

const CardsSetting = (props) => {

  //🍉setup
  useEffect(() => {
    var mDom = $(contentRef.current);
    //console.log("🐞cards setting:",mDom);

    //🍋show/hide
    $('body').click(function () {
      hideSettingsPanel();
    })
    mDom.find("#mode-button").click(function (e) {
      e.stopPropagation();
      toggleSettingsPanel();
    })

    function toggleSettingsPanel() {
      //console.log("🐞toggle!")

      //toggle
      mDom.toggleClass('hidden');

      //update options
      refetchThenUpdateOptions();
    }

    function hideSettingsPanel() {
      mDom.addClass('hidden');
    }

    function showSettingsPanel() {
      //update options
      refetchThenUpdateOptions();

      //show
      mDom.removeClass('hidden');
    }

    //🍋options storage
    const StudyCardsOptionKey = {
      OptionMode: "STUDY-CARDS-MODE-1.0",
      OptionShowMemo: "STUDY-CARDS-SHOW-MEMO-1.0",
      OptionAutoSpeak:"STUDY-CARDS-AUTO-SPEAK-1.0",
    }
    Object(window.top).StudyCardsOptionKey = StudyCardsOptionKey;

    var mOptionMode = 'mode_slide_rate',
      mOptionShowMemo = true,
      mOptionAutoSpeak = true,

      refetchOptions = () => {
        let getOptionMode = localStorage.getItem(StudyCardsOptionKey.OptionMode);
        if (getOptionMode != null) {
          mOptionMode = getOptionMode;
        }
        let getOptionShowMemo = localStorage.getItem(StudyCardsOptionKey.OptionShowMemo);
        if (getOptionShowMemo != null) {
          mOptionShowMemo = getOptionShowMemo == "true";
        }
        let getOptionAutoSpeak = localStorage.getItem(StudyCardsOptionKey.OptionAutoSpeak);
        if (getOptionAutoSpeak != null) {
          mOptionAutoSpeak = getOptionAutoSpeak == "true";
        }
      };

    //🍋update ui
    function refetchThenUpdateOptions() {
      refetchOptions();
      updateOptions({
        option_modes: [mOptionMode],
        option_show_memo: mOptionShowMemo,
        option_auto_speak: mOptionAutoSpeak
      });
    }

    function updateOptions(Settings) {

      //console.log("🐞Settings:",Settings);

      let option_modes = Settings['option_modes'];

      if (option_modes !== undefined) {
        mDom.find(".card").removeClass('active');
        mDom.find(".card").find(".active").remove();

        $.each(option_modes, function (index, option_mode) {
          mDom.find(".card#" + option_mode).append("<span class='active'>" + (index + 1) + "</span>");
          mDom.find(".card#" + option_mode).addClass('active');
        });
      }

      let option_keyboard = Settings['option_keyboard'];
      if (option_keyboard !== undefined) {
        mDom.find(".square").removeClass('active');
        mDom.find("#" + option_keyboard).addClass('active');
      }

      let option_pair_num = Settings['option_pair_num'];
      if (option_pair_num !== undefined) {
        mDom.find("#pair_num").text(option_pair_num);
      }

      let option_remember_mode = Settings['option_remember_mode'];
      if (option_remember_mode !== undefined) {
        mDom.find("#option_remember_mode").prop('checked', option_remember_mode === true);
      }

      let option_show_memo = Settings['option_show_memo'];
      if (option_show_memo !== undefined) {
        mDom.find("#option_show_memo").prop('checked', option_show_memo === true);
      }

      let option_auto_speak = Settings['option_auto_speak'];
      if (option_auto_speak !== undefined) {
        mDom.find("#option_auto_speak").prop('checked', option_auto_speak === true);
      }
    }

    //🍋click options

    //option mode
    mDom.on('click', '.card', function () {
      let id = $(this).attr('id');
      onCallDevice("TOGGLE-OPTION-MODE", id);
    });

    //option keyboard
    mDom.on('click', '#set-keyboards', function () {
      $(".squares-stack").toggleClass('hidden');
    });
    mDom.on('click', '.keyboard', function () {
      let id = $(this).attr('id');
      onCallDevice("TOGGLE-OPTION-KEYBOARD", id);
    });

    //option pair
    mDom.on('click', '#set-pair', function (e) {
      onCallDevice("SET-OPTION-PAIR-NUM", null);
      e.stopPropagation();
    });

    //option switchs
    mDom.on('click', '.option_remember_mode .switch', function () {
      let input = $(this).find('input');
      setTimeout(function () {
        let checked = input.prop('checked');
        onCallDevice("SET-OPTION-REMEMBER-MODE", checked);
      }, 200);
    });
    mDom.on('click', '.option_show_memo .switch', function (e) {

      e.stopPropagation();

      let input = $(this).find('input');
      setTimeout(function () {
        let checked = input.prop('checked');
        onCallDevice("SET-OPTION-SHOW-MEMO", checked);
      }, 200);
    });
    mDom.on('click', '.option_auto_speak .switch', function () {
      let input = $(this).find('input');
      setTimeout(function () {
        let checked = input.prop('checked');
        onCallDevice("SET-OPTION-AUTO-SPEAK", checked);
      }, 200);
    });


    //🍋CallDevice
    function onCallDevice(Action, Object) {
      //console.log("🐞onCallDevice:",Action, Object);

      if (Action == "TOGGLE-OPTION-MODE") {
        localStorage.setItem(StudyCardsOptionKey.OptionMode, Object);
      }

      if (Action == "SET-OPTION-SHOW-MEMO") {
        localStorage.setItem(StudyCardsOptionKey.OptionShowMemo, Object);
      }

      if (Action == "SET-OPTION-AUTO-SPEAK"){
        localStorage.setItem(StudyCardsOptionKey.OptionAutoSpeak, Object);
      }

      //reload
      refetchThenUpdateOptions();

      if (window.top.reloadCards) {
          window.top.reloadCards();
      }
    }


    if (props.onRef) {
      props.onRef({
        getOptionMode: () => {
          return mOptionMode
        },
        getOptionAutoSpeak: ()=>{
          return mOptionAutoSpeak;
        },

        refetchOptions: refetchOptions,
        refetchThenUpdateOptions: refetchThenUpdateOptions,
        toggleSettingsPanel: toggleSettingsPanel,
      });
    }
  });

  const contentRef = useRef(this);
  return (
    <div className="settings-panel-wrapper hidden" ref={contentRef}>
      <div id="settings-panel" className="blur-effect-panel dark scroller-hidden">
        <div className="section">
          <div className="title">认识新单词</div>

          <div className="row">
            <div className="card" id="mode_slide_detail">
              <div className="icon">
                <div className="title">abandon</div>
                <div className="bar bar-y-large bar-x-large"></div>
                <div className="bar bar-y-short bar-x-middle"></div>
              </div>
              <div className="title">单词详情</div>
              <div className="description">显示单词的所有详情, 适合初次学习</div>
              <i className="fa fa-chevron-right"></i>
            </div>

            <div className="card" id="mode_slide_rate">
              <div className="icon">
                <div className="title">abandon</div>
                <div className="bar bar-y-middle bar-x-large bar-color-green"></div>
                <div className="bar bar-y-middle bar-x-large bar-color-blue"></div>
                <div className="bar bar-y-middle bar-x-large bar-color-red"></div>
              </div>
              <div className="title">认识吗</div>
              <div className="description">快速浏览, 选择认识或不认识</div>
              <i className="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="title">选择练习</div>

          <div className="row">

            <div className="card" id="mode_select_zh">
              <div className="icon">
                <div className="title">abandon</div>
                <div className="bar bar-y-short bar-x-large"></div>
                <div className="bar bar-y-short bar-x-middle"></div>
                <div className="bar bar-y-short bar-x-short"></div>
              </div>
              <div className="title">单词选择释义</div>
              <div className="description">根据给出的单词, 选择正确的释义</div>
              <i className="fa fa-chevron-right"></i>
            </div>

            <div className="card" id="mode_select_en">
              <div className="icon">
                <div className="title">遗弃</div>
                <div className="bar bar-y-short bar-x-large"></div>
                <div className="bar bar-y-short bar-x-middle"></div>
                <div className="bar bar-y-short bar-x-short"></div>
              </div>
              <div className="title">释义选择单词</div>
              <div className="description">根据给出的释义, 选择对应的单词</div>
              <i className="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="title">拼写练习</div>

          <div className="row">
            <div className="card" id="mode_spell_word">
              <div className="icon">
                <div className="title">遗弃</div>
                <div className="textfield">aban...</div>
              </div>
              <div className="title">单词拼写</div>
              <div className="description">根据给出的释义, 拼写正确的单词</div>
              <i className="fa fa-chevron-right"></i>
            </div>

            {/*<div class="card" id="mode_spell_frase">*/}
            {/*<div class="icon">*/}
            {/*<div class="title title-long">Don’t ___<br>*/}
            {/*yourself!</div>*/}
            {/*<div class="textfield">aban...</div>*/}
            {/*</div>*/}
            {/*<div class="title">填空拼写</div>*/}
            {/*<div class="description">根据给出的例句, 补填空缺的单词</div>*/}
            {/*</div>*/}
          </div>

          {/*<div class="row">*/}
          {/*<div class="title action" id="set-keyboards">设置拼写方式</div>*/}

          {/*<div class="keyboards squares-stack hidden">*/}
          {/*<div class="keyboard square active" id="keyboard_default">*/}
          {/*<img src="assets/icons/cards-setting/keyboard-default.png"/>*/}
          {/*<div class="title">系统键盘</div>*/}
          {/*</div>*/}

          {/*<div class="keyboard square" id="keyboard_key">*/}
          {/*<img src="assets/icons/cards-setting/keyboard-key.png"/>*/}
          {/*<div class="title">字母拼接</div>*/}
          {/*</div>*/}

          {/*<div class="keyboard square" id="keyboard_piece">*/}
          {/*<img src="assets/icons/cards-setting/keyboard-piece.png"/>*/}
          {/*<div class="title">碎片拼接</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
        </div>

        {/*<div class="section">*/}
        {/*<div class="title">连线配对</div>*/}

        {/*<div class="row">*/}
        {/*<div class="card" id="mode_pair_normal">*/}
        {/*<div class="icon">*/}
        {/*<div class="bars-stack">*/}
        {/*<div class="bar fade bar-y-large bar-x-middle"></div>*/}
        {/*<div class="bar bar-y-large bar-x-middle"></div>*/}
        {/*</div>*/}
        {/*<div class="bars-stack">*/}
        {/*<div class="bar bar-y-large bar-x-middle"></div>*/}
        {/*<div class="bar fade bar-y-large bar-x-middle"></div>*/}
        {/*</div>*/}
        {/*<div class="bars-stack">*/}
        {/*<div class="bar fade bar-y-large bar-x-middle"></div>*/}
        {/*<div class="bar fade bar-y-large bar-x-middle"></div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div class="title">*/}
        {/*单词释义配对*/}
        {/*<a>每<span id="pair_num">5</span>个一组</a>  <span id="set-pair" class="action">设置</span>*/}
        {/*</div>*/}
        {/*<div class="description">选择对应的单词和释义, 将它们一一匹配</div>*/}
        {/*<i class="fa fa-chevron-right"></i>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}

        {/*<div className="section option_show_memo">*/}
          {/*<div className="row">*/}
            {/*<div className="label">显示记忆标记颜色</div>*/}
            {/*<label className="switch">*/}
              {/*<input type="checkbox" id="option_show_memo"/>*/}
              {/*<span></span>*/}
            {/*</label>*/}
          {/*</div>*/}
        {/*</div>*/}

        <div className="section option_auto_speak">
          <div className="row">
            <div className="label">自动发音</div>
            <label className="switch">
              <input type="checkbox" id="option_auto_speak"/>
              <span></span>
            </label>
          </div>
        </div>

        {/*<div class="section option_remember_mode">*/}
        {/*<div class="row">*/}
        {/*<div class="label">记住习惯</div>*/}

        {/*<label class="switch">*/}
        {/*<input type="checkbox" id="option_remember_mode">*/}
        {/*<span></span>*/}
        {/*</label>*/}
        {/*</div>*/}
        {/*</div>*/}
    </div>
  </div>);

}
export default CardsSetting;