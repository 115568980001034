import $ from "jquery";
import * as MParser from "./MdxParser";
import * as MRenderer from "./MdxRenderer";
import WordShell from "./WordShell";
import ResultMaker from "./ResultMaker"
import * as Extension from "../Extension.js";
import * as Tools from "../Tools";

var mLanguage = "fr";

var mMdxObjects = [];
var mMdxMainObjects = {};
var mMdxOriginObject = null;

var mMdxOptions = [];
var mMdxOptionForIndexs = {};//dizio_name:bool
var mMdxOptionForMainParts = {};//dizio_name:bool

var mSearchingIndex = "";

var mMdxNameMaps = {
  //file_name : dizio_name
};
var mMdxLanguageMaps = {
  //dizio_name : language
  "5 Million Dictionary":"en",
  "法汉汉法词典":"fr",
  "意汉汉意词典109910单词":"it"
};


//🍋Debug

//Auto Load
// if(typeof file !== "undefined"){
//   setTimeout(function () {
//     didSelectFiles([file]);
//   },2000);
//
//   setTimeout(function () {
//     //mdxFetchMainpart("parlare");
//     //mdxSearchNormal('come',null);
//   },5000);
// }

//Manual Load
function didSelectFiles(Files) {
  if (Files.length > 0) {

    MParser(Files).then(function(resources) {

      let dict_attrs = (resources['mdx'] || resources['mdd']).value().attrs;
      let object = MRenderer(resources);
      mMdxObjects.push(object);

      //console.log("🐞object",object);

      //desc
      $('#definition').prepend(dict_attrs.Description+"<hr>");
      //attr
      Tools.triger_call_device("mdxParseDone", dict_attrs);

      $(".searchbar .search_normal").click();

    }).then(function () {

      if (mMdxObjects.length > 0){

        mLanguage = "fr";
        mMdxMainObjects = {"fr":mMdxObjects[0]};

        let dizioname = mMdxObjects[0].getAttrs().Title;
        mMdxOptionForIndexs = {};
        mMdxOptionForMainParts = {};
        mMdxOptionForIndexs[dizioname] = true;
        mMdxOptionForMainParts[dizioname] = true;
      }
    });
  }
}
window.didSelectFiles = didSelectFiles;

//🍋documentReady
$(document).ready(function (){
  Tools.triger_call_device("mdxDocumentReadyCallBack","document ready");
});

//🍋mdxMakeObject
//@params:
function mdxMakeObject(Parms) {

  //console.log("🐞开始解析词典文件:",Parms);

  //初始化
  mLanguage = Parms["language"];

  mMdxObjects = [];
  mMdxMainObjects = {};

  mMdxNameMaps = {};
  mMdxOptions = Parms["options"];
  mMdxOptionForIndexs = {};
  mMdxOptionForMainParts = {};


  //开始获取 MdxObjects
  let filepaths = Parms["filepaths"];
  Promise.each(filepaths, function (filepath) {
    //console.log("🐞ajax start",filepath);
    return Tools.promiseAjax(filepath).then(function (file) {
      if (file != null){
        //console.log("🐞ajax success",file.name);
        let filename = file.name;
        return MParser([file]).then(function (resources) {
          let mdx_object = MRenderer(resources);
          let dizio_name = mdx_object.getAttrs().Title;
          mMdxNameMaps[filename] = dizio_name;
          mMdxObjects.push(mdx_object);


          if (filename === "109907.mdx" || dizio_name === "意汉汉意词典109910单词"){
            mMdxMainObjects["it"] = mdx_object;
          }
          if (filename === "动词变位词典.mdx"|| filename === "coniugati.mdx" || dizio_name === "1000 Italian Proverbs"){
            mMdxOriginObject = mdx_object;
          }
          if (filename === "5Million.mdx"){
            mMdxMainObjects["en"] = mdx_object;
          }
          if (
            filename === "法汉汉法词典2007.mdx" ||
            filename === "法汉汉法词典.mdx" ||
            filename === "fr-zh.mdx" ||
            dizio_name === "法汉汉法词典"
          ){
            mMdxMainObjects["fr"] = mdx_object;
          }
        });
      }else{
        //console.log("🐞ajax null:",filepath);
      }
    });

  }).then(function () {

    //开始获取选项
    $.each(mMdxOptions, function (index, option) {
      let option_dizio_filename = option['file_name'];
      if (Object.keys(mMdxNameMaps).includes(option_dizio_filename)){
        let option_dizio_name = mMdxNameMaps[option_dizio_filename];

        let option_for_index = option['for-index'];
        if (option_for_index){
          mMdxOptionForIndexs[option_dizio_name] = option_for_index;
        }

        let option_for_mainparts = option['for-mainpart'];
        if (option_for_mainparts){
          mMdxOptionForMainParts[option_dizio_name] = option_for_mainparts;
        }
      }
    });

    Tools.triger_call_device(
      "mdxMakeObjectCallBack",
      {
        files:filepaths.length,
        packs:mMdxObjects.length,
        mMdxOptions:mMdxOptions,
        mMdxNameMaps:mMdxNameMaps,
        mMdxOptionForIndexs:mMdxOptionForIndexs,
        mMdxOptionForMainParts:mMdxOptionForMainParts
      }
    );
  });
}
window.mdxMakeObject = mdxMakeObject;

//🍋mdx Search
// @return: ResultObject
//      result_object.dizio_name
//      result_object.searching
//      result_object.result_index
//      result_object.result_mean
//      result_object.result_mainpart

//🍓普通查词
function mdxSearchNormal(SearchingIndex, CallBack){
  mSearchingIndex = SearchingIndex;

  let mResults = [];
  return Promise.each(mMdxObjects, function(MdxObject){
    let dizio_name = MdxObject.getAttrs().Title;
    let dizio_language = mMdxLanguageMaps[dizio_name];
    let for_index = mMdxOptionForIndexs[dizio_name];
    if (for_index === true){
      return MdxObject.lookup({action:"search_normal", phrase:SearchingIndex, max: 25}).then(function (list) {
        if (list !== undefined && list !== null){
          mResults = mResults.concat(list.map(function (word) {
            let result_object = {};
            result_object.searching = SearchingIndex;
            result_object.result_index = word.toString();
            result_object.result_mean = "";
            result_object.result_mainpart = "";
            result_object.dizio_name = dizio_name;
            result_object.dizio_language = dizio_language;
            return result_object;
          }));
        }
      });
    }
  }).then(function () {

    //unique.map.sort
    let result_objects = mResults.uniqueSearchResults().sortByItemKey('result_index');

    //prepend (index == searching)
    let matchIndex = null;
    $.each(result_objects, function (index, obj) {
      if (obj['result_index']==SearchingIndex){
        matchIndex = index;
      }
    });
    if (matchIndex != null && matchIndex != 0){
      let obj = result_objects[matchIndex];
      result_objects.splice(matchIndex,1);
      result_objects.unshift(obj);
    }

    //custom callback
    if (CallBack != null){
      CallBack(result_objects);
    }
    //default callback
    else{
      runloopFetchMainparts(0, SearchingIndex, result_objects, "mdxSearchNormalCallBack");
    }
  });
}
window.mdxSearchNormal = mdxSearchNormal;

//🍓词根查词
function mdxSearchRoot(SearchingIndex, CallBack){
  mSearchingIndex = SearchingIndex;
  let mResults = [];
  return Promise.each(mMdxObjects, function(MdxObject){
    let dizioname = MdxObject.getAttrs().Title;
    let for_index = mMdxOptionForIndexs[dizioname];
    if (for_index === true){
      return MdxObject.lookup({action:"search_root", phrase:SearchingIndex, max: 50}).then(function (list) {
        mResults = mResults.concat(list);
      });
    }
  }).then(function () {

    //unique.map.sort
    let result_objects = mResults.unique().map(function (result_index) {
      var result_object = {};
      result_object.dizio_name = "root";
      result_object.searching = SearchingIndex;
      result_object.result_index = result_index.toString();
      result_object.result_mean = "";
      result_object.result_mainpart = "";
      return result_object;
    }).sortByItemKey('result_index');

    //prepend (index == searching)
    let matchIndex = null;
    $.each(result_objects, function (index, obj) {
      if (obj['result_index']==SearchingIndex){
        matchIndex = index;
      }
    });
    if (matchIndex != null && matchIndex != 0){
      let obj = result_objects[matchIndex];
      result_objects.splice(matchIndex,1);
      result_objects.unshift(obj);
    }

    runloopFetchMainparts(0, SearchingIndex, result_objects, "mdxSearchRootCallBack");
  })
}
window.mdxSearchRoot = mdxSearchRoot;

//获取释义
var mResultObjectsWithMainparts = [];
function runloopFetchMainparts(Index, Searching, ResultObejects, CallBackName) {
  //初始化
  if (Index == 0){
    mResultObjectsWithMainparts = ResultObejects;
  }

  //Break
  if (Searching !== mSearchingIndex){
    throw 'force terminated';
  }

  //Main Loop
  var isEnouph = false;

  if (Object.keys(mMdxMainObjects).length > 0 && Index < ResultObejects.length){
    //只取前25个
    if (Index < 25){
      let result_object = mResultObjectsWithMainparts[Index];
      let result_index = result_object.result_index;
      let result_dizio_language = result_object.dizio_language;

      let mMdxMainObject = mMdxMainObjects[result_dizio_language];
      if (mMdxMainObject){
        mMdxMainObject.fetch_mainpart({phrase: result_index}).then(function (definitions) {
          var definition_string = "";
          $.each(definitions, function (index, definition) {
            if (index>0){
              definition_string += "<hr>"+definition;
            }else{
              definition_string += definition;
            }
          });
          result_object.result_mainpart = definition_string;
          mResultObjectsWithMainparts[Index] = result_object;

          //next loop
          runloopFetchMainparts(Index+1, Searching, ResultObejects, CallBackName);
        });
      }else{
        //next loop
        runloopFetchMainparts(Index+1, Searching, ResultObejects, CallBackName);
      }
    }else{
      isEnouph = true;
    }
  }else{
    isEnouph = true;
  }

  if (isEnouph){
    let results = mResultObjectsWithMainparts.map(function (result) {
      return ResultMaker.makeResultsForSearchNormalAndRoot(Object.clone(result));//必须clone, 才不影响caches
    });
    Tools.triger_call_device(CallBackName, results);
  }
}

//🍓全文搜索
function mdxSearchAll(SearchingIndex, CallBack) {
  mSearchingIndex = SearchingIndex;

  let mResults = [];

  return Promise.each(mMdxObjects, function(MdxObject){
    let dizioname = MdxObject.getAttrs().Title;
    let for_index = mMdxOptionForIndexs[dizioname];
    if (for_index === true){
      return MdxObject.lookup({action:"search_all", phrase:SearchingIndex, max:50}).then(function (list) {
        let DizioName = MdxObject.getAttrs().Title;
        mResults = mResults.concat(list.map(function (ResultIndexWithMainPart){
          let result_object = ResultIndexWithMainPart;

          result_object.dizio_name = DizioName;
          result_object.searching = SearchingIndex;
          result_object.result_mean = "";

          return result_object;
        }));
      });
    }
  }).then(function () {

    mResults = mResults.map(function (object) {
      return ResultMaker.makeResultsForSearchAll(Object.clone(object));//必须clone, 才不影响caches
    });

    Tools.triger_call_device("mdxSearchAllCallBack", mResults);
  });
}
window.mdxSearchAll = mdxSearchAll;

//🍓反查原型
function mdxSearchOrigin(SearchingIndex, CallBack) {
  mSearchingIndex = SearchingIndex;
  let mResults = [];
  return mMdxOriginObject.lookup({action:"search_all", phrase:SearchingIndex, max:100}).then(function (list) {
    let DizioName = mMdxOriginObject.getAttrs().Title;
    mResults = mResults.concat(list.map(function (ResultIndexWithMainPart){
      let result_object = ResultIndexWithMainPart;
      result_object.dizio_name = DizioName;
      result_object.searching = SearchingIndex;
      return result_object;
    }));
  }).then(function () {
    let origin_reuslts = [];
    for (let result of mResults){
      let origin_result = ResultMaker.makeResultsForSearchOrigin(Object.clone(result));//必须clone, 才不影响caches
      if (origin_result != null){
        origin_reuslts.push(origin_result);
      }
    }

    //custom callback
    if (CallBack != null){
      CallBack(origin_reuslts);
    }
    //default callback
    else{
      Tools.triger_call_device("mdxSearchAllCallBack", origin_reuslts);
    }
  });
}
window.mdxSearchOrigin = mdxSearchOrigin;

//🍊生成相似结果
function generateAllRelatedWords(SearchingIndex){

  var allPosibleResults = []

  //代词缩合
  allPosibleResults = allPosibleResults.concat( covertCombinedWords(SearchingIndex) )
  //单复数+形容词
  allPosibleResults = allPosibleResults.concat( convertPlurWords(SearchingIndex) )

  //移除重复
  allPosibleResults = allPosibleResults.unique();

  return allPosibleResults;
}
function covertCombinedWords(Parole){
  var allPosibleResults = []

  //(直接宾语)
  let dir_prons = ["mi","ti","ci","vi","lo","la","li","le","ne","gli","loro","si"]
  //(直接宾语双写)
  let dir_prons_double = ["mmi","tti","cci","vvi","llo","lla","lli","lle","nne","lloro","ssi"]//动词(3) - 如果上述代词或小品词用在dare，direare， andare， stare等动词的命令式第二人称单数（你）的后面时，应去掉动词后面的断音撇，将代词或小品词与动词连写在一起，并重复代词或小品词词首的辅音（gli除外），从而形成双辅音形式，如: dimmi, dalle
  //(可缩合间接宾语)
  let indir_prons = ["me","te","ce","ve","gli","le","loro","glie"]//人称代词 - 间接宾语代词与直接宾语代词lo，la，i，le，ne组合后，间接宾语代词mi，tici，vi分别变成me，te，ce，ve，而gli、le和loro（loro亦可不与直接宾语代词组合，仍保持原样）则变成glie，并与后面的直接宾语代词连写，跟在间接宾语代词后面的直接宾语代词不变

  //连接所有的宾语
  var all_prons = dir_prons.concat(dir_prons_double);
  for (let indir_pron of indir_prons){
    for (let dir_pron of dir_prons){
      all_prons.push(indir_pron+dir_pron)
    }
  }

  for (let one_pron of all_prons){

    let word_lower = Parole.toLowerCase()

    //🍓不定式
    //人称代词 - 如果非重读直接宾语代词与动词不定式（modo infinito）一起使用应将其置于不定式的后面，并去掉动词词尾e，与动词连写在一起，如:portarmelo
    //# - r
    if (word_lower. endsWith("r"+one_pron)){
      var pure = word_lower
      pure = pure.replace(new RegExp("r"+one_pron+"$"), '');
      allPosibleResults.push(pure+"re")
    }

    //🍓命令式
    //人称代词 - 在命令式中,一般情况下宾语代词被置于动词的后面,并与其连写;尊称时应将其置于动词的前面,在第二人称单数(你)的否定句中,既可将其置于动词的后面,亦可将其置于动词的前面。
    /*
     TODO:
     (5)在命令式中,请按下面的规则放置非重读直接宾语代词:
     Si deve aprire questa porta?
     应该打开这扇门吗?
     肯定句:Si, aprila per favore
     是的,请你打开它!
     Si, I'apra per favore!
     是的,请您打开它!
     Si,apriamola!
     是的,我们打开它!
     Si, apritela per favorel
     是的,请你们打开它!
     Si, I'aprano per favore!
     是的,请您们打开它
     否定句:no, non aprirla per favore!no, non Paprire per favore
     不,请你不要打开它!
     No, non I'apra per favore!
     不,请您不要打开它
     No, non'apriamola!
     不,我们不要打开它
     No, non apritela per favore
     不,请你们不要打开它!
     No, non I'aprano per favore!
     不,请您们不要打开它
     */

    //# - i/a
    if ((word_lower. endsWith("i"+one_pron) || word_lower. endsWith("a"+one_pron))){
      //es: diglielo
      var pure = word_lower
      var suffix = ""
      if (word_lower.endsWith( "i"+one_pron)){
        suffix = "i"
      }else{
        suffix = "a"
      }
      pure = pure.replace(new RegExp(suffix+one_pron+'$'),'');
      allPosibleResults.push(pure + suffix + "re")

    }
    //# - ite/ate/ete
    if ((word_lower. endsWith("ite"+one_pron) || word_lower. endsWith("ate"+one_pron) || word_lower. endsWith("ete"+one_pron))){
      //es: dateglielo
      var pure = word_lower
      var longSuffix = ""
      var shortSuffix = ""
      if (word_lower.endsWith( "ite"+one_pron)){
        longSuffix = "ite"
        shortSuffix = "i"
      }else if (word_lower.endsWith( "ate"+one_pron)){
        longSuffix = "ate"
        shortSuffix = "a"
      }else if (word_lower.endsWith( "ete"+one_pron)){
        longSuffix = "ete"
        shortSuffix = "e"
      }
      pure = pure.replace(new RegExp(longSuffix+one_pron+'$'),'');
      allPosibleResults.push(pure + shortSuffix + "re")
    }
    // - iamo
    if (word_lower. endsWith("iamo"+one_pron)){
      //es: diamoglielo
      var pure = word_lower
      let suffixs = ["a","i","e"]
      pure = pure.replace(new RegExp("iamo"+one_pron+'$'),'');


      for (let suffix of suffixs){
        allPosibleResults.push(pure + suffix + "re")
      }
    }

    //🍓副动词
    //人称代词 - 如果非重读代词与副动词（gerundio）一起使用，应将其置于副动词的后面，并与副动词连写，如: leggendolo
    // - ndo
    if (word_lower. endsWith("ndo"+one_pron)){
      //TODO:
      //动词(4) - 有少量动词的副动词不规则，如:fare- -facendo做，dire- -dicendo说，bere- bevendo喝， tradurre-traducendo-翻译， trarre-traendo-拉， porre-ponendo-放置
      var pure = word_lower
      var find_pre = "a"
      let pres = ["a","i","e"]
      for (let pre of pres){
        if (word_lower.endsWith( pre+"ndo"+one_pron)){
          find_pre = pre
        }
      }
      pure = pure.replace(new RegExp(find_pre+"ndo"+one_pron+'$'),'');

      allPosibleResults.push(pure + find_pre + "re")
    }
  }
  return allPosibleResults
}
function convertPlurWords(Parole){

  let plursI = ["a","o","e","io"]     //以 i 结尾单词的对应原型:
  let plursE = ["a","o","e","io"]     //以 e 结尾单词的对应原型: ia pioggia->piogge

  //var plurs = ["i","e","chi","ghi","che","ghe"] - afreschi, chirurgli, amiche, colleghe
  //mani
  //pattate
  //a>e o>i
  //特殊单复数变位

  //人称代词 - 非重读直接宾语代词与过去分词的性数配合
  //在使用动词复合时态（即由助动词与过去分词两个部分组成的时态，如近过去时、先将来时等）的同时也使用非重读直接宾语人称代词的时候，需遵守以下语法规则:（1）在使用代词lo，la，li，le，ne时，过去分词必须与其性数配合（2）使用mi，ti，ci，vi时，过去分词可以与代词配合，亦可不配合

  var plurs = []

  let word_lower = Parole.toLowerCase()

  //复数
  if (word_lower. endsWith("chi")){
    var pure = word_lower
    pure = pure.replace(new RegExp("chi"+'$'),'');
    plurs = plurs.concat([pure+"co"])
  }
  if (word_lower. endsWith("ghi")){
    var pure = word_lower
    pure = pure.replace(new RegExp("ghi"+'$'),'');
    plurs = plurs.concat([pure+"go"])
  }
  if (word_lower. endsWith("che")){
    var pure = word_lower
    pure = pure.replace(new RegExp("che"+'$'),'');
    plurs = plurs.concat([pure+"ca"])
    plurs = plurs.concat([pure+"co"])   //poche
  }
  if (word_lower. endsWith("ghe")){
    var pure = word_lower
    pure = pure.replace(new RegExp("ghe"+'$'),'');
    plurs = plurs.concat([pure+"ga"])
  }
  if ( word_lower. endsWith("i")){
    var pure = word_lower
    pure = pure = pure.replace(/.{1}$/g, '');
    var iss = []
    for (let ii of plursI){
      iss.push(pure+ii)
    }
    plurs = plurs.concat(iss)
  }
  if (word_lower. endsWith("e")){
    var pure = word_lower
    pure = pure = pure.replace(/.{1}$/g, '');
    var ess = []
    for (let ee of plursE){
      ess.push(pure+ee)
    }
    plurs = plurs.concat(ess)
  }

  //>=5.5
  //附加形容词
  //a <=> o
  if (Parole.endsWith("a")){
    var pure = Parole.toLowerCase()
    pure = pure.replace(/.{1}$/g, '');
    pure = pure+"o"
    plurs.push(pure)
  }
  if (Parole.endsWith("o")){
    var pure = Parole.toLowerCase()
    pure = pure.replace(/.{1}$/g, '');
    pure = pure+"a"
    plurs.push(pure)
  }
  //e <=> o: certe->certo
  if (Parole.endsWith("e")){
    var pure = Parole.toLowerCase()
    pure = pure.replace(/.{1}$/g, '');
    pure = pure+"o"
    plurs.push(pure)
  }
  if (Parole.endsWith("o")){
    var pure = Parole.toLowerCase()
    pure = pure.replace(/.{1}$/g, '');
    pure = pure+"e"
    plurs.push(pure)
  }

  //webapp
  //添加自反动词
  if (word_lower.endsWith("re")){
    var riflesivo = word_lower.replace(new RegExp("re"+'$'),'')+"rsi";
    plurs.push(riflesivo);
  }

  return plurs
}
window.generateAllRelatedWords = generateAllRelatedWords;

//🍋mdx Fetch

//🍓mainpart
function mdxFetchMainpart(SearchingIndex, CustomKey, CallBack){
  SearchingIndex = SearchingIndex.toLowerCase();
  //console.log("🐞mdxFetchMainpart:",SearchingIndex, "using mdxs:",mMdxObjects.length);
  let mainparts = [];
  return Promise.each(mMdxObjects, function(MdxObject){
    let DizioName = MdxObject.getAttrs().Title;
    let for_mainpart = mMdxOptionForMainParts[DizioName];
    //console.log("🐞mMdxOptionForMainParts:", DizioName);
    if (for_mainpart === true){
      return MdxObject.fetch_mainpart({phrase: SearchingIndex}).then(function (definitions) {
        //console.log("🐞definitions:",definitions);
        if (definitions !== null){
          var definition_string = "";

          $.each(definitions, function (index, definition) {
            if (definition !== null && definition !== ""){
              if (index>0 && definition_string !== ""){
                definition_string += "<hr>"+definition;
              }else{
                definition_string += definition;
              }
            }
          });

          if(definition_string !== ""){
            let result_object = {};
            result_object.dizio_name = DizioName;
            result_object.searching = SearchingIndex;
            result_object.result_index = SearchingIndex;
            result_object.result_mean = "";
            result_object.result_mainpart = definition_string;
            mainparts.push(result_object);
          }
        }
      });
    }
  }).then(function () {

    var hasMainDizioResult = false;

    //添加词典释义
    mainparts = mainparts.map(function (object) {

      let dizioName = object['dizio_name'];

      //.it
      if (dizioName == '意汉汉意词典109910单词'){
        object = ResultMaker.makeResultsForFetchMainPart_IT(object, CustomKey);
        object.dizio_name = "main-it";
        hasMainDizioResult = true;
      }
      else if (dizioName === "1000 Italian Proverbs"){
        object = ResultMaker.makeResultsForFetchMainPart_IT_OriginTable(object);
      }
      //.fr
      else if (dizioName == "法汉汉法词典"){
        object = ResultMaker.makeResultsForFetchMainPart_FR(object, CustomKey);
        object.dizio_name = "main-fr";
        hasMainDizioResult = true;
      }
      //.en
      else if (dizioName == "5 Million Dictionary"){
        object = ResultMaker.makeResultsForFetchMainPart_EN(object, CustomKey);
        object.dizio_name = "main-en";
        hasMainDizioResult = true;
      }
      //general dizio
      else{
        let dizio_class = "";
        if (object.dizio_name == "1000 Italian Proverbs"){
          dizio_class = "OriginTable it";
        }
        let rendered_dizio_name = ResultMaker.refineDizioName(object.dizio_name);
        let rendered_html_string =
          "<div>" +
          "<div class='functionButton'>"+rendered_dizio_name+"</div>" +
          "<div class='checkerDIV TouchAction "+dizio_class+"'>"+object.result_mainpart+"</div>" +
          "</div>";
        object.result_mainpart = rendered_html_string;
      }

      //add dizio info
      let mainpartHtml = $($.parseHTML(object.result_mainpart));
      if (mLanguage == "all"){
        mainpartHtml.addClass('multiple-language');
      }
      mainpartHtml.addClass('one-dizio');
      mainpartHtml.attr('data-dizio-name',object.dizio_name);
      object.result_mainpart = mainpartHtml[0].outerHTML;

      return object;
    });

    //添加 Index+Keys (if not exist)
    if (hasMainDizioResult == false){
      let indexString = SearchingIndex.replace(/\r\n/g,'<br>').replace(/\n\r/g,'<br>').replace(/\n/g,'<br>').replace(/\r/g,'<br>');
      let meanString = CustomKey != null ? CustomKey : '';
      meanString = meanString.replace(/\r\n/g,'<br>').replace(/\n\r/g,'<br>').replace(/\n/g,'<br>').replace(/\r/g,'<br>');

      let object = {
        dizio_name:'translation',
        result_index:indexString,
        result_mean:meanString,
        result_mainpart:
        "<div class='Preview one-dizio'>"+
        "<p id='theIndice' class='Fast TouchAction'>" +
        indexString +
        "</p>" +
        "<p id='theKeys' class='Fast TouchAction'>" +
        meanString +
        "</p>"+
        "</div>",
      };

      mainparts.unshift(object);
    }

    //添加网络释义
    // if (mLanguage == "en" || mLanguage == "all"){
    //   let object = {
    //     dizio_name:'Bing在线词典',
    //     result_mainpart:'<iframe class="online-dict-frame bing" src="https://cn.bing.com/dict/search?q='+SearchingIndex+'" onload="window.expandIframe(this)"></iframe>'
    //   };
    //   let dizio_class = "";
    //
    //   let rendered_dizio_name = ResultMaker.refineDizioName(object.dizio_name);
    //   let rendered_html_string =
    //     "<div class='one-dizio'>" +
    //     "<div class='functionButton'>"+rendered_dizio_name+"</div>" +
    //     "<div class='checkerDIV TouchAction frameContainer"+dizio_class+"'>"+object.result_mainpart+"</div>" +
    //     "</div>";
    //   object.result_mainpart = rendered_html_string;
    //
    //   mainparts.push(object);
    // }

    CallBack(mainparts);

    //@Depracated!
    //Tools.triger_call_device("mdxFetchMainpartCallBack", mainparts);

  }).catch(function(error){
    //console.log("🐞错误:",error);
    CallBack(null);
  });
}
window.mdxFetchMainpart = mdxFetchMainpart;

//🍓wordshell
function mdxFetchWordShell(SearchingIndex) {
  var wordshell = null;

  let mMdxMainObject = mMdxMainObjects[Object.keys(mMdxMainObjects)[0]];

  let DizioName = mMdxMainObject.getAttrs().Title;

  var result_object = {};
  result_object.dizio_name = DizioName;
  result_object.searching = SearchingIndex;
  result_object.result_index = SearchingIndex;
  result_object.result_mean = "";
  result_object.result_mainpart = "";

  mMdxMainObject.fetch_mainpart({phrase: SearchingIndex}).then(function ($definitions) {

    if ($definitions != null){
      var definition_string = "";
      $.each($definitions, function (index, definition) {
        if (index>0){
          definition_string += "<hr>"+definition;
        }else{
          definition_string += definition;
        }
      });

      if (definition_string !== ""){
        result_object.result_mainpart = definition_string;
        wordshell = ResultMaker.makeResultsForFetchWordShell(result_object);
        Tools.triger_call_device("mdxFetchWordShellCallBack", JSON.stringify(wordshell));
        return;
      }
    }
    Tools.triger_call_device("mdxFetchWordShellCallBack", "fail");
  });
}
window.mdxFetchWordShell = mdxFetchWordShell;

const MDict = {                    //= Mdict
  getMdxObjects:()=>{return mMdxObjects;},

  Promise:Promise,
  MParser:MParser,
  MRenderer:MRenderer,
  Tools:Tools,            //expose Mdict.Tools
};
export default MDict;