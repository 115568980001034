//🍋TableView
import {useEffect, useRef} from 'react';
import {render} from "react-dom";
import $ from "jquery";
import UITableView from "../../tools/uikit/UITableView";
import * as ColorSuits from "../../theme/ColorSuits.js";
import StudyDataController from "../../tools/StudyDataController";
import "./WordsTableView.css"
import Alert from "../../tools/Alert";
import {MenuWord, presentMenuWord, setClicking} from "../elements/MenuWord";
import {IonButton, IonIcon} from "@ionic/react";
import {caretDown} from "ionicons/icons";
import * as Tool from "../../tools/Tools";
import NativeTools from "../../tools/NativeTools";
import {LimitFunction, Settings} from "../../tools/Settings";
import SelectionArea from "@simonwep/selection-js";
import MenuController from "../../tools/uikit/MenuController";
import {eyeOutline, funnelOutline, swapVerticalOutline} from "ionicons/icons";
import CardsViewController from "./CardsViewController/CardsViewController";
import {IframeNavPresentPage} from "../../tools/uikit/IframeNav";

const WordsTableView = (props) => {

  var instance = {
    startLoopPlayBy:startLoopPlayBy
  };

  var mContext;

  var mIndexPath;
  var mList;
  var mWords = [];
  var mRuledWords = [];
  var mTableView;

  const refetchWordsThenReloadTableView = function(HasUI=true){
    //UI
    if (HasUI){
      showLoading();
    }

    //Reload
    if (mList.unico_id == "LIST-TODAY-WORDS"){
      if (window.top.TabStudy){
        window.top.TabStudy.refetchAllDataThenRedisplay(true);
      }
    }
    else{
      StudyDataController.refetchAllStudyData(true, function () {
        //todo: StudyDataController.getWordsByList() ["unico_id", "LIST-TODAY-WORDS"...]
        // mWords = StudyDataController.allNewAddWords.filter((word)=>{
        //   return word.parent_list_unico_id == mList.unico_id;
        // });
        if (mIndexPath){
          mWords = StudyDataController.getWordsBy(mIndexPath.section, mIndexPath.row);
        }else{
          //keep not updated words
        }

        reloadTableView(mIndexPath, mList, mWords);
        //console.log("🐞refetchAllStudyData:",mIndexPath,mWords);
      });
    }
  }
  
  const reloadTableViewCurrent = function () {
    showLoading();
    setTimeout(function () {
      reloadTableView(mIndexPath, mList, mWords);
    },500);
  };

  const reloadTableView = function reloadTableView(indexpath, list, words) {
    mIndexPath = indexpath;
    mList = list;
    mWords = words;

    mPlayingIndex = 0;

    //@Depracated!
    tryPreloadVoices();

    Settings.refetchWordsVCorRules();

    //banner
    if (Settings.mOptionWordsOrder == Settings.OptionValue.order_plan && Settings.mOptionMemos.length >= 4){
      $('.options-description').addClass('hidden');
    }else{
      $('.options-description').removeClass('hidden');
    }

    $('.options-description .order-description').text(Settings.getLocalizedValue(Settings.mOptionWordsOrder));

    if (Settings.mOptionMemos.length < 4){
      $('.options-description').addClass('warning');

      //@Depracated!
      //let list_name = mList ? mList.list_name+"中" : ""

      $('.options-description .memo-description').text(
        "只显示: "+Settings.mOptionMemos.map((value)=>{return Settings.getLocalizedValue(value)}).join(", ")+"的词"
      )
    }else{
      $('.options-description').removeClass('warning');
      $('.options-description .memo-description').text(mList.list_name)
    }

    //Rules
    mRuledWords = mWords.filter(()=>true);
    //记忆程度:
    if (
      Settings.mOptionMemos.length < 4
    ){
      mRuledWords = mRuledWords.filter(function (this_word) {

        var fine_memo = false;
        let this_memo_model = this_word.MemoModel;
        if (this_memo_model != null){
          fine_memo = Settings.mOptionMemos.includes(this_memo_model.MemoType);
        }
        return fine_memo;

        //@Depracated!
        // let this_memo_model = this_word.MemoModel;
        // //.memo
        // var fine_memo = Settings.mOptionMemos.length >= 4 ? true : false;
        // if (fine_memo == false){
        //   //try compare
        //   if (this_memo_model != null){
        //     fine_memo = Settings.mOptionMemos.includes(this_memo_model.MemoType);
        //   }
        // }
        // //.start time
        // var fine_start_time = Settings.mOptionStartTime == Settings.OptionValue.start_time_all ? true : false;
        // if (fine_start_time == false){
        //   //try compare
        //   if (this_memo_model != null && Tools.isEmpty(this_memo_model.StartTime.replace(/\s/g,''))==false){
        //     let days_diff = TimeController.countDistanceFromTo(today, this_memo_model.StartTime);
        //     fine_start_time = (days_diff > option_start_time_min && days_diff <= option_start_time_max)
        //   }
        // }
        // //.repeat time
        // var fine_repeat_time = Settings.mOptionRepeatTime == Settings.OptionValue.repeat_time_all ? true : false
        // if (fine_repeat_time == false){
        //   //try compare
        //   if (this_memo_model != null){
        //     let this_word_repeat_time = this_memo_model.RepeatTimes;
        //     if (option_repeat_time > 6){
        //       fine_repeat_time = this_word_repeat_time > 6
        //     }else{
        //       fine_repeat_time = (this_word_repeat_time == option_repeat_time)
        //     }
        //   }
        // }
        //
        // return fine_memo && fine_repeat_time && fine_start_time;
      })
    }
    //顺序:
    if (Settings.mOptionWordsOrder == Settings.OptionValue.order_plan){
      if (mList.unico_id == "LIST-TODAY-WORDS"){
        //...do nothing
      }else{
        mRuledWords = mRuledWords.sortByItemKey('order_id');
      }
    }
    if (Settings.mOptionWordsOrder == Settings.OptionValue.order_shuffle){
      mRuledWords = mRuledWords.shuffle();
    }
    if (Settings.mOptionWordsOrder == Settings.OptionValue.order_char_pass){
      mRuledWords = mRuledWords.sortByItemKey('word_string');
    }
    if (Settings.mOptionWordsOrder == Settings.OptionValue.order_char_reverse){
      mRuledWords = mRuledWords.sortByItemKey('word_string', true);
    }
    if (Settings.mOptionWordsOrder == Settings.OptionValue.order_time_pass){
      mRuledWords = mRuledWords.sortByItemKey('created_at');
    }
    if (Settings.mOptionWordsOrder == Settings.OptionValue.order_time_reverse){
      mRuledWords = mRuledWords.sortByItemKey('created_at',true);
    }
    if (Settings.mOptionWordsOrder == Settings.OptionValue.order_repeat_pass){

      mRuledWords = mRuledWords.sort(function( a, b ){

        let a_value = a.MemoModel.RepeatTimes;
        let b_value = b.MemoModel.RepeatTimes;

        if (parseFloat(a_value) != NaN){
          a_value = parseFloat(a_value);
          b_value = parseFloat(b_value);
        }

        if(a_value < b_value) {
          return -1
        };
        if(a_value > b_value) {
          return 1
        };

        return 0;
      })
    }
    if (Settings.mOptionWordsOrder == Settings.OptionValue.order_repeat_reverse){
      mRuledWords = mRuledWords.sort(function( a, b ){

        let a_value = a.MemoModel.RepeatTimes;
        let b_value = b.MemoModel.RepeatTimes;

        if (parseFloat(a_value) != NaN){
          a_value = parseFloat(a_value);
          b_value = parseFloat(b_value);
        }

        if(a_value > b_value) {
          return -1
        };
        if(a_value < b_value){
          return 1
        };

        return 0;
      })
    }


    if (!mTableView){
      //console.log("🐞mTableView new!");
      mTableView = UITableView.init(
        mContext.find(".words-render-wrapper"),
        TableViewDelegate(),
        TableViewDatasource()
      );
    }else{
      //console.log("🐞mTableView reload!");
      mTableView.reload(mContext.find(".words-render-wrapper"));
    }

    //console.log("🐞mTableView:", mTableView.mContext, mTableView.mDataSource.numberOfRows());
  }

  // @Depracated!
  var mVoiceLoadedLists = {};
  const tryPreloadVoices = () => {
    if (Settings.debug){return;}

    if (!mVoiceLoadedLists[mList.unico_id]){
      mVoiceLoadedLists[mList.unico_id] = true;

      //preload voices
      let wait = NativeTools.mTTSPreloader ? 2000 : 5000;
      setTimeout(()=>{
        NativeTools.preloadWordsVoicesBy(mWords, true, 0, true, false, ()=>{});
      }, wait);
    }
  }

  function showLoading() {
    Alert.showLoadingAlert("更新学习数据",null);
    setTimeout(()=>{
      Alert.clearAlerts();
    },1000);
  }

  //🍉Loop Play
  var mPlayMode = false;
  var mPlayedWords = [];
  var mPlaying = false;
  var mPlayingIndex = 0;
  var mForceWait = false;     //点击index后强制播放index
  //.
  var enName = {"en":"英","fr":"法","it":"意","zh":"中","all":"外"}[Settings.getLanguage()];
  var mReadLanguage = "en_zh",
      mReadLanguageNames = {
        "en_zh":"先"+enName+"后中",
        "zh_en":"先中后"+enName,
        "en":"只读"+enName+"语",
        "zh":"只读中文"
      };
  function updateReadLanguageUI(){
    if (mPlaying){
      mReadLanguageNames['en'] = "只读"+enName+"语";
      mReadLanguageNames['zh'] = "只读中文";
    }else{
      mReadLanguageNames['en_zh'] = "点击阅读";
      mReadLanguageNames['zh_en'] = "点击阅读";
      mReadLanguageNames['en'] = "抽认释义";
      mReadLanguageNames['zh'] = "抽认单词";
    }
    $(".player-button.language .label").text(mReadLanguageNames[mReadLanguage]);
    mContext.attr('read-language', mReadLanguage);
  }
  //.
  var mReadSpead = 1,
      mReadSpeadRates = [0.4, 0.5, 0.6],
      mReadSpeedIntervals = [2500, 500, 0],
      mReadSpeadNames = ["慢速","正常速度","快速"];
  function updateReadSpeadUI() {
    $(".player-button.speed .label").text(mReadSpeadNames[mReadSpead]);
  }

  function enterPlayMode() {

    //.validate
    //.
    if (mRuledWords.length == 0){
      stopLoopPlay();
      Alert.showWarningAlert("😨 暂时无法播放哦","目前列表是空的, 你可以点击添加单词","好的");
      return;
    }
    //.
    if (!NativeTools.mTTSPreloader){
      Alert.showWarningAlert("发音引擎还未就绪哦","稍等几秒钟再试试","好吧");
      return;
    }

    //ui.start
    mPlayMode = true;
    let page = mContext.closest('.ion-page');
    page.addClass("mode-play");
    mContext.addClass('safe-area-top');
    $("ion-tab-bar").fadeOut(0);
    $("ion-header").fadeOut(0);

    //preload voices
    NativeTools.preloadWordsVoicesBy(mRuledWords, true, mPlayingIndex, true, true, ()=>{

      //@start play
      startLoopPlay();

      //@offline tts
      setTimeout(()=>{
        //require tts
        NativeTools.tryRequireOfflineTTS();
      },1000);
    });
  }
  function exitPlayMode() {
    //ui.stop
    mPlayMode = false;
    $(".current").removeClass('current');
    let page = mContext.closest('.ion-page');
    page.removeClass("mode-play");
    mContext.removeClass('safe-area-top');
    $("ion-tab-bar").fadeIn(0);
    $("ion-header").fadeIn(0);

    //stop play
    stopLoopPlay();
  }

  var nextTimer;
  function startLoopPlayBy(Index) {
    mPlayingIndex = Index;

    if (mPlaying){
      mForceWait = true;
    }else{
      startLoopPlay();
    }
  }
  function startLoopPlay(Once=false) {

    //console.log("🐞startLoopPlay");

    //.validate
    if (!mPlayMode){
        enterPlayMode();
        return;
    }

    if ($(".ion-page.mode-play").length <= 0){
      console.info("🐞退出播放: 没有检测到播放页面");
      exitPlayMode();
      return;
    }

    if (mRuledWords.length == 0){
      stopLoopPlay();
      Alert.showWarningAlert("😨 暂时无法播放哦","目前列表是空的, 你可以点击添加单词","好的");
      return;
    }

    //.prepare word
    if (mPlayingIndex >= mRuledWords.length){mPlayingIndex = 0};
    let word = mRuledWords[mPlayingIndex];
    if (mPlayedWords.includes(word) != true){
      mPlayedWords.push(word);
    }

    //.will start play
    if (Once != true){

      //start play
      if (!mPlaying){

        mPlaying = true;
        setPlayingUI();

        //tts background
        NativeTools.trySetRequireBackgroundAudio(true);

        //@Depracated: preload by tableview
        //preload voices
        //NativeTools.preloadWordsVoicesBy(mRuledWords, true, mPlayingIndex, true, false, ()=>{});

      }
      //continue play
      else{
        //preload voices
        NativeTools.preloadWordsVoicesBy(mRuledWords, true, mPlayingIndex, false, false, ()=>{});
      }
    }

    var zh_string, en_string;
    var first_string, second_string;

    if (Tool.hasChinese(word.word_string)){
      zh_string = word.word_string;
      en_string = word.word_key;
    }else{
      zh_string = word.word_key;
      en_string = word.word_string;
    }

    first_string = en_string;
    second_string = zh_string;
    if (Once != true){
      if (mReadLanguage == "en_zh"){
        //...
      }
      if (mReadLanguage == "zh_en"){
        first_string = zh_string;
        second_string = en_string;
      }
      if (mReadLanguage == "en" || mReadLanguage == "zh"){
        if (mReadLanguage == "en"){
          first_string = en_string;
          second_string = null;
        }
        if (mReadLanguage == "zh"){
          first_string = zh_string;
          second_string = null;
        }
      }
    }else{
      if (mReadLanguage == "en"){
        first_string = zh_string;
        second_string = null;
      }
      if (mReadLanguage == "zh" || mReadLanguage == "zh_en" || mReadLanguage == "en_zh"){
        first_string = en_string;
        second_string = null;
      }
    }

    // @Depracated!
    // first_string = Tool.pureChinese(first_string);
    // second_string = Tool.pureChinese(second_string);

    //current ui
    updateCurrentUI();

    //prepare to speak
    function speakFirst(OnFinish) {
      let rate = mReadSpeadRates[mReadSpead];
      NativeTools.speak(first_string, Tool.hasChinese(first_string) ? "zh" : word.preferred_language, OnFinish, rate);
    }
    function speakSecond(OnFinish) {
      let rate = mReadSpeadRates[mReadSpead];
      //wait
      clearTimeout(nextTimer);
      nextTimer = setTimeout(()=>{
        //speak second
        NativeTools.speak(second_string, Tool.hasChinese(second_string) ? "zh" : word.preferred_language, OnFinish, rate);
      },500);
    }
    function speakNext() {

      if (Once){ return; }

      //wait
      let wait = mReadSpeedIntervals[mReadSpead];
      clearTimeout(nextTimer);
      nextTimer = setTimeout(()=>{
        if (mPlaying){
          //force wait clicked index
          if (mForceWait){
            mForceWait = false;
          }
          //next
          else{
            mPlayingIndex ++;
          }
          startLoopPlay();
        }
      },wait);
    }

    //speak
    speakFirst(function () {
      clearTimeout(nextTimer);
      if (mReadLanguage == "en" || mReadLanguage == "zh"){
        speakNext();
      }else{
        speakSecond(function () {
          speakNext();
        });
      }
    });
  }
  function setPlayingUI() {
    mContext.find(".button-play").addClass('playing');
    mContext.find("#player-buttons").addClass('playing');
  }

  function stopLoopPlay() {
    mPlaying = false;
    setPausingUI();

    //tts background
    NativeTools.trySetRequireBackgroundAudio(false);
  }
  function setPausingUI() {
    mContext.find(".button-play").removeClass('playing');
    mContext.find("#player-buttons").removeClass('playing');
  }

  function updateCurrentUI() {
    $(".current").removeClass('current');
    $(".ui-tableview-cell[row="+mPlayingIndex+"]").addClass("current");
    if (mTouching != true){
      let current_item = $(".current")[0];
      if (current_item){
        setTimeout(()=>{
          current_item.scrollIntoView({behavior: "smooth", block: "center"});
        },200);
      }
    }
  }

  var mTouching = false;
  var setTouchFalse;

  $("body").off("touchstart mousedown", ".ui-tableview");
  $("body").on("touchstart mousedown", ".ui-tableview",function () {
    mTouching = true;
    clearTimeout(setTouchFalse);
  });

  $("body").off("touchend mouseup", ".ui-tableview");
  $("body").on("touchend mouseup", ".ui-tableview", function () {
    setTouchFalse = setTimeout(()=>{
      mTouching = false;
    },5000);
  });

  //🍉Options
  function setupOptionsMenu(mContext) {
    const orderMenu = MenuController.addMenu(
      mContext.find('#option_order'),// Context,
      null,                   // ClassName,
      null,                            // Style,
      function (menu) {                // OnCreatedMenu,
        MenuController.addMenuItem(
          menu,           // Menu,
          "disabled",           // ClassName,
          "单词顺序",    // HtmlContent,
          function () {   // OnClick
            //...
          }
        );

        for (let order_value of [
          Settings.OptionValue.order_plan,
          Settings.OptionValue.order_shuffle,
          Settings.OptionValue.order_char_pass,
          Settings.OptionValue.order_char_reverse,
          Settings.OptionValue.order_repeat_pass,
          Settings.OptionValue.order_repeat_reverse,
          Settings.OptionValue.order_time_pass,
          Settings.OptionValue.order_time_reverse,
        ]){
          MenuController.addMenuItem(
            menu,           // Menu,
            Settings.mOptionWordsOrder == order_value ? "checked":null,           // ClassName,
            Settings.getLocalizedValue(order_value),    // HtmlContent,
            function () {   // OnClick
              trigerClickOptionOrder(order_value);
            }
          );
        }

        MenuController.addMenuItem(
          menu,           // Menu,
          "finish",           // ClassName,
          "完成",    // HtmlContent,
          function () {   // OnClick

          }
        );
      },
      false,                          //RightClick,
      MenuController.MenuDelegate(    //MenuDelegate
        menuDidAppear,
        menuDidDisappear,
      ),
      mContext.find(".options-wrapper")
    );
    // const repeatTimeMenu = MenuController.addMenu(
    //   mContext.find('#option_repeat_time'),// Context,
    //   null,                   // ClassName,
    //   null,                            // Style,
    //   function (menu) {                // OnCreatedMenu,
    //     for (let repeat_time_value of [
    //       Settings.OptionValue.repeat_time_1,
    //       Settings.OptionValue.repeat_time_2,
    //       Settings.OptionValue.repeat_time_3,
    //       Settings.OptionValue.repeat_time_4,
    //       Settings.OptionValue.repeat_time_5,
    //       Settings.OptionValue.repeat_time_6,
    //       Settings.OptionValue.repeat_time_6_more,
    //       Settings.OptionValue.repeat_time_all
    //     ]){
    //       MenuController.addMenuItem(
    //         menu,             // Menu,
    //         null,             // ClassName,
    //         Settings.getLocalizedValue(repeat_time_value),// HtmlContent,
    //         function () {     // OnClick
    //           trigerClickOptionRepeatTime(repeat_time_value);
    //         }
    //       );
    //     }
    //   },
    //   false,                          //RightClick,
    //   MenuController.MenuDelegate(    //MenuDelegate
    //     menuDidAppear,
    //     menuDidDisappear,
    //   )
    // );
    const memoMenu = MenuController.addMenu(
      mContext.find('#option_memo'),// Context,
      null,                   // ClassName,
      null,                            // Style,
      function (menu) {                // OnCreatedMenu,
        MenuController.addMenuItem(
          menu,           // Menu,
          "disabled",           // ClassName,
          "仅显示选中记忆程度的单词 (可多选)",    // HtmlContent,
          function () {   // OnClick
            //...
          }
        );

        var selectionMemos = [];
        for (let memo_value of [
          Settings.OptionValue.curve_memo_known,
          Settings.OptionValue.curve_memo_danger,
          Settings.OptionValue.curve_memo_familiar,
          Settings.OptionValue.curve_memo_untracked,

          //@Depracated!
          //Settings.OptionValue.curve_memo_all,
        ]){
          let item_class = "memo-item"; //Settings.mOptionMemos.includes(memo_value) ? "checked memo-item":"memo-item";

          // @Depracated!
          // let item_class = Settings.mOptionMemos.includes(memo_value) ? "checked memo-item":"memo-item";
          // if (memo_value == Settings.OptionValue.curve_memo_all){
          //   item_class += " action";
          // }

          let item = MenuController.addMenuItem(
            menu,             // Menu,
            item_class,           // ClassName,
            Settings.getLocalizedValue(memo_value),       // HtmlContent,
            function (item) {     // OnClick
              // @Depracated!
              // if (memo_value == Settings.OptionValue.curve_memo_all){
              //   $(".memo-item").addClass("checked");
              //   item.removeClass("checked");
              // }else{
              //   item.toggleClass("checked");
              // }
              // trigerClickOptionMemo(memo_value);

              if (selectionMemos.includes(memo_value)){
                selectionMemos.removeItem(memo_value);
                item.removeClass("checked");
              }else{
                selectionMemos.push(memo_value);
                item.addClass("checked");
              }
            },
            true
          );

          item.attr('title', memo_value);
        }

        MenuController.addMenuItem(
          menu,           // Menu,
          "finish",           // ClassName,
          "完成",    // HtmlContent,
          function () {   // OnClick
            memoMenu.dismiss();
            if (selectionMemos.length > 0){
              Settings.mOptionMemos = selectionMemos;
              reloadTableViewCurrent();
            }
          }
        );
      },
      false,                          //RightClick,
      MenuController.MenuDelegate(    //MenuDelegate
        menuDidAppear,
        menuDidDisappear,
      ),
      mContext.find(".options-wrapper")
    );
    $('body').on('click',function () {
      orderMenu.dismiss();
      memoMenu.dismiss();
      //repeatTimeMenu.dismiss();
    });
  }

  //MenuDelegate
  function menuDidAppear(){
    //console.log("🐞menuDidAppear")
    $("#pie-wrapper").css({'height':'100%'});
  }
  function menuDidDisappear(){
    $("#pie-wrapper").css({'height':'100px'});
  }

  //ClickOption
  function trigerClickOptionOrder(Value) {
    Settings.setWordDisplayingPreferenceBy('GLOBAL', Settings.OptionKey.WordsOrder, Value);
    reloadTableViewCurrent();
  }
  function trigerClickOptionMemo(Value) {
    // let options_dicts = {
    //   "memo_start":[Settings.OptionValue.curve_memo_start],
    //   "memo_known":[Settings.OptionValue.curve_memo_known],
    //   "memo_familiar":[Settings.OptionValue.curve_memo_familiar],
    //   "memo_danger":[Settings.OptionValue.curve_memo_danger],
    //   "memo_untracked":[Settings.OptionValue.curve_memo_untracked],
    //   "memo_all":[
    //     Settings.OptionValue.curve_memo_start,
    //     Settings.OptionValue.curve_memo_known,
    //     Settings.OptionValue.curve_memo_familiar,
    //     Settings.OptionValue.curve_memo_danger,
    //     Settings.OptionValue.curve_memo_untracked,
    //   ]
    // }
    // Settings.mOptionMemos = options_dicts[Value];

    if (Value == "memo_all"){
      Settings.mOptionMemos = [
        //@Depracated: Settings.OptionValue.curve_memo_start,

        Settings.OptionValue.curve_memo_known,
        Settings.OptionValue.curve_memo_familiar,
        Settings.OptionValue.curve_memo_danger,
        Settings.OptionValue.curve_memo_untracked,
      ]
    }else{
      if (Settings.mOptionMemos.includes(Value)){
        Settings.mOptionMemos.removeItem(Value);
      }else{
        Settings.mOptionMemos.push(Value);
      }
    }

    //console.log("🐞Settings.mOptionMemos:",Settings.mOptionMemos);
  }
  //@Depracated!
  // function trigerClickOptionClear() {
  //   Settings.mOptionStartTime = Settings.OptionValue.start_time_all;
  //   Settings.mOptionRepeatTime = Settings.OptionValue.repeat_time_all;
  //   Settings.mOptionMemos = [
  //     Settings.OptionValue.curve_memo_start,
  //     Settings.OptionValue.curve_memo_known,
  //     Settings.OptionValue.curve_memo_familiar,
  //     Settings.OptionValue.curve_memo_danger,
  //     Settings.OptionValue.curve_memo_untracked,
  //   ];
  //   reloadTableViewCurrent();
  // }
  //@Depracated
  // function trigerClickOptionStartTime(Value) {
  //   Settings.mOptionStartTime = Value;
  //   reloadTableViewCurrent();
  // }
  // function trigerClickOptionRepeatTime(Value){
  //   Settings.mOptionRepeatTime = Value;
  //   reloadTableViewCurrent();
  // }
  
  
  //🍉TableView

  //@TableView Datasource
  function TableViewDatasource() {
    return UITableView.DataSource(
      function numberOfSections() {
        return 1;
      },
      function numberOfRows() {
        if (mRuledWords.length == 0){
          return 1;
        }
        return mRuledWords.length;
      },
      function cellForRowAtIndexPath(section, row) {

        let this_word_elm;

        if (mRuledWords.length == 0){
          if(mList.unico_id == "LIST-TODAY-WORDS" && Settings.mOptionMemos.length >= 4){
            this_word_elm = $(
              "<div class='word add'>\n" +
              "    <div class=''>📝 下载单词</div>" +
              "</div>");
          }else{
            this_word_elm = $(
              "<div class='word add'>\n" +
              "    <div class='text-sandy p-3 text-center'>根据当前规则, 没有单词显示哦</div>" +
              "</div>");
          }
          return this_word_elm;
        }else{
          this_word_elm = $(
            "<div class='word'>\n" +
            "    <div class='texts'>"+
            "    <div class='note'></div>\n" +
            "    </div>"+
            "    <div class='memo_wrapper'><sapn class='memo'><span class='repeat_times'></span></sapn></div>" +
            "    <div class='action_wrapper'></div>"+
            "</div>");

          let this_word = mRuledWords[row];
          this_word_elm.find(".texts").prepend($("<div class='word_mean'>"+this_word.word_key+"</div>"));
          this_word_elm.find(".texts").prepend($("<div class='word_string'>"+this_word.word_string+"</div>"));

          //memo
          let this_word_memo_type = this_word.MemoModel.MemoType;
          this_word_elm.addClass(this_word_memo_type);
          this_word_elm.find(".memo").attr('title',this_word_memo_type);
          this_word_elm.find(".memo").css({'background-color':ColorSuits['standard-red'][this_word_memo_type]});

          //action
          render(<IonIcon icon={caretDown}/>,this_word_elm.find(".action_wrapper")[0]);

          //repeat
          if (parseInt(this_word.MemoModel.RepeatTimes) > 0){
            this_word_elm.find(".repeat_times").text(this_word.MemoModel.RepeatTimes);
          }

          //appunto
          let match_appuntis = StudyDataController.allAppuntis.filter(function (appunto) {
            return appunto.parent_word == this_word.word_string;
          });
          if (match_appuntis.length > 0){
            this_word_elm.find(".note").text(match_appuntis[0].content);
          }else{
            this_word_elm.find(".note").remove();
          }

          //click
          this_word_elm.find(".memo_wrapper").click(function (e) {
            e.stopPropagation();
            setClicking(
              instance,
              mList,
              mRuledWords,
              this_word,
              row
            )
            presentMenuWord(true);
          });

          return this_word_elm;
        }
      },
    );
  }

  //@TableView Delegate
  function TableViewDelegate() {
    return UITableView.Delegate(
      function didFinishLoad(Instace){},
      function didSelectRow(Row){
        //console.log("🐞select:",Row);

        //is managing
        if (mIsManaging){
            return;
        }

        //click 0
        if (mRuledWords.length <= 0){
          //show BookStore
          if(mList.unico_id == "LIST-TODAY-WORDS"){
            //🔐禁止未登录
            if (LimitFunction.isLogin('default') != true){
              return;
            }

            if (window.top.mBookViewController){
                window.top.mBookViewController.showUP("add-both");
            }

            // @Depracated!
            // if (window.top.ListViewController){
            //   window.top.ListViewController.showUP();
            // }
          }
          //nothing
          else{

          }
        }
        //click index
        else{
          if (Row < mRuledWords.length){
            //switch index
            if (mPlayMode){
              mPlayingIndex = Row;
              updateCurrentUI();

              //切换序号
              if (mPlaying){
                mForceWait = true;
              }
              //阅读一次
              else{
                startLoopPlay(true);
              }
            }
            //show WordDetail
            else{
              window.top.mCardsViewController.showUP(mList, mRuledWords,Row);

              // @Depracated!
              // let word = mWords[Row];
              // window.top.SearchDetail.showUP(word.word_string, word.word_key);
            }
          }
        }
      },
      function didSelectColumn(Row, ColumnKey, RowData){},
      function didHoverCell(Row, Cell, RowData){},
      function didLeaveCell(Row, Cell, RowData){},
    )
  }

  //🍉Selection
  var mIsManaging = false;
  function setupSelection(){

    //🍋toggle Manage

    //todo: 桌面端 drag to toggle manage
    // var mShouldDetectStartSelection = false;
    // var mStartTouchPointY = 0;
    // $("#words").on('mousedown',function (e) {
    //   if (mIsManaging == false){
    //     mShouldDetectStartSelection = true;
    //     mStartTouchPointY = e.clientY;
    //   }
    // }).on('mousemove',function (e) {
    //   if (mShouldDetectStartSelection){
    //     if (Math.abs( e.clientY - mStartTouchPointY ) > 2){
    //       mShouldDetectStartSelection = false;
    //
    //       //进入整理模式
    //       setManagingUI();
    //     }
    //   }
    // }).on('mouseup',function (e) {
    //   mShouldDetectStartSelection = false;
    // });

    //click to toggle manage
    mContext.find("#manage-toggle-button").click(function (e) {
      e.stopPropagation();
      if (mIsManaging){
        cancelManagingUI();
      }else{
        setManagingUI();
      }
    });
    mContext.find(".manage-button.finish").click(function (e) {
      e.stopPropagation();
      cancelManagingUI();
    });

    //toggle
    function setManagingUI() {
      if (mRuledWords.length == 0){
        Alert.showWarningAlert("😨 列表是空的哦","你可以先添加一些单词","好的");
        return;
      }

      mIsManaging = true;
      //.
      enableMultipleSelection();
      //.
      clearSelection();
      mContext.addClass('selecting');
      mContext.addClass('safe-area-top');
      mContext.find("#manage-buttons").removeClass('hidden');
      mContext.find("#manage-buttons").addClass('invalid');
      mContext.find("#manage-toggle-button").text("完成");
      mContext.find(".buttons-wrapper").fadeOut(100);
      $("ion-header").fadeOut(0);

      if (localStorage.getItem("NOTIFY-SELECT-WORDS-1.06") == null){
        mContext.find(".select-helper").removeClass('hidden');
      }

      $("ion-tab-bar").fadeOut(0);

      // setTimeout(()=>{
      //   if (localStorage.getItem("NOTIFY-SELECT-WORDS-1.06") == null){
      //     Alert.showConfirmAlert(
      //       "整理单词的技巧",         // Title,
      //       "👉 手指滑动屏幕左边可以批量选择<br>👉 将不同分类的单词放到不同单词本<br>👉 自由地标记单词熟悉程度",    // HtmlMessage,
      //       false,           // isDangerous,
      //       "不再提示",        // ActionText,
      //       function () {    // OnConfirm
      //         localStorage.setItem("NOTIFY-SELECT-WORDS-1.06", true);
      //       }
      //     );
      //     //todo: 桌面端
      //     // Alert.showConfirmAlert(
      //     //   "小提示",         // Title,
      //     //   "1.点击单词即可选择单词, 拖动鼠标可圈选多个单词<br>2. 按住 ctrl 或 command 键可以同时选择多个单词<br>3. 按住 shift 键可以选择两个单词之间的所有单词",    // HtmlMessage,
      //     //   false,           // isDangerous,
      //     //   "不再提示",        // ActionText,
      //     //   function () {    // OnConfirm
      //     //     localStorage.setItem("NOTIFY-SELECT-WORDS-1.06", true);
      //     //   }
      //     // );
      //   }
      // },1000);
    }
    function cancelManagingUI() {
      mIsManaging = false;
      //.
      disableMultipleSelection();
      //.
      clearSelection();
      mContext.removeClass('selecting');
      mContext.removeClass('safe-area-top');
      mContext.find("#manage-buttons").addClass('hidden');
      mContext.find("#manage-toggle-button").text("整理");
      mContext.find(".buttons-wrapper").fadeIn(100);
      $("ion-tab-bar").fadeIn(0);
      $("ion-header").fadeIn(0);
    }

    //🍋 Selecting
    var mSelectionController = null;
    var mIsSelecting = false;
    function initMultipleSelection() {
      //console.log("🐞initMultipleSelection!");
      if(mSelectionController != null){
        return;
      }

      mSelectionController = new SelectionArea({
        class: 'words-render-wrapper',
        frame: document,
        boundaries: ['.words-render-wrapper'],
        startareas: ['.ui-tableview-cell .texts'],
        selectables: ['.ui-tableview-cell .texts'],
        scrollSpeedDivider: 20,
        manualScrollSpeed: 750,
        allowTouch: true,
        singleTap: {
          allow: true,
          intersect: 'touch'
        },
        startThreshold: 10,
      }).on('beforestart', function (event) {

        //console.log("🐞before start")

      }).on('start', ({store, event}) => {

        //console.log("🐞start")

        mIsSelecting = true;

        //todo: 桌面端 如果不是多选的话, 清除其它选择
        // if (!event.shiftKey && !event.metaKey && !event.ctrlKey) {
        //
        //   // Unselect all elements
        //   for (const el of store.stored) {
        //     el.classList.remove('selected');
        //   }
        //
        //   // Clear previous selection
        //   mSelectionController.clearSelection();
        // }

      }).on('move', ({store: {changed: {added, removed}}}) => {

        // Add a custom class to the elements that where selected.
        for (const el of added) {
          el.classList.add('selected');
        }

        // Remove the class from elements that where removed
        // since the last selection
        for (const el of removed) {
          el.classList.remove('selected');
        }

        //更新数量
        updateSelectedNum();

      }).on('stop', ({}) => {
        // Remember selection in case the user wants to add smth in the next one
        mSelectionController.keepSelection();

        mIsSelecting = false;
      });

      disableMultipleSelection();
    }

    function enableMultipleSelection(){
      //console.log("🐞mSelectionController 01:",mSelectionController);
      mSelectionController.enable();
    }
    function disableMultipleSelection(){
      mSelectionController.disable();
    }

    //todo: onMouseOut
    // window.onmouseout=function(event){
    //   if(event.toElement===null){
    //
    //     //cancel selecting
    //     if (mIsSelecting){
    //       mIsSelecting = false;
    //
    //       clearSelection();
    //       Alert.showWarningAlert(
    //         "🌚 担心的事情还是发生了",
    //         "拖拽选择时请不要离开浏览器边界哦<br>(原理很复杂, 不要问, 如果你不想头秃的话)",
    //         "好吧")
    //     }
    //
    //     //cancel detect
    //     //@pause: mShouldDetectStartSelection = false;
    //   }
    // }

    //selection
    function clearSelection() {
      mSelectionController.cancel();
      mSelectionController.clearSelection();
      mContext.find(".selected").removeClass('selected');

      updateSelectedNum();
    }
    function updateSelectedNum(){
      mContext.find(".select-helper").addClass('hidden');

      let selected_num = mContext.find(".selected").length;
      if (selected_num <= 0){
        mContext.find("#selected_num").addClass('hidden');
        mContext.find("#manage-buttons").addClass('invalid');
      }else{
        mContext.find("#selected_num").removeClass('hidden');
        mContext.find("#manage-buttons").removeClass('invalid');
        mContext.find("#selected_num").text(selected_num+" 已选");
      }
    }
    function getSelectedWords(){
      let selected_rows = mContext.find(".selected").map(function () {
        return $(this).closest('.ui-tableview-cell').attr('row');
      }).get();

      return selected_rows.map(function (row) {
        return mRuledWords[row];
      });
    }

    //🍋 actions

    //clear actions
    $('body').on('click',function () {
      $(".manage-button.remark .memo_menu").remove();
    });

    //invalid alert
    // mContext.find("#manage-buttons.invalid").click(function () {
    //   Alert.showWarningAlert("请选择要整理的单词哦","您可以点击单词或者轻扫单词来多选","好的");
    // });

    //reload then reset selection
    function reloadThenReset(){
      refetchWordsThenReloadTableView(false);

      mSelectionController.clearSelection();
      mContext.find(".selected").removeClass('selected');
      updateSelectedNum();
      mContext.find("#manage-buttons").addClass('invalid');
    }

    //Select
    MenuController.addMenu(
      $(".manage-button.select"),   // Context,
      'for-bottom-bar',                   // ClassName,
      null,                         // Style,
      function (menu) {           // OnCreatedMenu
        MenuController.addMenuItem(
          menu,               // Menu,
          null,         // ClassName,
          '全选',     // HtmlContent,
          function () {       // OnClick
            mSelectionController.select(".ui-tableview-cell .texts");
            mSelectionController.resolveSelectables();
            updateSelectedNum();
          }
        )
        MenuController.addMenuItem(
          menu,               // Menu,
          null,               // ClassName,
          '反选',               // HtmlContent,
          function () {       // OnClick
            var words_to_select = [],
                words_to_deselect = [];
            $.each($(".ui-tableview-cell .texts"), function () {
              let this_text = $(this);
              if(this_text.attr('class').includes('selected')){
                words_to_deselect.push(this_text[0]);
              }else{
                words_to_select.push(this_text[0]);
              }
            });
            for (let word of words_to_select){
              mSelectionController.select(word);
            }
            for (let word of words_to_deselect){
              mSelectionController.deselect(word, false);
            }
            mSelectionController.resolveSelectables();
            updateSelectedNum();
          }
        )
        MenuController.addMenuItem(
          menu,               // Menu,
          null,         // ClassName,
          '清除选择',     // HtmlContent,
          function () {       // OnClick
            $.each($(".ui-tableview-cell .texts"), function () {
              let this_text = $(this);
              mSelectionController.deselect(this_text[0], false);
            });
            mSelectionController.clearSelection();
            mSelectionController.resolveSelectables();
            updateSelectedNum();
          }
        )
      }
    )

    //Remark
    mContext.find(".manage-button.remark").off('click');
    mContext.find(".manage-button.remark").on('click',function (e) {
      e.stopPropagation();

      if(mContext.find('.memo_menu').length > 0){
        MenuController.hideMask();
        mContext.find(".dropdown-menu").remove();
      }else{
        MenuController.hideMask();
        mContext.find(".dropdown-menu").remove();

        //Menu
        let memo_menu = $(
          '<div class="dropdown-menu show memo_menu scroller-hidden for-bottom-bar">\n' +
        //'    <a class="dropdown-item action-memo" title="memo_start">新记</a>\n' +
          '    <a class="dropdown-item memo-item action-memo" title="memo_danger">需要复习</a>\n' +
          '    <a class="dropdown-item memo-item action-memo" title="memo_familiar">已牢记</a>\n' +
          '    <a class="dropdown-item memo-item action-memo" title="memo_known">已学习</a>\n' +
          '    <a class="dropdown-item memo-item action-memo" title="memo_untracked">未学习</a>\n' +
          '    <a class="dropdown-item action-update">模拟一次记忆</a>\n' +
          '</div>'
        );
        $.each(memo_menu.find('.dropdown-item'), function () {
          let this_word_memo_type = $(this).attr('title');
          $(this).find('span').css({'color':ColorSuits['standard-red'][this_word_memo_type]});
        });

        MenuController.showMask();
        $('body').append(memo_menu);

        //MenuActions
        memo_menu.on('click','.action-memo', function (e) {
          e.stopPropagation();

          let this_word_memo_type = $(this).attr('title');
          let selected_words = getSelectedWords();
          StudyDataController.remarkWordsBy(selected_words, this_word_memo_type);

          MenuController.hideMask();
          $(".dropdown-menu").remove();

          Alert.showSuccessAlert(
            "已修改记忆标记",
            null,
            '好的',
            true
          );
          setTimeout(()=>{
            reloadThenReset();
          },1000);
        });
        memo_menu.on('click','.action-update', function (e) {
          e.stopPropagation();

          let selected_words = getSelectedWords();
          StudyDataController.updateWordsMemo(selected_words);

          MenuController.hideMask();
          $(".dropdown-menu").remove();

          Alert.showSuccessAlert(
            "已修改记忆标记",
            null,
            '好的',
            true
          );
          setTimeout(()=>{
            reloadThenReset();
          },1000);
        });
      }
    });

    //Copy
    MenuController.addMenu(
      $(".manage-button.copy"),   // Context,
      'for-bottom-bar',                 // ClassName,
      null,                       // Style,
      function (menu) {           // OnCreatedMenu
        //lists
        for (let list of StudyDataController.allNewAddLists){
          let class_name = list.unico_id == mList.unico_id ? "disabled" : null;
          MenuController.addMenuItem(
            menu,               // Menu,
            class_name,         // ClassName,
            list.list_name,     // HtmlContent,
            function () {       // OnClick
              copyWordsTo(list, null);
            }
          )
        }
        //new
        MenuController.addMenuItem(
          menu,               // Menu,
          null,         // ClassName,
          '新建单词本',     // HtmlContent,
          function () {       // OnClick
            StudyDataController.showCreateListAlert(function (inserted_list) {
              copyWordsTo(inserted_list, null);
            })
          }
        )
      }
    )

    //Move
    MenuController.addMenu(
      $(".manage-button.move"),   // Context,
      'for-bottom-bar',                 // ClassName,
      null,                       // Style,
      function (menu) {           // OnCreatedMenu
        //lists
        for (let list of StudyDataController.allNewAddLists){
          let class_name = list.unico_id == mList.unico_id ? "disabled" : null;
          MenuController.addMenuItem(
            menu,               // Menu,
            class_name,         // ClassName,
            list.list_name,     // HtmlContent,
            function () {       // OnClick
              moveWordsBy(mList, list)
            }
          )
        }
        //new
        MenuController.addMenuItem(
          menu,               // Menu,
          null,         // ClassName,
          '新建单词本',     // HtmlContent,
          function () {       // OnClick
            StudyDataController.showCreateListAlert(function (inserted_list) {
              moveWordsBy(mList, inserted_list);
            });
          }
        )
      }
    )

    //DeleteAction
    $('.manage-button.delete').click(function () {
      Alert.showConfirmAlert("确认删除选中单词吗?","单词删除后不可恢复",true,"确认删除", function (){
        archiveWordsFrom(mList, null);
      });
    });
    
    //.
    function moveWordsBy(sourceList, targetList) {
      //copy
      copyWordsTo(targetList, function (skiped_num) {
        //archive
        archiveWordsFrom(sourceList, function (total) {
          let html_message = skiped_num > 0 ?
            "总共"+total+"个单词, 更新了"+skiped_num+"个重复单词":"";
          Alert.showSuccessAlert(
            "已移动到"+targetList.list_name,
            html_message,
            '好的',
            true
          );
          reloadThenReset();
        });
      });
    }
    function copyWordsTo(targetList, onFinish){
      let selected_words = getSelectedWords();
      //copy
      StudyDataController.copyWordsBy(targetList.unico_id, selected_words, function (skiped_num) {
        
        //finish
        if (onFinish != null){
          onFinish(skiped_num);
        }else{
          let html_message = skiped_num > 0 ?
            "总共"+getSelectedWords().length+"个单词, 跳过了"+skiped_num+"个重复单词":"";
          Alert.showSuccessAlert(
            "已复制到"+targetList.list_name,
            html_message,
            '好的',
            true
          );
          reloadThenReset();
        }
      })
    }
    function archiveWordsFrom(sourceList, onFinish){
      //archive words
      let selected_words = getSelectedWords();
      //console.log("🐞archive:",sourceList,selected_words,sourceList.unico_id);
      let selected_words_strings = selected_words.map(function (selected_word) {
        return selected_word.word_string;
      });
      StudyDataController.archiveWordsBy(selected_words_strings, sourceList.unico_id);
      
      //finish
      if (onFinish != null){
          onFinish(selected_words.length);
      }else{
        Alert.showSuccessAlert(
          "已删除"+selected_words.length+"个单词",
          '',
          '好的',
          true
        );
        reloadThenReset();
      }
    }

    initMultipleSelection();
    //console.log("🐞mSelectionController:",mSelectionController);
  }


  //🍉Init
  useEffect(() => {
    mContext = $(contentRef.current);
    if(props.onRef){
      props.onRef({
        refetchWordsThenReloadTableView:refetchWordsThenReloadTableView,
        reloadTableView: reloadTableView
      });
    }

    setupSelection();
    setupOptionsMenu(mContext);

    updateReadLanguageUI();
  });

  const contentRef = useRef(this);
  var willPlay;
  return (<div className="wordstableview" ref={contentRef} read-language="en_zh">
    <MenuWord/>

    {/*float-button*/}
    <div className="float-button">
      {/*IonFab*/}
      {/*<IonFab className="float-button" vertical="bottom" horizontal="center" slot="fixed">*/}
      <div className="buttons-wrapper">
        {/*play*/}
        <div className="button button-play" onClick={()=>{
          enterPlayMode();
        }}>
          <img className="play" src={"assets/StudyViewController/StudyItem-Play@50X50.png"}/>
          <img className="pause" src={"assets/StudyViewController/StudyItem-Pause@50X50.png"}/>
        </div>

        {/*card*/}
        <div className="button button-card" onClick={(e)=>{
          e.stopPropagation();
          window.top.mCardsViewController.showUP(mList, mRuledWords,0);
        }}>
          <img src={"assets/StudyViewController/StudyItem-Card@50X50.png"}/>
        </div>

        {/*grid*/}
        {/*<div className="button grid">*/}
        {/*<img src={"assets/StudyViewController/StudyItem-Grid@50X50.png"}/>*/}
        {/*</div>*/}
      </div>
    </div>

    {/*title*/}
    <div className="top-buttons-wrapper d-flex align-items-center justify-content-between">
      <div className="top-left-buttons-wrapper d-flex flex-row flex-grow-1">
        {/*<div className="button view" title="view">*/}
          {/*<IonIcon icon={eyeOutline}/>*/}
          {/*<span className="label">显示模式</span>*/}
        {/*</div>*/}

        <div id="option_memo" className="button filter" title="filter">
          <i className="fas fa-eye-slash"/>
          {/*<IonIcon icon={funnelOutline}/>*/}
          <span className="label">过滤</span>
        </div>

        <div id="option_order" className="button order" title="order">
          <i className="fas fa-sort-alpha-down"/>
          {/*<IonIcon icon={swapVerticalOutline}/>*/}
          <span className="label">顺序</span>
        </div>

      </div>
      <div id="manage-toggle-button">整理</div>
      <div id="player-guide-button" onClick={()=>{
        //Alert.showWarningAlert("单词播放隐藏技巧","后台播放: 需要在读单词的时候快速按下锁屏按钮");
        Alert.showWarningAlert(
          "单词播放隐藏技巧",
          "<p>后台播放 👇<br>" + (NativeTools.isIOS ? "支持后台播放和锁屏播放<br>(部分设备锁屏播放的话需要先进入后台再锁屏)" : "支持后台播放和锁屏播放") +"</p>" +
          "<p>调整进度 👇<br>在播放时点击单词可以跳转进度</p>" +
          "<p>单个发音 👇<br>暂停播放, 可以点击单词发音</p>" +
          "<p>抽认单词 👇<br>暂停播放, 并将模式调整到只显示中文或英文, 可以点击单词听到答案</p>",
          "知道啦");
      }}><i className="fas fa-question-circle"/>隐藏技巧</div>
    </div>

    <div className="options-description d-flex justify-content-between">
      <span>
        <span className="memo-description">所有单词</span> / <span className="order-description">默认顺序</span>
      </span>
      <div className="action" onClick={()=>{trigerClickOptionMemo("memo_all"); reloadTableViewCurrent();}}>取消过滤</div>
    </div>
    <div className="options-wrapper"></div>



    {/*words*/}
    <div className="words-render-wrapper">
      <div className="p-3 text-sandy d-flex justify-content-center">正在准备单词</div>
    </div>

    {/*select help indicator*/}
    <div className="select-helper hidden" onClick={()=>{
      mContext.find(".select-helper").addClass('hidden');
      localStorage.setItem("NOTIFY-SELECT-WORDS-1.06", true);
    }}>
      <i className="fas fa-hand-point-left"/>
      <span>手指上下滑动屏幕左侧<br/>可以快速进行批量选择</span>
      <a className="close">知道啦</a>
    </div>

    {/*manage buttons*/}
    <div id="manage-buttons" className="invalid hidden">
      <div id="selected_num" className="hidden">0</div>

      <div className="manage-button select">
        <img className="icon" src="assets/icons/wordsvcor-selection/words_selection_vcor_select.png"/>
      </div>
      <div className="manage-button normal remark">
        <img className="icon" src="assets/icons/wordsvcor-selection/words_selection_vcor_mark.png"/>
      </div>
      <div className="manage-button normal copy">
        <img className="icon" src="assets/icons/wordsvcor-selection/words_selection_vcor_copy.png"/>
      </div>
      <div className="manage-button normal move">
        <img className="icon" src="assets/icons/wordsvcor-selection/words_selection_vcor_move.png"/>
      </div>
      <div className="manage-button normal delete">
        <img className="icon" src="assets/icons/wordsvcor-selection/words_selection_vcor_delete.png"/>
      </div>

      {/*<div className="manage-button finish">*/}
        {/*完成*/}
      {/*</div>*/}
    </div>

    {/*player buttons*/}
    <div id="player-buttons" className="safe-area-bottom">
      <div className="player-button play" onClick={()=>{
        if (mPlaying){
          clearTimeout(willPlay);
          stopLoopPlay();
        }else{
          setPlayingUI();
          willPlay = setTimeout(()=>{
            startLoopPlay();
          },100);
        }

        // @Depracated!
        // $(".wordstableview").addClass("invalid");
        // setTimeout(()=>{
        //   $(".wordstableview").removeClass("invalid");
        // },1000);

        updateReadLanguageUI();
      }}>
        <img className="play" src={"assets/StudyViewController/StudyItem-Play@50X50.png"}/>
        <img className="pause" src={"assets/StudyViewController/StudyItem-Pause@50X50.png"}/>
      </div>

      <div className="player-button language" onClick={()=>{
        var index = Object.keys(mReadLanguageNames).indexOf(mReadLanguage);
        index ++; if (index >= Object.keys(mReadLanguageNames).length){index = 0;}
        mReadLanguage = Object.keys(mReadLanguageNames)[index];
        updateReadLanguageUI();
      }}><i className="fas fa-language"/><span className="label">先英后中</span></div>

      <div className="player-button speed" onClick={()=>{
        mReadSpead ++; if (mReadSpead >= mReadSpeadRates.length){mReadSpead = 0;}
        updateReadSpeadUI();
      }}><i className="fas fa-wind"/><span className="label">正常速度</span></div>

      {/*<div className="player-button interval" onClick={()=>{}}><i className="fas fa-hourglass-half"/></div>*/}

      <div className="player-button cancel" onClick={()=>{
        exitPlayMode();

        //update data
        setTimeout(()=>{
          //update memos then reload
          if (mPlayedWords.length > 0){
            StudyDataController.updateWordsMemo(mPlayedWords);
            mPlayedWords = [];
          }
          refetchWordsThenReloadTableView();
        },100);
      }}>
        ✕
      </div>
    </div>

    {/*<div className="selectable-area"></div>*/}

  </div>);


}
export default WordsTableView;