import Tools from "./Tools";
import TimeController from "./TimeController";
import $ from "jquery";
import NativeTools from "./NativeTools";
import Alert from "./Alert";

const OptionKey = {
  //顺序
  ListsOrder : "ListsOrder",
  WordsOrder : "WordsOrder",
  //显示
  ShortMean : "ShortMean",
  ImageVisibility_InDetail : "ImageVisibility_InDetail",
  ImageAutoDownload_InDetail : "ImageAutoDownload_InDetail",
  DisplayingMode : "DisplayingMode",

  //记忆
  ListChartVisibility : "ListChartVisibility",
  MarkVisibility : "MarkVisibility",
  RepeatLabelVisibility : "RepeatLabelVisibility",
  MemoManual : "MemoManual",
  Memos : "Memos",
  StartTime : "StartTime",
  RepeatTime : "RepeatTime",
  NeedRewind : "NeedRewind",

  //显示(当前)
  ImageVisibility : "ImageVisibility",
  ImageAutoDownload : "ImageAutoDownload",
};
const OptionValue = {
  //顺序
  list_order_auto : "自动排序",
  list_order_manual : "调整单词本顺序",

  //@Depracated: order_origin : "原文顺序",
  order_plan : "默认顺序",
  order_shuffle : "随机顺序",
  order_time_pass : "时间顺序",
  order_time_reverse : "时间倒序",
  order_char_pass : "首字母顺序",
  order_char_reverse : "首字母倒序",
  order_repeat_pass : "复习次数顺序",
  order_repeat_reverse : "复习次数倒序",


  //显示
  image_shown : "image_shown",
  image_hide : "image_hide",
  image_auto_download_on : "image_auto_download_on",
  image_auto_download_off : "image_auto_download_off",

  short_mean_on : "short_mean_on",
  short_mean_off : "short_mean_off",

  displaying_mode_default : "正常显示",
  displaying_mode_only_index : "只显示单词",
  displaying_mode_only_mean : "只显示释义",

  image_in_detail_shown : "image_in_detail_shown",
  image_in_detail_hide : "image_in_detail_hide",
  image_in_detail_auto_download_on : "image_in_detail_auto_download_on",
  image_in_detail_auto_download_off : "image_in_detail_auto_download_off",

  //记忆
  curve_memo_start : "memo_start",
  curve_memo_known : "memo_known",
  curve_memo_familiar : "memo_familiar",
  curve_memo_danger : "memo_danger",
  curve_memo_untracked : "memo_untracked",
  curve_memo_all : "memo_all",

  start_time_0 : "start_time_0",
  start_time_1 : "start_time_1",
  start_time_2 : "start_time_2",
  start_time_4 : "start_time_4",
  start_time_7 : "start_time_7",
  start_time_15 : "start_time_15",
  start_time_30 : "start_time_30",
  start_time_30_more : "start_time_30+",
  start_time_all : "start_time_all",

  repeat_time_1 : "repeat_time_1",
  repeat_time_2 : "repeat_time_2",
  repeat_time_3 : "repeat_time_3",
  repeat_time_4 : "repeat_time_4",
  repeat_time_5 : "repeat_time_5",
  repeat_time_6 : "repeat_time_6",
  repeat_time_6_more : "repeat_time_7",
  repeat_time_all : "repeat_time_all",

  memo_manual_on : "memo_manual_on",
  memo_manual_off : "memo_manual_off",

  mark_shown : "memo_mark_shown",
  mark_hide : "memo_mark_hide",

  repeat_label_shown : "repeat_label_shown",
  repeat_label_hide : "repeat_label_hide",

  need_rewind_true : "need_rewind_true",
  need_rewind_false : "need_rewind_false",

  list_chart_shown : "list_chart_shown",
  list_chart_hide : "list_chart_hide",

  tansfer_memo : "使不同单词本里单词记忆保存一致",
  color_suit : "配色方案",
};
const DefaultValues = (function () {
  let defaultValues = {};

  //顺序
  defaultValues[OptionKey.ListsOrder] = OptionValue.list_order_auto;
  defaultValues[OptionKey.WordsOrder] = OptionValue.order_plan;

  //记忆
  defaultValues[OptionKey.ListChartVisibility] = OptionValue.list_chart_shown;
  defaultValues[OptionKey.MarkVisibility] = OptionValue.mark_shown;
  defaultValues[OptionKey.RepeatLabelVisibility] = OptionValue.repeat_label_shown;
  defaultValues[OptionKey.MemoManual] = OptionValue.memo_manual_off;
  defaultValues[OptionKey.Memos] = [
    //@Depracated: OptionValue.curve_memo_start,
    OptionValue.curve_memo_known,
    OptionValue.curve_memo_familiar,
    OptionValue.curve_memo_danger,
    OptionValue.curve_memo_untracked
  ];
  defaultValues[OptionKey.StartTime] = OptionValue.start_time_all;
  defaultValues[OptionKey.RepeatTime] = OptionValue.repeat_time_all;
  defaultValues[OptionKey.NeedRewind] = OptionValue.need_rewind_true;

  //显示
  defaultValues[OptionKey.ShortMean] = OptionValue.short_mean_off;
  defaultValues[OptionKey.ImageVisibility_InDetail] = OptionValue.image_in_detail_hide;
  defaultValues[OptionKey.ImageAutoDownload_InDetail] = OptionValue.image_in_detail_auto_download_off;
  defaultValues[OptionKey.DisplayingMode] = OptionValue.displaying_mode_default;

  //显示(当前)
  defaultValues[OptionKey.ImageVisibility] = OptionValue.image_hide;
  defaultValues[OptionKey.ImageAutoDownload] = OptionValue.image_auto_download_off;

  return defaultValues;
})();
const LocalizedValues = {
  "memo_start":"新词",

  "memo_known":"已学习",
  "memo_familiar":"已牢记",
  "memo_danger":"需要复习",
  "memo_untracked":"未学习",
  "memo_all":"选择所有",

  "start_time_0":"今天",
  "start_time_1":"昨天",
  "start_time_2":"昨天∼前天",
  "start_time_4":"前天∼4天",
  "start_time_7":"4天∼7天",
  "start_time_15":"7天∼15天",
  "start_time_30":"15天∼30天",
  "start_time_30+":"大于30天",
  "start_time_all":"开始时间",

  "repeat_time_1":"1次",
  "repeat_time_2":"2次",
  "repeat_time_3":"3次",
  "repeat_time_4":"4次",
  "repeat_time_5":"5次",
  "repeat_time_6":"6次",
  "repeat_time_7":"6+次",
  "repeat_time_all":"所有复习次数",

  "list_chart_shown":"显示每个单词本统计",

  "memo_mark_shown":"显示记忆标记",
  "need_rewind_true":"标记出需要复习的单词",
  "repeat_label_shown":"显示复习次数",
  "memo_manual_on":"手动标记",

  "image_shown":"显示图片",
  "image_auto_download_on":"自动下载缺失图片",

  "short_mean_on":"精简释义",

  "image_in_detail_shown":"在释义界面显示图片",
  "image_in_detail_auto_download_on":"在释义界面自动下载缺失图片",
}

export const Settings = {

  //🚫开发模式
  debug:false,

  //🍋App信息
  AppVersion:"1.071",
  AppName:"fangewords_too",
  AppDisplayName:"方格单词²",
  AppDevice:"ionic",

  //🍋用户和权限
  
  //UserEmail
  mUserEmail:null,
  getUserEmail(){
    //🚫test:测试账号
    //return "corxit@163.com";

    if (Settings.mUserEmail != null){
      return Settings.mUserEmail;
    }
    let get_email = localStorage.getItem('SETTING-USER-EMAIL');
    return get_email != null ? get_email : '';
  },
  setUserEmail(email){
    Settings.mUserEmail = email;
    if (Tools.isEmpty(email)){
      localStorage.removeItem('SETTING-USER-EMAIL');
    }else{
      localStorage.setItem('SETTING-USER-EMAIL', email);
    }
  },

  //NickName
  mNickName:null,
  getNickName(){
    if (Settings.mNickName != null){
      return Settings.mNickName;
    }
    return localStorage.getItem('SETTING-NICK-NAME');
  },
  setNickName(nickname){
    Settings.mNickName = nickname;
    if (Tools.isEmpty(nickname)){
      localStorage.removeItem('SETTING-NICK-NAME');
    }else{
      localStorage.setItem('SETTING-NICK-NAME', nickname);
    }
  },

  //DAYS-REMAIN
  setDaysRemain(Days){
    localStorage.setItem(Settings.makeUniqueKey("DAYS-REMAIN",true,true), Days)
  },
  getDaysRemain(){
    if (Tools.isEmpty(Settings.getUserEmail())){
      return null;
    }
    let days = localStorage.getItem(Settings.makeUniqueKey("DAYS-REMAIN",true,true));
    return days == null ? null:parseFloat(days);
  },

  //HAS-SHARED
  setHasShared(Shared){
    localStorage.setItem(Settings.makeUniqueKey("HAS-SHARED",true,true), Shared)
  },
  getHasShared(){
    if (Tools.isEmpty(Settings.getUserEmail())){
      return null;
    }
    let shared = localStorage.getItem(Settings.makeUniqueKey("HAS-SHARED",true,true));
    return shared == null ? null:shared == "true";
  },

  //TRYING-DAYS
  addTryingDays(){
    let days = Settings.getTryingDays();
    let today = TimeController.getTimeStringByDay();
    if (days.includes(today) != true){
      days.push(today);
      localStorage.setItem("TRYING-DAYS", JSON.stringify(days));
    }
  },
  getTryingDays(){
    let days = localStorage.getItem("TRYING-DAYS");
    if (days){
      days = JSON.parse(days); 
    }
    return Tools.isEmpty(days) ? []:days;
  },

  //IS-CURRENT-VERSION-PUBLISHED-TO-APP-STORE
  mPublished:null,
  setIsCurrentVersionPublishedToAppStore(Published){
    Settings.mPublished = Published;
    localStorage.setItem(Settings.makeUniqueKey("IS-CURRENT-VERSION-PUBLISHED-TO-APP-STORE",false,true), Published)
  },
  getIsCurrentVersionPublishedToAppStore(){
    if (Settings.mPublished != null){
      return Settings.mPublished;
    }

    let published = localStorage.getItem(Settings.makeUniqueKey("IS-CURRENT-VERSION-PUBLISHED-TO-APP-STORE",false,true));
    Settings.mPublished = published == null
      ? false
      :published == "true";
    return Settings.mPublished;
  },
  
  //🍋单词本设置

  //@Models:
  OptionKey:OptionKey,
  OptionValue:OptionValue,
  mDefaultValues : DefaultValues,
  getLocalizedValue(Value){
    let localized_value = LocalizedValues[Value];
    if (localized_value != undefined){
      return localized_value;
    }
    return Value;
  },

  //@Methods:
  refetchWordsVCorRules:function () {
    //顺序
    Settings.mOptionListOrder = Settings.getWordDisplayingPreferenceBy('GLOBAL', OptionKey.ListsOrder)
    Settings.mOptionWordsOrder = Settings.getWordDisplayingPreferenceBy('GLOBAL', OptionKey.WordsOrder)

    //记忆
    Settings.mOptionListChartVisibility = Settings.getWordDisplayingPreferenceBy('GLOBAL', OptionKey.ListChartVisibility)
    Settings.mOptionMarkVisibility = Settings.getWordDisplayingPreferenceBy('GLOBAL', OptionKey.MarkVisibility)
    Settings.mOptionRepeatLabelVisibility = Settings.getWordDisplayingPreferenceBy('GLOBAL', OptionKey.RepeatLabelVisibility)
    Settings.mOptionMemoManual = Settings.getWordDisplayingPreferenceBy('GLOBAL', OptionKey.MemoManual)
    Settings.mOptionNeedRewind = Settings.getWordDisplayingPreferenceBy('GLOBAL', OptionKey.NeedRewind)

    //显示
    Settings.mOptionShortMean = Settings.getWordDisplayingPreferenceBy('GLOBAL', OptionKey.ShortMean)
    Settings.mOptionImageVisibility_InDetail = Settings.getWordDisplayingPreferenceBy('GLOBAL', OptionKey.ImageVisibility_InDetail)
    Settings.mOptionImageAutoDownload_InDetail = Settings.getWordDisplayingPreferenceBy('GLOBAL', OptionKey.ImageAutoDownload_InDetail)
    Settings.mOptionDisplayMode = Settings.getWordDisplayingPreferenceBy('GLOBAL', OptionKey.DisplayingMode)

    //显示(当前列表)
    // Settings.mOptionImageVisibility = Settings.getWordDisplayingPreferenceBy(UnicoID: WordsVCor.getCurrentListUnicoID(), OptionKey.ImageVisibility)
    // Settings.mOptionImageAutoDownload = Settings.getWordDisplayingPreferenceBy(UnicoID: WordsVCor.getCurrentListUnicoID(), OptionKey.ImageAutoDownload)
  },
  getWordDisplayingPreferenceBy(UnicoID = "GLOBAL", OptionKey){
    let Setting_Key = Settings.makeUniqueKey(UnicoID + "-" + OptionKey);
    let valueString = localStorage.getItem(Setting_Key);
    if (Tools.isEmpty(valueString)==false){
      return valueString;
    }
    return Settings.mDefaultValues[OptionKey];
  },
  setWordDisplayingPreferenceBy(UnicoID = "GLOBAL", OptionKey, OptionValue){
    let Setting_Key = Settings.makeUniqueKey(UnicoID + "-" + OptionKey);
    localStorage.setItem(Setting_Key, OptionValue);
  },
  
  //顺序
  mOptionListOrder : OptionValue.list_order_auto,
  mOptionWordsOrder : OptionValue.order_plan,
  //显示
  mOptionShortMean : OptionValue.short_mean_off,
  mOptionImageVisibility_InDetail : OptionValue.image_in_detail_hide,
  mOptionImageAutoDownload_InDetail : OptionValue.image_in_detail_auto_download_off,
  mOptionDisplayMode : OptionValue.displaying_mode_default,

  //记忆
  mOptionListChartVisibility : OptionValue.list_chart_shown,
  mOptionMarkVisibility : OptionValue.mark_shown,
  mOptionRepeatLabelVisibility : OptionValue.repeat_label_shown,
  mOptionMemoManual : OptionValue.memo_manual_off,
  mOptionMemos : [
    //@Depracated: OptionValue.curve_memo_start,
    OptionValue.curve_memo_known,
    OptionValue.curve_memo_familiar,
    OptionValue.curve_memo_danger,
    OptionValue.curve_memo_untracked
  ],
  mOptionStartTime : OptionValue.start_time_all,
  mOptionRepeatTime : OptionValue.repeat_time_all,
  mOptionNeedRewind : OptionValue.need_rewind_true,

  //显示(当前)
  mOptionImageVisibility : OptionValue.image_hide,
  mOptionImageAutoDownload : OptionValue.image_auto_download_off,
  
  //🍋偏好设置
  mLanguage:null,
  validLanguage(){
    return Settings.getLanguage() == "it" || Settings.getLanguage() == "fr";
  },
  getLanguage(){
    if (Settings.mLanguage != null){
      return Settings.mLanguage;
    }
    let get_language = localStorage.getItem('SETTING-LANGUAGE');
    return get_language ? get_language:'all';
  },
  setLanguage(Language){
    Settings.mLanguage = Language;
    if (Tools.isEmpty(Language)){
      localStorage.removeItem('SETTING-LANGUAGE');
    }else{
      localStorage.setItem('SETTING-LANGUAGE', Language);
    }

    //@Depracated: voice language.
    //set voice language
    // if (Language != "all"){
    //   Settings.setVoiceLanguage(Language);
    // }
  },

  // @Depracated: voice language.
  // mVoiceLanguage:null,
  // getVoiceLanguage(){
  //   if (Settings.mVoiceLanguage != null){
  //     return Settings.mVoiceLanguage;
  //   }
  //   let get_VoiceLanguage = localStorage.getItem('SETTING-VOICE-LANGUAGE');
  //   if (get_VoiceLanguage == null || get_VoiceLanguage == "all"){
  //     return "en";
  //   }
  //   return get_VoiceLanguage;
  // },
  // setVoiceLanguage(VoiceLanguage){
  //   Settings.mVoiceLanguage = VoiceLanguage;
  //   if (Tools.isEmpty(VoiceLanguage)){
  //     localStorage.removeItem('SETTING-VOICE-LANGUAGE');
  //   }else{
  //     localStorage.setItem('SETTING-VOICE-LANGUAGE', VoiceLanguage);
  //   }
  // },

  mVoiceOffline:null,
  setVoiceOffline(Bool){
    Settings.mVoiceOffline = Bool
    localStorage.setItem("SETTING-OFFLINE-VOICE", Bool ? "true":"false");
  },
  getVoiceOffline(){
    if (Settings.mVoiceOffline != null){
      return Settings.mVoiceOffline;
    }
    let getValue = localStorage.getItem("SETTING-OFFLINE-VOICE");
    if (getValue != null){
      return getValue == "true";
    }

    let defaultOffline = false;//NativeTools.isAndroid ? false:true;  //Android 默认在线模式, 其它默认离线模式
    Settings.mVoiceOffline = defaultOffline;
    return defaultOffline;
  },
  
  getPerson(person){
    let persons = {
      "fr":{
        mIo : "je",
        mTu : "tu",
        mLui : "il.elle",
        mNoi : "nous",
        mVoi : "vous",
        mLoro : "ils",
      },
      "it":{
        mIo : "io",
        mTu : "tu",
        mLui : "lui.lei",
        mNoi : "noi",
        mVoi : "voi",
        mLoro : "loro",
      },
    }
    return persons[Settings.getLanguage()][person] ? persons[Settings.getLanguage()][person] : person;
  },
  getFlag(Language=null){
    if (Language == null){
      Language = Settings.getLanguage();
    }
    let language_name = {
      "en":"🇬🇧",
      "fr":"🇫🇷",
      "it":"🇮🇹",
      "all":"🏳️‍🌈",
    }[Language];
    if (language_name){
      return language_name;
    }
    return Language;
  },
  getLocalizedLanguageName(Language=null){
    if (Language == null){
      Language = Settings.getLanguage();
    }
    let language_name = {
      "en":"英语",
      "fr":"法语",
      "it":"意大利语",
      "all":"多国语",
    }[Language];
    if (language_name){
      return language_name;
    }
    return Language;
  },
  
  
  //🍋学习设置
  //PRIMARY-LIST-INDEXPATH
  mPrimaryListIndexPath:null,
  setPrimaryListIndexPath(Dict){
    Settings.mPrimaryListIndexPath = Dict;
    //console.log("🐞setPrimaryListIndexPath:",Dict);
    if (Dict == null){
      localStorage.removeItem("PRIMARY-LIST-INDEXPATH");
    }else{
      localStorage.setItem("PRIMARY-LIST-INDEXPATH",JSON.stringify(Dict));
    }
  },
  getPrimaryListIndexPath(){
    if (Settings.mPrimaryListIndexPath != null){
      return Settings.mPrimaryListIndexPath;
    }else{
      let getValue = localStorage.getItem("PRIMARY-LIST-INDEXPATH");
      if (getValue != null){
        let dictValue = JSON.parse(getValue);
        if (dictValue != null){
          Settings.mPrimaryListIndexPath = dictValue;
          return Settings.mPrimaryListIndexPath;
        }
      }
    }
    return null;
  },

  //STUDY-UPPER-LIMIT-NUM
  mUpperLimit:null,
  setUpperLimit(Int){
    Settings.mUpperLimit = Int
    localStorage.setItem("STUDY-UPPER-LIMIT-NUM", Int)
  },
  getUpperLimit(){
    if (Settings.mUpperLimit != null){
      return Settings.mUpperLimit
    }
    let getValue = localStorage.getItem("STUDY-UPPER-LIMIT-NUM");
    if (getValue != null){
      let intValue = parseInt(getValue);
      if (isNaN(intValue)==false){
        Settings.mUpperLimit = intValue;
        return Settings.mUpperLimit;
      }
    }
    return 30;
  },
  
  //🍋UI设置
  mBookFolded:null,
  setBookFolded(Bool){
    Settings.mBookFolded = Bool
    localStorage.setItem("SETTING-UI-BOOK-FOLD", Bool ? "true":"false");
  },
  getBookFolded(){
    if (Settings.mBookFolded != null){
      return Settings.mBookFolded;
    }
    let getValue = localStorage.getItem("SETTING-UI-BOOK-FOLD");
    if (getValue != null){
      return getValue == "true";
    }
    return false;
  },
  

  makeUniqueKey(PreferenceKey, WithUser=false, WithVersion=false){
    let key = PreferenceKey;
    if (WithVersion){
      key = Settings.AppVersion + "-" + PreferenceKey;
    }
    if (WithUser){
      key = key+"-"+Settings.getUserEmail();
    }
    return key;
  }
}
Object(window.top).Settings = Settings;

export const LimitFunction = {

  //🔐 权限检查
  canUse(LoginMessage = null, PaymentMessage = null, AllowShare){

    //检查登录
    if (LimitFunction.isLogin(LoginMessage) != true){
      return false;
    }

    //AppStore审核状态
    if (Settings.getIsCurrentVersionPublishedToAppStore() != true){
      return true;
    }

    //检查分享
    if (AllowShare){
      if (Settings.getHasShared()){
        return true;
      }

      //检查试用
      if (LimitFunction.isTrying()){
        return true;
      }
    }

    //检查购买
    if (LimitFunction.isPaid(PaymentMessage)){
      return true;
    }

    //未知情况
    return false;
  },

  //🔐 登录状态
  isLogin(Message = null){
    //.
    if (Tools.isEmpty(Settings.getUserEmail()) != true){
      return true;
    }

    //.
    if (Message == null){
      return false;
    }

    //.
    if (Message == "default"){Message = "登录后可以管理您的个人学习数据<br>还有更多有趣的原创功能等着您哦 😛";}
    Alert.showConfirmAlert(null,Message,false,"登录",function () {
      window.top.presentLoginPage();
    },"再逛逛");

    return false;
  },

  //❤️ 购买状态
  isPaid(Message = null){
    //.已激活
    if (Settings.getDaysRemain() && Settings.getDaysRemain() > 0){
      return true;
    }

    //.未激活
    if (Message){
      const Keys = require("./Keys.js").default;

      Alert.showActionsAlert(
        "😛 遇到了暂不可用的功能",
        "很抱歉哦, 方格单词还无法免费开放这个功能<br><br>您可以通过购买专业版，来解锁功能<br>或者参与内测来免费获取激活码",
        {
          "激活 | 恢复":function () {
            //console.log("🐞激活");
            window.top.presentPayPage();
          },
          "我想参与内测":function () {
            //console.log("🐞内测");
            NativeTools.openUrl(Keys.Url.page_join_beta);
          }
        },
        "再逛逛"
      );
      return false;

      // // 👇 fix confirm() bug
      // // eslint-disable-next-line no-restricted-globals
      // if (confirm("😛 试用期限已经结束咯"+"\n\n很抱歉哦, 方格语法还无法免费开放\n\n您可以通过激活专业版，来解锁功能")){
      //   window.top.presentPayPage();
      //   return false;
      // }

      //@Depracated!
      //Alert.showUnpaidAlert(Context: Context!, Message: Message!)
    }
    return false;
  },
  
  //🔋 试用状态
  isTrying(){
    return Settings.getTryingDays().length <= 3;
  },
}

export const ShareController = {

}

export const UpdateController = {
  autoCheckUpdate(){
    //console.log("🐞autoCheckUpdate");
    let auto_version_check_date = localStorage.getItem('AUTO-VERSION-CHECK-UPDATE-'+Settings.AppVersion);
    let today = TimeController.getTimeStringByDay();
    if (auto_version_check_date == null || auto_version_check_date != today){
      localStorage.setItem('AUTO-VERSION-CHECK-UPDATE-'+Settings.AppVersion,today);
      UpdateController.checkUpdate(false);
    }
  },
  checkUpdate(HasUI=false){

    const Keys = require("./Keys.js").default;

    if (HasUI){
      Alert.showLoadingAlert('正在检查更新','如果遇到问题, 您可以访问<br>https://iofange.com/download<br>来查看最新版本');
    }

    let version_check_link = Keys.Url.version_check +
      '?app_name='+Settings.AppName+
      '&device='+Settings.AppDevice;
    //console.log("🐞检查更新",version_check_link);
    $.get(version_check_link, function (data) {
      let dict = JSON.parse(data);
      //console.log("🐞检查更新结果",dict);
      if (dict && dict['version']){
        let current_version = Settings.AppVersion;

        let target_version = dict['version'];
        let target_url = dict['download_url'] ? dict['download_url'] : 'https://iofange.com/download';
        let target_desc = dict['update_description'] ? dict['update_description'] : '';

        //设置AppStore发布版本
        Settings.setIsCurrentVersionPublishedToAppStore(false);
        let app_store_published_version = dict['app_store_published_version'];
        if (app_store_published_version){
          let published_version_greater = Tools.versionCompare(app_store_published_version, current_version);
          //console.log("🐞versionCompare:",app_store_published_version, current_version,published_version_greater);
          if (Tools.isEmpty(published_version_greater) != true && published_version_greater >= 0){
            //console.log("🐞versionCompare published");
            Settings.setIsCurrentVersionPublishedToAppStore(true);
          }
        }

        //检查版本
        let compare = Tools.versionCompare(target_version, current_version);
        if (compare && compare > 0){

          Alert.showConfirmAlert("有新的版本发布啦",target_desc+"<br>您也可以稍后在工具箱手动更新",false, "现在更新", function () {
            NativeTools.openUrl(target_url);
          },"稍后");

          // @Depracated!
          // 👇 fix confirm() bug
          // eslint-disable-next-line no-restricted-globals
          // if (confirm("有新的版本发布, 您也可以稍后手动更新") == true){
          //   NativeTools.openUrl(target_url);
          // }
        }else{
          if (HasUI){
            Alert.showSuccessAlert('当前版本是最新的');

            //@Depracated!
            //alert('当前版本是最新的');
          }
        }
      }
    });
  }
}