import $ from "jquery";
import * as Tools from "../Tools";
import Mdict from "./Mdict"
import CacheController from "./CacheController"
import {Settings} from "../Settings";
import * as Extension from "../Extension.js";
import Alert from "../Alert";

// MdxService
// define(['jquery','bluebird','Tools','CacheController','Mdict','Swal','Alert'],function (
//     $, Promise, Tools, CacheController, Mdict, Swal, Alert
// ){
//
//
// });

//🍋 init
function init() {
  //console.log("🐞MdxService init!");

  //fetch Language
  MdxService.mLanguage = Settings.getLanguage();

  //setup Mdict CallDevice Listener
  Mdict.Tools.CallDeviceContext = MdxService;

  //refetch MdxService.mMdxOrderDicts
  MdxService.refetchOrderDict(false, function () {
    //load files
    MdxService.tryAutoLoadFromCaches();
  });
}

//🍋 Validate Files
function validateDictionarys(){
  if (MdxService.mMdxCaches.length <= 0){
    Alert.showSetupGuide();
    return false;
  }else if (Mdict.getMdxObjects().length <= 0){
    Alert.showEmptyDizioAlert();
    return false;
  }
  return true;
}

//🍋 OrderDicts + Files
function refetchOrderDict(ForceFetch, OnFinish){
  CacheController.getMdxLists(ForceFetch, function (lists) {
    MdxService.mMdxLists = lists;
    MdxService.mMdxCaches = CacheController.getMdxCaches();
    MdxService.mMdxOrderDicts = CacheController.getMdxOrderDicts();

    //优化MdxOrderDicts
    //1.force asign default mOderDict
    let default_order = {
      "it":[
        {"file_name":"109907.mdx", "dizio_name":"意汉汉意词典","for-index":true, "for-mainpart":true},
      ],
      "fr":[
        {"file_name":"法汉汉法词典2007.mdx","dizio_name":"法汉汉法词典", "for-index":true, "for-mainpart":true}
      ],
      "en":[
        {"file_name":"5Million.mdx", "dizio_name":"英汉汉英简明词典", "for-index":true, "for-mainpart":true}
      ]
    };
    $.each(MdxService.mMdxOrderDicts, function (lan, order_items) {
      var default_order_items = default_order[lan];
      $.each(order_items, function (i, order_item) {
        if (
          default_order_items.filter(function (d_order_item) {
            return d_order_item['file_name'] == order_item['file_name'];
          }).length <= 0
        ){
          default_order_items.push(order_item);
        }
      });
      default_order[lan] = default_order_items;
    });
    MdxService.mMdxOrderDicts = default_order;

    //2.attach mdx_lists + mdx_caches to mOrderDict
    $.each(MdxService.mMdxLists.concat(MdxService.mMdxCaches), function (i, mdx_list) {
      let language = mdx_list['preferred_language'];
      let dizio_name = mdx_list['dizio_name'];
      let file_name = mdx_list['file_name'];
      let file_link= mdx_list['file_link'];

      var has_match = false;
      $.each(MdxService.mMdxOrderDicts, function (lan, lan_order_items) {
        $.each(lan_order_items, function (ii, order_item) {
          if (order_item['file_name'] == file_name){
            order_item['dizio_name'] = dizio_name;
            order_item['file_link'] = file_link;
            order_item['downloaded'] = false;
            has_match = true;
          }
        });
      })

      if (!has_match){
        let lan_order_items = MdxService.mMdxOrderDicts[language];
        if (lan_order_items == null){
          lan_order_items = [];
        }
        lan_order_items.push({
          file_name:file_name,
          dizio_name: dizio_name,
          file_link: file_link,
          downloaded: false,
          "for-index":false,
          "for-mainpart":true,
        })
        MdxService.mMdxOrderDicts[language] = lan_order_items;
      }
    });

    //3.map dizio_name | downloaded | file_link to mOrderDict from Cache
    $.each(MdxService.mMdxOrderDicts, function (lan, order_items) {
      $.each(order_items, function (i, order_item) {
        let match_caches = MdxService.mMdxCaches.filter(function (cache) {
          return cache['file_name'] == order_item['file_name'];
        });

        if (match_caches.length > 0){
          order_item['dizio_name'] = match_caches[0]['dizio_name'];
          order_item['file_link'] = match_caches[0]['file_link'];
          order_item['downloaded'] = match_caches.length > 0;
        }
      });
    });

    //console.log("🐞mdx_caches:",MdxService.mMdxCaches);
    //console.log("🐞mdx_lists:",MdxService.mMdxLists);
    //console.log("🐞mdx_orders:",MdxService.mMdxOrderDicts);

    OnFinish();
  });
}
function tryAutoDownloadFilesByLanguage(OnFinish){
  let language = Settings.getLanguage();
  let lists = MdxService.mMdxLists.filter(function (list) {
    if (language == 'all'){
      return true;
    }
    return list['preferred_language'] == language;
  });

  let main_dizios = ["109907.mdx", "法汉汉法词典2007.mdx", "5Million.mdx"];
  let main_lists = lists.filter((list)=>{return main_dizios.includes(list.file_name)});

  //console.log("🐞MdxService.mMdxLists:",MdxService.mMdxLists);
  //console.log("🐞download lists:",lists,language);

  return Promise.each(main_lists, function (list) {
    let filepath = list['file_link'];
    return Tools.promiseAjax(filepath).then(function () {
      MdxService.CacheController.addMdxCache(list);
      //console.log("🐞finish download:",list);
    });
  }).then(function () {
    if (OnFinish != null){
      OnFinish();
    }
  });
}

//🍋 Caches + MdxObjects
function tryAutoLoadFromCaches() {
  let totalOptions = [];
  for (let lan of Object.keys(MdxService.mMdxOrderDicts)){
    totalOptions = totalOptions.concat(MdxService.mMdxOrderDicts[lan]);
  }
  let options = MdxService.mLanguage == 'all'
    ? totalOptions
    : MdxService.mMdxOrderDicts[MdxService.mLanguage];
  var filepaths = []
  $.each(options,function (i, option) {
    let match_caches = MdxService.mMdxCaches.filter(function (cache) {
      return cache['file_link'] == option['file_link'];
    });

    if (
      match_caches.length > 0
    ){
      let for_index = option['for-index'];
      let for_mainpart = option['for-mainpart'];
      if (for_index == true || for_mainpart == true){
        let match_lists = MdxService.mMdxLists.filter(function (list) {
          return list['file_name'] == option['file_name'];
        });

        if (match_lists.length > 0){
          let valid_path = match_lists[0]['file_link'];
          filepaths.push(valid_path);
        }else{
          filepaths.push(match_caches[0]['file_link']);
        }
      }
    }
  });
  let params = {language:MdxService.mLanguage, filepaths: filepaths, options: options};
  window.mdxMakeObject(params);
  //console.log("🐞mdxMakeObject",params)
}

//🍋 CallDevice
let MdxParserEvents = {
  mdxDocumentReadyCallBack : "mdxDocumentReadyCallBack",
  mdxMakeObjectCallBack : "mdxMakeObjectCallBack",

  mdxSearchNormalCallBack : "mdxSearchNormalCallBack",
  mdxSearchRootCallBack : "mdxSearchRootCallBack",
  mdxSearchAllCallBack : "mdxSearchAllCallBack",

  mdxFetchMainpartCallBack : "mdxFetchMainpartCallBack",
  mdxFetchWordShellCallBack : "mdxFetchWordShellCallBack"
};

function onCallDevice(Action, Object){

  //console.log("🐞mdx:",Action, Object);

  if (Action == MdxParserEvents.mdxMakeObjectCallBack){

    MdxService.isReady = true;

    //call deletegate
    if (MdxService.MdxServiceDelegate.OnMakeObject !== undefined){
      MdxService.MdxServiceDelegate.OnMakeObject();
    }
  }

  if (
    Action == MdxParserEvents.mdxSearchNormalCallBack ||
    Action == MdxParserEvents.mdxSearchRootCallBack ||
    Action == MdxParserEvents.mdxSearchAllCallBack
  ){
    //call deletegate
    if (MdxService.MdxServiceDelegate.OnSearchResult !== undefined){
      MdxService.MdxServiceDelegate.OnSearchResult(Object);
    }
  }

  if (Action == MdxParserEvents.mdxFetchMainpartCallBack){
    //call deletegate
    if (MdxService.MdxServiceDelegate.OnFetchMainpart !== undefined){
      MdxService.MdxServiceDelegate.OnFetchMainpart(Object);
    }
  }
}

const MdxService = {
  mLanguage:null,                 //for mdx language
  mMdxLists:[],                 //for download file
  mMdxCaches:[],                //for downloaded files
  mMdxOrderDicts:[],            //for manage files
  isReady:false,                  //for Utils.ready state

  init:init,
  validateDictionarys:validateDictionarys,
  refetchOrderDict:refetchOrderDict,
  tryAutoLoadFromCaches:tryAutoLoadFromCaches,
  tryAutoDownloadFilesByLanguage:tryAutoDownloadFilesByLanguage,

  onCallDevice:onCallDevice,      //for Mdict.Tools

  CacheController:CacheController,
  MdxServiceDelegate:{            //MdxService Events => for MdxService.context
    OnMakeObject    : undefined,     //  - OnMakeObject()
    OnSearchResult  : undefined,     //  - OnSearchResult(Object)
    OnFetchMainpart : undefined      //  - OnFetchMainpart(Object)
  }
};

window.top.Mdict = Mdict;

export default MdxService;