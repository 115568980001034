import {useEffect} from 'react';
import $ from "jquery";
import {IonContent, IonPage} from '@ionic/react';
import {IframeNavPresentPage} from '../../tools/uikit/IframeNav'
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react/dist/index";
import {ellipsisHorizontal, ellipsisVertical, heart} from "ionicons/icons/index";
import StudyDataController from '../../tools/StudyDataController.js'
import Alert from "../../tools/Alert";
import {LimitFunction, Settings} from "../../tools/Settings";
import WordsTableView from "./WordsTableView";
import './WordsViewController.css';
import NavBar from "../../components/NavBar";
import {setClicking, presentMenuList} from "../elements/MenuList";
import NativeTools from "../../tools/NativeTools";
//🍉instance
var instance = {
  mIndexPath:null,
  mList:null,
  mWords:[],
  //mWordsTableView:WordsTableView(),
  showUP : function (Section, Row) {
    instance.mIndexPath = {};
    instance.mIndexPath.section = Section;
    instance.mIndexPath.row = Row;
    instance.mList = StudyDataController.getListBy(Section, Row);
    instance.mWords = StudyDataController.getWordsBy(Section, Row);

    if (instance.validWords()){
      IframeNavPresentPage(WordsViewController, "WordsViewController", false);
    }
  },
  showWords : function (list, words) {
    instance.mIndexPath = {section:0, row:0};
    for(let index in StudyDataController.studyLists){
      if (StudyDataController.studyLists[index].unico_id == list.unico_id){
        instance.mIndexPath = {section:0, row:index};
      }
    }
    for(let index in StudyDataController.studyBookChapters){
      if (StudyDataController.studyBookChapters[index].unico_id == list.unico_id){
        instance.mIndexPath = {section:1, row:index};
      }
    }

    instance.mList = list;
    instance.mWords = words;

    if (instance.validWords()){
      IframeNavPresentPage(WordsViewController, "WordsViewController", false);
    }
  },
  validWords : function () {
    //console.log("🐞instance:",instance);
    //console.log("🐞instance.mWords:",instance.mWords);

    //.
    if (instance.mWords.length > 500){
      Alert.showConfirmAlert("不建议直接打开单词书","建议打开单词书章节。因为当前版本存在性能问题, 单词数量过多会造成设备会卡死。这个问题会在下一次更新解决。",false, "我还是想试试", function () {
        Alert.showWarningAlert("不行!",null,"好吧 😖");
      },"好的");
      return false;
    }

    //.
    if (instance.mWords.length <= 0){
      Alert.showWarningAlert("这个单词本是空的哦","您可以进入其它单词本整理您的单词","好的");
      return false;
    }

    //.
    return true;
  }
};
Object(window).top.WordsViewController = instance;

function reloadPageData(refetch=false) {
  if (mWordsTableViewRef){
    if (refetch){
      mWordsTableViewRef.refetchWordsThenReloadTableView(false);
    }else{
      mWordsTableViewRef.reloadTableView(instance.mIndexPath, instance.mList, instance.mWords);
    }
  }

  let isPrimary = instance.mIndexPath && Settings.getPrimaryListIndexPath() &&
    Settings.getPrimaryListIndexPath().section == instance.mIndexPath.section &&
    Settings.getPrimaryListIndexPath().row == instance.mIndexPath.row;
  //console.log("🐞isPrimary:",isPrimary,Settings.getPrimaryListIndexPath());
  if (isPrimary){
    $("#WordsViewController ion-title ion-icon").removeClass('ion-hide');
  }else{
    $("#WordsViewController ion-title ion-icon").addClass('ion-hide');
  }
}
Object(window).top.WordsViewController.reloadPageData = reloadPageData;

//🍉LifeCycle
var hasAppearedOnece = false;
window.top.WordsViewController.viewDidAppear = function () {
  if (hasAppearedOnece){
      reloadPageData(true);
  }
  hasAppearedOnece = true;
}


//🍉init
var mWordsTableViewRef, setWordsTableViewRef = function(tableview){
  mWordsTableViewRef = tableview;
};
const WordsViewController = () => {

  useEffect(() => {
    //console.log("🐞WordsViewController ready:", instance.mWords);
    reloadPageData();
  });

  return (
    <IonPage id="WordsViewController">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/" onClick={(e)=>{
              //for IonNav
              //为 IonBackButton 添加一个 onClick 事件, 可以防止 IonBackButton 默认的 Router 导航 (e.preventDefault 可选)
              e.preventDefault();
            }}/>
          </IonButtons>
          <IonTitle>
            <span>
            {instance.mList.parent_folder
            ? instance.mList.parent_folder.list_name +" "+ instance.mList.list_name
            : instance.mList.list_name}
            </span>

            <IonIcon icon={heart} className="ion-hide"/>

            </IonTitle>
          <IonButtons slot="end">
            <IonButton className="menu-button" onClick={()=>{
              setClicking(instance.mList, instance.mWords, instance.mIndexPath);
              presentMenuList(true);
            }}>
              <IonIcon className="color-dark" slot="icon-only" icon={ellipsisHorizontal}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/*<IonHeader>*/}
        {/*<IonToolbar>*/}
          {/*<IonButtons slot="start">*/}
            {/*<IonBackButton text="返回" defaultHref="/" />*/}
          {/*</IonButtons>*/}
          {/*<IonTitle></IonTitle>*/}
          {/*<IonButtons slot="end">*/}
            {/*<IonButton className="menu-button">*/}
              {/*<IonIcon className="color-dark" slot="icon-only" icon={ellipsisHorizontal}/>*/}
            {/*</IonButton>*/}
          {/*</IonButtons>*/}
        {/*</IonToolbar>*/}
      {/*</IonHeader>*/}

      <IonContent className="WordsViewContent">

        <div className="section section-tableview">
          <WordsTableView onRef={setWordsTableViewRef}/>
        </div>

        {/*<div id="words-wrapper" className="col-12">*/}

        {/*</div>*/}

      </IonContent>

    </IonPage>
  );
};

export default WordsViewController;