import React from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
/* Tools */
import {IframeWrapper, IframeWrapperDelegate} from "../../tools/uikit/IframeWrapper.jsx";
import {IframeNavPresentPage, IframeNavPresent, IframeNavBack} from "../../tools/uikit/IframeNav";
import Keys from '../../tools/Keys.js';

import {ellipsisHorizontal, shareOutline} from "ionicons/icons";
import StudyDataController from "../../tools/StudyDataController";
import Alert from "../../tools/Alert";
import NativeTools from "../../tools/NativeTools";


//PracticeCards
//===========================================

//.🍉shared vars
export const mPronounceViewController = {
  
  showUP : function () {
    IframeNavPresentPage(PronounceViewController, "PronounceViewController", true);
  }
}

//🍉.delagate
const delegate = IframeWrapperDelegate(
  // DidFinishLoad,
  function (){

  },

  // DidScroll,
  function (){

  },

  // DidAppear,
  function (){
    //console.log("🐞Pronounce.didAppear!");
  },

  // DidDismiss,
  function (){

  },

  // DidReceiveCallDevice
  function (CallBackName, Objects){
    //console.log("🐞Pronounce.Call:", CallBackName, Objects);
    if (CallBackName == "SPEAK"){
      NativeTools.speak(
        Objects.text,
        Objects.language
      );
    }
  }
);

//.🍉view
const PronounceViewController = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="返回" defaultHref="/" onClick={(e)=>{e.preventDefault();}}/>
          </IonButtons>
          <IonTitle>学习法语发音</IonTitle>
          <IonButtons slot="end">
            <IonButton className="share-button" onClick={()=>{
              NativeTools.share("🇫🇷学习法语发音",Keys.Url.page_pronounce_fr);
            }}>
              <IonIcon className="color-dark" slot="icon-only" icon={shareOutline}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div id="gesture-handlr"></div>
        <IframeWrapper src={Keys.Url.page_pronounce_fr} delegate={delegate}></IframeWrapper>
      </IonContent>
    </IonPage>
  );
};
export default PronounceViewController;
