/**
 * AppState
 * Provide global state for app pages and elements
 * ref: https://sanonz.github.io/2020/state-management-and-performance-optimization-for-react-context-api-with-hooks/
 *      https://stackblitz.com/edit/ionic-react-demo?file=components%2FTrackPlayer.jsx
 */
import React, { createContext, useReducer, useContext } from 'react';

const initialState = {
  login_page_state : false,
  pay_page_state : false,
};

/**
 * @Prepare App Context
 */
const AppStateContext = createContext();
const AppStateDispatchContext = createContext();

function useAppState() {
  return useContext(AppStateContext);
}
function useAppStateDispatch() {
  return useContext(AppStateDispatchContext);
}

/**
 * @Implement App Context Provider
 */
function reducer(state, action) {
  switch (action.type) {
    case 'set_login_page_state_open':
      return {login_page_state:true};
    case 'set_login_page_state_close':
      return {login_page_state:false};
    case 'set_pay_page_state_open':
      return {pay_page_state:true};
    case 'set_pay_page_state_close':
      return {pay_page_state:false};
    default:
      throw new Error();
  }
}
function AppStateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppStateContext.Provider value={state}>
      <AppStateDispatchContext.Provider value={dispatch}>
        {children}
      </AppStateDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

/**
 * @Export
 */
export { AppStateProvider, useAppState, useAppStateDispatch, AppStateContext, AppStateDispatchContext };

export function openLoginPage(dispatch){
  dispatch({type:'set_login_page_state_open'});
}
export function closeLoginPage(dispatch){
  dispatch({type: 'set_login_page_state_close'});
}

export function openPayPage(dispatch){
  dispatch({type:'set_pay_page_state_open'});
}
export function closePayPage(dispatch){
  dispatch({type: 'set_pay_page_state_close'});
}




