import React, {useState} from 'react';
import {IonActionSheet} from "@ionic/react";
import {trash, close, logOut} from "ionicons/icons";
import {openLoginPage, openPayPage, useAppStateDispatch} from '../../AppState';
import {logout} from '../LoginPage.jsx';
import {Settings, UpdateController, LimitFunction} from '../../tools/Settings.js';

export var presentMenuApp:any;

export const MenuApp:React.FC = () => {
  const dispatch = useAppStateDispatch();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [actions, setActions] = useState([]);

  //@export:
  presentMenuApp = setShowActionSheet;
  (window as any).top.presentMenuApp = presentMenuApp;
  (window as any).top.presentLoginPage = () => {openLoginPage(dispatch)};
  (window as any).top.presentPayPage = () => {openPayPage(dispatch)};

  return (
    <IonActionSheet
      isOpen={showActionSheet}
      onDidDismiss={() => setShowActionSheet(false)}
      header={Settings.AppDisplayName+" "+Settings.AppVersion}
      // subHeader={
      //   Settings.getIsCurrentVersionPublishedToAppStore() && !LimitFunction.isPaid(null)
      //     ? "试用剩余"+ (5-Object(Settings.getTryingDays()).length) +"天"
      //     : ""
      // }
      onWillPresent={() => {
        let user_email = Settings.getUserEmail();

        var options = [
          //.用户
          user_email ? {
            text: "退出 "+user_email,
            role: 'destructive',
            //icon: logOut,
            handler: () => {
              logout();
            }
          }:{
            text: '登录方格账号',
            handler: () => {
              openLoginPage(dispatch)
            }
          },

          //.清除学习记录
          // {
          //   text: '清除学习记录',
          //   handler: () => {
          //     setTimeout(()=>{
          //       (window as any).top.clearMemoRecordsThenReload();
          //     },1000);
          //   }
          // },

          //.检查更新
          {
            text: '检查更新',
            handler: () => {
              //setTimeout(()=>{
              UpdateController.checkUpdate(true);
              //},1000);
            }
          },

          //.设置语言
          // {
          //   text: '设置语言',
          //   handler: () => {
          //     (window as any).top.showLanguageAlert();
          //   }
          // },

          //.关于
          // {
          //   text: '关于',
          //   handler: () => {
          //     //console.log("🐞关于');
          //   }
          // },

          //.取消
          {
            text: '取消',
            role: 'cancel',
            handler: () => {}
          }
        ];

        //.激活和购买
        if(Settings.getIsCurrentVersionPublishedToAppStore()){
          options.push({
            text: LimitFunction.isPaid(null) ? '已激活':'激活|恢复',
            handler: () => {

              //🔐禁止未登录
              if(LimitFunction.isLogin("default") != true){
                return;
              }

              openPayPage(dispatch)
            }
          })
        }

        setActions(options as any);
      }}
      cssClass='app-menu'
      buttons={actions}
    ></IonActionSheet>
  )
}
