/* eslint-disable */
/* 👆 this is required for disabling eslint for amd. */
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import FileUploader from './FileUploader.js';
import "./TTSPreloader.css";
import * as Tools from "./Tools";
import TimeController from "./TimeController";

//todo: 不支持下载
//todo: 看看会不会影响web打包
//import { NativeAudio } from '@ionic-native/native-audio';


var $;
var mContext,
    mAudiosWrapper;

export function TTSPreloader(){
  this.init = () => {
    //console.log("🐞TTSPreloader init!");

    FileUploader.init();

    $ = window.$;

    $('.TTSPreloaderContext').remove();

    mContext = $(`
      <div class="TTSPreloaderContext hidden">
        <h2>🍉 发音引擎</h2>
        <div id="audios-wrapper" style="display: none;"></div>
  
        <div>已加载发音</div>
        <div id="words-wrapper"></div>
      </div>`);
    mAudiosWrapper = mContext.find("#audios-wrapper");

    //@Depracated!
    //$(document.body).append(mContext);
  }
  this.init();

/**
 * 🍉Preloader.Main
 SpeakWord => LocalCacheRecord => Audio
 => ServerCachedRecord => LocalCacheRecord => Audio
 => Stream --> Audio
 ------------> SaveLocalCacheRecord
 ------------> UploadStream - SaveServerCachedRecord
 */

var mCheckOnly = false,
    mUploadOnly = false,
    mHasInterface = false;
const setPreloaderPreferences = ({CheckOnly, UploadOnly, HasInterFace}) => {
  mCheckOnly = CheckOnly;
  mUploadOnly = UploadOnly;
  mHasInterface = HasInterFace;
}


//🍋Models
const SpeakWord = function (word, language, classname /* 'word'|'mean' */, row, status='waiting') {
  return {
    word: word,
    language: language,
    class:classname,
    row:row,
    status:status,
  }
}
const LoadedAudio = function (word, language, type, content, speakword) {
  return {
    word:word,
    language:language,
    type:'player',
    content: content,
    speakword:speakword,
  }
};

//🍋Speak
  const mStreamPlayer = new SpeechSDK.BaseAudioPlayer();
  function speak(speakword, onfinish = ()=>{}, Rate=0.5){
    let audio = getLoadedAudioBy(speakword);
    //console.log("🐞speak audio:",speakword,'=>',audio);

    if (audio != null){
      let audiocontent = audio.content;

      if (audio.type == "stream"){
        mStreamPlayer.playAudio(audiocontent.slice(0)).then((result)=>{
          //console.log("🐞stream play success:",result);
          onfinish(true);
        });

        // @NotWork: playAudio 根本不支持回调
        // mStreamPlayer.playAudio(audiocontent.slice(0), result => {
        //   //console.log("🐞stream play success:",result);
        //   onfinish(true);
        // }, error => {
        //   //console.log("🐞stream play error:",error);
        //   onfinish(false);
        // });
      }

      if (audio.type == "player"){

        Rate = Rate*2;
        audiocontent.playbackRate=Rate;

        //@搞清楚 audio 事件
        //加载事件: .on(loadedmetadata) | .on(error)
        //播放事件: .on(ended)          | .catch(error)

        $(audiocontent).off('ended');
        $(audiocontent).on('ended', function(){
          //console.log("🐞audio play success");
          onfinish(true);
        });

        audiocontent.play().catch(error => {
          console.warn("🐞audio play error:",audio,error);
          onfinish(false);
        })
      }

      if (audio.type == "player-native"){
        audiocontent.playNative(
          //onSuccess
          function () {
            onfinish(true);
          },
          //onError
          function (e) {
            console.warn("🐞audio-native play error:",e);
            onfinish(false);
          }
        )
      }

    }else{
      onfinish(false);
    }
  }

//🍋Preload
  var
    mWordsToLoad = [],
    mWordsLoadedNum = 0,

    mWordOnLoad = null,
    mWordsOnPrepared = null,
    mWordsOnFinishLoad = null;

  async function preloadSpeakWords(SpeakWords, OnLoad, OnPrepared, OnFinish){

    if (SpeakWords.length <= 0){
      OnPrepared([]);
      OnFinish([]);
      return;
    }

    //count words to load
    let total_seapkwords = SpeakWords;
    let total = total_seapkwords.length;

    mWordsToLoad = SpeakWords.filter((SpeakWord)=>{
      let match_audios = mLoadedAudios.filter((loadedAudio)=>{
        return loadedAudio.speakword.word == SpeakWord.word && loadedAudio.speakword.language == SpeakWord.language;
      });
      return match_audios.length <= 0;
    });

    mWordsLoadedNum = total - mWordsToLoad.length;
    mWordOnLoad = OnLoad;
    mWordsOnPrepared = OnPrepared;
    mWordsOnFinishLoad = OnFinish;

    console.info("🐞mLoadedAudios:",mLoadedAudios.length);
    console.info("🐞mWordsLoadedNum:",mWordsLoadedNum);
    console.info("🐞mSpeakWordsToLoad:",SpeakWords.length,'=>',mWordsToLoad.length);

    //words all loaded
    if (mWordsToLoad.length <= 0){
      OnPrepared(total_seapkwords);
      OnFinish(total_seapkwords);
      return;
    }

    //start preload words
    startPreloadSpeakWords(mWordsToLoad);
  }

  // @Depracated!
  // function makeSpeakWordsFromStrings(WordStrings, VoiceLanguage) {
  //   var speakwords = [];
  //   for (let wordstring of WordStrings){
  //     if (hasChinese(wordstring)){
  //       speakwords.push({word: wordstring, language: "zh"})
  //     }else{
  //       speakwords.push({word: wordstring, language: VoiceLanguage})
  //     }
  //   }
  //   return speakwords;
  // }

  async function startPreloadSpeakWords(SpeakWords /*[{word:string, language:string}]*/) {
    console.info("🐞startPreloadSpeakWords:",SpeakWords.length);

    //.from local cache
    refetchCachedRecords();
    //console.log("🐞mCacheRecords:",mCacheRecords);

    var non_cached_speakwords = [];
    for (let speakword of SpeakWords){
      let record = getCacheRecordBy(speakword.word, speakword.language);
      if (record != null){
        preloadAudioFromRecord(speakword, record);
      }else{
        non_cached_speakwords.push(speakword);
      }
    }
    console.info("🐞+ local records:", SpeakWords.length - non_cached_speakwords.length,"/",SpeakWords.length);

    console.info("🐞- server requests: ",non_cached_speakwords.length);

    //.from server cache
    if (non_cached_speakwords.length > 0){
      $.ajax({
        type: "POST",
        url:window.mRecordsFetchLink,
        data: JSON.stringify(non_cached_speakwords),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (records) {
          console.info("🐞+ server records: ", records.length,"/",SpeakWords.length);
          //save local cached records
          for (let record of records){ putCachedRecord(record, false); }
          saveCachedRecords();

          //make audio
          var non_server_speakwords = [];
          for (let speakword of non_cached_speakwords){
            let record = getCacheRecordBy(speakword.word, speakword.language);
            if (record){
              preloadAudioFromRecord(speakword, record);
            }else{
              non_server_speakwords.push(speakword);
            }
          }

          //download audio stream
          if (mCheckOnly == true){
            mWordsOnFinishLoad(mWordsLoadedNum);
          }else{
            if (non_server_speakwords.length > 0){
              console.info("🐞- download requests:",non_server_speakwords.length);
              downloadAudioStreams(non_server_speakwords);
            }
          }
        }
      });
    }
  }


//🍋MakeSpeakWords

  //todo: NewHome Change @preloadWordStrings call;
  var mSpeakWordsMade = {/* word: { lan: speakword } */};
  function makeSpeakWordsByWords(Words, WithMean) {
    var speakwords = [];

    $.each(Words, (index, word)=>{
      if (Tools.isEmpty(word.word_string) != true){
        const pure_text = hasChinese(word.word_string) ? Tools.pureChinese(word.word_string) : word.word_string;
        const pure_language = hasChinese(word.word_string) ? "zh":word.preferred_language;
        if (mSpeakWordsMade[pure_text] && (mSpeakWordsMade[pure_text])[pure_language]){
          speakwords.push((mSpeakWordsMade[pure_text])[pure_language]);
        }else{
          const new_speakword = SpeakWord(
            pure_text,
            pure_language,
            'word',
            index,
          )
          speakwords.push(new_speakword);
          mSpeakWordsMade[pure_text] = {pure_language: new_speakword};
        }
      }

      if (WithMean){
        if (Tools.isEmpty(word.word_key) != true){
          const pure_text = hasChinese(word.word_key) ? Tools.pureChinese(word.word_key) : word.word_key;
          const pure_language = hasChinese(word.word_key) ? "zh":word.preferred_language;
          if (mSpeakWordsMade[pure_text] && (mSpeakWordsMade[pure_text])[pure_language]){
            speakwords.push((mSpeakWordsMade[pure_text])[pure_language]);
          }else{
            const new_speakword = SpeakWord(
              pure_text,
              pure_language,
              'mean',
              index,
            )
            speakwords.push(new_speakword);
            mSpeakWordsMade[pure_text] = {pure_language: new_speakword};
          }
        }
      }
    });

    return speakwords;
  }


//🍋LocalCachedRecord
  var mCacheRecords = [/*{ word:string, language:string, audio:urlstring }*/];
  function refetchCachedRecords() {
    let getRecords = localStorage.getItem("PRELOADER-CACHED-RECORDS");
    if (getRecords != null){
      try{
        getRecords = JSON.parse(getRecords);
        if (getRecords != null){
          mCacheRecords = getRecords;
        }
      }catch (e) {
        //...
      }
    }
  }
  function saveCachedRecords() {
	  if (mUploadOnly != true && mCheckOnly != true){
	  	localStorage.setItem("PRELOADER-CACHED-RECORDS", JSON.stringify(mCacheRecords));	  
	  }
  }
  function getCacheRecordBy(word, language) {
    let match_records = mCacheRecords.filter((rec)=>{
      return rec.parent_word == word && rec.preferred_language == language;
    });
    return match_records.length > 0 ? match_records[0] : null;
  }

  function putCachedRecord(record, saving=true) {
    if (getCacheRecordBy(record.parent_word, record.language) == null){
      mCacheRecords.push(record);
    }
    if (saving){
      saveCachedRecords();
    }
  }
  function makeRecordBy(word, language, src) {
    return {
      parent_word:word,
      audio_file:src+"?"+TimeController.getTimeStringBySeconds(),
      preferred_language:language,
      platform:"microsoft",
      raw_info:null,
    }
  }

//🍋Stream & Uploads
  function downloadAudioStreams(speakwords){

    var uploadrecords = [];
    var index = 0;
    var threads = 0, maxThreads = 10;
    var total = 0;
    loop();

    function loop() {

      if (threads < maxThreads){
        //@download
        threads++;
        let speakword = speakwords[index];
        downloadAudioStream(speakword, function (stream) {
          threads--;
          total++;

          //console.log("🐞stream:",stream);
          if(stream != null){//操作 stream(ArrayBuffer) 需要使用克隆对象, 因为 stream 被播放后会被清零. 克隆方法: stream.slice(0)

            //todo:
            //@Depracated: 傻逼 stream 播放器, 回调垃圾. 之后添加 fast mode 直接在此播放!
            //.save audio (1)
            //putLoadedAudioBy(speakword, 'stream', stream.slice(0));

            //@upload
            let file = makeFileFromArrayBuffer(stream.slice(0), speakword.word);
            FileUploader.uploadAudioFile(
              file,
              speakword.language,
              function (process) {},
              function (result, path) {
                //console.log("🐞upload success:", speakword.word, uploadrecords.length);

                //console.log("🐞upload result: ",speakword.word, file, result, path);
                if (result && (result.code == 614 || result.key)){
                  let record = makeRecordBy(speakword.word, speakword.language, path);
                  //.save cache record (2)
                  putCachedRecord(record);
                  //.save server record (3)
                  uploadrecords.push(record);
                  //.put audio(4)
                  preloadAudioFromRecord(speakword, record);

                  //上传一组
                  if (uploadrecords.length == 10 || total == speakwords.length){
                    let records = [].concat(uploadrecords);
                    uploadrecords = [];
                    saveUploadRecords(records);
                  }
                }
              });
          }
        });
        index++;
      }else{
        //waiting for threads free.
      }

      setTimeout(()=>{
        //next
        if (index < speakwords.length){
          loop();
        }
      }, 200);
    };

    function saveUploadRecords(UploadRecords){
      if (UploadRecords.length > 0){
        //console.log("🐞save upload records:",uploadrecords);
        $.ajax({
          type: "POST",
          url:window.mRecordsSaveLink,
          data: JSON.stringify(UploadRecords),
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          success: function (data) {
            //console.log("🐞上传一组:",UploadRecords,data,(new Date()).toString());
          }
        });
      }
    }
  }
  function downloadAudioStream({word, language, row}, OnFinish){

    //console.log("🐞downloading:",row, word, language);
    const speechLanguageVoices = {
      "fr":{language:"fr-FR", voice:"fr-FR-DeniseNeural"},
      "zh":{language:"zh-CN", voice:"zh-CN-XiaoxiaoNeural"},
      "it":{language:"it-IT", voice:"it-IT-ElsaNeural"},
      "en":{language:"en-US", voice:"en-US-AriaNeural"},
    }
    if (!speechLanguageVoices[language]){
      console.warn("🐞没有匹配的语言:", language, row, word);
      OnFinish(null);
      return;
    }

    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
      "efe2f827437342c7aad3f0c7a74f9159",//3a28a21892fe4c48854e068b72531df3
      "eastus");
    speechConfig.speechSynthesisLanguage = speechLanguageVoices[language].language;
    speechConfig.speechSynthesisVoiceName = speechLanguageVoices[language].voice;

    //const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
    //const audioConfig = SpeechSDK.AudioConfig.fromAudioFileOutput("path.wav");

    //.stream
    // var stream = SpeechSDK.AudioOutputStream.createPullStream();
    // const audioConfig = SpeechSDK.AudioConfig.fromStreamOutput(stream);
    // const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
    //.speak
    //const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig);
    //.mute
    const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, null);/*, audioConfig */

    //console.log("🐞start request:",word);
    synthesizer.speakTextAsync(
      word,
      result => {
        if (result) {
          const { audioData } = result;
          OnFinish(audioData);

          if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
            //console.log("🐞","synthesis finished:",audioData,result);
          } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
            console.warn("🐞synthesis failed:", word, result.errorDetails)
          }
          synthesizer.close();
          return audioData;
        }
      },
      error => {
        console.warn("🐞stream download error:",error);
        OnFinish(null);
        synthesizer.close();
        return null;
      });
  }
  function makeFileFromArrayBuffer(ArrayBuffer, WordString){
    let file = new Blob([ArrayBuffer]);
    file.lastModifiedDate = new Date();
    file.name = WordString+".wav";
    return file;
  }

//🍋Audios
  var mLoadedAudios = [/*{ word:string, language:string, type:'stream'|'player', content:sream|player }*/]; window.mLoadedAudios = mLoadedAudios;
  async function preloadAudioFromRecord(speakword, record) {
    if (record == null){return};

    //console.log("🐞preload audio:", speakword);

    //@跳过预加载
    if (mUploadOnly){
      putLoadedAudioBy(speakword, null);
      return;
    }

    //@搞清楚 audio 事件
    //加载事件: .on(loadedmetadata) | .on(error)
    //播放事件: .on(ended)          | .catch(error)

    // todo: 不支持下载
    // const uniqueID = speakword.word;
    // NativeAudio.preloadSimple(uniqueID, record.audio_file).then(
    //   //onSuccess
    //   function () {
    //     //console.log("🐞audio-native load success:", speakword);
    //     const playerNative = {
    //       playNative:(onSuccess, onError)=>{
    //         NativeAudio.play(uniqueID).then(onSuccess, onError);
    //       }
    //     }
    //     putLoadedAudioBy(speakword, playerNative, 'player-native');
    //   },
    //   //onError
    //   function (e) {
    //     console.warn("🐞audio-native load fail:", record, e);
    //   });

    const audioSrc = encodeURI(record.audio_file); //encode for ios
    const audioDom = $('<audio class="" title="'+speakword.word+'" src="'+audioSrc+'"></audio>');

    //todo: hasInterface case
    //@Depracated: 去掉 controls 属性, 可以禁止浏览器渲染, 提升性能
    //const audioDom = $('<audio class="" title="'+speakword.word+'" src="'+record.audio_file+'" controls></audio>');
    //mAudiosWrapper.append(audioDom);

    //监听 audio 进度: wechat 无法监听 audio 加载进度
    if (Tools.isWechatBrowser()){
      setTimeout(()=>{
        audioDom.addClass('loaded');
        putLoadedAudioBy(speakword, audioDom[0]);
      },5000);
    }
    //监听 audio 进度: 加载成功 | 失败
    else{

      audioDom.on('loadedmetadata',function () {// loadedmetadata (canplay 有 ios safari bug)
        //console.log("🐞audio load success:",record);

        audioDom.off('loadedmetadata');
        audioDom.addClass('loaded');
        putLoadedAudioBy(speakword, audioDom[0]);
      });
      audioDom.on('error',function (e) {
        console.warn("🐞audio load error:",record.parent_word, audioSrc, e);

        switch (e.target.error.code) {
          case e.target.error.MEDIA_ERR_ABORTED:
            console.warn('🐞MEDIA_ERR_ABORTED.');
            break;
          case e.target.error.MEDIA_ERR_NETWORK:
            console.warn('🐞MEDIA_ERR_NETWORK.');
            break;
          case e.target.error.MEDIA_ERR_DECODE:
            console.warn('🐞MEDIA_ERR_DECODE.');
            break;
          case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
            console.warn('🐞MEDIA_ERR_SRC_NOT_SUPPORTED.');

            //todo: 暂时禁用
            //invalidRecordAudioThenRedownload(speakword, record);

            break;
          default:
            console.warn('🐞An unknown error occurred.');
            break;
        }
      });
    }
  }

  function getLoadedAudioBy(speakword){
    let match_audios = mLoadedAudios.filter((audio)=>{return audio.word == speakword.word && audio.language == speakword.language});

    //console.log("🐞find audio:",speakword,match_audios,mLoadedAudios);

    if (match_audios.length > 0){
      return match_audios[0];
    }
    return null;
  }

  async function putLoadedAudioBy(speakword, player, type='player'){
    //.
    let getAudio = getLoadedAudioBy(speakword);
    if (getAudio != null){
      mLoadedAudios.removeItem(getAudio);
    }

    //.
    let loadedAudio = LoadedAudio(
      speakword.word,
      speakword.language,
      type,
      player,
      speakword,
    )

    //.
    mLoadedAudios.push(loadedAudio);

    //@点击发音
    if (mHasInterface == true){
      //.
      if (getAudio == null){
        var wordItem = $("<div class='word-cell'></div>");
        wordItem.text(speakword.word+", "+speakword.language);
        $("#words-wrapper").append(wordItem);
        wordItem.click(function () {
          speak(speakword);
        });
      }
    }

    //@事件通知
    //.
    mWordsLoadedNum ++; //mWordsLoadedNum = mLoadedAudios.length;

    //console.log("🐞mWordsLoadedNum:",mWordsLoadedNum,speakword);

    //.
    mWordOnLoad( loadedAudio );

    //.
    if (mWordsToLoad.length < 5){
      if (mWordsLoadedNum == mWordsToLoad.length){
        mWordsOnPrepared(mWordsToLoad);
        mWordsOnFinishLoad(mWordsToLoad);
        console.info("🐞mWordsOnFinishLoad:",mWordsToLoad.length);
      }
    }else{
      if (mWordsLoadedNum == 5){
        mWordsOnPrepared(mWordsToLoad);
      }
      if (mWordsLoadedNum == mWordsToLoad.length){
        mWordsOnFinishLoad(mWordsToLoad);
        console.info("🐞mWordsOnFinishLoad:",mWordsToLoad.length);
      }
    }
  }

//🍋Invalid
  function invalidRecordAudioThenRedownload(speakword, record) {
    //remove local record
    mCacheRecords.removeItem(record);
    saveCachedRecords();

    //redownload
    downloadAudioStreams([speakword]);
  }


//🍉UI
  function showPreloaderContext() {
    $(document.body).append(mContext);
    mContext.removeClass('hidden');
    mContext.addClass('visible');
  }

//🍉Helper

  //.hasChinese
  const hasChinese =  function (Text) {

    let reg = new RegExp(/(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])/,'gu');
    return reg.test(Text);

    // @Depracated: 会将意大利语特殊字符识别为中文
    // let reg = new RegExp(/[^\x00-\xff]+/g);
    // return reg.test(Text);

    // ref:
    // ----------------------------------------
    // 提到 sc=Han
    // https://juejin.cn/post/6844904116842430471
    // 提到 sc=Han 兼容问题
    // https://zhuanlan.zhihu.com/p/33335629
    // Regex 和 Unicode 文档
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Cheatsheet
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Unicode_Property_Escapes
    // https://zh.javascript.info/regexp-unicode

    // 存在兼容性问题:
    // let reg = new RegExp(/\p{sc=Han}/gu);

    // multi language:
    // function isNonLatinCharacters(s) {
    //   return /[^\u0000-\u007F]/.test(s);
    //   //console.log(isNonLatinCharacters("身分"));// Japanese
    //   //console.log(isNonLatinCharacters("测试"));// Chinese
    //   //console.log(isNonLatinCharacters("حمید"));// Persian
    //   //console.log(isNonLatinCharacters("테스트"));// Korean
    //   //console.log(isNonLatinCharacters("परीक्षण"));// Hindi
    //   //console.log(isNonLatinCharacters("מִבְחָן"));// Hebrew
    // }
  };


  //.removeItem()
  if (!Array.prototype.removeItem){
    Array.prototype.removeItem = function(item){
      const index = this.indexOf(item);
      if (index > -1) {
        this.splice(index, 1);
      }
      return this;
    };
  };

//🍉Exports
  return {
    //models
    SpeakWord:SpeakWord,

    //speak
    speak:speak,

    //preload
    setPreloaderPreferences: setPreloaderPreferences,
    makeSpeakWordsByWords: makeSpeakWordsByWords,
    preloadSpeakWords: preloadSpeakWords,

    //ui
    showPreloaderContext: showPreloaderContext,

    //helper
    hasChinese:hasChinese,
  }
}
window.TTSPreloader = TTSPreloader;
