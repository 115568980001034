/* eslint-disable */
/* 👆 this is required for disabling eslint for amd. */
import * as qiniu from './sdk/qiniu/qiniu.min';
import TimeController from "./TimeController";
var $;


const FileUploader = {
  init(){
    //console.log("🐞FileUploader init!");
    $  = window.$;
  },

  mToken:null,
  getToken(OnFinish){
    if (FileUploader.mToken != null){
      OnFinish(FileUploader.mToken);
      return;
    }

    $.ajax({
      url: window.mTokenLink,
      success: function(token){
        FileUploader.mToken = token;
        OnFinish(token);
      }
    });
  },

  uploadAudioFile(file, language, OnProcess /*(int)*/, OnFinish /*(result, path)*/) {
    let filename = file.name;
    if (filename.length > 25){
      filename = TimeController.getTimeStringBySeconds()+"_"+filename.substring(0, 10)+"___"+filename.substring(filename.length-10, filename.length);
    }
    let upload_file_path = "audio/microsoft/"+language+"/"+ FileUploader.escapeFileName(filename);
    FileUploader.uploadFileToCloud(
      file,
      upload_file_path,
      function(progress){OnProcess(progress);},
      function(result, path){OnFinish(result, path)});
  },

  uploadFileToCloud(file, path=null, OnProcess /*(int)*/, OnFinish/*(result, path)*/) {
    FileUploader.getToken((token)=>{

      let upload_file_path = path == null ? "files/"+TimeController.getTimeStringBySeconds()+"_"+file.name : path;
      var config = {
        useCdnDomain: true,
        disableStatisticsReport: false,
        retryCount: 6,
        region: "up-z0.qiniup.com",
        chunkSize:10,//mb
      };
      var putExtra = {
        fname: "",
        params: {},
        mimeType: null
      };
      //prepare
      var observable = qiniu.upload(file, upload_file_path, token, putExtra, config);
      let cloudPath = 'https://cloud.iofange.com/'+upload_file_path;
      //uploading
      var subscription = observable.subscribe({
        next: function (response) {//object => {total:{loaded, total, percent}...}
          let percent = response.total.percent.toFixed(0);
          //console.log("🐞已上传 "+percent+"%");
          OnProcess(percent);
        },
        error: function (response) {
          console.warn("🐞上传失败: "+response.message);
          OnProcess(-1);
          OnFinish(response, cloudPath);
        },
        complete: function (result) {
          //console.log("🐞上传完成");
          OnProcess(100);
          OnFinish(result, cloudPath);
        }
      });

    });
  },

  escapeFileName(FileName) {
    //special chars: "f?:i/le>  n%a|m\\e.ext"
    return FileName.replace(/[/\\?%*:|"<>\s]/g, '_');
  }
};
export default FileUploader;

