import $ from "jquery";
import * as Tools from "../../../tools/Tools";
import TimeController from "../../../tools/TimeController";
import * as Extension from "../../../tools/Extension.js";

const curves = [
  {
    "start":0,
    "end":1,
    countValueByDistance:function (CurrentDistance) {
      let i = CurrentDistance - 0;
      let value = 1/Math.pow(5,i)*(100-21)+21;
      return value;
    }
  },
  {
    "start":1,
    "end":2,
    countValueByDistance:function (CurrentDistance) {
      //let i = CurrentDistance - 1;
      let i = CurrentDistance - 0;
      let value = 1/Math.pow(5,i)*(100-25)+25;
      return value;
    }
  },
  {
    "start":2,
    "end":4,
    countValueByDistance:function (CurrentDistance) {
      //let i = CurrentDistance - 2;
      let i = CurrentDistance - 0;
      let value = 1/Math.pow(5,i/2)*(100-29)+29;
      return value;
    }
  },
  {
    "start":4,
    "end":7,
    countValueByDistance:function (CurrentDistance) {
      //let i = CurrentDistance - 4;
      let i = CurrentDistance - 0;
      let value = 1/Math.pow(5,i/3)*(100-34)+34;
      return value;
    }
  },
  {
    "start":7,
    "end":15,
    countValueByDistance:function (CurrentDistance) {
      //let i = CurrentDistance - 7;
      let i = CurrentDistance - 0;
      let value = 1/Math.pow(5,i/7.3)*(100-38)+38;
      return value;
    }
  },
  {
    "start":15,
    "end":30,
    countValueByDistance:function (CurrentDistance) {
      //let i = CurrentDistance - 15;
      let i = CurrentDistance - 0;
      let value = 1/Math.pow(5,i/13)*(100-40)+40;
      return value;
    }
  },
  {
    "start":30,
    "end":36500,
    countValueByDistance:function (CurrentDistance) {
      //let i = CurrentDistance - 30;
      let i = CurrentDistance - 0;
      let value = 1/Math.pow(5,i/30.3)*(100-55)+55;
      return value;
    }
  }
];

const MemoModel = function (
  StartTime,
  MemoType,
  MemoDistance,
  RepeatTimes,
  CurrentValue
){
  this.StartTime = StartTime
  this.MemoType = MemoType
  this.MemoDistance = MemoDistance
  this.RepeatTimes = RepeatTimes
  this.CurrentValue = CurrentValue
}

//🍋根据当前的点, 找到对应的遗忘曲线
function findCurveIndexBy(Distance){
  var index = 0;
  $.each(curves, function (curve_index, curve) {
    if (Distance >= curve["start"] && Distance < curve["end"]){
      index = curve_index;
    }
  });
  return index;
}

//🍋初始化遗忘曲线
function tryInitCurve(Word) {
  let start_time = TimeController.getTimeStringByMinites();
  return {
    word : Word.word_string,
    parent_list_unico_id : Word.parent_list_unico_id,
    start_time : start_time,
    points : [
      {
        time: start_time,
        distance : 0,
        value : 100,
        type : "memo"
      }
    ],
  };
}

//🍋对曲线进行遗忘处理
function curveAddForgetPoint(WordCurve) {

  //根据单词, 找到初始时间, 当前相对时间
  //然后找到最近记忆的 momo_point, 对应相对的遗忘曲线公式
  //根据遗忘曲线公式, 更新最近的 forget_point 或添加 forget_point
  if (Tools.isEmpty(WordCurve)){
    return WordCurve;
  }

  //找到最近的 momo_point 和 遗忘公式
  let curve_points = WordCurve;

  let start_time = curve_points['start_time'];
  let points = curve_points["points"];

  if (start_time === undefined || points === undefined){
    return WordCurve;
  }

  var last_memo_point;
  $.each(points, function (point_index, point) {
    if (point['type'] === "memo" || point['mark'] === "force"){
      last_memo_point = point;
    }
  });

  if (last_memo_point === undefined){
    return WordCurve;
  }else{
    //根据遗忘公式更新
    let current_time_string = TimeController.getTimeStringByMinites();
    let last_memo_point_time = last_memo_point['time'];
    let last_memo_point_distance = last_memo_point['distance'];
    if (last_memo_point_time === undefined || last_memo_point_distance === undefined){
      return WordCurve;
    }else{

      //直接根据当前时间, 和初始时间计算 distance
      //这种算法会忽略 返回复习的情况
      //let current_distance = TimeController.countDistanceFromTo(current_time_string, curve_points["start_time"]);

      let relative_distance = TimeController.countDistanceFromTo(current_time_string, last_memo_point_time);
      let current_distance = relative_distance+last_memo_point_distance;//根据与最后一个记忆点的相对位置, 计算 distance
      let curve = curves[findCurveIndexBy(last_memo_point_distance)];

      let curve_value_after_decrease = curve.countValueByDistance(relative_distance);
      let decrease = 100 - curve_value_after_decrease;
      let final_value = last_memo_point['value']-decrease;

      let minimal_value = curve.countValueByDistance(1000);
      if (final_value < minimal_value){
        final_value = minimal_value;
      }

      if (points[points.length-1]["type"] === "forget"){
        points.splice(points.length-1,1);
      }
      points.push({"time":current_time_string, "distance":current_distance, "value":final_value, "type":"forget"});

      curve_points["points"] = points;
      return curve_points;
    }
  }
}

//🍋添加记忆点
function curveAddMemoPoint(WordCurve) {

  //TODO: 根据iOS优化 curveAddMemoPoint()

  //🍓先添加遗忘点
  let curve_points = curveAddForgetPoint(WordCurve);

  //🍓再添加记忆点

  //找到最近的 point,
  //  如果没有超出安全区, 填充 memo_point by current x
  //  如果超出安全区, 填充 memo_point by x of next_curve_start_point of lastest_memo_point
  //      *如果超出安全区, 但是没有到达下一个曲线开始, 依然 填充 memo_point by current x
  var points = curve_points["points"];
  let last_point = points[points.length-1];
  let current_time_string = TimeController.getTimeStringByMinites();

  let last_memo_point;
  $.each(points, function (point_index, point) {
    if (point['type'] === "memo"){
      last_memo_point = point;
    }
  });
  var current_distance = TimeController.countDistanceFromTo(current_time_string, curve_points['start_time']);    //直接根据当前时间, 和初始时间计算 distance
  if (last_memo_point !== undefined){
    let last_memo_point_time = last_memo_point['time'];
    let last_memo_point_distance = last_memo_point['distance'];
    if (last_memo_point_time !== undefined && last_memo_point_distance !== undefined){
      current_distance = TimeController.countDistanceFromTo(current_time_string, last_memo_point_time)+last_memo_point_distance;     //根据与最后一个记忆点的相对位置, 计算 distance
    }
  }

  if (last_point['value'] >= 50){
    points.push({"time":current_time_string, "distance":current_distance, "value":100, "type":"memo"})
  }else{
    if (last_memo_point !== undefined){
      let next_curve_start = curves[findCurveIndexBy(last_memo_point["distance"]+1)]["start"];//
      if (next_curve_start<=current_distance){
        points.push({
          "time": current_time_string,
          "distance": next_curve_start,
          "value":100,
          "type":"memo",
          "mark":"rewind"
        });
      }else{
        points.push({
          "time": current_time_string,
          "distance": current_distance,
          "value":100,
          "type":"memo",
          "mark":"rewind"
        });
      }
    }
  }

  curve_points['points'] = points;

  return curve_points;
}

//🍋标记记忆点
function curveForceAddMemoPoint(WordCurve, MemoType) {
  if (MemoType == Curve.MemoType.memo_untracked){
    return null;
  }else{
    let new_point;
    let time = TimeController.getTimeStringByMinites();
    if (MemoType == Curve.MemoType.memo_start){
      new_point = {
        time:time,
        distance:0,
        value:100,
        type:"memo",
        mark:"force"
      }
    }
    if (MemoType == Curve.MemoType.memo_known){
      new_point = {
        time:time,
        distance:1.99,
        value:100,
        type:"memo",
        mark:"force"
      }
    }
    if (MemoType == Curve.MemoType.memo_familiar){
      new_point = {
        time:time,
        distance:30.99,
        value:100,
        type:"memo",
        mark:"force"
      }
    }
    if (MemoType == Curve.MemoType.memo_danger){
      new_point = {
        time:time,
        distance:3.99,
        value:49,
        type:"memo",
        mark:"force"
      }
    }
    WordCurve['points'].push(new_point);
    return WordCurve;
  }
}
// for word in extended_words{
//     //curve
//     if (MemoType == "memo_untracked"){
//         word.curve_points = nil
//     }else{
//         word.curve_points = Curve.curveForceAddMemoPoint(Word: word, MemoType: MemoType)
//     }
//
//     //memo
//     if let memo_value = Settings.mMemoValues[MemoType]{
//         word.memo = memo_value
//     }
//
//     word.is_fresh = true
// }

//🍋获取记忆标记
function curveFindMemo(WordCurve){
  var start = ""
  var memo_type = "memo_untracked"
  var memo_distance = 0
  var current_value = 0
  var repeat_times = 0

  if (WordCurve != null){

    let start_time = WordCurve["start_time"]
    let points = WordCurve["points"]

    if (!Tools.isEmpty(start_time) && !Tools.isEmpty(points)){
      let last_point = points.last()
      let last_point_distance = last_point["distance"]
      let last_point_value = last_point["value"]

      //+start_time
      start = start_time

      //+current value
      current_value = last_point_value

      let memo_points = points.filter(function (point) {
        return point['type'] == 'memo';
      })

      let last_memo_point = memo_points.last()
      let last_memo_point_time = last_memo_point["time"]
      let last_memo_point_distance = last_memo_point["distance"]

      //+memo distance
      let start_distance = TimeController.countDistanceFromTo(
        start_time,
        start_time.split(" ")[0]+" 00:00")
      memo_distance = (start_distance + last_memo_point_distance);

      //+repeat times
      //get repeat times by memo distance
      if (memo_distance < 1){
        repeat_times = 1
      }else if (memo_distance < 2){
        repeat_times = 2
      }else if (memo_distance < 4){
        repeat_times = 3
      }else if (memo_distance < 7){
        repeat_times = 4
      }else if (memo_distance < 15){
        repeat_times = 5
      }else if (memo_distance < 30){
        repeat_times = 6
      }else{
        repeat_times = 7
      }

      //+memo type
      if (memo_distance < 1){
        memo_type = "memo_known";
        //@Depracated!
        //memo_type = "memo_start"
      }else if (memo_distance < 7){
        memo_type = "memo_known";
      }else{
        memo_type = "memo_familiar";
      }

      //cast to danger
      if (current_value > 0 && current_value <= 50){
        memo_type = "memo_danger";
      }
    }
  }

  memo_distance = memo_distance.toFixed(2);

  let fine_memo = new MemoModel(
    start,          // StartTime,
    memo_type,      // MemoType,
    memo_distance,  // MemoDistance,
    repeat_times,   // RepeatTimes,
    current_value  // CurrentValue
  );

  return fine_memo
}

//🍋获取记忆行为
const MemoBehaviour = {
  repeat_memo:'repeat',
  start_memo:'start',
}
function curveFineMemoBehaviour(WordCurve) {
  let start_time = WordCurve["start_time"]
  let points = WordCurve["points"];

  let memo_points = points.filter(function (point) {
    return point["type"] == "memo";
  });
  if (memo_points.length > 0){
    let last_memo_point = memo_points[memo_points.length-1];
    let last_memo_point_time = last_memo_point["time"];
    let last_memo_point_distance = parseFloat(last_memo_point["distance"]);

    //今天有学习
    if (last_memo_point_time.includes(TimeController.getTimeStringByDay())){

      let start_distance = TimeController.countDistanceFromTo(
        start_time,
        start_time.split(" ")[0]+" 00:00")
      let distance = start_distance + last_memo_point_distance

      //复习
      if (distance > 1){
        return MemoBehaviour.repeat_memo
      }
      //学习
      else{
        return MemoBehaviour.start_memo
      }
    }
  }
  return null;
}

//🍋将数据按天, 分析分组
//@return {
//  all_days: [day_string],   每日日期
//  valid_words: [{...}],     每日对应的记忆点
//  valid_behaviours: [{time_string:"", repeat_memo:0, start_memo:0}]
//}
function makeGroupedWords(WordCurves, Days){

  //所有点
  let all_points = [];
  let all_valid_curves = [];

  $.each(WordCurves, function (index, word_curve) {

    //iOS已经模拟遗忘了
    let curve = word_curve;

    //@测试: Web再次遗忘
    //let curve = curveAddForgetPoint(word_curve);

    if (curve['start_time'] !== undefined){
      let start_distance = TimeController.countDistanceFromTo(curve['start_time'], curve['start_time'].split(" ")[0]+" 00:00");
      curve['start_distance'] = start_distance;
    }

    if (curve['points'] !== undefined){
      all_points = all_points.concat(curve['points']);
      all_valid_curves.push(curve);
    }
  });

  //console.log("🐞all_curves",WordCurves);
  //console.log("🐞all_points",all_points);

  var days_to_display = [];

  // 只显示一天
  if (Days <= 1){
    days_to_display = [TimeController.getTimeStringByMinites()];
  }
  //动态显示往日
  else{
    //所有时间标记
    var all_days = all_points.map(function (point) {
      return TimeController.decodeTimeStringOfMinites(point['time'].split(" ")[0]);
    }).sort(function(a,b){return a.getTime() - b.getTime()});

    //添加今天的时间标记
    let today_date = TimeController.getCurrentDate();
    if (all_days.includes(today_date) === false){
      all_days.push(today_date);
    }

    let first_day = all_days[0];
    let last_day = all_days[all_days.length-1];

    //按每一天平滑处理后的 所有时间标记
    let diff = TimeController.countDistanceFromDateToDate(last_day, first_day);
    for (var day=0; day<=diff; day++) {
      let this_day = TimeController.addHours(last_day, (-24*day));
      if (days_to_display.length<Days){
        days_to_display.push(TimeController.encodeDateToTimeStringByMinites(this_day));
      }
    }
  }

  //遍历每一天
  let grouped_words = [];
  let grouped_behaviours = [];
  $.each(days_to_display, function (day_index, this_day) {

    let this_day_string = this_day;

    let day_points = {
      "time_string":this_day_string,
      "points":[],
      "stack":{
        "total":0,
        "memo_danger":0,
        "memo_start":0,
        "memo_known":0,
        "memo_familiar":0,
      }
    };

    var day_behavours = {time_string:this_day_string, repeat_memo:0, start_memo:0};

    //遍历每一个单词曲线
    var max_points_distance = 0;
    $.each(all_valid_curves, function (curve_index, curve) {

      let word = curve['word'];
      let parent_list_unico_id = curve['parent_list_unico_id'];
      let points = curve["points"];
      let start_time = curve['start_time'];
      let start_distance = curve['start_distance'] === undefined ? 0 : curve['start_distance'];

      var find_point_first;
      var find_point_last;

      //@TODO
      //🍓计算复习次数
      // let repeat_times = points.filter(function (point) {
      //     let point_time = point['time'];
      //     let point_type = point['type'];
      //     return (point_type == 'memo' || point_type == 'rewind') && TimeController.countDistanceFromTo(this_day_string, point_time) > 0;
      // }).length;
      // //附加复习次数
      // find_point['repeat_times'] = repeat_times;

      //🍓计算位置
      //寻找曲线 在这一天 对应的点
      let all_point_times = points.map(function (point) {
        return point['time'];
      });
      let first_point_time = all_point_times[0];
      let last_point_time = all_point_times[all_point_times.length-1];

      //查找当前日期的点
      let find_points = points.filter(function (point) {
        let point_time = point['time'];
        return point_time.includes(this_day_string.split(" ")[0]);
        //=
        //this_day < point_time < this_day+1
        //return TimeController.countDistanceFromTo(point_time, this_day_string)>=0 &&
        //    TimeController.countDistanceFromTo(encodeDateToTimeStringByMinites(addHours(this_day,24)), point_time)>=0;
      });

      //1.直接在当前日期找到遗忘点
      //如果在当前日期一天内找到记忆点(遗忘点)
      if (find_points.length > 0){
        //取这一天里面的第一个点 (倾向遗忘点)
        find_point_first = find_points[find_points.length-1];//find_points[0];

        //取这一天里面的最后一个点 (倾向记忆点)
        find_point_last = find_points[find_points.length-1];

        //取记忆点获取学习数量
        let possible_memo_points = find_points.filter((point)=>{return point.type != "forget";});
        if (possible_memo_points.length > 0){
          if (possible_memo_points[possible_memo_points.length-1].distance > 1){
            day_behavours.repeat_memo ++;
          }else{
            day_behavours.start_memo ++;
          }
        }
      }
      //2.计算遗忘点
      //如果 this_day 在 单词记忆曲线开始之后, 寻找最近的 memo_point, 作为 anchor_point 计算遗忘公式
      else if (TimeController.countDistanceFromTo(this_day_string, first_point_time) >= 0){
        let memo_points_before_this_day = points.filter(function (point) {
          return (point['type'] === "memo" || point['mark'] === "force") && TimeController.countDistanceFromTo(this_day_string, point['time']) >= 0;
        });
        if (memo_points_before_this_day.length > 0){
          let anchor_point = memo_points_before_this_day[memo_points_before_this_day.length-1];
          let anchor_point_distance = anchor_point["distance"];

          //根据遗忘公式更新
          let relative_distance = TimeController.countDistanceFromTo(this_day_string, anchor_point['time']);

          let curve_index = findCurveIndexBy(anchor_point_distance);
          let curve = curves[curve_index];

          let curve_value_after_decrease = curve.countValueByDistance(relative_distance);
          let decrease = 100 - curve_value_after_decrease;
          let final_value = anchor_point['value']-decrease;

          let minimal_value = curve.countValueByDistance(1000);
          if (final_value < minimal_value){
            final_value = minimal_value;
          }

          let current_distance = relative_distance + anchor_point['distance'];

          find_point_last = {
            "time": this_day_string,
            "distance":current_distance,
            "value":final_value,
            "type":"forget",
            "anchor_distance":anchor_point_distance,
          };
          find_point_first = find_point_last;
        }
      }

      //add To Group
      if (find_point_last !== undefined){

        find_point_last['word'] = word;
        find_point_last['parent_list_unico_id'] = parent_list_unico_id;
        find_point_last['start'] = start_time;
        find_point_last['start_distance'] = start_distance;

        let current_distance = find_point_last['distance']+start_distance;

        let value = find_point_last['value'];
        if (value < 50){
          find_point_last['memo_type'] = 'memo_danger';
        }else{
          let memo_distance;
          if (find_point_last['anchor_distance'] == undefined){
            memo_distance = current_distance;
          }else{
            memo_distance  = find_point_last['anchor_distance'] + start_distance;
          }

          if (memo_distance < 1){
            find_point_last['memo_type'] = 'memo_start';
          }else if (memo_distance < 7){
            find_point_last['memo_type'] = 'memo_known';
          }else{
            find_point_last['memo_type'] = 'memo_familiar';
          }
        }

        let forget_value = find_point_first['value'];
        if (forget_value < 50){
          day_points['stack']['memo_danger'] += 1;
        }else{
          let forget_distance;
          if (find_point_first['anchor_distance'] == undefined){
            forget_distance = current_distance;
          }else{
            forget_distance  = find_point_first['anchor_distance'] + start_distance;
          }

          if (forget_distance < 1){
            day_points['stack']['memo_start'] += 1;
          }else if (forget_distance < 7){
            day_points['stack']['memo_known'] += 1;
          }else{
            day_points['stack']['memo_familiar'] += 1;
          }
        }

        //max_points_distance
        if (current_distance > max_points_distance){
          max_points_distance = current_distance;
        }

        //push
        day_points['stack']['total'] += 1;
        day_points['points'] = day_points['points'].concat([find_point_last]);
        day_points['max_points_distance'] = max_points_distance;
      }
    });
    grouped_words.push(day_points);
    grouped_behaviours.push(day_behavours);
  });

  return {
    "valid_behaviours":grouped_behaviours,
    "valid_words":grouped_words,
    "all_days":days_to_display
  };
}


//@Curve:
let Curve = {
  MemoType:{
    memo_untracked:'memo_untracked',
    memo_start:'memo_start',
    memo_known:'memo_known',
    memo_familiar:'memo_familiar',
    memo_danger:'memo_danger',
  },
  MemoValues:{
    "memo_untracked":-1,
    "memo_start":0,
    "memo_known":2,
    "memo_familiar":4,
    //@Depracated?
    "memo_danger":3,
  },
  MemoBehaviour:MemoBehaviour,

  tryInitCurve:tryInitCurve,
  curveAddForgetPoint : curveAddForgetPoint,
  curveAddMemoPoint : curveAddMemoPoint,
  curveForceAddMemoPoint : curveForceAddMemoPoint,
  curveFindMemo : curveFindMemo,
  curveFineMemoBehaviour:curveFineMemoBehaviour,
  makeGroupedWords:makeGroupedWords,
};
export default Curve;