import React, {useState} from 'react';
import {IonActionSheet} from "@ionic/react";
import {trash, close, logOut} from "ionicons/icons";
import {openLoginPage, openPayPage, useAppStateDispatch} from '../../AppState';
import {logout} from '../LoginPage.jsx';
import {Settings, UpdateController, LimitFunction} from '../../tools/Settings.js';
import StudyDataController from "../../tools/StudyDataController";
import {presentWordsPicker} from "../studyplan/WordsPicker/WordsPicker";

export var presentMenuAddList:any;

export const MenuAddList:React.FC = () => {
  const dispatch = useAppStateDispatch();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [actions, setActions] = useState([]);

  //@export:
  presentMenuAddList = setShowActionSheet;
  (window as any).top.presentMenuAddList = presentMenuAddList;

  return (
    <IonActionSheet
      isOpen={showActionSheet}
      onDidDismiss={() => setShowActionSheet(false)}
      onWillPresent={() => {

        var options = [

          //.从方格单词下载
          {
            text: '从方格单词下载',
            handler: () => {
              if ((window as any).top.mBookViewController){
                (window as any).top.mBookViewController.showUP("add-both");
              }
            }
          },

          //.新建空单词本
          {
            text: '新建空单词本',
            handler: () => {
              StudyDataController.showCreateListAlert(function (inserted_list:any) {
                if ((window as any).top.tryRegroupStudyDataThenReloadUI){
                    (window as any).top.tryRegroupStudyDataThenReloadUI();
                }
              })
            }
          },

          //.批量工具
          {
            text: '批量添加工具',
            handler: () => {
              //🔐禁止未登录
              if (LimitFunction.isLogin('default') != true){
                return;
              }

              presentWordsPicker();
            }
          },

          //.取消
          {
            text: '取消',
            role: 'cancel',
            handler: () => {}
          }
        ];

        setActions(options as any);
      }}
      cssClass='app-menu'
      buttons={actions}
    ></IonActionSheet>
  )
}
