import $ from "jquery";
import * as Tools from "./Tools";
import Keys from "./Keys";
import TimeController from "./TimeController";
import Curve from "../pages/studyplan/Curve/Curve";
import {LimitFunction, Settings} from "./Settings";
import Alert from "./Alert";


const StudyDataController = {

  //🍋 Models
  ListModel : {
    initFromDict:function  (Dict) {
      return this.initFromValues(
        Dict["unico_id"],
        Dict["list_name"],
        Dict["parent_user_email"],
        Dict["parent_cover_list_id"],
        Dict["being"],
        Dict["preferred_language"],
        Dict["is_book"],
        Dict["order_id"],
        Dict["created_at"],
        Dict["is_fresh"]
      )
    },
    initFromValues:function (
      unico_id,
      list_name,
      parent_user_email,
      parent_cover_list_id,
      being,
      preferred_language,
      is_book,
      order_id,
      created_at,
      is_fresh
    ){
      let list = {};
      list.unico_id = unico_id
      list.list_name = list_name
      list.parent_user_email = parent_user_email
      list.parent_cover_list_id = parent_cover_list_id
      list.being = being
      list.preferred_language = preferred_language
      list.is_book = is_book
      list.order_id = order_id
      list.created_at = created_at
      list.is_fresh = is_fresh
      return list;
    }
  },
  WordModel:{
    initFromValues:function (
      word_string,
      word_key,
      parent_list_unico_id,
      being,
      order_id,
      parent_user_email,
      preferred_language,
      curve_points,
      is_fresh
    ){
      let word = {};
      word.word_string = word_string
      word.word_key = word_key
      word.parent_list_unico_id = parent_list_unico_id
      word.being = being
      word.order_id = order_id
      word.parent_user_email = parent_user_email
      word.preferred_language = preferred_language
      word.curve_points = curve_points
      word.is_fresh = is_fresh
      return word;
    },

    initFromDict:function(Dict) {
      return this.initFromValues(
        Dict["word_string"],
        Dict["word_key"],
        Dict["parent_list_unico_id"],
        Dict["being"],
        Dict["order_id"],
        Dict["parent_user_email"],
        Dict["preferred_language"],
        Dict["curve_points"],
        Dict["is_fresh"]
      )
    },
  },
  AppuntoModel:{
    initFromValues:function (
      parent_user_email,
      parent_word,
      content,
      likes,
      is_fresh,
    ){
      let appunto = {};
      appunto.parent_user_email = parent_user_email;
      appunto.parent_word = parent_word;
      appunto.content = content;
      appunto.likes = likes;
      appunto.is_fresh = is_fresh;
      return appunto;
    },
    initFromDict:function (Dict) {
      return this.initFromValues(
        Dict['parent_user_email'],
        Dict['parent_word'],
        Dict['content'],
        Dict['likes'],
        Dict['is_fresh'],
      )
    }
  },


  //🍋 refetch

  //raw
  allLists:[],
  allWords:[],
  allAppuntis:[],

  //diaplaying
  allNewAddLists:[],
  //.
  studyLists:[],
  studyListWords:{},
  //.
  studyBooks:[],
  studyBooksWords:[],
  studyBookChapters:[],
  studyBookChapterWords:{},
  //.
  studyTempTodayWords:[],         //今日学习
  studyTempTodayDangerWords:[],   //今日学习: 复习
  studyTempTodayUntrackedWords:[],//今日学习: 新词

  studyTempLearningWords:[],
  //.
  allNewAddWords:[],
  allNewAddWordsUnique:[],

  //records
  recordWords:[],
  recordLists:[],
  recordAppuntis:[],

  isFetching:false,
  refetchDataOnFinishPool:[],
  refetchAllStudyData:function (LoadFromCache, OnFinish, FlattenAlign='top') {

    //console.log("🐞downloading studydata, from cache:",LoadFromCache);
    // if (LimitFunction.isLogin() != true){
    // }

    if (StudyDataController.isFetching){
      StudyDataController.refetchDataOnFinishPool.push(OnFinish);
      return;
    }

    StudyDataController.isFetching = true;

    StudyDataController.tryDownloadStudyData(LoadFromCache, function(results, reason){

      //console.log("🐞download studydata finsih:",results);

      if (results && results['duration']){
        //from cache
        StudyDataController.allLists = results['lists'];
        StudyDataController.allWords = results['words'];
        if (LoadFromCache){
          StudyDataController.refetchRecordAppuntis();
          StudyDataController.allAppuntis = StudyDataController.recordAppuntis;
        }else{
          StudyDataController.recordAppuntis = results['appuntis'];
          StudyDataController.allAppuntis = StudyDataController.recordAppuntis;
          StudyDataController.saveRecordAppuntis();
        }

        //console.log("🐞refetchAllStudyData.allNewAddLists",StudyDataController.allNewAddLists);
        //console.log("🐞refetchAllStudyData.studyLists",StudyDataController.studyLists);
        //console.log("🐞refetchAllStudyData.studyBookChapters",StudyDataController.studyBookChapters, StudyDataController.studyBookChapterWords);
      }else{
        if (LimitFunction.canUse(null,null,false)){
          Alert.showWarningAlert("暂时无法单词数据",reason,"好的");
        }else{
          StudyDataController.allLists = [];
          StudyDataController.allWords = [];
          StudyDataController.allAppuntis = [];
        }
      }

      //refine
      for (let one_word of StudyDataController.allWords){
        if (one_word.curve_points == ""){
          one_word.curve_points = null;
        }
      }

      //from local
      StudyDataController.refetchAllRecords();

      //regroup
      StudyDataController.regroupStudyData(FlattenAlign);

      //console.log("🐞StudyDataController.allLists:",StudyDataController.allLists);

      //finish
      StudyDataController.isFetching = false;

      if (OnFinish != null){
        OnFinish();
      }

      if (StudyDataController.refetchDataOnFinishPool.length > 0){
        for (let onfinish of StudyDataController.refetchDataOnFinishPool){
          onfinish();
        }
        StudyDataController.refetchDataOnFinishPool = [];
      }
    });
  },

  refetchAllRecords:function(){
    StudyDataController.refetchRecordWords();
    StudyDataController.refetchRecordLists();
    StudyDataController.refetchRecordAppuntis();
    //console.log("🐞recordWords 001:", StudyDataController.recordWords.length);
  },
  saveAllRecords:function(){
    StudyDataController.saveRecordWords();
    StudyDataController.saveRecordLists();
    StudyDataController.saveRecordAppuntis();
  },
  clearAllRecords:function(){
    localStorage.removeItem(Keys.Storage.kRecordLists);
    localStorage.removeItem(Keys.Storage.kRecordWords);
    localStorage.removeItem(Keys.Storage.kRecordAppuntis);
  },

  regroupStudyData:function(FlattenAlign='top'){
    //console.log("🐞regroupStudyData!");

    StudyDataController.tryMapRecordWords(StudyDataController.recordWords);
    StudyDataController.tryMapRecordLists(StudyDataController.recordLists);

    //🍓lists
    StudyDataController.allNewAddLists = StudyDataController.allLists.filter(function (list) {
      return Settings.getLanguage() == 'all'
        ? list['being'] == "NewAdd"
        : list['being'] == "NewAdd" && list['preferred_language'] == Settings.getLanguage();
    });

    StudyDataController.studyLists = [];
    StudyDataController.studyListWords = {};
    StudyDataController.studyBooks = [];
    StudyDataController.studyBooksWords = [];
    StudyDataController.studyBookChapters = [];
    StudyDataController.studyBookChapterWords = {};
    for (let list of StudyDataController.allNewAddLists){
      if (list['is_book'] == true){
        if (StudyDataController.studyBooks.length == 0){
          StudyDataController.studyBooks.push(StudyDataController.ListModel.initFromDict(list));
        }
      }else{
        StudyDataController.studyLists.push(StudyDataController.ListModel.initFromDict(list));
      }
    }

    //TODO: 列表排序

    //🍓words
    let new_add_lists_unico_ids = StudyDataController.allNewAddLists.map(function (list) {
      return list['unico_id'];
    });

    //get NewAdd words
    StudyDataController.allNewAddWords = StudyDataController.allWords.filter(function (word) {
      return word['being']=='NewAdd' && new_add_lists_unico_ids.includes(word['parent_list_unico_id']);
    })

    //get NewAdd words => flatten memos
    //StudyDataController.tryFlattenMemos(FlattenAlign);

    //get NewAdd words => cast model
    StudyDataController.allNewAddWords = StudyDataController.allNewAddWords.map(function (word) {
      let word_curve = null;
      if (Tools.isEmpty(word.curve_points)==false){
        word_curve = JSON.parse(word.curve_points);
      }

      //add forget point
      if (word_curve != null){
        word_curve = Curve.curveAddForgetPoint(word_curve);
        word.curve_points = JSON.stringify(word_curve);
      }

      //find memo
      word.MemoModel = Curve.curveFindMemo(word_curve);//污染

      return word;
    });


    //#study_lists + words
    let study_lists_unico_ids = StudyDataController.studyLists.map(function (list) {
      return list['unico_id'];
    })
    StudyDataController.studyLists = StudyDataController.studyLists.sortByItemKey('list_name');
    for (let list of StudyDataController.studyLists){
      StudyDataController.studyListWords[list['unico_id']] = [];
    }
    //.push words
    let study_lists_words = StudyDataController.allNewAddWords.filter(function (word) {
      return study_lists_unico_ids.includes(word['parent_list_unico_id']);
    })
    for (let word of study_lists_words){
      StudyDataController.studyListWords[word['parent_list_unico_id']].push(word);
    }
    //.unify
    for (let key in StudyDataController.studyListWords){
      StudyDataController.studyListWords[key] = StudyDataController.unifyWords(StudyDataController.studyListWords[key]);
    }

    //#study_books_chapters + words
    if (StudyDataController.studyBooks.length > 0){
      //1.get books words
      let study_books_unico_ids = StudyDataController.studyBooks.map(function (list) {
        return list['unico_id'];
      });
      let study_books_words = StudyDataController.allNewAddWords.filter(function (word) {
        return study_books_unico_ids.includes(word['parent_list_unico_id']);
      }).sort(function (a, b) {
        if (a['word_string'] < b['word_string']) return -1;
        if (a.last_nom > b.last_nom) return 1;
        return 0;
      });
      StudyDataController.studyBooksWords = study_books_words;

      //2.make indices by words
      let alpha_keys = [];
      for(let word of study_books_words){
        let alpha_key = word['word_string'][0];
        if (Tools.hasChinese(alpha_key)){
          alpha_key = "其它";
        }
        alpha_key = alpha_key.toUpperCase();

        if (alpha_keys.includes(alpha_key)==false){
          alpha_keys.push(alpha_key);
        }
      }
      //3.make chapters by indices
      let book = StudyDataController.studyBooks[0];
      let book_name = book['list_name'];
      let book_language = book['preferred_language'];
      let book_parent_user_email = book['book_parent_user_email'];
      let book_created_at = book['created_at'];
      for(let alpha_key of alpha_keys){

        let chapter_unico_id = "CHAPTER-"+alpha_key;
        let chapter_name = alpha_key;//book_name + " " + alpha_key;
        let chapter = StudyDataController.ListModel.initFromValues(
          chapter_unico_id,          // unico_id,
          chapter_name,              // list_name,
          book_parent_user_email,   // parent_user_email,
          null,                     // parent_cover_list_id,
          "NewAdd",                 // being,
          book_language,            // preferred_language,
          true,                     // is_book,
          null,                     // order_id,
          book_created_at,          //created_at,
          false                     // is_fresh
        );
        chapter.parent_folder = book;
        StudyDataController.studyBookChapters.push(chapter);
      }
      StudyDataController.studyBookChapters = StudyDataController.studyBookChapters.sortByItemKey('list_name');
      for (let chapter of StudyDataController.studyBookChapters){
        StudyDataController.studyBookChapterWords[chapter['unico_id']] = [];
      }

      //4.push words
      for (let word of study_books_words){
        //.
        let alpha_key = word['word_string'][0];
        if (Tools.hasChinese(alpha_key)){
          alpha_key = "其它";
        }
        alpha_key = alpha_key.toUpperCase();
        //.
        let chapter_unico_id = "CHAPTER-"+alpha_key;
        //.
        StudyDataController.studyBookChapterWords[chapter_unico_id].push(word);
      }

      //.unify
      for (let key in StudyDataController.studyBookChapterWords){
        StudyDataController.studyBookChapterWords[key] = StudyDataController.unifyWords(StudyDataController.studyBookChapterWords[key]);
      }
    }

    //get NewAdd words unique by PrimaryListIndexPath
    StudyDataController.allNewAddWordsUnique = [];
    let primary_list_indexpath = Settings.getPrimaryListIndexPath();
    if (primary_list_indexpath == null){
      primary_list_indexpath = {section:0, row:0};
    }
    let primary_list = StudyDataController.getListBy(primary_list_indexpath.section, primary_list_indexpath.row);
    if (primary_list != null){
      var primary_list_words = [];
      var rest_words = [];
      $.each(Object.keys(StudyDataController.studyListWords), function (index, key) {
        let words = StudyDataController.studyListWords[key];
        if (primary_list_indexpath.section == 0 && primary_list_indexpath.row == index){
          primary_list_words = words;
        }else{
          rest_words = rest_words.concat(words);
        }
      });
      $.each(Object.keys(StudyDataController.studyBookChapterWords), function (index, key) {
        let words = StudyDataController.studyBookChapterWords[key];
        if (primary_list_indexpath.section == 2){
          primary_list_words = primary_list_words.concat(words);
        }
        else if (primary_list_indexpath.section == 1 && primary_list_indexpath.row == index){
          primary_list_words = words;
        }else{
          rest_words = rest_words.concat(words);
        }
      });
      StudyDataController.allNewAddWordsUnique = primary_list_words.concat(rest_words);
    }else{
      StudyDataController.allNewAddWordsUnique = StudyDataController.allNewAddWords.filter(()=>true);
    }

    //unify!
    var results_strings = [];
    StudyDataController.allNewAddWordsUnique = StudyDataController.allNewAddWordsUnique.reduce(function (results, item, index, origins) {
      if (results_strings.includes(item.word_string) != true){
        results_strings.push(item.word_string);
        results.push(item);
      }
      return results;
    },[]);
  },
  unifyWords(words){
    var results_strings = [];
    return words.reduce(function (results, item, index, origins) {
      if (results_strings.includes(item.word_string) != true){
        results_strings.push(item.word_string);
        results.push(item);
      }
      return results;
    },[]);
  },

  refetchRecordWords:function(){
    let get_record_words = JSON.parse(localStorage.getItem(Keys.Storage.kRecordWords));
    StudyDataController.recordWords = (get_record_words != null && get_record_words.length > 0) ? get_record_words : [];
    let user_email = Settings.getUserEmail().toLowerCase();

    StudyDataController.recordWords = StudyDataController.recordWords.filter((record)=>{
      return record.parent_user_email.toLowerCase() == user_email;
    });
  },
  saveRecordWords:function(){
    localStorage.setItem(Keys.Storage.kRecordWords, JSON.stringify(StudyDataController.recordWords));
  },
  tryPluckRecordWords:function(StudyWords, ShouldBeOrrided){

    if (Tools.isEmpty(ShouldBeOrrided)){
      ShouldBeOrrided = false;
    }

    //start pluck
    let plucked_records = [];
    for (let study_word of StudyWords){
      let match_records = StudyDataController.recordWords.filter(function (record_word) {
        return record_word.word_string == study_word.word_string && record_word.parent_list_unico_id == study_word.parent_list_unico_id
      });

      //pluck record
      if (match_records.length <= 0){
        StudyDataController.recordWords.push(study_word);
        plucked_records.push(study_word);
      }
      //exist record
      else{
        if (ShouldBeOrrided){
          for (var i=0; i<StudyDataController.recordWords.length; i++){
            let this_record = StudyDataController.recordWords[i];
            if (this_record.word_string == study_word.word_string && this_record.parent_list_unico_id == study_word.parent_list_unico_id){
              StudyDataController.recordWords[i] = study_word;
            }
          }
          for (var i=0; i<match_records.length; i++){
            let this_record = match_records[i];
            if (this_record.word_string == study_word.word_string && this_record.parent_list_unico_id == study_word.parent_list_unico_id){
              match_records[i] = study_word;
            }
          }
        }
        plucked_records = plucked_records.concat(match_records)
      }
    }

    return plucked_records;
  },
  tryMapRecordWords:function(RecordWords){
    for (let record_word of RecordWords){
      //replace or push
      let match_indexs = [];
      for (var i=0; i<StudyDataController.allWords.length; i++){
        let study_word = StudyDataController.allWords[i];
        if (record_word.word_string == study_word.word_string && record_word.parent_list_unico_id == study_word.parent_list_unico_id){
          match_indexs.push(i);
        }
      }

      //replace
      if (match_indexs.length > 0){
        for (let match_index of match_indexs){
          StudyDataController.allWords.splice(match_index, 1, record_word);
        }
      }
      //push
      else{
        StudyDataController.allWords.push(record_word);
      }
    }
  },

  refetchRecordLists:function(){
    let get_record_lists = JSON.parse(localStorage.getItem(Keys.Storage.kRecordLists));
    StudyDataController.recordLists = (get_record_lists != null && get_record_lists.length > 0) ? get_record_lists : [];
    let user_email = Settings.getUserEmail().toLowerCase();
    StudyDataController.recordLists = StudyDataController.recordLists.filter((record)=>{
      return record.parent_user_email.toLowerCase() == user_email;
    });
    //console.log("🐞StudyDataController.recordLists:",StudyDataController.recordLists);
  },
  saveRecordLists:function(){
    localStorage.setItem(Keys.Storage.kRecordLists, JSON.stringify(StudyDataController.recordLists));
  },
  tryPluckRecordLists:function(StudyLists, ShouldBeOrrided){

    if (Tools.isEmpty(ShouldBeOrrided)){
      ShouldBeOrrided = false;
    }

    //start pluck
    let plucked_lists = [];
    for (let study_list of StudyLists){
      let match_records = StudyDataController.recordLists.filter(function (record_list) {
        return record_list.unico_id == study_list.unico_id;
      });

      //pluck record
      if (match_records.length <= 0){
        StudyDataController.recordLists.push(study_list);
        plucked_lists.push(study_list);
      }
      //exist record
      else{
        if (ShouldBeOrrided){
          for (var i=0; i<StudyDataController.recordLists.length; i++){
            let this_record = StudyDataController.recordLists[i];
            if (this_record.unico_id == study_list.unico_id){
              StudyDataController.recordLists[i] = study_list;
            }
          }
        }
        plucked_lists = plucked_lists.concat(match_records);
      }
    }
    return plucked_lists;
  },
  tryMapRecordLists:function(RecordLists){
    for (let record_list of RecordLists){
      let match_index = null;
      let match_record_list = null;
      for (var i=0; i<StudyDataController.allLists.length; i++){
        if (match_index == null){
          let study_list = StudyDataController.allLists[i];
          if (record_list.unico_id == study_list.unico_id){
            match_index = i;
            match_record_list = record_list;
          }
        }
      }
      //replace
      if (match_index != null){
        StudyDataController.allLists.splice(match_index, 1, match_record_list);
      }
      //push
      else{
        StudyDataController.allLists.push(record_list)
      }
    }
  },

  refetchRecordAppuntis:function(){
    let get_record_appuntis = JSON.parse(localStorage.getItem(Keys.Storage.kRecordAppuntis));
    StudyDataController.recordAppuntis = (get_record_appuntis != null && get_record_appuntis.length > 0) ? get_record_appuntis : [];
    let user_email = Settings.getUserEmail().toLowerCase();
    StudyDataController.recordAppuntis = StudyDataController.recordAppuntis.filter((record)=>{
      return record.parent_user_email.toLowerCase() == user_email;
    });
  },
  saveRecordAppuntis:function(){
    localStorage.setItem(Keys.Storage.kRecordAppuntis, JSON.stringify(StudyDataController.recordAppuntis));
  },

  //flatten memo
  tryFlattenMemos(Align="top"){//bottom
    //get samewords
    var grouped_words = {}//{word_string:[word], ...}
    for (let word of StudyDataController.allNewAddWords){
      if (word.word_string){
        let key = word.word_string;
        var existed_words = grouped_words[key] ? grouped_words[key] : [];
        existed_words.push(word);
        grouped_words[key] = existed_words
      }
    }

    //flatten
    var words_to_flatten = [];
    for (let key of Object.keys(grouped_words)){
      let same_words = grouped_words[key]
      if (same_words.length > 1){
        var anchor_word = null;
        for (let word of same_words){
          //align top
          if (Align == "top"){
            if (word.curve_points != null && word.curve_points != ""){
              if (anchor_word == null){
                anchor_word = word;
              }else{
                if (word.curve_points.length > anchor_word.curve_points.length){
                  anchor_word = word;
                }
              }
            }
          }
          //align bottom
          else{
            if (anchor_word == null){
              anchor_word = word;
            }else{
              if (anchor_word.curve_points == null){
                //...
              }else if (Tools.isEmpty(word.curve_points) || word.curve_points.length < anchor_word.curve_points.length){
                anchor_word = word;
              }
            }
          }
        }

        if (anchor_word != null){
          for (let word of same_words){
            if (word.curve_points != anchor_word.curve_points){
              word.curve_points = anchor_word.curve_points;
              word.is_fresh = true;
              words_to_flatten.push(word);
            }
            words_to_flatten.push(anchor_word);
          }
        }
      }
    }

    let records = StudyDataController.tryPluckRecordWords(words_to_flatten, true);
    //console.log("🐞words_to_flatten:",words_to_flatten,records);
    StudyDataController.saveRecordWords();
  },


  //🍉上传/下载

  //同步学习数据
  trySyncStudyData:function(HasUI, OnFinish){

    //❤️同步数据
    if (LimitFunction.canUse('default','default',false) != true){
      //console.log("🐞canUse false');
      return;
    }
    //console.log("🐞canUse true');

    Alert.showLoadingAlert("正在同步学习数据","这可能需要一点时间");//🔥
    setTimeout(function () {
      //upload
      StudyDataController.tryUploadStutyData(function (success, reason) {
        //fail
        if(success != true){
          Alert.clearAlerts();//🔥
          Alert.showWarningAlert("同步未完成",reason,"好的");
          return;
        }

        //success
        StudyDataController.clearAllRecords();

        //download
        StudyDataController.refetchAllStudyData(false, function () {
          if (StudyDataController.allLists.length > 0){
            let list_num = StudyDataController.allLists.filter(function (list) {
              return list.being == "NewAdd";
            }).length;
            let words_num = StudyDataController.allWords.filter(function (word) {
              return word.being == "NewAdd";
            }).length;
            let message = list_num + "个单词本/单词书<br>"+words_num+"个单词";

            Alert.showSuccessAlert("同步成功",message,"好的",false);//🔥
            OnFinish();
          }else{
            Alert.showWarningAlert("似乎还没有单词本","您可以背单词页面去下载单词本哦","知道啦");//🔥
            OnFinish();
          }
        });
      });
    },500);
  },

  //上传学习数据
  tryUploadStutyData:function(OnFinish){

    //❤️上传数据
    if (LimitFunction.canUse('default','default',false) != true){
      return;
    }

    StudyDataController.recordWords.map(function (recordWord) {
      delete recordWord['MemoModel'];
      return recordWord
    });//污染治理

    let studydata_upload_link = Keys.Url.studydata_upload_link;

    let lists_for_upload = StudyDataController.recordLists.filter(function (list) {
      return list['is_fresh'] == true;
    });;
    let words_for_upload = StudyDataController.recordWords.filter(function (word) {
      return word['is_fresh'] == true;
    });
    let appuntis_for_upload = StudyDataController.recordAppuntis.filter(function (appunto) {
      return appunto.is_fresh;
    });
    let data_for_upload = {
      user_email:Settings.getUserEmail(),
      lists:lists_for_upload,
      words:words_for_upload,
      appuntis:appuntis_for_upload,
    }
    //console.log("🐞upload:",studydata_upload_link,JSON.stringify(data_for_upload));
    if (lists_for_upload.length <= 0 && words_for_upload.length <= 0 && appuntis_for_upload.length <= 0){
      //console.log("🐞upload skip.");
      OnFinish(true);
      return;
    }

    $.ajax({
      type: "POST",
      url:studydata_upload_link,
      data: JSON.stringify(data_for_upload),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
      },
      success: function (data) {

        //console.log("🐞upload result:",data);

        let results = data;
        if (results != null){
          let reuslt_code = results['result_code'];
          if (reuslt_code === 'success'){
            OnFinish(true);
            return;
          }
          if (reuslt_code == 'fail'){
            Alert.showWarningAlert("😨 学习数据没有上传成功", results['detail']);
            OnFinish(false, results['detail']);
            return;
          }
        }
        Alert.showWarningAlert("😨 学习数据似乎没有上传成功", data);
        OnFinish(false, data);
      },
      error:function (data) {
        //console.log("🐞upload result:",data);

        Alert.showWarningAlert("😨 学习数据没有上传成功", data);
        OnFinish(false, data);
      }
    });
  },
  //下载学习数据
  tryDownloadStudyData:function (LoadFromCache, OnFinish) {

    //@Depracated!
    //❤️下载数据
    // if (LimitFunction.canUse(null, null, false) != true){
    //   OnFinish(null);
    //   return;
    // }

    let studydata_download_link;

    //@cache control:
    let key = Settings.makeUniqueKey(Keys.Storage.kDownloadLink, true, false);
    let cached_url = localStorage.getItem(key);
    //console.log("🐞cached_url:",cached_url);
    if (cached_url != null && LoadFromCache){
      studydata_download_link = cached_url;
    }else{
      studydata_download_link = Keys.Url.studydata_download_link
        + "?user_email=" + Settings.getUserEmail()
        +"&app_name="+Settings.AppName
        + "&version="+Settings.AppVersion
        + "&timestamp=" + TimeController.getTimeStringByMinites().replace(/\s/,"_");
      localStorage.setItem(key, studydata_download_link);
    }
    //console.log("🐞from:",studydata_download_link);

    $.ajax({
      type: "GET",
      url: studydata_download_link,
      success:function(data){
        const results = JSON.parse(data);
        //console.log("🐞results:",results);

        if (results && results['duration'] && results['file']){

          const file_url = results['file'];
          //console.log('🐞download file:', file_url);

          $.ajax({
            type: "GET",
            url: file_url,
            cache: true,
            xhr: function()
            {
              var xhr = new window.XMLHttpRequest();
              //Download progress
              xhr.addEventListener("progress", function(evt){
                if (evt.lengthComputable) {
                  var percentComplete = evt.loaded / evt.total;
                  //Do something with download progress
                  //console.log("🐞pregress:",percentComplete);
                }
              }, false);
              return xhr;
            },

            success:function(file_json){
              console.log("🐞download file complete:", file_json);
              OnFinish(file_json);
            },
            error: function (data) {
              OnFinish(null,'下载学习数据失败 001');
            }
          });
        }else{
          OnFinish(null,'下载学习数据失败 002');
        }
      },
      error: function (data) {
        OnFinish(null, '服务器发生错误');
      }
    });
  },


  //🍉导入
  tryCalculateImportStudyData:function(StudyData, OnFinish){
    let lists = StudyData['lists'];
    let words = StudyData['words'];
    let appuntis = StudyData['appuntis'];

    let getLists = lists.filter(function (list) {
      return list['is_fresh'] || StudyDataController.allLists.filter(function (study_list) {
        return study_list['unico_id'] == list['unico_id'];
      }).length == 0;
    });


    let getWords = words.filter(function (word) {
      return word['is_fresh'] || StudyDataController.allWords.filter(function (study_word) {
        return study_word['word_string'] == word['word_string'] && study_word['parent_list_unico_id'] == word['parent_list_unico_id'];
      }).length == 0;
    });

    let getAppuntis = appuntis.filter(function (appunti) {
      return appunti['is_fresh'] || StudyDataController.allAppuntis.filter(function (study_appunti) {
        return study_appunti['parent_word'] == appunti['parent_word'];
      }).length == 0;
    })

    OnFinish({
      lists:getLists,
      words:getWords,
      appuntis:getAppuntis
    });
  },
  tryWriteImportStudyData:function(CalculatedStudyData, OnFinish){
    let lists = CalculatedStudyData['lists'];
    let words = CalculatedStudyData['words'];
    let appuntis = CalculatedStudyData['appuntis'];

    //1.
    for (let list of lists){
      StudyDataController.insertListByDict(list);
    }
    //2.
    StudyDataController.insertWordsByDicts(words);
    //3.
    for (let appunti of appuntis){
      StudyDataController.modifyOrInsertAppuntoContentBy(
        appunti['parent_word'],
        appunti.content
      )
    }
    OnFinish();
  },

  //🍉UI接口

  //创建单词本
  showCreateListAlert(OnFinish) {
    Alert.showInputAlert("创建新单词本","请尽量与已存在的单词本不要重名哦",null, "确认", function (value) {
      if (Tools.isEmpty(value)==false){
        //insert
        let inserted_list = StudyDataController.insertListByName(value);
        OnFinish(inserted_list);
        return;
      }
      Alert.showWarningAlert("单词本创建失败","您好像没有输入单词本的名字","好吧");
    });
  },

  //清除学习记录
  showClearMemosAlert(){
    Alert.showConfirmAlert(
      "清除学习进度",  // Title,
      "只清除学习记录，不会删除单词<br>学习记录清除后不可恢复，确认清除咯?",                                     // HtmlMessage,
      true,                                                   // isDangerous,
      "确认清除",                                              // ActionText,
      function () {                                           // OnConfirm
        Alert.showLoadingAlert("正在清理单词学习数据");
        setTimeout(()=>{
          StudyDataController.clearWordsMemo(StudyDataController.allNewAddWordsUnique, function (total) {
            Alert.showSuccessAlert("学习记录已清除","总共清除了"+total+"记录","好的",false);
          });
        },800);
      }
    )
  },

  //🍉数据接口

  //快速获取
  getListBy(Section, Row){
    let this_list = null;
    //单词本
    if (Section == 0){
      this_list = StudyDataController.studyLists[Row];
    }
    //单词书(章节)
    if (Section == 1){
      this_list = StudyDataController.studyBookChapters[Row];
    }
    //单词书(全部)
    if (Section == 2){
      this_list = StudyDataController.studyBooks[0];
    }
    //所有单词
    if (Section == -1){

    }
    //所有学过的单词
    if (Section == -2){
      this_list = StudyDataController.ListModel.initFromValues(
        "LIST-LEARNING-WORDS",           // unico_id,
        "所有学过的单词",              // list_name,
        "",                         // parent_user_email,
        "",                         // parent_cover_list_id,
        "NewAdd",                   // being,
        "",                         // preferred_language,
        false,                      // is_book,
        null,                       // order_id,
        "",                         //created_at,
        false                       // is_fresh
      )
    }
    //正在遗忘的单词
    if (Section == -3){

    }
    //今日学习计划
    if (Section == -4){
      this_list = StudyDataController.ListModel.initFromValues(
        "LIST-TODAY-WORDS",           // unico_id,
        "今日学习计划",              // list_name,
        "",                         // parent_user_email,
        "",                         // parent_cover_list_id,
        "NewAdd",                   // being,
        "",                         // preferred_language,
        false,                      // is_book,
        null,                       // order_id,
        "",                         //created_at,
        false                       // is_fresh
      )
    }
    //选中的单词
    if (Section == -5){
      this_list = StudyDataController.ListModel.initFromValues(
        "LIST-SELECTED-WORDS",       // unico_id,
        "选中的单词",                  // list_name,
        "",                         // parent_user_email,
        "",                         // parent_cover_list_id,
        "NewAdd",                   // being,
        "",                         // preferred_language,
        false,                      // is_book,
        null,                       // order_id,
        "",                         //created_at,
        false                       // is_fresh
      )
    }
    return this_list;
  },

  //选中的单词
  mSelectedWords:[],
  getWordsBy(Section, Row){
    let words = [];
    let list = StudyDataController.getListBy(Section, Row);
    if (list != null){
      //单词本
      if (Section == 0){
        words = StudyDataController.studyListWords[list.unico_id]
      }

      //单词书(章节)
      if (Section == 1){
        //console.log("🐞chepter:",list,StudyDataController.studyBookChapterWords);
        words = StudyDataController.studyBookChapterWords[list.unico_id];
      }

      //单词书(全部)
      if (Section == 2){
        words = StudyDataController.studyBooksWords;
      }

      //所有学习的单词
      // if (Section == -2){
      //   let tempLearningWords = window.top.ListVCor.getTempLearningWords();
      //   if (tempLearningWords.length > 0){
      //     words = tempLearningWords;
      //   }else{
      //     words = StudyDataController.allNewAddWords.filter(function (one) {
      //       return one.MemoModel.MemoType != "memo_untracked";
      //     })
      //   }
      // }

      //今日学习
      if (Section == -4){
        words = StudyDataController.studyTempTodayWords;
      }

      //选中的单词
      if (Section == -5){
        for (let selected_word of StudyDataController.mSelectedWords){
          words = words.concat(StudyDataController.allNewAddWordsUnique.filter((word)=>{
            return word.word_string == selected_word.word_string && word.parent_list_unico_id == selected_word.parent_list_unico_id;
          }))
        }
        //update
        StudyDataController.mSelectedWords = words;
      }
    }
    return words;
  },

  //清除学习记录
  clearWordsMemo(words, onfinish){
    var modified_words = [];
    for (let word of words){
      if (Tools.isEmpty(word.curve_points) != true){
        word.curve_points = null;
        word.is_fresh = true;
        modified_words.push(word);
      }
    }

    StudyDataController.tryPluckRecordWords(modified_words, true);
    StudyDataController.saveRecordWords();

    StudyDataController.refetchAllStudyData(false, function () {
      onfinish(modified_words.length);
    },'bottom');
  },

  //🍉 Model CRUD

  //🍋words

  //-memo
  updateWordsMemo: function(Words){

    //pluck records
    let match_record_words = StudyDataController.tryPluckRecordWords(Words);

    //console.log("🐞update memo:",Words,"=>",match_record_words.length);

    for(let record_word of match_record_words){
      let word_curve = null;
      if (Tools.isEmpty(record_word.curve_points)==false){
        word_curve = JSON.parse(record_word.curve_points);
      }
      if (word_curve == null){
        record_word.curve_points = JSON.stringify(Curve.tryInitCurve(record_word));
        record_word.memo = 0;
      }else{
        record_word.curve_points = JSON.stringify(Curve.curveAddMemoPoint(word_curve));
        record_word.memo = record_word.memo + 1;
      }
      record_word.is_fresh = true;
    }
    //console.log("🐞updateWordsMemo:", match_record_words[0], JSON.parse(match_record_words[0].curve_points))
    //console.log("🐞recordWords:", StudyDataController.recordWords.length);

    StudyDataController.saveRecordWords();
  },
  remarkWordsBy: function(Words, MemoType){
    //console.log("🐞remarkWordsBy:",Words,MemoType);
    //pluck records
    let match_record_words = StudyDataController.tryPluckRecordWords(Words);
    for (let record_word of match_record_words){
      if (MemoType == Curve.MemoType.memo_untracked){
        record_word.curve_points = null;
      }else{
        let word_curve = null;
        if (Tools.isEmpty(record_word.curve_points)==false){
          word_curve = JSON.parse(record_word.curve_points);
        }
        if (word_curve == null){
          word_curve = Curve.tryInitCurve(record_word);
        }
        word_curve = Curve.curveForceAddMemoPoint(word_curve, MemoType);
        record_word.curve_points = JSON.stringify(word_curve);
      }
      record_word.memo = Curve.MemoValues[MemoType];
      record_word.is_fresh = true;
    }
    StudyDataController.saveRecordWords();
  },
  //-mean
  modifyWordMeanBy: function(WordString, WordKey){
    //match words
    let words = StudyDataController.allWords.filter(function (word) {
      return word.word_string == WordString;
    });

    //pluck records
    let match_record_words = StudyDataController.tryPluckRecordWords(words);
    for (let record_word of match_record_words){
      record_word.word_key = WordKey;
      record_word.is_fresh = true;
    }
    StudyDataController.saveRecordWords();
  },
  //-insert
  insertWordBy(ListUnicoID, WordString, WordKey){

    let match_words = StudyDataController.allWords.filter(function (word) {
      return word.word_string == WordString && word.parent_list_unico_id == ListUnicoID
    });

    //恢复
    if (match_words.length > 0){

      //pluck records
      let match_records = StudyDataController.tryPluckRecordWords(match_words);

      //modify
      for (let record of match_records){
        record.being = "NewAdd";
        record.is_fresh = true;
      }
    }
    //新增
    else{
      let new_word = StudyDataController.WordModel.initFromValues(
        WordString,                 // word_string,
        WordKey,                    // word_key,
        ListUnicoID,                // parent_list_unico_id,
        "NewAdd",                   // being,
        -1,                         // order_id,
        Settings.getUserEmail(),  // parent_user_email,
        Settings.getLanguage(),    // preferred_language,
        null,                       // curve_points,
        true                        // is_fresh
      );

      StudyDataController.tryPluckRecordWords(
        [new_word], //by study words
        true        //override
      );
    }

    //save records
    StudyDataController.saveRecordWords();
  },
  insertWordsBy(ListUnicoID, Pairs){
    let new_words = Pairs.map(function (pair) {
      let new_word = StudyDataController.WordModel.initFromValues(
        pair.word,                 // word_string,
        pair.mean,                    // word_key,
        ListUnicoID,                // parent_list_unico_id,
        "NewAdd",                   // being,
        -1,                         // order_id,
        Settings.getUserEmail(),  // parent_user_email,
        Settings.getLanguage(),    // preferred_language,
        null,                       // curve_points,
        true                        // is_fresh
      );
      return new_word;
    })

    StudyDataController.tryPluckRecordWords(
      new_words,  //by study words
      true        //override
    );

    //save records
    StudyDataController.saveRecordWords();
  },

  insertWordsByDicts(WordsDicts){
    let words = WordsDicts.map(function (word_dict) {
      return StudyDataController.WordModel.initFromDict(word_dict);
    });
    StudyDataController.insertWords(words);
  },
  insertWords(Words){
    StudyDataController.tryPluckRecordWords(
      Words, //by study words
      true        //override
    );

    //save records
    StudyDataController.saveRecordWords();
  },

  //-archive
  archiveWordsBy(WordStrings, InListUnicoID){ //InListUnicoID: null 代表所有列表

    let match_words = StudyDataController.allWords.filter(function (word) {
      //所有列表
      if (InListUnicoID == null || InListUnicoID.startsWith("LIST-")){
        return WordStrings.includes(word.word_string);
      }
      //父列表
      else{
        return WordStrings.includes(word.word_string) && word.parent_list_unico_id == InListUnicoID;
      }
    });

    //pluck records
    let match_records = StudyDataController.tryPluckRecordWords(match_words);

    //modify
    for (let match_record of match_records){
      match_record.being = "Archived";
      match_record.is_fresh = true;
    }

    //console.log("🐞archiveWordsBy:",InListUnicoID,WordStrings,match_words,match_records)
    //save records
    StudyDataController.saveRecordWords();
  },
  //-copy
  copyWordsBy(ListUnicoID, Words, OnFinish){ //OnFinish( skip_num )

    var words_for_recover = [];
    var words_for_add = [];

    let existed_words = StudyDataController.allWords.filter(function (word_in_all) {
      return word_in_all.parent_list_unico_id == ListUnicoID;
    });
    //console.log("🐞existed_words:",existed_words);
    for (let Word of Words){
      let match_words = existed_words.filter(function (existed_word) {
        return existed_word.word_string == Word.word_string && existed_word.parent_list_unico_id == Word.parent_list_unico_id;
      });

      //恢复
      if (match_words.length > 0){
        words_for_recover = words_for_recover.concat(match_words);
      }
      //新增
      else{
        let new_word = Object.clone(Word);
            new_word.parent_list_unico_id = ListUnicoID;
        words_for_add.push(new_word);
      }
    }
    //console.log("🐞recover words:", words_for_recover);
    //console.log("🐞add words:", ListUnicoID, words_for_add);

    //modify
    var words_for_save = words_for_recover.concat(words_for_add);
    for (let word of words_for_save){
      word.being = "NewAdd";
      word.is_fresh = true;
    }
    //pluck records
    StudyDataController.tryPluckRecordWords(words_for_save, true);
    //save records
    StudyDataController.saveRecordWords();

    //OnFinish
    if (OnFinish){
        OnFinish(words_for_recover.length);
    }
  },

  //🍋lists

  //-insert
  insertListByName(ListName){

    //新增
    let new_list = StudyDataController.ListModel.initFromValues(
      TimeController.getUnicoIDByTimeString(),            // unico_id,
      ListName,                                           // list_name,
      Settings.getUserEmail(),                  // parent_user_email,
      null,                                               // parent_cover_list_id,
      'NewAdd',                                           // being,
      Settings.getLanguage(), // preferred_language,
      false,                                              // is_book,
      -1,                                                 // order_id,
      TimeController.getTimeStringByMinites(),            // created_at,
      true                                                // is_fresh
    );
    StudyDataController.insertList(new_list);

    return new_list;
  },
  insertListByDict(ListDict){
    //新增
    let new_list = StudyDataController.ListModel.initFromDict(ListDict);
    StudyDataController.insertList(new_list);
    return new_list;
  },
  insertList(List){

    // @NotWork!
    //list: unique
    // let existed_lists = StudyDataController.allLists.filter(function (item) {
    //   return item.parent_cover_list_id &&
    //     List.parent_cover_list_id &&
    //     item.parent_cover_list_id == List.parent_cover_list_id;
    // });
    // //console.log("🐞insert list:",List,existed_lists);
    // if (existed_lists.length > 0){
    //   for (let exlist of existed_lists){
    //     exlist.being = "Archived";
    //     exlist.is_fresh = true;
    //   }
    //   existed_lists[0].being = "NewAdd";
    //
    //   //insert book records
    //   StudyDataController.tryPluckRecordLists(
    //     existed_lists,     //StudyLists
    //     true               //ShouldBeOrrided
    //   );
    //   StudyDataController.saveRecordLists();
    //   //console.log("🐞recover list:",List,existed_lists);
    //   return existed_lists[0];
    // }

    //book: archive other books
    if (List.is_book){
      let books_to_archive = StudyDataController.allNewAddLists.filter((item)=>{return item.is_book;});
      //console.log("🐞archive:",books_to_archive);
      for (let book of books_to_archive){
        if (
          Settings.getLanguage() == "all" ||                  //多国语: 关闭所有其它单词本
          Settings.getLanguage() == book.preferred_language   //单语言: 关闭当前语言下的其它单词本
        ){
          StudyDataController.archiveListBy(book, true);
        }
      }
      StudyDataController.saveRecordLists();
    }

    //insert list records
    StudyDataController.tryPluckRecordLists(
      [List],     //StudyLists
      true        //ShouldBeOrrided
    );
    StudyDataController.saveRecordLists();
    return List;
  },


  //-archive
  archiveListBy(List, IsBatch=false){
    //pluck records
    let match_records = StudyDataController.tryPluckRecordLists([List], false);

    //modify
    for (let match_record of match_records){
      match_record.being = "Archived";
      match_record.is_fresh = true;
    }

    //save records
    if (IsBatch != true){
      StudyDataController.saveRecordLists();
    }
  },
  //-modify
  modifyListNameBy(List, NewName){
    //pluck records
    let match_records = StudyDataController.tryPluckRecordLists([List], false);

    //modify
    for (let match_record of match_records){
      match_record.list_name = NewName;
      match_record.is_fresh = true;
    }

    //save records
    StudyDataController.saveRecordLists();
  },

  //🍋appuntis

  modifyOrInsertAppuntoContentBy(WordString, ContentString){
    if (Tools.isEmpty(ContentString)){
      ContentString = '';
    }

    var hasMatch = false;
    for (var i=0; i<StudyDataController.recordAppuntis.length; i++){
      let appunto = StudyDataController.recordAppuntis[i];
      if (appunto.parent_word == WordString){
        appunto.content = ContentString;
        appunto.is_fresh = true;
        hasMatch = true;
      }
    }
    if (!hasMatch){
      let new_appunto = StudyDataController.AppuntoModel.initFromValues(
        Settings.getUserEmail(),  // parent_user_email,
        WordString,                     // parent_word,
        ContentString,                  // content,
        null,                           // likes,
        true                            // is_fresh,
      )
      StudyDataController.recordAppuntis.push(new_appunto);
    }

    StudyDataController.allAppuntis = StudyDataController.recordAppuntis;
    StudyDataController.saveRecordAppuntis();
  }
};
export default StudyDataController;