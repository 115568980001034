//@jquery / js extension

//🍋String

//.escapeRegExp()
String.prototype.escapeRegExp = function (){
    var escaped = this.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    escaped = escaped
        .replace("\\(","(\\(|（)")
        .replace("\\)","(\\)|）)");
    return escaped;
}

//.escapeHtml()
String.prototype.escapeHtml = function() {
    var tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;'
    };
    return this.replace(/[&<>]/g, function(tag) {
        return tagsToReplace[tag] || tag;
    });
};

//.hasChinese()
String.prototype.hasChinese = function (){
    let zh_reg = /[^\x00-\xff]+/g;
    return zh_reg.test(this);
}

//.cleanSpaces
String.prototype.cleanSpaces = function(){
    return this.replace(/\s/g,'');
}

//.replaceJunkChars
String.prototype.replaceJunkChars = function(){
    return this.replace(/ /g,' ');//全角空格[ ] => 半角空格[ ]
}

//🍋Array

//.unique()
Array.prototype.unique = function() {
    var find_indexs = [];
    var find_indexs_string = [];

    for (var i = 0; i < this.length; i++) {
        var current = this[i];

        if (find_indexs_string.indexOf(current.toString().toLowerCase()) < 0){
            find_indexs.push(current);
            find_indexs_string.push(current.toString().toLowerCase());
        }
    }
    return find_indexs;
}

Array.prototype.uniqueSearchResults = function() {
    var find_results = [];
    for (var i = 0; i < this.length; i++) {
        var current = this[i];

        //过滤 index 相同 && dizio_language 相同的词(如果 dizio_language == undefined视为相同)
        if (find_results.filter(function (find_result) {
            return find_result.result_index.toLowerCase() == current.result_index.toLowerCase() &&
                (find_result.dizio_language == undefined || current.dizio_language == undefined || find_result.dizio_language == current.dizio_language);
        }).length == 0){
            find_results.push(current);
        }
    }
    return find_results;
}

//.last()
if (!Array.prototype.last){
    Array.prototype.last = function(){
        return this[this.length - 1];
    };
};

//.first()
if (!Array.prototype.first){
    Array.prototype.first = function(){
        return this[0];
    };
};

//.shuffle()
if (!Array.prototype.shuffle){
    Array.prototype.shuffle = function () {
        var j, x, i;
        var a = this;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }
}

//.sortByItemKey()
if (!Array.prototype.sortByItemKey){
    Array.prototype.sortByItemKey = function (ItemKey, Desc=false) {
        return this.sort(function( a, b ){

            let a_value = a[ItemKey];
            let b_value = b[ItemKey];
            if (a_value == undefined){a_value = ""};
            if (b_value == undefined){b_value = ""};
            a_value = a_value.toString();
            b_value = b_value.toString();

            //all_numbers_reg: /^[0-9]*$/g;
            if (/^[0-9]*$/g.test(a_value.trim()) && /^[0-9]*$/g.test(b_value.trim())){
                a_value = parseFloat(a_value);
                b_value = parseFloat(b_value);

                if (Desc){
                    if(a_value > b_value) {
                        return -1
                    };
                    if(a_value < b_value){
                        return 1
                    };
                }else{
                    if(a_value < b_value) {
                        return -1
                    };
                    if(a_value > b_value) {
                        return 1
                    };
                }
                return 0;
            }else{
                if (Desc){
                    return b_value.localeCompare(a_value);
                }else{
                    return a_value.localeCompare(b_value);
                }
            }
        });
    }
};

//.removeItem()
if (!Array.prototype.removeItem){
  Array.prototype.removeItem = function(item){
    const index = this.indexOf(item);
    if (index > -1) {
      this.splice(index, 1);
    }
    return this;
  };
};

//🍋Object
//-Object.keyNameOf( object.key )
//@报错: jquery: Uncaught TypeError: X[g].exec is not a function
//@解决: 用 defineProperty 将 enumerable 定义为 false
// Object.prototype.keyNameOf = function (item) {
//     let obj = this;
//     let keyname = Object.keys(obj)[
//         Object.values(obj).indexOf(
//             item
//         )];
//     return keyname;
// }
Object.defineProperty(Object.prototype, 'clone', {
    writable: true,
    configurable: true,
    enumerable: false,
    value: function (obj) {
        return Object.assign({}, obj);

        //@报错!
        //@原因: 对于没有 constructor 的 obj 无效
        // if (null == obj || "object" != typeof obj) return obj;
        // var copy = obj.constructor();
        // for (var attr in obj) {
        //     if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        // }
        // return copy;
    }
})

//
Object.defineProperty(Object.prototype, 'keyNameOf', {
    writable: true,
    configurable: true,
    enumerable: false,
    value: function (item) {
        let obj = this;
        let keyname = Object.keys(obj)[
            Object.values(obj).indexOf(
                item
            )];
        return keyname;
    }
})

//🍋Js.Element


//🍋 Function

//.Promise
Promise.each = function(arr, fn) { // take an array and a function
  if(!Array.isArray(arr)) return Promise.reject(new Error("Non array passed to each"));
  if(arr.length === 0) return Promise.resolve();
  return arr.reduce(function(prev, cur) {
    return prev.then(() => fn(cur))
  }, Promise.resolve());
}

//.防抖/节流
function debounce(fn, wait) {
    var timeout = null;
    return function() {
        if(timeout !== null)
            clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
    }
}
window.debounce = debounce;
