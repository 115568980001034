import $ from "jquery";
import React, {useState} from 'react';
import {IonActionSheet} from "@ionic/react";
import {useAppStateDispatch} from '../../AppState';
import Alert from "../../tools/Alert";
import * as Tools from "../../tools/Tools.js";
import StudyDataController from '../../tools/StudyDataController';
import {Settings} from "../../tools/Settings.js";
import {IframeNavBack} from "../../tools/uikit/IframeNav";
import {presentWordsPicker} from "../studyplan/WordsPicker/WordsPicker";

export var presentMenuList:any;

var mClickingList:any,
    mClickingIndexPath:any,
    mClickingWords:any = [];

export function setClicking(List:any, Words:any, IndexPath:any){
  mClickingList = List;
  mClickingWords = Words;
  mClickingIndexPath = IndexPath;
}

export const MenuList:React.FC = () => {
  const dispatch = useAppStateDispatch();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [actions, setActions] = useState([]);

  //@export:
  presentMenuList = setShowActionSheet;
  (window as any).top.presentMenuList = presentMenuList;

  return (
    <IonActionSheet
      isOpen={showActionSheet}
      onDidDismiss={() => setShowActionSheet(false)}
      //header={mClickingList.list_name}
      //subHeader={Object(window).mClickingWord.word_key}
      onWillPresent={() => {

        let isPrimary = Settings.getPrimaryListIndexPath() != null && (Settings.getPrimaryListIndexPath() as any).section == mClickingIndexPath.section &&
          (Settings.getPrimaryListIndexPath() as any).row == mClickingIndexPath.row;

        var options = [];
        options.push(
          //.设为优先学习
          {
            text: isPrimary ? '取消优先学习':'设为优先学习',
            handler: () => {
              if (isPrimary){
                Settings.setPrimaryListIndexPath(null);
              }else{
                Settings.setPrimaryListIndexPath(mClickingIndexPath);
              }
              (window as any).top.WordsViewController.reloadPageData(false);
              (window as any).top.ListViewController.reloadPage();
            }
          }
        );
        options.push(
          //.清除学习进度
          {
            text: '清除学习进度',
            handler: () => {
              Alert.showConfirmAlert(
                "清除学习进度",  // Title,
                "确认清除「"+mClickingList.list_name+"」所有单词的学习进度?",                                     // HtmlMessage,
                true,                                                   // isDangerous,
                "确认清除",                                              // ActionText,
                function () {                                           // OnConfirm
                  Alert.showLoadingAlert("正在清理「"+mClickingList.list_name+"」学习进度");
                  setTimeout(()=>{
                    StudyDataController.clearWordsMemo(mClickingWords, function () {
                      Alert.clearAlerts();
                      (window as any).top.WordsViewController.reloadPageData(true);
                      (window as any).top.ListViewController.reloadPage();
                    });
                  },800);
                }
              );
            }
          },
        );

        if (mClickingIndexPath.section == 0 || mClickingIndexPath.section == 2){
          options.push(
            //.修改名称
            {
              text: '修改名称',
              handler: () => {
                Alert.showInputAlert("修改单词名称","请尽量与已存在的单词本不要重名哦",mClickingList.list_name, "确认修改", function (value:any) {
                  if (Tools.isEmpty(value)==false){
                    StudyDataController.modifyListNameBy(mClickingList, value);

                    //reload wordsvcor
                    if ($("#WordsViewController").length > 0){
                      $("#WordsViewController ion-title").text(value);
                    }
                    //reload list
                    else{
                      (window as any).top.ListViewController.reloadPage();
                    }
                  }
                });
              }
            },

            //.批量添加单词
            // {
            //   text: '批量添加单词',
            //   handler: () => {
            //     presentWordsPicker(mClickingList);
            //   }
            // },
          )
        }
        options.push(
          //.删除
          {
            text: '删除',
            cssClass:(mClickingIndexPath.section == 0 || mClickingIndexPath.section == 1 || mClickingIndexPath.section == 2) ? '':'disabled',
            role: 'destructive',
            handler: () => {
              //console.log("🐞mClickingList:",mClickingList);
              let title = mClickingList.parent_folder
                ? "删除列表所在的整本单词书 "+mClickingList.parent_folder.list_name+"?"
                : "确认删除 "+mClickingList.list_name+"?";

              Alert.showConfirmAlert(
                title,  // Title,
                "删除后无法恢复哦, 如果再次需要您可以重新下载",                                     // HtmlMessage,
                true,                                                   // isDangerous,
                "确认删除",                                              // ActionText,
                function () {                                           // OnConfirm

                  let list_to_delete = mClickingList.parent_folder ? mClickingList.parent_folder : mClickingList;
                  StudyDataController.archiveListBy(list_to_delete);
                  Alert.showSuccessAlert("已删除"+list_to_delete.list_name,'','',true);

                  setTimeout(()=>{
                    //reload wordsvcor
                    if ($("#WordsViewController").length > 0){
                      IframeNavBack();
                    }
                    //reload lists
                    else{
                      (window as any).top.ListViewController.reloadPage();
                    }
                  },2000);
                }
              );
            }
          },
        );
        options.push(
          //.取消
          {
            text: '取消',
            role: 'cancel',
            handler: () => {}
          }
        );
        
        setActions(options as any);
      }}
      cssClass='menu-word'
      buttons={actions}
    ></IonActionSheet>
  )
}
