import $ from "jquery";
import {useEffect, useRef} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import {IframeNavPresentPage} from '../../tools/uikit/IframeNav'
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react/dist/index";
import {ellipsisHorizontal} from "ionicons/icons/index";
import * as Tools from "../../tools/Tools";
import * as TouchDND from '../../tools/sdk/touch-dnd/touch-dnd'
import '../../tools/sdk/fontawesome/css/all.css'
import '../../theme/composed-uikit.css'
import './MdxFileManager.css';
import Alert from "../../tools/Alert";
import {FileController} from "../../tools/FileController";
import TimeController from "../../tools/TimeController";
import * as qiniu from 'qiniu-js';

//console.log("🐞TouchDND:",TouchDND);

//🍉instance
var instance = {
  showUP : function () {
    IframeNavPresentPage(MdxFileManager, "MdxFileManager", false);
  }
};
Object(window.top).MdxFileManager = instance;

//🍉init
const MdxFileManager = () => {
  useEffect(() => {
    //console.log("🐞MdxFileManager ready!");
    const mDom = $(contentRef.current);
    const MdxService = window.top.MdxService;
    
    //🍉词典

    //刷新词典列表显示
    function updateOrderDictUI(){
      MdxService.refetchOrderDict(true, function () {//获取列表
        refreshLists(
          'webapp',
          MdxService.mMdxOrderDicts
        );
      });
    }
    function refreshLists(Client,Dicts) {

      //菜单
      mDom.addClass(Client);

      //词典显示

      //.添加词典
      if (Client === "android"){
        $(".presentation .adition").html("<a id='download-button' class='action-button'>安装词典包</a>");
      }
      else if(Client === "ios"){
        $(".presentation .adition").html("<a id='download-button' class='action-button'>安装词典包</a>");
      }
      else if(Client === "osx"){
        $(".presentation .adition").html("也可以将自己的词典包文件拖拽到这个页面来添加词典。");
        //😪TODO
        //<a id='download-button' class='action-button'>下载词典包</a>
      }
      else if(Client === "webapp"){
        // $(".presentation .adition").html(
        //   "<div class='file-picker'>\n" +
        //   "    <div class='image_box_div'></div>\n" +
        //   "    <form id='implements_form' action='upload.html' method='post' enctype='multipart/form-data' accept-charset='utf-8'>\n" +
        //   "        <input type='file' name='upload1' class='image_pick_input' multiple style='display:none;'/>\n" +
        //   "        <input type='submit' value='Submit' style='display: none;'>\n" +
        //   "    </form>\n" +
        //   "</div>"
        // );
      }
      else{
        $(".presentation .adition").html("");
      }

      //.更新列表菜单
      if(Client != 'webapp'){
        $('#dicts-menu').remove();
      }

      //SQL查词
      // if (Client == "webapp"){
      //   setLegacySearch('disable');
      // }

      let section_titles = {
        "en":"🇬🇧 英语",
        "fr":"🇫🇷 法语",
        "it":"🇮🇹 意大利语",
        "hidden":"禁用",
      };

      $("#sections_div").empty();
      let hidden_section_elm;
      $.each(Dicts, function (section, packs) {

        //section
        let section_elm = $("<span class='section' title='"+section+"'></span>");

        //title
        let section_title = section_titles[section];
        let section_title_elm = $('<h4>'+section_title+'</h4>');
        section_elm.append(section_title_elm);

        //items
        let section_items_elm = $('<ul class="list" id="'+section+'"></ul>');
        $.each(packs, function (index, item) {
          let file_name = item["file_name"];
          let dizio_name = item["dizio_name"];
          let downloaded = item['downloaded'];
          let file_link = item['file_link'];
          let active_for_index = item["for-index"] ? "active":"";
          let active_for_mainpart = item["for-mainpart"] ? "active":"";

          var is_main = false;
          // if (section === "it" && file_name === "109907.mdx"){
          //     is_main = true;
          // }
          // if (section === "fr" && file_name === "法汉汉法词典2007.mdx"){
          //     is_main = true;
          // }
          // if (section === "en" && file_name === "5Million.mdx"){
          //     is_main = true;
          // }

          let item_elm;
          if (is_main){
            item_elm = $(
              '<li class="item main" title="'+dizio_name+'">' +
              '<span class="name">'+dizio_name+'</span>' +
              '<div class="for for_index '+active_for_index+'">用于查词</div>'+
              '<div class="for for_mainpart '+active_for_mainpart+'"></div>'+
              '</li>'
            );
            section_elm.append(item_elm);
          }else{
            item_elm = $(
              '<li class="item" title="'+dizio_name+'">' +
              '<span class="name">'+dizio_name+'</span>' +
              '<div class="for for_index '+active_for_index+'">用于查词</div>'+
              '<div class="for for_mainpart '+active_for_mainpart+'"></div>'+
              '<i class="handlr fas fa-grip-lines float-right"></i>' +
              '</li>'
            );
          }
          item_elm.attr('file_name',item['file_name']);
          item_elm.attr('dizio_name',item['dizio_name']);
          if (Client=='webapp' && downloaded != true){
            //1.
            item_elm.find('.handlr').remove();
            //2.
            if (Tools.isEmpty(file_link)){
              item_elm.append('<i class="error fas fa-exclamation-circle float-right"></i>');
            }else{
              item_elm.append(
                '<i class="download fas fa-arrow-down float-right" title="'+file_link+'"></i>'
              );
            }
          }else{
            item_elm.append(
              '<span class="downloaded">已下载</span>'
            );
          }

          section_items_elm.append(item_elm);
        });
        section_elm.append(section_items_elm);


        if (section === "hidden"){
          hidden_section_elm = section_elm;
        }else{
          $("#sections_div").append(section_elm);
        }
      });
      $("#sections_div").append(hidden_section_elm);

      tryAddEmptyItem();
      listenSortable();
    }
    window.refreshLists = refreshLists;

    updateOrderDictUI();

    //启用sortable并监听
    var call_once;

    //插入空的li
    function tryAddEmptyItem() {
      $.each($(".section"),function () {
        let section = $(this);

        let items = section.find("li");
        let empty_items = section.find(".item_empty");
        let items_length = items.length - empty_items.length;

        if (items_length > 0){
          let place_holder = section.find(".item_empty");
          place_holder.remove();
        }else{
          if (empty_items.length === 0){
            let empty_item = $('<li class="item_empty"><i class="handlr fas fa-grip-lines float-right"></i></li>');
            section.find("ul").append(empty_item);
          }
        }
      });
    }

    //显示Draging Mask
    function showDragingMask(Bool) {
      if (Bool == true){
        $("#draging_mask").removeClass('hidden');
      }else{
        $("#draging_mask").addClass('hidden');
      }
    }

    //获取OrderDict
    function getOrderDict() {
      let order_dict = {};
      $.each($("#sections_div .section"),function () {
        let section = $(this);
        let section_key = section.attr('title');
        if (section_key){
          let items = [];
          $.each(section.find(".item"),function () {
            let dizio_name = $(this).attr('dizio_name');
            let file_name = $(this).attr('file_name');
            let active_for_index = $(this).find('.for_index').attr('class').includes('active');
            let active_for_mainpart = $(this).find('.for_mainpart').attr('class').includes('active');
            items.push({
              'dizio_name':dizio_name,
              'file_name':file_name,
              'for-index':active_for_index,
              'for-mainpart':active_for_mainpart
            });
          });
          order_dict[section_key] = items;
        }
      });
      return order_dict;
    }

    //🍋滑动Cell
    function listenSortable() {
      $(".list").sortable({ handle: '.handlr', connectWith: '.list'}).on('sortable:start',function () {
        $(".menu").remove();
      })
        .on('sortable:start',function (event, ui) {
          ui.item.addClass('dragging');
        })
        .on('sortable:stop', function() {

          $('.dragging').removeClass('dragging');

          tryAddEmptyItem();

          //call_device
          if (call_once != null){clearTimeout(call_once);}
          call_once = setTimeout(function () {
            let order_dict = getOrderDict();
            onCallDevice("SAVE-ORDER",order_dict);
          },200);
        });

      //$('.main').sortable('disable')
      //$('.main').sortable('option', { disabled: true })


      // $(".handlr").on('touchstart',function () {
      //     document.body.style.overflow = "hidden";
      // });
      // $(".handlr").on('touchend touchcancel',function () {
      //     document.body.style.overflow = "auto";
      // });
    }

    //🍋点击For
    mDom.on('click','.for',function (e) {
      e.preventDefault();
      e.stopPropagation();

      $(this).toggleClass('active');
      let order_dict = getOrderDict();
      onCallDevice("SAVE-ORDER",order_dict);
    });

    //🍋点击发现词典
    mDom.on('click touchend','#download-button',function () {
      onCallDevice("SHOW-DISCOVER-BOX",null);
    });

    //🍋点击下载词典
    mDom.on('click','.download',function (e) {
      e.preventDefault();
      e.stopPropagation();

      let file_link = $(this).attr('title');
      onCallDevice("DOWNLOAD-FILE-LINK",file_link);
    });

    //🍋点击同步列表
    mDom.on('click','#fetch-lists-button',function (e) {
      e.preventDefault();
      e.stopPropagation();

      Alert.showLoadingAlert(
        "正在同步词典记录",
        "<div>方格单词可以在多个设备中同步您的词典记录<br>减少导入词典包的时间成本<br><br>目前此功能支持的平台: Windows</div>"
      );
      //refetch MdxService.mMdxOrderDicts
      MdxService.refetchOrderDict(true, function () {//获取列表
        MdxService.tryAutoDownloadFilesByLanguage(function () {//自动下载文件
          updateOrderDictUI();
          Alert.showReloadAlert();//应用设置
        });
      });
    });

    //🍋文件处理
    function tryUploadMdxFile(Files){

      //1.check local
      //2.check server
      //3.upload & save server model
      //4.downlaod & save local model

      //console.log("🐞Did Drop MdxFile", Files);
      let help_message = "如需帮助, 您可以微信添加 iofange 联系开发者。";

      //验证文件
      if (Files.length != 1) {
        Alert.showWarningAlert("每次只能添加一个词典包文件哦", help_message);//🔥
        return;
      };

      let File = Files[0];
      let mb = File.size/(1000*1024);
      if (mb > 512){
        Alert.showWarningAlert("🌚 令人头秃事情还是发生了","暂时不支持大于500M词典文件的解析哦。"+help_message);//🔥
        return;
      }

      if (File.name.endsWith("mdx")==false){
        Alert.showWarningAlert("🙅‍♀️ 不支持解析的文件类型","目前方格单词只支持解析MDX格式的词典包哦。"+help_message);//🔥
        return;
      }

      //开始解析文件
      Alert.showUploadingAlert('正在解析词典包', '这可能需要一些时间，但是值得等待<br>解析完成后，您将可以随时查词而无需再次等待');//🔥
      FileController.tryGetFileMd5Hash(File, function (hash) {
        if (Tools.isEmpty(hash)){
          Alert.showWarningAlert("文件解析失败", "无法校验文件哈希值");//🔥
          return;
        }

        //开始解析词典包
        window.top.Mdict.MParser(Files).then(function(resources) {
          let Attrs = (resources['mdx'] || resources['mdd']).value().attrs;
          //console.log("🐞attrs",Attrs);

          //for uplaod file
          let file_name_composed = File['name']
            .replace(".mdx", "@"+TimeController.getTimeStringByDay()+".mdx")
            .replace(".mdd", "@"+TimeController.getTimeStringByDay()+".mdd");
          let file_path = "mdx/"+MdxService.mLanguage+"/"+file_name_composed;

          //for save model
          let model = {};
          model.dizio_name = Attrs['Title'];
          model.description = Attrs['Description'];
          model.size = File.size;
          model.raw_info = Attrs;
          model.file_name = file_name_composed;
          model.file_info = FileController.tryGetFileDetails(File);
          model.file_hash = hash;
          model.file_link = "cloud:/" + file_path;
          model.preferred_language = MdxService.mLanguage;

          //🍓1.check local
          let cache = MdxService.CacheController.getMdxCacheBy(hash);
          //load local
          if (cache != null){
            Alert.showWarningAlert("已经存在相同的词典包", "无需重复导入哦");//🔥
            return;
          }

          //🍓2.check server
          let mdx_check_link = window.top.mOptions['mdx_check_link'];
          let mdx_check_data = JSON.stringify({
            hash : hash
          });
          //console.log("🐞start mdx check:",mdx_check_link,mdx_check_data);
          $.ajax({
            type: "POST",
            url: mdx_check_link,
            data: mdx_check_data,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (data) {
              let results = data;
              if (results != null){

                //console.log("🐞check file success:",results);

                let file = results['file'];
                if (file != null){
                  tryDownloadMdxFile(file);
                  return;
                }

                //🍓3.upload & save server model
                $.ajax({url: window.top.mOptions['token_link'], success: function(token){
                    let config = {
                      useCdnDomain: true,
                      disableStatisticsReport: false,
                      retryCount: 6,
                      region: "up-z0.qiniup.com"
                    };
                    let putExtra = {
                      fname: "",
                      params: {},
                      mimeType: null
                    };
                    //prepare
                    var observable = qiniu.upload(File, file_path, token, putExtra, config);
                    //uploading
                    var subscription = observable.subscribe({
                      next: function (response) {//object => {total:{loaded, total, percent}...}
                        let percent = response.total.percent.toFixed(0);
                        Alert.updateUploadingProgress(percent+"%");//🔥
                      },
                      error: function (response) {
                        Alert.showWarningAlert("上传失败", response.message);//🔥
                      },
                      complete: function (response) {
                        //console.log("🐞上传成功: ", response);

                        //save server model
                        let mdx_store_link = window.top.mOptions['mdx_store_link'];
                        let mdx_store_data = JSON.stringify(model);
                        //console.log("🐞start mdx store:",mdx_store_link,mdx_store_data);
                        $.ajax({
                          type: "POST",
                          url : mdx_store_link,
                          data: mdx_store_data,
                          contentType: "application/json; charset=utf-8",
                          dataType: "json",
                          success: function (data) {
                            //console.log("🐞store file success:",data);
                            let results = data;
                            if (results != null){
                              let file = results['file'];
                              if (file != null){
                                tryDownloadMdxFile(file);
                                return;
                              }
                            }
                          },
                          error: function (data) {
                            Alert.showWarningAlert("上传失败", data);//🔥
                          }
                        });
                      }
                    });
                  }
                });
              }
            },
            error: function (data) {
              Alert.showWarningAlert("上传失败", data);//🔥
            }
          });
        });
      });
    }

    function tryDownloadMdxFile(StudyMdx){
      let file_link = StudyMdx.file_link;

      //console.log("🐞start download:",file_link,StudyMdx);

      if (!Tools.isEmpty(file_link)){

        Alert.showDownloadingMDXAlert();//🔥

        Tools.promiseAjax(file_link).then(function (file) {


          MdxService.CacheController.addMdxCache(StudyMdx);

          Alert.showDownloadFinishAlert();//🔥

          setTimeout(function () {
            Alert.showReloadAlert();//🔥
          },2000);

          updateOrderDictUI();
        });
      }
    }

    function tryDownloadMdxFileByLink(FileLink) {
      let match_mdx_lists = MdxService.mMdxLists.filter(function (item) {
        return item['file_link'] == FileLink;
      });
      if (match_mdx_lists.length > 0){
        let mdx = match_mdx_lists[0];
        tryDownloadMdxFile(mdx);
      }else{
        console.log("🔥未找到");
      }
    }

    //🍋CallDevice
    function onCallDevice(Action, Object){

      //console.log("🐞MdxFileManager onCallDevice:",Action,Object);

      if (Action == "SAVE-ORDER"){
        MdxService.CacheController.setMdxOrderDicts(Object);
      }

      if ( Action == "SET-LANGUAGE"){
        MdxService.CacheController.setLanguage(Object);
      }

      if (Action == "DOWNLOAD-FILE-LINK"){
        tryDownloadMdxFileByLink(Object);
      }

      if (Action == "SHOW-DISCOVER-BOX"){
        //...
      }
    }
  });

  const contentRef = useRef(this);
  return (
    <IonPage id="MdxFileManager" ref={contentRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="返回" defaultHref="/" onClick={(e)=>{e.preventDefault()}}/>
          </IonButtons>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            {/*<IonButton className="menu-button">*/}
              {/*<IonIcon className="color-dark" slot="icon-only" icon={ellipsisHorizontal}/>*/}
            {/*</IonButton>*/}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="MdxFileManagerContent">

        {/*<div id="language_div" className="block">*/}
        {/*<div className="section_title">词典和学习语言</div>*/}

        {/*<div className="language_menu">*/}
        {/*<div className="presentation">*/}
        {/*<div>选择方格单词主要学习语言<br/>您的词典, 单词本, 大发现社区, 都会跟随您的设置。</div>*/}
        {/*</div>*/}

        {/*<div className="dropdown">*/}
        {/*<button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton"*/}
        {/*data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
        {/*学习语言*/}
        {/*</button>*/}
        {/*<ul id="languages" className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">*/}
        {/*<div id="all" className="cell dropdown-item">*/}
        {/*<img src="assets/icons/flags/all.png" className="flag"/>*/}
        {/*<span className="language_label">多国语</span>*/}
        {/*</div>*/}
        {/*<div id="en" className="cell dropdown-item">*/}
        {/*<img src="assets/icons/flags/uk.svg" className="flag"/>*/}
        {/*<span className="language_label">英语</span>*/}
        {/*</div>*/}

        {/*<div id="it" className="cell dropdown-item">*/}
        {/*<img src="assets/icons/flags/it.svg" className="flag"/>*/}
        {/*<span className="language_label">意大利语</span>*/}

        {/*</div>*/}

        {/*<div id="fr" className="cell dropdown-item">*/}
        {/*<img src="assets/icons/flags/fr.svg" className="flag"/>*/}
        {/*<span className="language_label">法语</span>*/}
        {/*</div>*/}
        {/*</ul>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}

        {/*<div id="voice_language_div" className="block">*/}
        {/*<div className="section_title">声音语言</div>*/}

        {/*<div className="language_menu">*/}
        {/*<div className="presentation">*/}
        {/*<div>为单词发音， 语音识别设定语言</div>*/}
        {/*</div>*/}

        {/*<div id="speaker-menu-button" className="btn btn-light btn-block">*/}
        {/*<i className="fa fa-volume-up"></i> 选择朗读者*/}
        {/*</div>*/}

        {/*<div className="dropdown">*/}
        {/*<button className="btn btn-light dropdown-toggle" type="button" id="dropdownVoiceMenuButton"*/}
        {/*data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
        {/*声音语言*/}
        {/*</button>*/}
        {/*<ul id="voice_languages" className="dropdown-menu dropdown-menu-right"*/}
        {/*aria-labelledby="dropdownVoiceMenuButton">*/}
        {/*<div id="voice-en" title="en" className="cell dropdown-item">*/}
        {/*<img src="assets/icons/flags/uk.svg" className="flag"/>*/}
        {/*<span className="language_label">英语</span>*/}
        {/*</div>*/}

        {/*<div id="voice-it" title="it" className="cell dropdown-item">*/}
        {/*<img src="assets/icons/flags/it.svg" className="flag"/>*/}
        {/*<span className="language_label">意大利语</span>*/}

        {/*</div>*/}

        {/*<div id="voice-fr" title="fr" className="cell dropdown-item">*/}
        {/*<img src="assets/icons/flags/fr.svg" className="flag"/>*/}
        {/*<span className="language_label">法语</span>*/}
        {/*</div>*/}
        {/*</ul>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}


        <div id="dictionary_div" className="block">

          <div className="section_title position-relative">
            词典管理

            {/*<div id="dicts-menu" className="dropdown">*/}
            {/*<button className="btn btn-light" type="button" data-toggle="dropdown">*/}
            {/*<i className="fas fa-ellipsis-v"></i>*/}
            {/*</button>*/}
            {/*<div className="dropdown-menu dropdown-menu-right">*/}
            {/*<a id="fetch-lists-button" className="dropdown-item">更新词典列表</a>*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>

          <div className="presentation">
            {/*<p>拖动词典来调整词典显示的顺序。</p>*/}

            <div id="fetch-lists-button" className="btn btn-block">更新可用词典列表</div>

            {/*<div className="adition">也可以将自己的词典包文件拖拽到这个页面来添加词典。</div>*/}
          </div>

          <div id="sections_div"></div>
          <div id="draging_mask" className="hidden"></div>
        </div>

        {/*<div id="lab_div" className="block disable">*/}
        {/*<div className="section_title">扩展功能</div>*/}
        {/*<div id="legacy_search_cell" className="item">*/}
        {/*<div className="cell-title">🇮🇹 使用SQLite查词引擎</div>*/}
        {/*<label className="switch">*/}
        {/*<input type="checkbox"/>*/}
        {/*<span></span>*/}
        {/*</label>*/}
        {/*</div>*/}
        {/*</div>*/}

      </IonContent>
    </IonPage>
  );
};

export default MdxFileManager;
