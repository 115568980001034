import $ from "jquery";
import React, {useState} from 'react';
import {IonActionSheet} from "@ionic/react";
import {useAppStateDispatch} from '../../AppState';
import Alert from "../../tools/Alert";
import * as Tools from "../../tools/Tools.js";
import StudyDataController from '../../tools/StudyDataController';
import {Settings} from "../../tools/Settings.js";
import {IframeNavBack} from "../../tools/uikit/IframeNav";

export var presentMenuLists:any;

export const MenuSelectList:React.FC = (props:any) => {
  const dispatch = useAppStateDispatch();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [actions, setActions] = useState([]);

  //@export:
  presentMenuLists = setShowActionSheet;
  (window as any).top.presentMenuLists = presentMenuLists;

  return (
    <IonActionSheet
      isOpen={showActionSheet}
      onDidDismiss={() => setShowActionSheet(false)}
      onWillPresent={() => {

        var onClickList:any = props.onClickList;
        var options:any[] = [];

        //.Lists
        for (let list of StudyDataController.allNewAddLists){
          options.push({
            text: Object(list).list_name,
            handler: () => {
              if (onClickList){
                onClickList(list);
              }
            }
          })
        }

        //.Add
        options.push({
          text: "新建单词本",
          handler: () => {
            if (onClickList){
              StudyDataController.showCreateListAlert(function (inserted_list:any) {
                onClickList(inserted_list);
              })
            }
          }
        })

        //.取消
        options.push({
            text: '取消',
            role: 'cancel',
            handler: () => {}
        })
        
        setActions(options as any);
      }}
      cssClass='menu-word'
      buttons={actions}
    ></IonActionSheet>
  )
}
