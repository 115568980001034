import $ from "jquery";
import WordShell from "./WordShell";
import {Settings} from "../Settings";


const ResultMaker = {

  ResultObjectModel : {
    initFromDict:function () {

    },
    initFromValues:function (
      dizio_name,
      searching,
      result_index,
      result_mean,
      result_mainpart
    ){
      var result_object = {};
      result_object.dizio_name = dizio_name;
      result_object.searching = searching;
      result_object.result_index = result_index;
      result_object.result_mean = result_mean;
      result_object.result_mainpart = result_mainpart;
      return result_object;
    }
  },

  //🍋Search
  makeResultsForSearchNormalAndRoot : function (ResultObject){

    let result_index = ResultObject.result_index.toString();
    let result_mainpart = ResultObject.result_mainpart;
    let result_mainpart_txt = $($.parseHTML(result_mainpart)).text();

    let result_dizio_language = ResultObject.dizio_language;
    if (typeof(result_dizio_language) == "undefined"){
      result_dizio_language = Settings.getLanguage();
    }

    //优化
    if (result_mainpart_txt !== ""){
      let lines = result_mainpart_txt.split("\n").filter(function (line) {
        return line.replace(/\s+/, "")  !== "";
      });

      //FR: 变位提示
      if (lines.length === 2 && lines[1].includes(" - ")){
        ResultObject = this.refineResultNormal_FR(ResultObject, lines);
      }
      //FR, EN, IT
      else{
        let wordshell = null;
        if (result_dizio_language === "en"){
          wordshell = WordShell.makeWordShell_EN(result_index, result_mainpart);
        }
        if (result_dizio_language === "fr"){
          wordshell = WordShell.makeWordShell_FR(result_index, result_mainpart);
        }
        if (result_dizio_language === "it"){
          wordshell = WordShell.makeWordShell_IT(result_index, result_mainpart);
        }

        //keys
        if (wordshell != null){
          //has Keys
          if(wordshell.keys.length > 0){
            ResultObject.result_mean = wordshell.keys.join(" / ");
          }
          //use RawValue
          else{
            let raw_value = (wordshell.preferred_raw_value === undefined || wordshell.preferred_raw_value === null) ? "":wordshell.preferred_raw_value;
            let raw_text = $($.parseHTML(raw_value)).text().replace(/;/g,",");
            ResultObject.result_mean = raw_text;
          }
        }
      }
    }

    //完成提取变位提示
    return ResultObject;
  },
  refineResultNormal_FR : function (ResultObject, lines) {
    let breaks = lines[1].split(" - ");
    if (breaks.length === 2){
      let index_string = ResultObject.result_index;
      let origin_string = breaks[1];
      var tips = breaks[0].split(",").filter(function (tip) {return tip.replace(/\s+/, "")  !== "";});

      tips = tips.map(function replaceConiugatoInfo_French(str){

        //str = "3 p.s. Fut.";

        //替换人称
        let reg_num = /\d/g;
        let matches = str.match(reg_num);
        if (matches !== null){
          str = str.replace(reg_num, "第"+matches[0]+"人称");
        }

        //替换单复数
        str = str.replace("p.s.","单数");
        str = str.replace("p.p.","复数");

        //替换时态
        let infos = {
          "Prés. subj.":"虚拟式现在时",
          "Impar. subj.":"虚拟式未完成过去时",

          "Prés.":"直陈式现在时",
          "Pas.":"直陈式简单过去时",
          "Impar.":"直陈式未完成过去时",
          "Fut.":"直陈式简单将来时",

          "Cond.":"条件式",

          "Part.":"分词",
          "prés.":"现在时",
          "pas.":"过去时"
        };
        $.each(infos, function (key, val) {
          str = str.replace(key,val,true);
        });

        str = str.replace(" ","");
        str = str.replace(" ","");

        if (str.includes("数")){
          let breaks = str.split("数");
          str = breaks[0]+"数"+" "+breaks[1];
        }

        return str;
      });

      ResultObject.result_index = origin_string;
      ResultObject.result_mean = index_string;
      ResultObject.result_mainpart = "<变位提示>"+tips.join("<br>");
    }

    return ResultObject;
  },
  makeResultsForSearchAll : function (ResultObject) {

    let result_index = ResultObject.result_index;

    //make keys_string
    //let wordshell = WordShell.makeWordShell_FR(result_index, result_mainpart);
    //ResultObject.result_mean = wordshell.keys.join(" / ");

    //make highlight
    //result_mainpart = wordshell.toHtmlString();
    //let result_mainpart_txt = $(result_mainpart).text();
    //var start_index = result_mainpart_txt.indexOf(ResultObject.searching)-20;
    //if (start_index < 0){start_index = 0;}
    //let result_mainpart_txt_slice = result_mainpart_txt.substr(start_index, 80);
    //let highlight_reg = new RegExp(ResultObject.searching);
    //let result_mainpart_txt_slice_highlighted = result_mainpart_txt_slice.replace(highlight_reg, "<span class='highlight' style='background: red;'>"+ResultObject.searching+"</span>")

    // ResultObject.result_mainpart = $($.parseHTML(ResultObject.result_mainpart)).text();
    // ResultObject.result_mean = ResultObject.result_mainpart;

    let mainpart_text = $($.parseHTML(ResultObject.result_mainpart)).text().replace(/★★/g, ',').replace(/★/g,' ');
    let mainpart_text_replaced = mainpart_text;

    let mainpart_splice = mainpart_text.match(new RegExp('.{0,20}'+ResultObject.searching.escapeRegExp()+'.{0,200}', 'g'));
    if (mainpart_splice != null && mainpart_splice.length > 0){
      mainpart_splice = mainpart_splice[0].replace(
        new RegExp(ResultObject.searching.escapeRegExp()),
        '<span class="highlight">'+ResultObject.searching+'</span>'
      );
      mainpart_text_replaced = mainpart_splice;
    }

    ResultObject.result_mean = mainpart_text_replaced;
    ResultObject.result_mainpart = mainpart_text;
    return ResultObject;
  },
  makeResultsForSearchOrigin : function(ResultObject){
    let searching = ResultObject.searching;
    let result_index = ResultObject.result_index;
    let result_mainpart = ResultObject.result_mainpart;

    var tips = [];

    //check equal
    let equal_reg = new RegExp('\\s{1}'+searching+'[,<]');
    if (equal_reg.test(result_mainpart)){

      console.log("🍋result_index:",result_index);

      //modis
      let modi_reg = new RegExp(
        //@startswith <font: red>, incluces searching, endswithout next <font: red>
        '<font style="color: #FFFFFF; background-color: #800000;">((?!<font style="color: #FFFFFF; background-color: #800000;">).)*'+searching+'((?!<font style="color: #FFFFFF; background-color: #800000;">).)*',
        'gi'
      );
      let match_modis = result_mainpart.match(modi_reg);
      if (match_modis != null){
        for (let match_modi of match_modis){
          let modi_key_reg = new RegExp(
            '<font style="color: #FFFFFF; background-color: #800000;">((?!</font>).)*</font>',
            'gi'
          );
          let match_modi_keys = match_modi.match(modi_key_reg);
          if (match_modi_keys != null && match_modi_keys.length == 1){
            let match_modi_key = $($.parseHTML(match_modi_keys[0])).text();
            //console.log("🐞match_modi_key:", match_modi_key);

            //tempis
            let tempis_reg = new RegExp(
              //@startswith <font: green>, includes searching, endswithout next <font: green>
              '<font style="color: #009900">((?!<font style="color: #009900">).)*'+searching+'((?!<font style="color: #009900">).)*',
              'gi'
            );
            let match_tempis = match_modi.match(tempis_reg);
            if (match_tempis != null){
              for (let match_tempi of match_tempis){
                let tempi_key_reg = new RegExp(
                  '<font style="color: #009900">((?!</font>).)*</font>',
                  'gi'
                )
                let match_tempi_keys = match_tempi.match(tempi_key_reg);
                if (match_tempi_keys != null && match_tempi_keys.length == 1){
                  let match_tempi_key = $($.parseHTML(match_tempi_keys[0])).text().replace(':','');
                  //console.log("🐞match_tempi_key:",match_tempi_key);

                  //coniugatis
                  let coniugatis = match_tempi.replace(tempi_key_reg, '').split(',').map(function (coniugato) {
                    return $($.parseHTML(coniugato)).text().replace(/^\s*/g,'').replace(/\s*$/g,'');
                  });
                  //console.log("🐞coniugatis:",coniugatis);
                  let match_coniugatis = coniugatis.filter(function (coniugato) {
                    return coniugato.endsWith(searching);
                  });
                  //console.log("🐞-:",match_coniugatis);
                  if (match_coniugatis.length > 0){
                    let match_coniugato = match_coniugatis[0];
                    //console.log("🐞match_coniugato:",match_coniugato);
                    tips.push(
                      (match_modi_key + " " + match_tempi_key + ": " + match_coniugato).toLowerCase()
                    )
                  }
                }
              }
            }
          }
        }
      }else{
        //console.log("🐞modi not found:",result_mainpart,modi_reg);
      }
    }

    if (tips.length > 0){
      ResultObject.result_mainpart = "<变位提示>" + tips.join("<br>");
      ResultObject.result_mean = searching;
      return ResultObject;
    }else{
      return null;
    }
  },

  //🍋Fetch

  //fast Render
  makeResultsForFastRender:function(ResultObject){
    //console.log("🐞ResultObject:",ResultObject);
    let fast_rendered_html_string =
      "<div class='Preview one-dizio'>"+
      "<p id='theIndice' class='Fast TouchAction'>"+ResultObject.result_index+"</p>" +
      "<p id='theKeys' class='Fast TouchAction'>"+ResultObject.result_mean+"</p>"+
      "</div>";
    ResultObject.result_mainpart = fast_rendered_html_string;
    return ResultObject;
  },
  makeHtmlStringForFastRender:function(Word){
    let fast_rendered_html_string =
      "<div class='Preview one-dizio'>"+
      "<p id='theIndice' class='Fast TouchAction'>"+Word.word_string+"</p>" +
      "<p id='theKeys' class='Fast TouchAction'>"+Word.word_key+"</p>"+
      "</div>";
    return fast_rendered_html_string;
  },

  //fetch Mainpart
  makeResultsForFetchMainPart_IT : function (ResultObject, CustomKey=null){
    let result_index = ResultObject.result_index;
    let result_mainpart = ResultObject.result_mainpart;

    //- 提取 Keys 和 MainpartHtmlString
    let wordshell = WordShell.makeWordShell_IT(result_index, result_mainpart);

    let rendered_html_string = wordshell.toHtmlString(CustomKey);
    let rendered_keys_string = CustomKey != null ? CustomKey : wordshell.keys.join(" / ");
    ResultObject.result_mean = rendered_keys_string;
    ResultObject.result_mainpart = rendered_html_string;

    return ResultObject;
  },
  makeResultsForFetchMainPart_IT_OriginTable : function (ResultObject){

    let OriginString = ResultObject.result_mainpart;

    //🍓返回html值
    var composedString = ""

    var OriginTableString = ""
    var NearWordsString = ""

    //🍃拆分 变位表/近反义词
    if (OriginString.includes("<b>ANTONYMS</font></b>") && OriginString.includes("<b>SYNONYMS</font></b>")){
      let breaks = OriginString.split("<b>SYNONYMS</font></b>")
      OriginTableString = breaks[0]
      NearWordsString = "<b>SYNONYMS</font></b>"+breaks[1]
    }else if (OriginString.includes("<b>SYNONYMS</font></b>")){
      let breaks = OriginString.split("<b>SYNONYMS</font></b>")
      OriginTableString = breaks[0]
      NearWordsString = "<b>SYNONYMS</font></b>"+breaks[1]
    }else if (OriginString.includes("<b>ANTONYMS</font></b>")){
      let breaks = OriginString.split("<b>ANTONYMS</font></b>")
      OriginTableString = breaks[0]
      NearWordsString = "<b>ANTONYMS</font></b>"+breaks[1]
    }else{
      OriginTableString = OriginString
    }

    var theOriginString = OriginTableString

    //🍃生成变位表
    if (theOriginString != ""){

      //#替换增加 语法说明
      let modis = [
        ["INFINITO","不定式"],["PARTICIPIO","分词"],["GERUNDIO","副动词"],["INDICATIVO","直陈式"],["CONGIUNTIVO","虚拟式"],["CONDIZIONALE","条件式"],["IMPERATIVO","命令式"],
      ]
      for (let one of modis){
        theOriginString = theOriginString.replace(new RegExp(one[0].escapeRegExp(),'gi'), one[1]+"<span>"+one[0]+"</span>");
      }

      let tempis = [
        ["Presente","现在时"],
        ["Passato","过去时"],
        ["Trapassato prossimo","近过去完成时"],
        ["Passato prossimo","近过去时"],
        ["Imperfetto","未完成过去时"],
        ["Trapassato remoto","远过去完成时"],
        ["Passato remoto","远过去时"],
        ["Futuro semplice","将来时"],
        ["Futuro anteriore","先将来时"],
      ]
      for (let one of tempis){
        let reg = new RegExp( (one[0]+":").escapeRegExp(), 'gi');
        theOriginString = theOriginString.replace(reg, one[1]+"<span>"+one[0]+"</span>");
      }

      theOriginString = "<div id='OriDiv'><div class='functionButton'>变位表</div><div class='checkerDIV TouchAction Origintable it'>"+theOriginString+"</div></div>"

    }
    composedString += theOriginString

    if ((NearWordsString != "")){
      //🍃生成近反义词
      var theNearParolesString = NearWordsString

      //#拆分变位和同反义词 到两个DIV
      var SemiDiv = ""
      var AntiDiv = ""

      //.近反义词
      if (theNearParolesString.includes("<b>ANTONYMS</font></b>") && theNearParolesString.includes("<b>SYNONYMS</font></b>")){

        let breaks = theNearParolesString.split("<b>ANTONYMS</font></b>")
        SemiDiv = "<div class='SemDiv'><div class='functionButton'>近义词</div><div class='checkerDIV TouchAction'>"+breaks[0]+"</div></div>"
        AntiDiv = "<div class='SemDiv'><div class='functionButton'>反义词</div><div class='checkerDIV TouchAction'>"+breaks[1]+"</div></div>"


        //.反义词
      }else if (theNearParolesString.includes("<b>ANTONYMS</font></b>")){
        AntiDiv = "<div class='SemDiv'><div class='functionButton'>反义词</div><div class='checkerDIV TouchAction'>"+theNearParolesString+"</div></div>"

        //.近义词
      }else if (theNearParolesString.includes("<b>SYNONYMS</font></b>")){
        SemiDiv = "<div class='SemDiv'><div class='functionButton'>近义词</div><div class='checkerDIV TouchAction'>"+theNearParolesString+"</div></div>"
      }

      //去掉SYNONYMS
      SemiDiv = SemiDiv.replace("<b>SYNONYMS</font></b>", "")

      //去掉ANTONYMS
      AntiDiv = AntiDiv.replace("<b>ANTONYMS</font></b>", "")

      //#合并近反义词
      theNearParolesString = SemiDiv+AntiDiv

      composedString+=theNearParolesString
    }

    ResultObject.result_mainpart = composedString;
    return ResultObject;
  },
  makeResultsForFetchMainPart_FR : function (ResultObject, CustomKey=null){
    let result_index = ResultObject.result_index;
    let result_mainpart = ResultObject.result_mainpart;

    //- 提取 Keys 和 MainpartHtmlString
    let wordshell = WordShell.makeWordShell_FR(result_index, result_mainpart);

    let rendered_html_string = wordshell.toHtmlString(CustomKey);
    let rendered_keys_string = CustomKey != null ? CustomKey : wordshell.keys.join(" / ");
    ResultObject.result_mean = rendered_keys_string;
    ResultObject.result_mainpart = rendered_html_string;

    return ResultObject;
  },
  makeResultsForFetchMainPart_EN : function (ResultObject, CustomKey=null){
    let result_index = ResultObject.result_index;
    let result_mainpart = ResultObject.result_mainpart;

    let wordshell = WordShell.makeWordShell_EN(result_index, result_mainpart);

    let rendered_html_string = wordshell.toHtmlString(CustomKey);
    let rendered_keys_string = CustomKey != null ? CustomKey : wordshell.keys.join(" / ");
    ResultObject.result_mean = rendered_keys_string;
    ResultObject.result_mainpart = rendered_html_string;

    return ResultObject;
  },
  //fetch WorShell
  makeResultsForFetchWordShell : function (ResultObject){
    let result_index = ResultObject.result_index;
    let result_mainpart = ResultObject.result_mainpart;

    //- 提取 Keys 和 MainpartHtmlString
    if (Settings.getLanguage() === "en"){
      let wordshell = WordShell.makeWordShell_EN(result_index, result_mainpart);
      return wordshell;
    }else{
      let wordshell = WordShell.makeWordShell_FR(result_index, result_mainpart);
      return wordshell;
    }
  },


  //🍋GoogleSearch
  makeResultForGoogleSearch:function(SearchText, data){
    let results = data;
    if (results != null && results['result_code']=='success'){
      return ResultMaker.ResultObjectModel.initFromValues(
        null,                   // dizio_name,
        SearchText,             // searching,
        SearchText,             // result_index,
        results['translation'], // result_mean,
        null                    // result_mainpart
      )
    }
    return null;
  },


  //🍋Mapper
  refineDizioName : function (DizioName) {
    let maps = {
      "意汉汉意词典109910单词":"意汉汉意词典",
      "1000 Italian Proverbs":"变位表",
      "《意大利语同义词辨析词典》":"近义词辨析",
      "意大利语动词搭配大全":"动词搭配讲解"
    }
    if (maps[DizioName] !== undefined){
      return maps[DizioName];
    }
    return DizioName;
  },

};
export default ResultMaker;