import React, { useContext, useRef, useState } from 'react';
import {
  IonBackButton, IonButtons, IonButton, IonHeader,
  IonToolbar, IonTitle, IonContent, IonPage,
  IonList, IonItem, IonLabel, IonInput, IonLoading, IonModal, IonIcon
} from '@ionic/react';
import {arrowBack} from "ionicons/icons";
import { Plugins } from '@capacitor/core';

import Keys from "../tools/Keys.js";
import {closePayPage, useAppState, useAppStateDispatch} from "../AppState";
import {IframeWrapper, IframeWrapperDelegate} from '../tools/uikit/IframeWrapper.jsx';
import {Settings} from "../tools/Settings";

// import {TabNotesReload} from "./tabs/TabNotes";
// import {TabDiscoverReload} from "./tabs/TabDiscover";

const {  Browser } = Plugins;

export function logout() {
  // window.top.DataController.setUserEmail(null);
  // window.top.DataController.setNickName(null);
  //
  // TabNotesReload();
  // TabDiscoverReload();
}

const PayPage = () => {

  const state = useAppState();
  const dispatch = useAppStateDispatch();

  const delegate = IframeWrapperDelegate(
    function (){
      //console.log("🐞IframeJSX.dom receive delegate!');
    },
    function (){

    },
    function (){

    },
    function (){

    },
    function (CallBackName, Objects){
      //console.log("🐞PayPage receive calldevice:", CallBackName, Objects);
      if (CallBackName == "ReveivePayments"){
        let Dict = Objects;
        if (Dict["user_email"] && Dict["user_email"] == Settings.getUserEmail()){
          let user_email = Dict["user_email"];
          let shared = Dict["has_shared"];
          let mobile_mul_payments = Dict["mobile_mul_payments"];
          let days_remain = mobile_mul_payments["days"];

          Settings.setHasShared(shared);
          Settings.setDaysRemain(days_remain);
          //console.log("🐞:',{days: Settings.getDaysRemain(),shared:Settings.getHasShared()})
        }
      }

      //"yapilaTrade" – "https://iofange.com/pay/alipay_trade?user_name=test_pay_001%40fange.it&product=Mobile-FangeCoCo&device=ionic&language=it&version=1.4"
      if (CallBackName == "yapilaTrade"){
        Browser.open({ url: Objects });
      }
    }
  );

  return (
    <IonModal
      isOpen={state.pay_page_state}
      cssClass='my-custom-class'
      swipeToClose={true}
      onDidDismiss={() => closePayPage(dispatch)}>

      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton fill="clear" onClick={() => closePayPage(dispatch)}>
                <IonIcon icon={arrowBack} className="color-dark"/>
              </IonButton>
            </IonButtons>
            <IonTitle></IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <br />
          <IonLabel color="dark" class="ion-padding">正在连接...</IonLabel>
          <br />
          <IframeWrapper src={Keys.Url.page_pay+"&user_email="+Settings.getUserEmail()} delegate={delegate}/>
        </IonContent>
        {/*<IonHeader>*/}
        {/*    <IonToolbar color="light">*/}
        {/*        <IonButtons slot="start">*/}
        {/*            <IonBackButton defaultHref={`/`} />*/}
        {/*        </IonButtons>*/}
        {/*        <IonTitle>PayPage</IonTitle>*/}
        {/*    </IonToolbar>*/}
        {/*</IonHeader>*/}
        {/*<IonContent className="form">*/}
        {/*    LOGIN PAGE*/}
        {/*</IonContent>*/}
      </IonPage>
    </IonModal>
  );
};

export default PayPage;
