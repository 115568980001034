import React, { useContext, useRef, useState } from 'react';
import {
  IonBackButton, IonButtons, IonButton, IonHeader,
  IonToolbar, IonTitle, IonContent, IonPage,
  IonList, IonItem, IonLabel, IonInput, IonLoading, IonModal, IonIcon
} from '@ionic/react';
import {arrowBack} from "ionicons/icons";

import Keys from "../tools/Keys.js";
import {Settings} from "../tools/Settings.js";
import {closeLoginPage, closePayPage, useAppState, useAppStateDispatch} from "../AppState";
import {IframeWrapper, IframeWrapperDelegate} from '../tools/uikit/IframeWrapper.jsx';
import {LimitFunction} from "../tools/Settings";
import Alert from "../tools/Alert";
import StudyDataController from "../tools/StudyDataController";

// import {TabNotesReload} from "./tabs/TabNotes";
// import {TabDiscoverReload} from "./tabs/TabDiscover";

export function logout() {
  Settings.setUserEmail(null);
  Settings.setNickName(null);

  if (window.top.TabStudy && window.top.TabStudy.refetchAllDataThenRedisplay){
    window.top.TabStudy.refetchAllDataThenRedisplay(true);
  }

  if (window.top.reloadUserInfo){
      window.top.reloadUserInfo();
  }
}

const LoginPage = () => {

  const state = useAppState();
  const dispatch = useAppStateDispatch();

  const delegate = IframeWrapperDelegate(
    function (){
      //console.log("🐞IframeJSX.dom receive delegate!');
    },
    function (){

    },
    function (){

    },
    function (){

    },
    function (CallBackName, Objects){
      //console.log("🐞Login receive calldevice:', CallBackName, Objects);
      if (CallBackName == "OnLoginResult" || CallBackName == "OnRegistResult"){
        if (Objects){
          //console.log("🐞🍋Login success!');

          Settings.setUserEmail(Objects.user_email);
          Settings.setNickName(Objects.nick_name);

          //todo: checkpayment while login & ask sync
          // if (LimitFunction.canUse(null, null, false)){
          //   if (window.top.TabStudy && window.top.TabStudy.refetchAllDataThenRedisplay){
          //     Alert.showConfirmAlert("欢迎回到方格单词","似乎在服务器有一些您的学习数据, 同步一下吗?", false, "现在同步", function () {
          //       StudyDataController.trySyncStudyData(true, function () {
          //         //reload
          //         if (window.top.TabStudy){
          //             window.top.TabStudy.refetchAllDataThenRedisplay(false);
          //         }
          //       });
          //
          //       //@Depracated!
          //       //window.top.TabStudy.refetchAllDataThenRedisplay(false);
          //     },"暂不");
          //   }
          // }

          if (window.top.reloadUserInfo){
              window.top.reloadUserInfo();
          }

          closeLoginPage(dispatch);
        }else{
          alert('Something wrong...');
        }
      }
    }
  );

  return (
    <IonModal
      isOpen={state.login_page_state}
      cssClass='my-custom-class'
      swipeToClose={true}
      onDidDismiss={() => closeLoginPage(dispatch)}>

      {/*<IonPage>*/}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" onClick={() => closeLoginPage(dispatch)}>
              <IonIcon icon={arrowBack} className="color-dark"/>
            </IonButton>
          </IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <br />
        <IonLabel color="dark" class="ion-padding">正在连接...</IonLabel>
        <br />

        <IframeWrapper src={Keys.Url.page_login} delegate={delegate}/>

      </IonContent>

        {/*<IonHeader>*/}
        {/*    <IonToolbar color="light">*/}
        {/*        <IonButtons slot="start">*/}
        {/*            <IonBackButton defaultHref={`/`} />*/}
        {/*        </IonButtons>*/}
        {/*        <IonTitle>Login</IonTitle>*/}
        {/*    </IonToolbar>*/}
        {/*</IonHeader>*/}
        {/*<IonContent className="form">*/}
        {/*    LOGIN PAGE*/}
        {/*</IonContent>*/}
      {/*</IonPage>*/}
    </IonModal>
  );
};

export default LoginPage;
