import $ from "jquery";

const MenuController = {

  mMenuWrapper:null,

  //mask
  showMask(){
    //console.log("🐞show mask:",MenuController.mMenuWrapper);
    if (MenuController.mMenuWrapper == null){
      $(window.top.document.body).append("<div class='mask'></div>");
    }
  },
  hideMask(){
    $(window.top.document.body).find(".mask").remove();
    $(".mask").remove();
  },

  addMenu(
    Context,
    ClassName,
    Style,
    OnCreatedMenu,
    RightClick = false,
    MenuDelegate = null,
    MenuWrapper = null,
  ){
    //console.log('MenuDelegate:',MenuDelegate);

    //click button to ShowMenu
    let event_name = RightClick ? 'contextmenu':'click';
    Context.off(event_name);
    Context.on(event_name,function (e) {

      e.stopPropagation();
      e.preventDefault();

      MenuController.mMenuWrapper = MenuWrapper;

      if (Context.closest('body').find('.dropdown-menu').length > 0){
        //remove mask
        MenuController.hideMask();
        //remove menu
        Context.closest('body').find(".dropdown-menu").remove();
        //remove toggle
        $(".toggle").removeClass('toggle');

        //delegate
        if (MenuDelegate != null){
          MenuDelegate.menuDidDisappear();
        }
      }else{
        //delegate
        if (MenuDelegate != null){
          MenuDelegate.menuDidAppear();
        }

        let menu = $('<ul class="dropdown-menu show scroller-hidden" role="menu"></ul>');
        if (ClassName != null){
          menu.addClass(ClassName);
        }
        if (Style != null){
          menu.attr('style',Style);
        }

        Context.closest('body').find(".dropdown-menu").remove();

        //add mask
        MenuController.showMask();
        //add toggle
        Context.addClass('toggle')
        //add menu
        if (MenuWrapper != null){
          MenuWrapper.append(menu);
        }else{
          //@Depracated!
          //Context.append(menu);

          Context.closest('body').append(menu);
        }

        //callback for add menu items
        OnCreatedMenu (menu);
      }
    });

    //click body to DismissMenu
    Context.closest('body').click(function () {
      //remove mask
      MenuController.hideMask();
      //remove menu
      Context.closest('body').find(".dropdown-menu").remove();
      //remove toggle
      $(".toggle").removeClass('toggle');

      //delegate
      if (MenuDelegate != null){
        MenuDelegate.menuDidDisappear();
      }
    });

    return {
      showMask:MenuController.showMask,
      hideMask:MenuController.hideMask,
      dismiss(){
        //remove mask
        MenuController.hideMask();
        //remove menu
        Context.closest('body').find(".dropdown-menu").remove();
        //remove toggle
        $(".toggle").removeClass('toggle');
        //delegate
        if (MenuDelegate != null){
          MenuDelegate.menuDidDisappear();
        }
      }
    }
  },
  addMenuItem(
    Menu,
    ClassName,
    HtmlContent,
    OnClick,
    MultiSelect = false,
  ){
    let menu_item = $('<li class="dropdown-item"><a ></a></li>');

    menu_item.addClass(ClassName);
    menu_item.html(HtmlContent);

    Menu.append(menu_item);

    menu_item.off('click');
    menu_item.on('click',function (e) {
      e.stopPropagation();

      if (!MultiSelect){
        //remove mask
        MenuController.hideMask();
        //remove toggle
        $(".toggle").removeClass('toggle');
        //remove menu
        Menu.remove();
        $(".dropdown-menu").remove();
      }

      OnClick(menu_item);
    });

    return menu_item;
  },
  addMenuItemSubMenu(
    Menu,
    ClassName,
    Title,
    OnCreatedMenu
  ){
    let menu_item = $('<li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle">'+Title+'</a><ul class="dropdown-menu"></ul></li>');
    menu_item.addClass(ClassName);
    Menu.append(menu_item);
    OnCreatedMenu(menu_item.find('.dropdown-menu'));
    return
  },

  MenuDelegate : function (
    menuDidAppear,
    menuDidDisappear
    //function menuDidAppear(){}
    //function menuDidDisappear(){}
  ) {
    return {
      menuDidAppear : menuDidAppear,
      menuDidDisappear : menuDidDisappear
    };
  }

};
export default MenuController;