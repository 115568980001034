import $ from "jquery";
import iSlider from "islider.js"

let islider;
let mContextID;
let mDatasource;
let mDelegate;

let mCurrentPageIndex = 0;
let mCurrentDataIndex = 0;

const PagesController = {
  init:function (ContextID, InitDataIndex, DataSource, Delegate) {
    mCurrentPageIndex = 0;
    mCurrentDataIndex = InitDataIndex;
    mContextID = ContextID;
    mDatasource = DataSource;
    mDelegate = Delegate;
    islider = new iSlider({
      //config
      dom:document.getElementById(ContextID),
      isLooping:false,
      isTouchable:true, //允许卡片左右滑动
      fixPage:false,    //需要关闭,否则页面无法上下滑动
      initIndex: 0,

      //datasource
      data:[
        mDatasource.viewControllerAtIndex(InitDataIndex),
        mDatasource.viewControllerAfter(InitDataIndex)
      ],

      //delegate
      onInitialized:function() {
        setTimeout(function () {
          mDelegate.didScrollToPageAtIndex(mCurrentDataIndex);
        },500);
      },
      onSlideChanged:function (ThisPageIndex) {

        //Current
        mCurrentPageIndex = ThisPageIndex;
        mCurrentDataIndex = islider.data[ThisPageIndex].index;

        setTimeout(function () {
          mDelegate.didScrollToPageAtIndex(mCurrentDataIndex);
        },200);


        //add Next
        if (mCurrentPageIndex == islider.data.length-1){            //滑动到最后一页
          islider.pushData(                                       //添加下一页
            mDatasource.viewControllerAfter(mCurrentDataIndex)
          )
        }
      }
    });
  },

  //methods
  reload:function(){
    $("#"+mContextID).empty();
    PagesController.init(mContextID, mCurrentDataIndex, mDatasource, mDelegate);
  },
  skipTo:function(SkipToDataIndex){
    $("#"+mContextID).empty();
    PagesController.init(mContextID, SkipToDataIndex, mDatasource, mDelegate);
  },
  slideNext:function(){
    islider.slideNext();
  },
  slideNextWithNewPage:function(PageContent){
    //splice data
    let page_data = {
      index: mCurrentDataIndex,
      content: PageContent
    }
    islider.data.splice(mCurrentPageIndex+1, 0, page_data);

    //load data
    islider.loadData(islider.data, mCurrentPageIndex+1);

    //triger delegate
    setTimeout(function () {
      mDelegate.didScrollToPageAtIndex(mCurrentDataIndex);
    },500);
  },

  //delegate
  Delegate : function (didScrollToPageAtIndex) {
    return {
      didScrollToPageAtIndex : didScrollToPageAtIndex
    }
  },
  DataSource : function (Total, NavigationControllerAtIndex) {
    //@optimized interface
    return {
      viewControllerAtIndex : function(Index) {
        let content = NavigationControllerAtIndex(Index);
        return {
          index: Index,
          content: content
        }
      },
      viewControllerAfter : function (Index) {
        let next_index = Index+1;
        if (next_index >= Total){
          next_index = 0;
        }
        return this.viewControllerAtIndex(next_index);
      }
    }
  }
};
export default PagesController;