import React, {useEffect, useRef} from 'react';
import './TabStudy.css';

import $ from "jquery";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon } from '@ionic/react';
import StudyDataController from '../tools/StudyDataController.js'
import Alert from "../tools/Alert";

import ExploreContainer from '../components/ExploreContainer';
import UITableView from "../tools/uikit/UITableView.js"
import Curve from "./studyplan/Curve/Curve";
import {Settings} from "../tools/Settings.js";
import {
  bookOutline,
  albumsOutline,
  libraryOutline,
  syncOutline,
  chevronForward,
  chevronDown,
  heart,
  ellipsisVertical
} from "ionicons/icons/index";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonFab,
  IonFabButton,
  IonImg,
  IonItem,
  IonLabel,
  useIonViewDidEnter
} from "@ionic/react";
import {IframeNavPresentPage} from "../tools/uikit/IframeNav";
import WordsTableView from "./studyplan/WordsTableView";
import ListViewController from "./studyplan/ListViewController";
import {LimitFunction} from "../tools/Settings";
import FireCards from "./elements/FireCards";
import {presentMenuAddList} from "./elements/MenuAddList";

var instance = {
  showUP : function () {

  }
};
Object(window).top.TabStudy = instance;


//🍉Study Data
let mTempBehavior = {};
let mTempMemoStack = {};
function tryRefreshPlanData(){
  //初始化
  StudyDataController.studyTempLearningWords = []
  StudyDataController.studyTempTodayWords = []
  StudyDataController.studyTempTodayUntrackedWords = []
  StudyDataController.studyTempTodayDangerWords = []
  mTempBehavior = {}

  //单词总量
  var memo_stack = {
    "total": StudyDataController.allNewAddWordsUnique.length,
    "memo_start":0,
    "memo_known":0,
    "memo_familiar":0,
    "memo_danger":0,
    "memo_untracked":0
  }
  // for (let word of StudyDataController.allNewAddWords){
  //   //get stack by memo_mode
  //   memo_stack[word.MemoModel.MemoType] ++;
  //   mTempMemoStack = memo_stack;
  //   //console.log("🐞🍋memo_stack:",memo_stack);
  // }

  //今日单词
  var memo_behaviour = {};
  memo_behaviour[Curve.MemoBehaviour.start_memo] = 0;
  memo_behaviour[Curve.MemoBehaviour.repeat_memo] = 0;

  var memo_behaviour_total = 0;
  var memo_behaviour_words = [];

  var danger_words = []
  var known_words = []
  var untracked_words = []

  for (let word of StudyDataController.allNewAddWordsUnique){

    let memo_string = word.MemoModel.MemoType

    //get stack by memo_mode
    memo_stack[memo_string] ++;
    mTempMemoStack = memo_stack;

    //get all learning words
    if (memo_string != "memo_untracked"){
      StudyDataController.studyTempLearningWords.push(word)
    }

    //get behaviour by mode_curve
    if (word.curve_points != null){
      let behaviour = null;
      let word_curve = JSON.parse(word.curve_points);
      if (word_curve != null){
        behaviour = Curve.curveFineMemoBehaviour(word_curve)
      }
      if (behaviour != null){
        memo_behaviour[behaviour] = memo_behaviour[behaviour] + 1
        memo_behaviour_total += 1
        memo_behaviour_words.push(word)
      }
    }

    //get words by mode_curve
    if (memo_string == "memo_danger"){
      danger_words.push(word)
    }
    if (memo_string == "memo_untracked"){
      untracked_words.push(word)
    }
    if (memo_string == "memo_known" || memo_string == "memo_start"){
      known_words.push(word)
    }
  }
  mTempBehavior = memo_behaviour
  //console.log("🐞🍋danger_words:",danger_words.length);

  var today_danger_words = []
  var today_known_words = []
  var today_untracked_words = []

  //1.get today danger words
  for (let word of danger_words.filter(function (danger_word) {
    return memo_behaviour_words.includes(danger_word) == false;
  })){
    if ((
      today_danger_words.length +
      today_untracked_words.length +
      today_known_words.length +
      memo_behaviour_words.length
    ) < Settings.getUpperLimit()*6
      // @Depracated: Settings.getUpperLimit(); //限制每日复习数(=目标新词)
      // @New: Settings.getUpperLimit()*6       //限制每日复习数(=目标新词*6)
    ){
      if (memo_behaviour_words.includes(word) == false){
        today_danger_words.push(word)
      }
    }
  }

  //2.get today untraked words
  // @Depracated: Settings.getUpperLimit()/6 限制每日新词数(=目标新词/6)
  // @New: Settings.getUpperLimit()          限制每日新词数(=目标新词)
  let today_untracked_words_uppper_limit = Settings.getUpperLimit() - memo_behaviour[Curve.MemoBehaviour.start_memo];
  if (today_untracked_words_uppper_limit > 0){
    for (var i=0; i<today_untracked_words_uppper_limit; i++){
      if (
        // @Depracated!
        // (
        //   today_danger_words.length +
        //   today_untracked_words.length +
        //   today_known_words.length +
        //   memo_behaviour_words.length
        // ) < Settings.getUpperLimit()
        // &&
        i < untracked_words.length
      ){
        today_untracked_words.push(untracked_words[i])
      }
    }
  }
  //3.get today known words
  let today_known_words_uppper_limit = Settings.getUpperLimit() - (today_danger_words.length + today_untracked_words.length + memo_behaviour_words.length)
  if (today_known_words_uppper_limit > 0){
    for (var i=0; i<known_words.length; i++){
      if (today_known_words.length < today_known_words_uppper_limit){
        if (memo_behaviour_words.includes(known_words[i]) == false){
          today_known_words.push(known_words[i]);
        }
      }
    }

    //@Depracated!
    // for (var i=0; i<today_known_words_uppper_limit; i++){
    //   if ((
    //       today_danger_words.length +
    //       today_untracked_words.length +
    //       today_known_words.length +
    //       memo_behaviour_words.length
    //     ) < Settings.getUpperLimit()
    //     &&
    //     i < known_words.length
    //   ){
    //     if (memo_behaviour_words.includes(known_words[i]) == false){
    //       today_known_words.push(known_words[i]);
    //     }
    //   }
    // }
  }
  //4.
  var memo_behaviour_words_first = []
  var memo_behaviour_words_last = []
  for (let one of memo_behaviour_words){
    let memo_type = one.MemoModel.MemoType;
    if (memo_type == "memo_danger" || memo_type == "memo_untracked"){
      memo_behaviour_words_first.push(one)
    }else{
      memo_behaviour_words_last.push(one)
    }
  }
  //console.log("🐞🍋today:",{"danger":today_danger_words, "untracked":today_untracked_words, "known":today_known_words, "behaviour":memo_behaviour_words,})

  //all.
  StudyDataController.studyTempTodayWords = today_danger_words.concat(memo_behaviour_words_first).concat(today_untracked_words).concat(today_known_words).concat(memo_behaviour_words_last);
  StudyDataController.studyTempTodayUntrackedWords = today_untracked_words;
  StudyDataController.studyTempTodayDangerWords = today_danger_words;
  //console.log("🐞studyTempTodayWords:",StudyDataController.studyTempTodayWords);
}

//.sync
var mHasAutoSync = false;
var mIsFirstFetchData = true;


function refetchAllDataThenRedisplay(FromCache) {

  startSyncingAnimation();//🔥

  //study data
  StudyDataController.refetchAllStudyData(FromCache, function () {

    stopSyncingAnimation();//🔥

    //同步完成
    if (FromCache == false){
        //@Depracated: 同步有自己的 alert
        //Alert.showSuccessAlert('同步完成','','',true);
    }
    //本地获取完成
    else{
      if (mIsFirstFetchData && StudyDataController.studyLists.length <= 0){
        //todo:Alert.showWarningAlert("似乎还没有单词本哦","您可以通过顶部的按钮来同步云端数据或下载单词本","知道啦");
      }
    }

    //redisplay
    refreshPlanDataThenDisplay();

    //@Depracated: redisplayListsTableView();

    //auto sync
    if (mHasAutoSync==false){
      mHasAutoSync = true;
      //@pause:
      // setTimeout(function () {
      //     refetchAllDataThenRedisplay(false);
      // },10);
    }

    //mark first fetch
    mIsFirstFetchData = false;
  });
}
instance.refetchAllDataThenRedisplay = refetchAllDataThenRedisplay;


//🍉Study UI
var mWordsTableViewRef, setWordsTableViewRef = function(tableview){
    mWordsTableViewRef = tableview;
};
function refreshPlanDataThenDisplay() {
  tryRefreshPlanData();

  //fire
  // if (StudyDataController.studyTempTodayWords.length > 0){
  //   $(".section-fire").removeClass('hidden');
  // }else{
  //   $(".section-fire").addClass('hidden');
  // }

  //display tableview
  if (mWordsTableViewRef){

      mWordsTableViewRef.reloadTableView(
        {section:-4, row:0},
        StudyDataController.getListBy(-4,0),
        StudyDataController.getWordsBy(-4,0));
  }

  //display listview
  window.top.ListViewController.reloadPage();

  //display progress bar
  $(".bars").html("");
  let total = mTempMemoStack['total'];
  let memo_known = mTempMemoStack['memo_start']+ mTempMemoStack['memo_known'];
  let memo_familiar = mTempMemoStack['memo_familiar'];
  let memo_danger = mTempMemoStack['memo_danger'];
  let memo_untracked = mTempMemoStack['memo_untracked'];
  if (total < memo_known+memo_familiar+memo_danger){
    total = memo_known+memo_familiar+memo_known;}
  if (total > 0){
    $(".total .num").text(total);

    $(".memo_known .num").text(memo_known);
    if (memo_known > 0){
      $(".bars").append('<div class="memo_known" style="width: '+(memo_known/total)*100+'%;"></div>')
    }

    $(".memo_danger .num").text(memo_danger);
    if (memo_danger > 0){
      $(".bars").append('<div class="memo_danger" style="width: '+(memo_danger/total)*100+'%;"></div>')
    }

    $(".memo_familiar .num").text(memo_familiar);
    if (memo_familiar > 0){
      $(".bars").append('<div class="memo_familiar" style="width: '+(memo_familiar/total)*100+'%;"></div>')
    }

    $(".memo_untracked .num").text(memo_untracked);
    if (memo_untracked > 0){
      $(".bars").append('<div class="memo_untracked" style="width: '+(memo_untracked/total)*100+'%;"></div>')
    }
  }

  //display books info
  if (StudyDataController.studyBooks.length > 0){
    $(".book-info").fadeIn(0);
    $(".book-name").text(StudyDataController.studyBooks[0].list_name);
  }else{
    $(".book-info").fadeOut(0);
  }
  $(".list-num").text(StudyDataController.studyLists.length);

  //display today info
  //console.log("🐞mTempBehavior:",mTempBehavior);
  let upperLimit = Settings.getUpperLimit();
  $(".upper-limit").text(upperLimit);

  $(".today-start .num").html(
    mTempBehavior.start+
    "<span class='separator'> | </span>"+
    "<span class='target'>"+(StudyDataController.studyTempTodayUntrackedWords.length+mTempBehavior.start)+"</span>"
  );
  $(".today-repeat .num").html(
    mTempBehavior.repeat+
    "<span class='separator'> | </span>"+
    "<span class='target'>"+(StudyDataController.studyTempTodayDangerWords.length+mTempBehavior.repeat)+"</span>"
  );
  $(".today-total .num").html(
    mTempBehavior.start+mTempBehavior.repeat+
    "<span class='separator'> | </span>"+
    "<span class='target'>"+StudyDataController.studyTempTodayWords.length+"</span>"
  );

  //primaryList
  let primaryIndex = Settings.getPrimaryListIndexPath();
  let primaryList = primaryIndex != null ? StudyDataController.getListBy(primaryIndex.section, primaryIndex.row) : null;
  if (primaryList != null){
    let list_name = primaryList.list_name;
    if (primaryList.parent_folder){
      list_name = primaryList.parent_folder.list_name + " - " + list_name;
    }
    $(".primary-list-name").text("优先学习 "+list_name);
    $(".page-study .primary-list-wrapper").removeClass('hidden');
  }else{
    $(".primary-list-name").text("暂无优先学习的单词列表");
    $(".page-study .primary-list-wrapper").addClass('hidden');
  }


  // //list-all
  // $("#list-all .memo_stack").empty();

  // let memo_stack = mTempMemoStack;
  //
  // //console.log("🐞memo_stack:",memo_stack);
  //
  //
  // let total = memo_stack['total'];
  // let learning_stack = Object.clone(memo_stack);
  // let learning_total =
  //   learning_stack['memo_start']+
  //   learning_stack['memo_known']+
  //   learning_stack['memo_familiar']+
  //   learning_stack['memo_danger'];
  // delete learning_stack['total'];
  // delete learning_stack['memo_untracked'];

  // $.each(learning_stack, function (key, value) {
  //   let stack_item = $("<span class='stack_item'></span>");
  //   stack_item.attr('title', key);
  //   stack_item.css({
  //     'width':(value/total)*100 + "%",
  //     'background-color':ColorSuits['standard-red'][key]
  //   });
  //   $("#list-all .memo_stack").append(stack_item);
  // });
  // $("#list-all .list_num").text(learning_total + " / " + total);
  //
  // //list-today
  // $("#list-today .today_plan_label").text(
  //   memo_behaviour_total + "已学习 / " + StudyDataController.studyTempTodayWords.length + "计划学习"
  // )
  // if (memo_behaviour_total >= StudyDataController.studyTempTodayWords.length && memo_behaviour_total > 0){
  //   $("#list-today .list_name").text("已完成");
  //   $("#list-today .today_plan_label").addClass('finish');
  // }else{
  //   $("#list-today .list_name").text("今日学习");
  //   $("#list-today .today_plan_label").removeClass('finish');
  // }
}

//🍋action sync
function setupActionSync(){
  $(".action.sync").click(function () {
    StudyDataController.trySyncStudyData(true, function () {
      //reload
      refetchAllDataThenRedisplay(false);
    });

    //@Depracated!
    // startSyncingAnimation();//🔥
    // //upload
    // StudyDataController.tryUploadStutyData(function (success, reason) {
    //   //fail
    //   if(success != true){
    //     stopSyncingAnimation();//🔥
    //     Alert.showWarningAlert("同步未完成",reason,"好的");
    //     return;
    //   }
    //
    //   //success
    //   StudyDataController.clearAllRecords();
    //
    //   //download
    //   refetchAllDataThenRedisplay(false);
    // });
  })
}
function startSyncingAnimation() {
  $(".action.sync").addClass('ing');
}
function stopSyncingAnimation() {
  $(".action.sync").removeClass('ing');
}

//🍋fire words
function setupFireWords(){
  // var wordIndex = 0;
  // var cardIndex = 0;
  // setInterval(function () {
  //   if (
  //     !StudyDataController.studyTempTodayWords ||
  //     StudyDataController.studyTempTodayWords.length == 0 ||
  //     $(".fire-words .cf-flame").length == 0
  //   ){
  //     return;
  //   }
  //
  //   wordIndex ++; if (wordIndex >= StudyDataController.studyTempTodayWords.length){wordIndex = 0;}
  //   cardIndex ++; if (cardIndex >= $(".fire-words .cf-flame").length){cardIndex = 0;};
  //   let word = StudyDataController.studyTempTodayWords[wordIndex];
  //   //console.log("🐞update:",wordIndex,cardIndex);
  //   $(".fire-words .cf-flame").eq(cardIndex).html(
  //     "<span>"+word.word_string+"</span>"
  //   )
  // },2000);
};


//🍉init

//@LifeCycle
function viewDidAppear() {
  //console.log("🐞TabStudy viewDidAppear");

  //set listview mode
  window.top.ListViewController.setMode('MODE-NORMAL');

  //redisplay
  refetchAllDataThenRedisplay(true);
}
window.top.TabStudy.viewDidAppear = viewDidAppear;

const TabStudy = () => {

  useEffect(function () {

    //setup
    setupActionSync();
    setupFireWords();

    //reload
    refetchAllDataThenRedisplay(true);

    //🚫test
    setTimeout(function () {
      // ListViewController
      // window.top.ListViewController.showUP();

      // WordsViewController
      // window.top.WordsViewController.showUP(0, 1);

      // CardsViewController
      // window.top.mCardsViewController.showUP(
      //   StudyDataController.getListBy(0,0),
      //   StudyDataController.getWordsBy(0,0)
      // );

      // BooksViewController
      // window.top.mBookViewController.showUP();

      // if (window.top.MdxService.mMdxCaches.length <= 0 || window.top.mMdxObjects.length <= 0){
      //   if (window.top.MdxService.mMdxCaches.length <= 0){
      //     Alert.showSetupGuide();
      //   }else{
      //     Alert.showEmptyDizioAlert();
      //   }
      // }

      // setTimeout(function () {
      //   window.top.SearchDetail.showFragment($("#searchdetail-div"),"andare","走");
      // },5000);
      // setTimeout(function () {
      //   window.top.SearchDetail.showUP("parlare","说话");
      //   // window.top.SearchDetail.showUP("andare","走");
      //   // window.top.SearchDetail.showUP("volare","飞");
      //   // window.top.SearchDetail.showUP("partire","出发");
      // },5000);
      // setTimeout(function () {
      //   window.top.SearchDetail.showUP("andare","走");
      // },10000);
      // if (window.top.SearchDetail){
      //   window.top.SearchDetail.showUP("parlare","说话");
      // }

    },1000);
  });

  useIonViewDidEnter(() => {
    viewDidAppear();
  });

  return (
    <IonPage className="page-study">
      <IonContent fullscreen>
        <div className="safe-area-top">
          {/*我的库存*/}
          <div className="section section-repo">
            <div className="section-title">
            <span className="label">
              我的库存

              <span className="action sync">
                {/*<img className="icon" src="assets/listsvcor-actions/sync.png"/>*/}
                <IonIcon className="icon" icon={syncOutline}/>
              </span>
            </span>


              {/*<IonButtons className="buttons-group">*/}
                {/*<IonButton size="small" shape="round" onClick={()=>{*/}

                {/*}}>添加</IonButton>*/}
                {/*<IonButton size="small" shape="round">列表</IonButton>*/}
              {/*</IonButtons>*/}

              <div className="button light" size="small" color="light" shape="round" onClick={()=>{

                //🔐禁止未登录
                if (LimitFunction.isLogin('default') != true){
                  return;
                }

                presentMenuAddList(true);

              }}>添加</div>

              {/*<IonButton size="small" color="light" shape="round">列表</IonButton>*/}
            </div>

            <div id="chart_wrapper" className="section-content" onClick={()=>{
              if (window.top.ListViewController){
                window.top.ListViewController.showUP();
              }
            }}>
              <div className="books-wrapper">
                <span className="book-info">
                  <IonIcon icon={libraryOutline}/><span className="label book-name">单词书</span>
                </span>
                  <span className="list-info">
                  <IonIcon icon={albumsOutline}/><span className="label"><span className="list-num">0</span>个单词本</span>
                </span>
                <span className="total">共<span className="num">0</span>个单词</span>
              </div>

              <div className="infos_div">
                <div className="bars-wrapper">
                  <div className="bars">
                  </div>
                </div>

                <div className="info">
                  {/*<span className="info memo_num">总共 <span className="num">3456</span> 个单词</span>*/}
                  {/*<span className="sub_info memo_start"><span className="num">0</span> 开始记忆</span>*/}
                  <span className="sub_info memo_known"><span className="num">0</span> 已学习</span>
                  <span className="sub_info memo_danger"><span className="num">0</span> 需要复习</span>
                  <span className="sub_info memo_familiar"><span className="num">0</span> 已牢记</span>
                  <span className="sub_info memo_untracked"><span className="num">0</span> 未学习</span>
                </div>
              </div>
            </div>

            <div className="all-lists-wrapper">

              <div className="cell" onClick={()=>{
                // if (window.top.ListViewController){
                //   window.top.ListViewController.showUP();
                // }

                //Function to animate height: auto
                // function autoHeightAnimate(element, time){
                //   var curHeight = element.height(), // Get Default Height
                //     autoHeight = element.css('height', 'auto').height(); // Get Auto Height
                //   element.height(curHeight); // Reset to Default Height
                //   element.stop().animate({ height: autoHeight }, time); // Animate to Auto Height
                // }

                $(".all-lists-wrapper").toggleClass('expanding');

                // var wrapper = $(".all-lists-wrapper .listview-wrapper"),
                //     animateTime = 200;
                // if ($(".all-lists-wrapper").attr('class').includes('expanding')){
                //   //autoHeightAnimate(wrapper, animateTime);
                //   //wrapper.fadeIn(animateTime);
                // }else{
                //   //wrapper.stop().animate({ height: '0' }, animateTime);
                //   //wrapper.fadeOut(animateTime);
                // }

              }}>
                <span className="title">所有列表</span>
                <IonIcon icon={chevronDown} />
              </div>

              <div className="icons-hidden">
                <span className="action action-heart template"><IonIcon icon={heart}/></span>
                <span className="action action-menu template"><IonIcon icon={ellipsisVertical}/></span>
              </div>
              <ListViewController fragment={true}/>

            </div>

            {/*<div className="total-progress-wrapper">*/}
            {/*<div className="progress">*/}
            {/*<span>单词总数</span>*/}
            {/*<span>4534</span>*/}
            {/*</div>*/}
            {/*<div className="progress">*/}
            {/*<span>已学习</span>*/}
            {/*<span>6%</span>*/}
            {/*</div>*/}
            {/*<div className="progress">*/}
            {/*<span>已牢记</span>*/}
            {/*<span>3%</span>*/}
            {/*</div>*/}
            {/*<div className="progress">*/}
            {/*<span>需要复习</span>*/}
            {/*<span>5%</span>*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>

          {/*<div className="section">*/}
          {/*<div className="list">*/}
          {/*23个列表*/}
          {/*</div>*/}
          {/*</div>*/}

          {/*每日学习数据*/}
          <div className="section section-today">
            <div className="section-title">

              <span className="label">每日学习<span className="upper-limit">50</span>新词</span>

              <div className="button light" size="small" color="light" shape="round" onClick={()=>{
                window.top.StudyPlanSetting.showUP();
              }}>修改计划</div>
            </div>
            <div className="section-description primary-list-wrapper hidden">
              <span className="primary-list-name">优先学习列表</span>
              <IonIcon icon={heart}/>
            </div>

            <div className="section-content">
              <div className="number-wrapper">
                <div className="today-start number">
                  <span className="num">0</span>
                  <span>新词</span>
                </div>
                <div className="today-repeat number">
                  <span className="num">0</span>
                  <span>复习</span>
                </div>
                <div className="today-total number">
                  <span className="num">0</span>
                  <span>今日已学</span>
                </div>
              </div>

            </div>
          </div>

          {/*今日单词*/}
          <div className="section section-tableview">
            <div className="section-content">

              <WordsTableView onRef={setWordsTableViewRef}/>

              <div id="tab-study-words-list">

              </div>
            </div>
          </div>

          {/*Fire*/}
          {/*<div className="section section-fire hidden">*/}
            {/*<FireCards/>*/}
          {/*</div>*/}

        </div>

      </IonContent>
    </IonPage>
  );
};

export default TabStudy;
