import $ from "jquery";
import {useEffect} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import {IframeNavPresentPage} from '../../tools/uikit/IframeNav'
import './StudyPlanSetting.css';
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react/dist/index";
import {pickerController} from '@ionic/core'

import {ellipsisHorizontal, chevronForward} from "ionicons/icons/index";
import NavBar from "../../components/NavBar";
import StudyDataController from "../../tools/StudyDataController";
import {Settings} from "../../tools/Settings";
import * as Tools from "../../tools/Tools";
import Alert from "../../tools/Alert";

//🍉instance
var instance = {
  showUP : function () {
    IframeNavPresentPage(StudyPlanSetting, "StudyPlanSetting", false);
  }
};
Object(window).top.StudyPlanSetting = instance;

//@LifyCycle
function viewDidAppear(){
  //console.log("🐞StudyPlanSetting viewDidAppear");

  //reload view
  //.upperLimit
  $(".upper-limit-num").text(Settings.getUpperLimit());
  //.primaryList
  let primaryIndex = Settings.getPrimaryListIndexPath();
  let primaryList = primaryIndex != null ? StudyDataController.getListBy(primaryIndex.section, primaryIndex.row):null;
  if (primaryList != null){
    let list_name = primaryList.list_name;
    if (primaryList.parent_folder){
      list_name = primaryList.parent_folder.list_name + " - " + list_name;
    }
    $(".primary-list-name").text(list_name);
  }else{
    $(".primary-list-name").text("暂无优先学习的单词列表");
  }
};
window.top.StudyPlanSetting.viewDidAppear = viewDidAppear;

//🍉
const multiColumnOptions = [
  [
    ['Minified', 5],
    ['Responsive', 4],
    ['Full Stack', 3],
    ['Mobile First', 2],
    ['Serverless', 1]
  ]
]

async function openPicker(columnOptions=multiColumnOptions){
  let numColumns = columnOptions.length;
  let numOptions = columnOptions[0].length;

  const picker = await pickerController.create({
    columns: getColumns(numColumns, numOptions, columnOptions),
    buttons: [
      {
        text: '取消',
        role: 'cancel'
      },
      {
        text: '确定',
        handler: (value) => {
          console.log(`Got Value ${value}`);
        }
      }
    ]
  });

  await picker.present();

  //customize option
  $.each($(picker).find(".picker-opts .picker-opt"), function (columnIndex) {
    let option = columnOptions[0][columnIndex];
    let option_dom = "<div class='option-content'>"+option[0]+"<span>词</span>"+option[1]+"<span>天</span></div>"
    let button = $(this);
    button.html(option_dom);
  });
}

function getColumns(numColumns, numOptions, columnOptions) {
  let columns = [];
  for (let i = 0; i < numColumns; i++) {
    columns.push({
      name: `col-${i}`,
      options: getColumnOptions(i, numOptions, columnOptions)
    });
  }
  return columns;
}

function getColumnOptions(columnIndex, numOptions, columnOptions) {
  let options = [];
  for (let i = 0; i < numOptions; i++) {
    // @Depracated!
    // let option = columnOptions[columnIndex][i % numOptions];
    // let option_dom = "<div class='option'>"+option[0]+"<span>词</span>"+option[1]+"<span>天</span></div>"

    options.push({
      text: '',
      value: i
    })
  }
  return options;
}



//🍉init
const StudyPlanSetting = () => {
  useEffect(() => {
    //console.log("🐞StudyPlanSetting ready!");
    viewDidAppear();
  });

  return (
    <IonPage id="StudyPlanSetting">
      <NavBar/>

      <IonContent className="StudyPlanSettingContent">
        <div className="content bg-watersmoke">
          {/*<div className="section-clear">*/}
            {/*<div className="title">选择单词书</div>*/}
            {/*<div className="buttons-group">*/}
              {/*<div className="button light action" id="action_close_book">停用</div>*/}
            {/*</div>*/}
            {/*<div className="card">*/}
              {/*<div className="cell">*/}
                {/*<span className="title">大学四级词汇</span>*/}
                {/*<IonIcon icon={chevronForward}/>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}

          <div className="section-clear">
            <div className="title">新词数</div>
            {/*<div className="description">*/}
              {/*总共<span className="total">3450</span>词 / <span className="memo_untracked">1234</span>未学习*/}
            {/*</div>*/}
            <div className="card">
              <div className="cell" onClick={()=>{

                Alert.showInputAlert(
                  "设置每日学习新词数量",             // Title,
                  "在记忆单词的过程中<br>需要复习的单词数量通常会达到新词的6倍左右哦",// HtmlMessage,
                  Settings.getUpperLimit(),   // DefaultValue,
                  "确定",                      // ActionText,
                  function (text) {           // OnFinish
                    if (Tools.isEmpty(text)==false){
                      let intValue = parseInt(text);
                      if (isNaN(intValue)==false){
                        Settings.setUpperLimit(intValue);
                        viewDidAppear();
                        return;
                      }
                    }
                    Alert.showWarningAlert(
                      "请输入有效的学习量哦",
                      "学习量是指您每天能浏览的单词单词数量<br>普通人每小时能够浏览100-300个单词<br>您可以根据自己的情况设定学习量<br>方格单词会自动为您安排新单词和复习单词",
                      "好的")
                  }
                )
              }}>
                <div>
                  <span>每日学习<span className="upper-limit-num">30</span>新词</span>
                  {/*<span>剩余20天完成</span>*/}
                </div>
                <IonIcon icon={chevronForward}/>
              </div>
            </div>
          </div>

          <div className="section-clear">
            <div className="title">优先学习列表</div>
            <div className="description">
              方格单词会为您优先安排列表的单词到学习计划
            </div>
            <div className="card">
              <div className="cell" onClick={()=>{
                if (window.top.ListViewController){
                  window.top.ListViewController.showUP("MODE-CHOOSE-PRIMARY-LIST");
                }
              }}>
                <span className="primary-list-name">暂无优先学习的单词列表</span>
                <IonIcon icon={chevronForward}/>
              </div>
            </div>
          </div>

          {/*<div className="section-clear">*/}
            {/*<div className="title">学习记录</div>*/}
            {/*<div className="description">*/}
              {/*重新开始新的学习 (不会删除单词)*/}
            {/*</div>*/}
            {/*<div className="card">*/}
              {/*<div className="cell" onClick={()=>{*/}
                {/*;*/}
              {/*}}>*/}
                {/*<span className="">清除学习记录</span>*/}
                {/*<IonIcon icon={chevronForward}/>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}


          {/*<div className="title">设置分组方式</div>*/}
          {/*<div className="tabs_wrapper">*/}
            {/*<div className="pills">*/}
              {/*<div className="pill active" title="number">数量分组</div>*/}
              {/*<div className="pill" title="alpha">字母分组</div>*/}
            {/*</div>*/}
            {/*<div className="tabs">*/}
              {/*<div className="card tab active" title="number">*/}
                {/*<div className="labels_stack">*/}
                  {/*<div className="label">每组单词</div>*/}
                  {/*<div className="label">组数</div>*/}
                {/*</div>*/}
                {/*<div className="picker_div"></div>*/}
              {/*</div>*/}
              {/*<div className="card tab" title="alpha">*/}
                {/*<div className="title">已按照单词首字母, 自动分组</div>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default StudyPlanSetting;
