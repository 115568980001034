import $ from 'jquery';

const SupportTools = {
  //🍋Http
  ajaxGet(Url, OnFinish){
    $.get(Url, function(data, status){
      console.log("status:",status);
      OnFinish(data, status);
    });
  },
};
export default SupportTools;