import $ from "jquery";
import React, {useState} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
/* Tools */
import {IframeWrapper, IframeWrapperDelegate} from "../../../tools/uikit/IframeWrapper.jsx";
import {IframeNavPresentPage, IframeNavPresent, IframeNavBack} from "../../../tools/uikit/IframeNav";
import Keys from '../../../tools/Keys.js';

import {ellipsisHorizontal} from "ionicons/icons";
import StudyDataController from "../../../tools/StudyDataController";
import Alert from "../../../tools/Alert";
import {useAppStateDispatch} from "../../../AppState";
import {IonActionSheet} from "@ionic/react/dist/index";


//PracticeCards
//===========================================

//.🍉shared vars
var instance = {
  mMode:"add-both",//add-both, add-list, switch-book
  showUP : function (Mode) {
    instance.mMode = Mode;

    let titles = {
      "add-both":"添加单词",
      "add-list":"添加单词本",
      "switch-book":"切换单词书"
    }
    let urls = {
      "add-both":Keys.Url.page_book(),
      "add-list":Keys.Url.page_book()+"&section-book=false",
      "switch-book":Keys.Url.page_book()+"&section-list=false",
    }

    IframeNavPresent({
      title:titles[Mode],
      url:urls[Mode],
      delegate:delegate,
      share:true,
    });
  },
  showBookWords : function (id){
    IframeNavPresent({
      title:"",
      url:Keys.Url.page_singlelist(id),
      delegate:null,
      share:true,
    });
  }
}
window.top.mBookViewController = instance;

//🍉.delagate
const delegate = IframeWrapperDelegate(
  // DidFinishLoad,
  function (sendMessage){
    delegate.sendMessage = sendMessage;
    sendMessage("SET-LISTS", StudyDataController.allNewAddLists);
  },

  // DidScroll,
  function (){

  },

  // DidAppear,
  function (){
    //console.log("🐞Book.didAppear!");
  },

  // DidDismiss,
  function (){

  },

  // DidReceiveCallDevice
  function (CallBackName, Objects){
    //console.log("🐞Book.Call:", CallBackName, Objects);

    if (CallBackName == "OPEN-LIST"){
      //.打开(本地)
      //.显示(网页)
      //.下载
      if (Objects['is_book'] != true){
        let list_id = Objects.id;
        let existed_lists = StudyDataController.allNewAddLists.filter((list)=>{return list_id == list.parent_cover_list_id});
        if (existed_lists.length > 0){
          let existed_list = existed_lists[0];
          let existed_words = StudyDataController.studyListWords[existed_list.unico_id]; if (!existed_words){existed_words = [];}
          window.top.WordsViewController.showWords(existed_list, existed_words);
          return;
        }
      }

      mClickingList = Objects;
      instance.showBookWords(mClickingList.id);

      //@Depracated!
      //presentMenuBook(true);
    }

    if (CallBackName == "START-DOWNLOAD"){
      Alert.showLoadingAlert("正在下载",Objects);
    }

    if (CallBackName == "DOWNLOADED-RESULTS"){

      //隐藏单词详情页面
      if ($("iframe-nav-detail").length >= 2){
        IframeNavBack();
      }

      //清除通知
      Alert.clearAlerts();

      let list = Objects.list;
      let words = Objects.words;
      //console.log("🐞download words:",words);

      //cast
      list['being'] = 'NewAdd';
      list['is_fresh'] = true;

      //save
      //.list
      let inserted_list = StudyDataController.insertListByDict(list);
      //.words
      StudyDataController.insertWordsByDicts(words);
      //.regroup
      StudyDataController.regroupStudyData();

      //@NotWork!
      //.words
      // words = words.map((word)=>{
      //   word.parent_list_unico_id = inserted_list.unico_id;
      //   return word;
      // });
      // //console.log("🐞copy words by:",inserted_list,words);
      // StudyDataController.copyWordsBy(inserted_list.unico_id, words, null);

      // redisplay lists
      // if (window.top.TabStudy){
      //     window.top.TabStudy.refetchAllDataThenRedisplay(true);
      // }

      //success
      //setTimeout(function () {

        //单词书: 返回
        if (list['is_book']){
          IframeNavBack();
        }
        //单词本: 打开
        else{
          Alert.showConfirmAlert("已下载「"+inserted_list.list_name+"」","打开单词本学习吗?", false, "去学习", function () {
            if (window.top.WordsViewController){
              let inserted_words = StudyDataController.allNewAddWords.filter((word)=>{return word.parent_list_unico_id == inserted_list.unico_id;})
              window.top.WordsViewController.showWords(inserted_list, inserted_words);
            }
          }, "暂不");
        }

        // @Depracated!
        // //添加单词: 下载后打开
        // if (instance.mMode.includes("add")){
        //   if (list.is_book){
        //     if (window.top.ListViewController){
        //       window.top.ListViewController.showUP();
        //     }
        //   }
        //   else{
        //     if (window.top.WordsViewController){
        //       window.top.WordsViewController.showWords(list, words);
        //     }
        //   }
        // }
        // //切换单词书: 下载后返回
        // else{
        //   IframeNavBack();
        // }
      //},500);
    }
  }
);

//.🍉view
const BookViewController = () => {
  return (
    <IonPage>
      {/*<IonHeader>*/}
        {/*<IonToolbar>*/}
          {/*<IonButtons slot="start">*/}
            {/*<IonBackButton text="返回" defaultHref="/" />*/}
          {/*</IonButtons>*/}
          {/*<IonTitle>*/}
            {/*/!*PracticeCards*!/*/}
          {/*</IonTitle>*/}
          {/*<IonButtons slot="end">*/}
            {/*<IonButton className="menu-button">*/}
              {/*<IonIcon className="color-dark" slot="icon-only" icon={ellipsisHorizontal}/>*/}
            {/*</IonButton>*/}
          {/*</IonButtons>*/}
        {/*</IonToolbar>*/}
      {/*</IonHeader>*/}

      {/*<IonContent>*/}
        {/*<div id="gesture-handlr"></div>*/}
        {/*<IframeWrapper src={Keys.Url.page_book()} delegate={delegate}></IframeWrapper>*/}
      {/*</IonContent>*/}
    </IonPage>
  );
};
export default BookViewController;

var presentMenuBook;
var mClickingList;
export const MenuBook = () => {
  const dispatch = useAppStateDispatch();

  const [title, setTitle] = useState("单词本");
  const [actions, setActions] = useState([]);
  const [showActionSheet, setShowActionSheet] = useState(false);
  presentMenuBook = setShowActionSheet;

  return (
    <IonActionSheet
      isOpen={showActionSheet}
      onDidDismiss={() => setShowActionSheet(false)}
      header={title}
      //subHeader={Object(window).mClickingWord.word_key}
      onWillPresent={() => {

        setTitle(mClickingList.title);

        var options = [

          //.显示内容
          {
            text: '显示内容',
            handler: () => {
              instance.showBookWords(mClickingList.id);
            }
          },

          //.下载
          {
            text: '下载',
            handler: () => {
              setTimeout(()=>{
                if (mClickingList.is_book){
                  // 👇 fix confirm() bug
                  // eslint-disable-next-line no-restricted-globals
                  if (!confirm("📘确定将学习的单词书切换到「"+mClickingList.title+"」?")){
                    return;
                  }
                }

                delegate.sendMessage("DOWNLOAD-LIST", mClickingList.id);
              },800);
            }
          },

          //.取消
          {
            text: '取消',
            role: 'cancel',
            handler: () => {}
          }
        ];

        setActions(options);
      }}
      cssClass='menu-word'
      buttons={actions}
    ></IonActionSheet>
  )
}