/**
 * IframeWrapper
 * by 大方 2020-11-17
 *
 * 参考:
 * iframe with loader: https://medium.com/@josh.j.pearson/handling-iframe-loading-in-react-57f044a9d0fa
 *
 * update 2020-12-01
 *      build under cheesenote ios 1.0
 *
 * update 2021-01-26
 *      build under fangecoco ios 1.4
 */

import React, {useRef} from "react";
import { findDOMNode } from 'react-dom';
import $ from 'jquery';
import "./IframeWrapper.css";

export const IframeWrapperDelegate = (
    DidFinishLoad,
    DidScroll,
    DidAppear,
    DidDismiss,
    DidReceiveCallDevice
) => {
    return {
        pageDidFinishLoad:DidFinishLoad,
        pageDidScroll:DidScroll,
        pageDidAppear:DidAppear,
        pageDidDismiss:DidDismiss,
        pageDidReceiveCallDevice:DidReceiveCallDevice
    }
}

var messageHanldr;
class IframeWrapper extends React.Component{

    constructor(props) {
        super(props);
        this.getIrameDom = this.getIrameDom.bind(this);
        this.getIframeWindow = this.getIframeWindow.bind(this);
    }

    //@method
    getIrameDom(){
      let iframe = $(findDOMNode(this));
      //console.log("🐞iframe:",iframe);
      return iframe;
    }
    getIframeWindow(){
        let iframe = this.getIrameDom();
        if (iframe != null){
            let iframeAddress = iframe.attr('src');

            //.remote
            if (iframeAddress.includes('http') && iframeAddress.includes(window.location.hostname) == false){

            }
            //.local
            else{
                try{
                    return iframe[0].contentWindow;
                }catch (e) {
                    return null;
                }
            }
        }
        return null;
    }

    reloadIframe() {
        let framwWindow = this.getIframeWindow();
        if (framwWindow){
            framwWindow.location.reload();
            //console.log("🐞🍋iframe reload:',framwWindow.location.href);
        }
    }


    //@lifecycle
    componentDidMount() {
        //console.log("🐞🍋IframeWrapper.componentDidMount:", this, this.props.delegate);

        //const dispatch = this.context;

        //@property
        var iframe = this.getIrameDom();
        var delegate = this.props.delegate;

        //@export methods
        if(this.props.refIrameWrapper){
           this.props.refIrameWrapper(this);
        }

        //@delegate
        const getIframeWindow = this.getIframeWindow;
        iframe.on('load',function (){

          iframe.removeClass('loading');
          //console.log("🐞🍋IframeWrapper.finish load:", iframe.attr.src);

            //setup
            let iframwWindow = getIframeWindow();
            const calldevice = {
                setup:function (){

                    //@SendMessage To Iframe
                    if (delegate){
                      function sendMessage(ActionName, Objects=null){
                        iframe[0].contentWindow.postMessage({action:ActionName, content:Objects}, "*");
                      }
                      delegate.pageDidFinishLoad(sendMessage);
                    }

                    //@ReceiveMessage From Iframe
                    //.iframeContent
                    if (iframwWindow){
                        //console.log("🐞setup calldevice by window!");

                        //set CallDeviceContext as window
                        iframwWindow.CallDeviceContext = calldevice;

                        //set CallDeviceContext as tools
                        if (typeof iframwWindow.Tools != "undefined"){
                            iframwWindow.Tools.CallDeviceContext = calldevice;
                        }
                    }
                    //.iframeMessage
                    else{
                        //console.log("🐞setup calldevice by postMessage!");

                        messageHanldr = function(event) {
                          let data = event.data;
                          let Action = data.action;
                          let Content = data.content;

                          calldevice.onCallDevice(Action, Content);
                        };
                        window.addEventListener('message', messageHanldr);
                    }
                },
                globalHandlr:function(CallBackName,Objects){
                    if (CallBackName.toLowerCase().includes('global') && window.top.CallDeviceGlobalHandlr){
                        return window.top.CallDeviceGlobalHandlr(CallBackName, Objects);;
                    }
                    return false;
                },
                onCallDevice:function (CallBackName,Objects){
                    //console.log("🐞onCallDevice:",iframe,CallBackName,Objects);
                    if (iframe && iframe[0] && iframe[0].isConnected){
                      if (!calldevice.globalHandlr(CallBackName, Objects)){
                        if (delegate){
                          delegate.pageDidReceiveCallDevice(CallBackName,Objects);
                        }else{
                          //console.log("🐞iframe delegate null:",delegate);
                        }
                      }
                    }else{
                      //console.log("🐞iframe delegate not call: iframe not connected!");
                    }
                }

            };

            //setup
            calldevice.setup();
        });
    }

    componentWillUnmount(){
      //console.log("🐞remove calldevice by postMessage!");
      window.removeEventListener('message',messageHanldr);
    }

    render() {
      return (<iframe className="iframe-wrapper fullscreen loading" src={this.props.src}></iframe>);
    }
}

//IframeWrapper.contextType = AppStateDispatchContext;

export {IframeWrapper};



/**
 * jsx: IframeWrapper
 */
// import React from "react";
//
// const IframeJSX = ({src}) => {
//     return (<iframe src={src}></iframe>);
// };
// export default IframeJSX;


/**
 * tsx: IframeWrapper
 */
// import React from 'react';
//
// interface Props {
//     src: string;
// }
//
// export const IframeWrapper: React.FC<Props> = ({src}) => {
//     return (<iframe src={src}></iframe>);
// };