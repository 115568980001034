import {useEffect} from 'react';
import {IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonTitle, IonToolbar} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './TabTools.css';
import $ from "jquery";
import {caretDown, triangle, sync, arrowDown, chevronForward, cubeOutline, hardwareChipOutline, megaphoneOutline} from "ionicons/icons";
import {Settings, LimitFunction} from '../tools/Settings';
import StudyDataController from '../tools/StudyDataController.js'
import Alert from "../tools/Alert";
import {IonButton} from "@ionic/react/dist/index";
import {archiveOutline, sparklesOutline, readerOutline} from "ionicons/icons/index";
import {presentWordsPicker} from "./studyplan/WordsPicker/WordsPicker";
import NativeTools from "../tools/NativeTools";

function reloadTabTools() {
  reloadUserInfo();
  reloadLanguage();
}

function reloadUserInfo() {
  if (LimitFunction.isLogin()){
    $(".user-info .title").text(Settings.getNickName());
    $(".unlogin").fadeOut(0);
    $(".logined").fadeIn(0);
  }else{
    $(".user-info .title").text("登录");
    $(".unlogin").fadeIn(0);
    $(".logined").fadeOut(0);
  }
}
window.top.reloadUserInfo = reloadUserInfo;

function reloadLanguage() {
  $(".cell-language").attr("language",Settings.getLanguage());

  //@Depracated!
  //$(".voice-language .language-name").text(Settings.getLocalizedLanguageName(Settings.getVoiceLanguage()));
}


const TabTools = () => {

  useEffect(() => {
    //console.log("🐞TabTools ready!");

    //setup userinfo
    reloadUserInfo();
    reloadLanguage();

    //setup file picker
    $("#input-source-file").click(function (e) {
      e.stopPropagation();
    })
    $("#input-source-file").change(function (e){
      var files = $(e.target).prop('files');
      //console.log("🐞files:',files);
      if (files.length == 1){
        let file = files[0];
        var reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = function (evt) {
          let content = evt.target.result;
          if (content){
            let dict = JSON.parse(content);
            if (dict){
              Alert.showLoadingAlert('正在计算导入数据','这可能需要一点时间');
              setTimeout(function () {
                StudyDataController.tryCalculateImportStudyData(dict, function (results) {
                  Alert.showConfirmAlert(
                    '确认导入',
                    '根据对比已存在的数据, 将导入'+
                    '<br>'+results['lists'].length+'个单词本 '+
                    '<br>'+results['words'].length+'个单词 '+
                    '<br>'+results['appuntis'].length+'条笔记',
                    false,
                    '确认',
                    function () {
                      Alert.showLoadingAlert('正在导入','这可能需要一点时间');
                      setTimeout(function () {
                        StudyDataController.tryWriteImportStudyData(results, function () {
                          //sync
                          Alert.showConfirmAlert("导入成功", "需要和服务器数据同步一下吗?",false,"同步",function () {
                            StudyDataController.refetchAllStudyData(true, function () {
                              StudyDataController.trySyncStudyData(true, function () {
                                //reload
                                if (window.top.TabStudy && window.top.TabStudy.refetchAllDataThenRedisplay){
                                    window.top.TabStudy.refetchAllDataThenRedisplay(false);
                                }
                              });
                            });
                            return;

                          },"暂不");

                          //redisplay
                          if (window.top.TabStudy && window.top.TabStudy.refetchAllDataThenRedisplay){
                            window.top.TabStudy.refetchAllDataThenRedisplay(true);
                          }

                        });
                      },200);
                    }
                  );
                });
              },200);
              return;
            }
          }
          Alert.showWarningAlert('请拖入正确的 source.txt 文件',null,'好的');
        }
        reader.onerror = function (evt) {
          Alert.showWarningAlert('请拖入正确的 source.txt 文件',null,'好的');
        }
      }else{
        Alert.showWarningAlert('请拖入正确的 source.txt 文件',null,'好的');
      }
    });
  });

  return (
    <IonPage id="TabTools" className="page-tools">
      <IonContent fullscreen>

        <div className="safe-area-top">
          <div className="section page-section">
            <div className="section-title">
              <div className="label">工具箱</div>
            </div>
          </div>

          <div className="section section-user">
            <div className="section-content user-info" onClick={()=>{window.top.presentMenuApp(true);}}>
              <span className="title"></span>
              <IonIcon className="unlogin" icon={chevronForward} />
              <IonIcon className="logined" icon={chevronForward} />
            </div>
          </div>

          {/*<div className="section section-cells">*/}
            {/*<div className="section-description">*/}
              {/*偏好设置*/}
            {/*</div>*/}
            {/*<div className="section-content">*/}
              {/*<IonGrid>*/}
                {/**/}

              {/*</IonGrid>*/}
            {/*</div>*/}
          {/*</div>*/}

          <div className="section section-cells">
            <div className="section-description">
              语言和词典
            </div>
            <div className="section-content">
              <IonGrid>
                {/*语言*/}
                <IonRow className="cell-language">
                  <IonCol size="12" onClick={()=>{
                    Alert.showLanguageAlert(function () {
                      //刷新Flag
                      reloadLanguage();
                    });
                  }}>
                    <div>
                      <span>学习语言</span>
                      <span className="icon flag"></span>
                    </div>

                    {/*@Depracated*/}
                    {/*<span className="voice-language" onClick={(e)=>{*/}
                      {/*e.stopPropagation();*/}
                      {/*e.preventDefault();*/}

                      {/*function setLanguage(language){*/}
                        {/*//设置语言*/}
                        {/*Settings.setVoiceLanguage(language);*/}
                        {/*//设置Flag*/}
                        {/*reloadLanguage();*/}
                      {/*}*/}
                      {/*Alert.showActionsAlert("设置发音语言","在多国语环境下, 选择一个语言作为主要的发音语音",{*/}
                        {/*"🇬🇧 英语":function () {*/}
                          {/*setLanguage("en");*/}
                        {/*},*/}
                        {/*"🇫🇷 法语":function () {*/}
                          {/*setLanguage("fr");*/}
                        {/*},*/}
                        {/*"🇮🇹 意语":function () {*/}
                          {/*setLanguage("it");*/}
                        {/*},*/}
                      {/*});*/}
                    {/*}}>*/}
                      {/*<span>以<span className="language-name">英语</span>作为单词发音</span>*/}
                      {/*<IonIcon icon={caretDown}/>*/}
                    {/*</span>*/}

                  </IonCol>
                </IonRow>

                {/*管理词典包*/}
                <IonRow className="cell-language">
                  <IonCol size="12" onClick={()=>{
                    if (window.MdxFileManager){
                        window.MdxFileManager.showUP();
                    }
                  }}><div>
                    <span>管理词典包</span>
                    <IonIcon icon={cubeOutline}/>
                  </div></IonCol>
                </IonRow>

                {/*词典引擎*/}
                {/*<IonRow className="cell-language">*/}
                  {/*<IonCol size="12" onClick={()=>{*/}

                  {/*}}><div>*/}
                    {/*<span>词典引擎</span>*/}
                    {/*<IonIcon icon={hardwareChipOutline}/>*/}
                  {/*</div></IonCol>*/}
                {/*</IonRow>*/}

              </IonGrid>
            </div>
          </div>

          <div className="section section-cells">
            <div className="section-description">
              学习数据
            </div>
            <div className="section-content">
              <IonGrid>
                {/*同步学习数据*/}
                <IonRow>
                  <IonCol size="12" onClick={()=>{
                    StudyDataController.trySyncStudyData(true, function () {
                      //reload
                      if (window.top.TabStudy && window.top.TabStudy.refetchAllDataThenRedisplay){
                        window.top.TabStudy.refetchAllDataThenRedisplay(false);
                      }
                    });
                  }}><div>
                    <span>同步学习记录</span>
                    <IonIcon icon={sync}/>
                  </div></IonCol>
                </IonRow>

                {/*导入学习数据*/}
                <IonRow className={NativeTools.isAndroid ? "ion-hide":""}>
                  <IonCol size="12" onClick={()=>{
                    //🔐禁止未登录
                    if (LimitFunction.isLogin('default') != true){
                      return;
                    }

                    Alert.showConfirmAlert(
                      "导入本地学习数据",
                      "1.先在旧版方格单词实用工具选择<br>[ 备份到文件管理器 ]<br><br>2.找到备份文件<br>[ iPhone/方格单词/APP/单词本备份/source.txt ]<br><br>😛 如果不知道怎么导入可以微信添加 iofange 哦",
                      false,
                      "选择文件",
                      ()=>{$("#input-source-file").click();}
                    )
                  }}><div>
                    <span>导入学习数据</span>
                    <IonIcon icon={archiveOutline}/>
                    <input id="input-source-file" type="file" accept=".txt"></input>
                  </div></IonCol>
                </IonRow>

                {/*批量添加单词*/}
                <IonRow>
                  <IonCol size="12" onClick={()=>{
                    //🔐禁止未登录
                    if (LimitFunction.isLogin('default') != true){
                      return;
                    }

                    presentWordsPicker();

                  }}><div>
                    <span>批量添加单词</span>
                    <IonIcon icon={readerOutline}/>
                    <input id="input-source-file" type="file" accept=".txt"></input>
                  </div></IonCol>
                </IonRow>

                {/*清除学习记录*/}
                <IonRow className="cell-clear-memo">
                  <IonCol size="12" onClick={()=>{
                    //🔐禁止未登录
                    if (LimitFunction.isLogin('default') != true){
                      return;
                    }

                    StudyDataController.showClearMemosAlert();
                  }}><div>
                    <span>清除学习记录</span>
                    <IonIcon icon={sparklesOutline}/>
                  </div></IonCol>
                </IonRow>

              </IonGrid>
            </div>
          </div>


          <div className="section section-cells">
            <div className="section-description">
              实验功能
            </div>
            <div className="section-content">
              <IonGrid>

                {/*发音模式*/}
                <IonRow className="cell-language">
                  <IonCol size="12" onClick={()=>{
                    function setVoiceOffline(offline){
                      Settings.setVoiceOffline(offline);
                    }
                    let offline_key = Settings.getVoiceOffline() ? "离线语音<span class='check'>✓</span>":"离线语音";
                    let online_key = Settings.getVoiceOffline() ? "在线语音":"在线语音 <span class='check'>✓</span>";
                    let options = {};
                    options[offline_key] = function () {
                      setVoiceOffline(true);
                      NativeTools.tryRequireOfflineTTS();
                    }
                    options[online_key] = function () {
                      setVoiceOffline(false);
                    }
                    let message = "🤖 离线语音: 发音中规中矩, 快速响应无需流量";
                    if (NativeTools.isAndroid){
                      message += ", 但是Android设备需要安装语言引擎并手动设置语音包";
                    }
                    message += "<br><br>😛 在线语音: 发音真实细腻, 但是需要请求网络, 并且会有一定的延迟";

                    Alert.showActionsAlert("选择您偏好的发音模式", message, options);
                  }}><div>
                    <span>发音模式</span>
                    <IonIcon icon={megaphoneOutline}/>
                  </div></IonCol>
                </IonRow>

              </IonGrid>
            </div>
          </div>

        </div>

      </IonContent>
    </IonPage>
  );
};

export default TabTools;
