/* eslint-disable */
/* 👆 this is required for disabling eslint for amd. */

(function (global) {
    let ColorSuits = {
        "standard-red":{
            "memo_untracked":'#D1D6DE',

            // "memo_start":'#54C0FF',
            // "memo_known":'#20D3DA',
            "memo_start":'#4083ff',
            "memo_known":'#4083ff',

            "memo_familiar":'#37df9c',
            "memo_danger":'#ff5a30',
        },
        "standard-dark":{
            "memo_untracked":'#D1D6DE',
            "memo_start":'#54C0FF',
            "memo_known":'#20D3DA',
            "memo_familiar":'#37df9c',
            "memo_danger":'#364452',
        }
    };

    //AMD
    if (typeof define === 'function' && define.amd) {
        define(function () {
            return ColorSuits;
        });
    }
    //ES5
    else if (typeof module === 'object' && module.exports) {
        module.exports = ColorSuits;
    }
    //Global
    else {
        global.ColorSuits = ColorSuits;
    }
})(this);


