import $ from "jquery";
import React from 'react';
import {
  IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow,
  useIonViewDidEnter
} from '@ionic/react';
import './TabTools.css';
import './TabDiscover.css';
import {chevronForward, headset} from "ionicons/icons/index";
import {mPronounceViewController} from "./discover/PronounceViewController";
import CurveView from "./studyplan/Curve/CurveView";
import StudyDataController from "../tools/StudyDataController";
import NativeTools from "../tools/NativeTools";
import {Settings} from "../tools/Settings";

Object(window).top.TabDiscover = {};

var mChartsViewRef, setChartsViewRef = function(chartsview){    mChartsViewRef = chartsview;};

//@LifyCycle
function viewDidAppear(){
  //console.log("🐞TabDiscover viewDidAppear");
  if (Settings.getLanguage() == "fr" || Settings.getLanguage() == "all"){
    $(".section-pronounce").removeClass('ion-hide');
  }else{
    $(".section-pronounce").addClass('ion-hide');
  }

  setTimeout(()=>{
    if (StudyDataController.allNewAddWords.length == 0){
      StudyDataController.refetchAllStudyData(true, function () {
        mChartsViewRef.tryRefreshChartsData();
      });
    }else{
      mChartsViewRef.tryRefreshChartsData();
    }
  },500);
};
window.top.TabDiscover.viewDidAppear = viewDidAppear;

//🍉view
const TabDiscover = () => {

  useIonViewDidEnter(() => {
    viewDidAppear();
  });

  return (
    <IonPage className="page-discover page-tools">
      <IonHeader>

      </IonHeader>
      <IonContent fullscreen>

        <div className="safe-area-top">
          <div className="section page-section">
            <div className="section-title">
              <div className="label">大发现</div>
            </div>
          </div>

          {/*统计*/}
          <div className="section section-cells" id="section-charts">
            <div className="section-description">
              单词遗忘情况和统计
            </div>
            <div className="section-content">
              <CurveView onRef={setChartsViewRef}/>
            </div>
          </div>

          {/*学习法语*/}
          <div className={"section section-cols section-pronounce"}>
            <div className="section-description">
              学习法语
            </div>
            <div className="section-content-clear">
              <IonGrid>
                {/*学习发音*/}
                <IonRow>
                  <IonCol size="6"  className="col-pronounce" onClick={()=>{
                    mPronounceViewController.showUP();
                  }}><div>
                    <span>学习法语发音</span>
                    <IonIcon icon={headset}/>
                  </div></IonCol>

                </IonRow>

              </IonGrid>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TabDiscover;
