// define(['Tools','Alert','EnvHelper'],function (Tools, Alert,EnvHelper){
//
//
// });

import Alert from "./Alert";
import NativeTools from "./NativeTools";

const TTSHelper = function() {

  var synth;
  var utterance;

  //MARK: Voices List
  let mGroupedVoices = {};
  let mVoices = [];
  function initVocies(){
    mVoices = speechSynthesis.getVoices();

    let voices = mVoices;//console.log("🐞voice.mVoices',mVoices);
    for (let voice of voices){
      let voice_language = voice.lang.split("-")[0];
      if (mGroupedVoices[voice_language] == undefined){
        mGroupedVoices[voice_language] = [voice];
      }else{
        mGroupedVoices[voice_language].push(voice);
      }
    }
  }

  function getVoicesByLanguage(Language){
    if (Object.keys(mGroupedVoices).length == 0){
      initVocies();
    }
    return mGroupedVoices[Language];
  }
  function getPreferredVoiceByLanguage(Language){
    //console.log("🐞getPreferredVoiceByLanguage");
    if (Language == 'all'){
      //Alert.showWarningAlert("多国语暂不支持发音","🌈 您当前使用的语言是多国语<br>如果您需要发音功能，可以将语言切换成您学习的语言","好的");
      console.warn("🐞多国语单词...");
      return null;
    }

    let language_voices = getVoicesByLanguage(Language);
    if (typeof language_voices == "undefined"){
      showVoiceAlert();
      return null;
    }
    return language_voices[0];
  }
  function showVoiceAlert() {
    Alert.showConfirmAlert(
      "系统缺少语言支持",// Title,
      "您可以通过系统设置快速安装语言支持",// HtmlMessage,
      false,// isDangerous,
      "查看安装教程",// ActionText,
      function () {// OnConfirm
        NativeTools.openUrl("https://iofange.com/post/274");
      }
    )
  }

  //MARK: Speaker LifeCycle
  this.init = function() {
    //console.log("🐞TTSHelper init!");

    // CHECK FOR SPEECHSYNTHESIS API
    if(!(window.speechSynthesis)) {
      console.log("🔥Your browser is not capable of speech synthesis.")
      return
    }

    // INITIALIZE SPEECH SYNTHESIS API ELEMENTS
    synth = window.speechSynthesis;

    // CHECK FOR AVAILABLE VOICES
    setTimeout(function () {
      initVocies();
      if(mVoices.length <= 0) {
        console.log("🔥No voices are provided by either your browser or system.")
        return
      }
    },2000);
  }
  this.init();

  this.speakByString = function (FraseString, ForceLanguage=null, OnFinish=null, Rate=0.5){
    //.language
    let language;
    if (ForceLanguage){
      language = ForceLanguage
    }else{
      if (FraseString.hasChinese()){
        language = 'zh';
      }else{
        language = window.top.Settings.getLanguage();
      }
    }

    //.speak
    if (synth != undefined){
      synth.cancel();
    }

    utterance = new SpeechSynthesisUtterance(FraseString);
    utterance.onend = function(event) {
      console.info('🔥Web TTS Success');
      if (OnFinish){
          OnFinish();
      }
    };

    //console.log("🐞web speak:",FraseString);
    //console.log("🐞rate:",Rate*2);
    //console.log("🐞utterance:",utterance);

    var selectedVoice = getPreferredVoiceByLanguage(language);
    if (selectedVoice) {
      utterance.rate = Rate*2;
      utterance.voice = selectedVoice;
      synth.speak(utterance);
    }else{
      console.warn('🔥Web TTS fail: no match voice:',language);
      if (OnFinish){
        OnFinish();
      }
    }
  }
}
export default TTSHelper;