import $ from "jquery";
import React, {useEffect, useRef} from "react";
import UITableView from "../../tools/uikit/UITableView";
import UITableViewInfinite, {UITableViewDataSource} from "../../tools/uikit/UITableViewInfinite";
import "./WordsRichTable.css";
import {Settings} from "../../tools/Settings";

const WordsRichTable = (props) => {
  //🍉Init
  var mDom;
  useEffect(()=>{
    mDom = $(contentRef.current);

    if(props.onRef){
      props.onRef({
        reloadRichTable: reloadRichTable,
        markSpeakWords: markSpeakWords,
      });
    }
  });

  //🍉TableView
  var mWords = [],
      mSpeakWords = [],
      mSpeakWordsStatus = {/* row: {word:'status', mean:'status'} */};

  function reloadRichTable(Words, SpeakWords) {
    mWords = Words;
    mSpeakWords = SpeakWords;
    mSpeakWordsStatus = {};
    markSpeakWords(mSpeakWords, 'waiting', false);

    mTableViewRef.reload();
  }

  function markSpeakWords(speakwords, status /*waiting | loading | loaded*/, reload=false){
    for (let speakword of speakwords){
      if (!mSpeakWordsStatus[speakword.row]){
           mSpeakWordsStatus[speakword.row] = {};
      }
      mSpeakWordsStatus[speakword.row][speakword.class] = status;
    }
    if (reload){
      mTableViewRef.scrollToRow(speakwords[speakwords.length-1].row);
    }
  }

  const datasource = UITableViewDataSource(
    function numberOfSections() {
      return 1;
    },
    function numberOfRows() {
      return mWords.length;
    },
    function heightForRowAtIndexPath(row) {
      return 25;
    },
    function cellForRowAtIndexPath(section, row) {
      let word = mWords[row];
      var word_status = '';
      var mean_status = '';
      if (mSpeakWordsStatus[row]){
        if (mSpeakWordsStatus[row]['word']){
          word_status = mSpeakWordsStatus[row]['word'];
        }
        if (mSpeakWordsStatus[row]['mean']){
          mean_status = mSpeakWordsStatus[row]['mean'];
        }
      }else{
        console.warn("🐞status not find:",word);
      }

      return (
        <div className='cell-content'>
          <div className='col order'>{row}</div>
          <div className={"col col-text word"} status={word_status}>{word.word_string}</div>
          <div className='col col-text mean' status={mean_status}>{word.word_key}</div>
          <div className='col language'>{Settings.getFlag(word.preferred_language)}</div>
        </div>
      );
    }
  )


  //🍉View
  const contentRef = useRef(this);

  var mTableViewRef, setTableViewRef = function(tableview){ mTableViewRef = tableview;};

  return (<div className="WordsRichTable" ref={contentRef}>
    <div className="tableview-header">
      <div className="ui-tableview-cell cell-content">
        <div className="col order">序号</div>
        <div className="col">单词</div>
        <div className="col">释义</div>
        <div className="col language">语言</div>
      </div>
    </div>
    <div className="tableview-cells-wrapper">
      <UITableViewInfinite datasource={datasource} onRef={setTableViewRef}/>
    </div>
  </div>)
}
export default WordsRichTable;