import {useEffect, useRef} from 'react';
import * as $ from "jquery";
import {IonContent, IonPage, useIonViewDidEnter} from '@ionic/react';
import {IframeNavBack, IframeNavPresentPage} from '../../tools/uikit/IframeNav';
import './ListViewController.css';
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react/dist/index";
import {ellipsisHorizontal, ellipsisVertical, heart, swapVerticalOutline, addOutline} from "ionicons/icons/index";
import StudyDataController from "../../tools/StudyDataController";
import Alert from "../../tools/Alert";
import * as Tools from "../../tools/Tools";
import * as ColorSuits from "../../theme/ColorSuits.js";
import {LimitFunction, Settings} from "../../tools/Settings";
import {IframeWrapperDelegate} from "../../tools/uikit/IframeWrapper";
import NavBar from "../../components/NavBar";
import {presentMenuAddList} from "../elements/MenuAddList";
import Hammer from 'hammerjs';
import {setClicking, presentMenuList} from "../elements/MenuList";

//import Hammer from 'react-hammerjs';


//🍉instance
var hasAppearedOnce = false;

var instance = {
  mMode : "MODE-NORMAL",
  setMode: (Mode)=>{
    instance.mMode = Mode;
  },
  showUP : function (Mode="MODE-NORMAL") {//"MODE-CHOOSE-PRIMARY-LIST"

    //🔐禁止未登录
    if (LimitFunction.isLogin('default') != true){
      return;
    }

    instance.mMode = Mode;

    IframeNavPresentPage(ListViewController, "ListViewController", false);
    //init
    // $("body").on('click','custom-practicehome .menu-button',function () {
    //   presentMenuPractice(true);
    //   //or
    //   //(window as any).top.presentMenuPractice(true);
    // });
  },
  reloadPage: reloadPage,
  delegate:IframeWrapperDelegate(
    // DidFinishLoad,
    function (){

    },

    // DidScroll,
    function (){

    },

    // DidAppear,
    function (){
      //console.log("🐞ListViewController viewDidAppear");
      if (hasAppearedOnce){
        regroupStudyDataThenReloadPage();
      }

      hasAppearedOnce = true;
    },

    // DidDismiss,
    function (){

    },

    // DidReceiveCallDevice
    function (CallBackName, Objects){
      //console.log("🐞ListVCor.Call:", CallBackName, Objects);
    }
  ),
};
Object(window.top).ListViewController = instance;


//🍉ui setup

//🍋bookcover
function setupBookCover() {
  if (Settings.getBookFolded()){
    $(".studybook-cover").addClass("folded");
  }else{
    $(".studybook-cover").removeClass("folded");
  }

  $(".studybook-cover").off('click');
  $(".studybook-cover").on('click','.button-fold',function (e) {
    e.stopPropagation();
    toggleBookExpand();
  });
}
function toggleBookExpand() {
  $(".studybook-cover").toggleClass('folded');
  Settings.setBookFolded($(".studybook-cover").attr("class") && $(".studybook-cover").attr("class").includes("folded"));
}

//🍋settions
function setupSections() {
  $(".button.switch").off('click');
  $(".button.switch").click(function () {
    //@Depracated!
    //window.top.mBookViewController.showUP("add-both");

    window.top.mBookViewController.showUP("switch-book");
  });

  $(".button.add").off('click');
  $(".button.add").click(function () {
    window.top.mBookViewController.showUP("add-list");
  });
}

//🍋
function setupElements() {
  $('body').off('click','.action-heart');
  $('body').on('click','.action-heart',function (e) {
    e.stopPropagation();
    e.preventDefault();

    Alert.showConfirmAlert("取消优先学习这个列表?",null, false, "好的", function () {
      Settings.setPrimaryListIndexPath(null);
      reloadPage();
    },"暂不");
  });
}


//🍉ui display
function regroupStudyDataThenReloadPage() {
  StudyDataController.regroupStudyData();
  reloadPage();
}
function reloadPage() {
  redisplayBookCover();
  redisplayListsTableView();
}

window.top.tryRegroupStudyDataThenReloadPage = () => {
  // @Depracated!
  // if ($(".ListViewController").length <= 0){
  //   return;
  // }

  regroupStudyDataThenReloadPage();
}

//🍋bookcover
function redisplayBookCover() {
  if (StudyDataController.studyBooks.length > 0){
    $(".studybook-cover").attr('row','0');
    $(".studybook-cover").removeClass('empty');

    let book = StudyDataController.studyBooks[0];
    $(".studybook-cover .list_name").text(
      Settings.getLanguage() == "all"
        ? Settings.getFlag(book.preferred_language) + " " + book['list_name']
        : book['list_name']
    )
    $(".studybook-cover .timestamp").text(book['created_at'].split(" ")[0]);
    $(".studybook-cover .list_num").text(StudyDataController.studyBookChapters.length+"列表 / "+StudyDataController.studyBooksWords.length+"词");
  }else{
    $(".studybook-cover").attr('row','-1');
    $(".studybook-cover").addClass('empty');
    $(".studybook-cover .list_name").text("📘 添加单词书");
  }

  let primary_indexpath = Settings.getPrimaryListIndexPath();
  if (primary_indexpath != null && primary_indexpath.section == 2 && primary_indexpath.row == 0){
    $(".studybook-cover").addClass('primary');
  }else{
    $(".studybook-cover").removeClass('primary');
  }
}

//🍋tableview
function redisplayListsTableView() {
  $(".studylists-wrapper").empty();
  if (StudyDataController.studyLists.length > 0){
    $.each(StudyDataController.studyLists, function (row, list) {
      let cell = cellForRowAt({section:0, row: row});
      $(".studylists-wrapper").append(cell);
    });
  }else{
    let cell = cellForRowAt({section:0, row: -1});
    $(".studylists-wrapper").append(cell);
  }

  $(".studychapters-wrapper").empty();
  if (StudyDataController.studyBookChapters.length > 0){
    $.each(StudyDataController.studyBookChapters, function (row, list) {
      let cell = cellForRowAt({section:1, row: row});
      $(".studychapters-wrapper").append(cell);
    });
  }else{
    let cell = cellForRowAt({section:1, row: -1});
    $(".studychapters-wrapper").append(cell);
  }
}
window.top.redisplayListsTableView = redisplayListsTableView;

//.cellForRowAtIndexPath
function cellForRowAt(IndexPath) {

  let this_list;
  let this_list_words = [];

  if (IndexPath.section == 0){
    this_list = StudyDataController.studyLists[IndexPath.row];
  }
  if (IndexPath.section == 1){
    this_list = StudyDataController.studyBookChapters[IndexPath.row];
  }

  let list_elm;
  if (Tools.isEmpty(this_list)==false){
    list_elm = $(
      "<div class='list'>" +
      "<div class='list-content ripple-parent'>\n" +
      "    <div class='list-name-group'>\n" +
      "        <div class='list_name'></div>\n" +
      "        <span class='badge badge-light'>" +
      "           <span class='list_num'></span>" +
      "           <span class='memo_stack'></span>" +
      "        </span>\n" +
      "    </div>\n" +
      "    <div class='timestamp'></div>\n" +
      "    <i class='arrow fas fa-chevron-right'></i>" +
      //"    <ion-ripple-effect></ion-ripple-effect>" +
      //"    <i class='menu-button fas fa-ellipsis-v'></i>\n" +
      "</div>" +
      "<div class='actions-wrapper'></div>\n" +
      "</div>");

    if (IndexPath.section == 0){
      this_list_words = StudyDataController.studyListWords[this_list.unico_id];
    }
    if (IndexPath.section == 1){
      this_list_words = StudyDataController.studyBookChapterWords[this_list.unico_id];
    }

    list_elm.attr('section',IndexPath.section);
    list_elm.attr('row',IndexPath.row);
    if (Settings.getLanguage() == "all" && IndexPath.section == 0){
      list_elm.find(".list_name").text(
        Settings.getFlag(this_list.preferred_language)
        +
        " "
        +
        this_list.list_name
      );
    }else{
      list_elm.find(".list_name").text(this_list.list_name);
    }

    list_elm.find(".list_num").text(this_list_words.length);
    list_elm.find(".timestamp").text(this_list.created_at.split(" ")[0]);
    list_elm.find(".actions-wrapper").append($(".action-heart.template").clone());
    //todo:
    //list_elm.find(".actions-wrapper").append($(".action-menu.template").clone());
    list_elm.find(".template").removeClass('template');

    //primary
    let primary_indexpath = Settings.getPrimaryListIndexPath();
    if (primary_indexpath != null && primary_indexpath.section == IndexPath.section && primary_indexpath.row == IndexPath.row){
      list_elm.addClass('primary');
    }

    //memo
    let total = this_list_words.length;
    if (total > 0){
      let memo_stack = {
        //@Depracated: memo_start : 0,
        memo_known : 0,
        memo_familiar : 0,
        memo_danger : 0,
        memo_untracked: 0,
      }
      for(let word of this_list_words){
        let memo_type = word.MemoModel.MemoType;
        memo_stack[word.MemoModel.MemoType]++;
      }
      $.each(memo_stack, function (key, value) {
        let stack_item = $("<span class='stack_item'></span>");
        let percent = (value/total)*100;
        stack_item.attr('title', key);
        stack_item.css({
          'width':percent + "%",
          'background-color':ColorSuits['standard-red'][key]
        });
        if (percent > 0){
          stack_item.addClass('has_value');
          list_elm.find(".memo_stack").append(stack_item);
        }
      });
    }

    //.press
    listenLongPress(list_elm[0]);

  }else{
    let new_list_title = "创建";
    if (IndexPath.section == 0){
      new_list_title = "📒 添加单词本";
      list_elm = $(
        "<div class='list'>" +
        "<div class='list-content'>\n" +
        "    <div class='list_name create'>"+new_list_title+"</div>\n" +
        "</div>" +
        "</div>");
      list_elm.attr('section',IndexPath.section);
      list_elm.attr('row', -1);
      return list_elm;
    }
    if (IndexPath.section == 1){
      //new_list_title = "📘 暂无单词";
      return "";
    }
  }

  return list_elm;
}

//.didSelectCellAtIndexPath
$('body').off('click','.listview-wrapper .list');
$('body').on('click','.listview-wrapper .list',function () {
  let section = $(this).attr('section');
  let row = $(this).attr('row');
  didSelectCellAtIndexPath(section, row);
});
function didSelectCellAtIndexPath(section, row) {
  //console.log("🐞didSelectCellAtIndexPath:",section,row);
  //添加单词
  if (row < 0){

    //🔐禁止未登录
    if (LimitFunction.isLogin('default') != true){
      return;
    }

    //点击cover
    if (section == 2){
      window.top.mBookViewController.showUP("switch-book");
      return;
    }

    //点击cell
    if (window.top.mBookViewController){
      window.top.mBookViewController.showUP("add-both");
    }

    // if (section == 0){
    //   showCreateListAlert();
    // }
    // if (section == 1){
    //   showCoverListsNav();
    // }
  }
  //进入列表
  else{
    //选择优先列表
    if (instance.mMode == "MODE-CHOOSE-PRIMARY-LIST"){

      Settings.setPrimaryListIndexPath({section:section, row:row});

      // @Depracated!
      // reloadPage();

      IframeNavBack();

      return;
    }

    //点击cover
    // if (section == 2){
    //   toggleBookExpand();
    //   return;
    // }

    //点击cell
    if (window.top.WordsViewController){
      window.top.WordsViewController.showUP(section, row);
    }else{
      alert("暂时无法打开单词本");
    }
  }
}

//.listenLongPress
function listenLongPress(list) {
  var hammer = new Hammer(list);
  hammer.on('press', function(e) {
    e.preventDefault();

    let section = $(list).attr('section');
    let row = $(list).attr('row');

    let clickingList = StudyDataController.getListBy(section, row);
    let clickingWords = StudyDataController.getWordsBy(section, row);
    if (clickingList != null){
      setClicking(clickingList,clickingWords,{section:section, row:row})
      presentMenuList(true);
    }
    //console.log("🐞You're pressing me:",e,[section, row]);
  });
}




//list menu
// $('body').on('click', function (e) {
//   $(".list_menu").remove();
// });
// $('body').on('click','.list .menu-button',function (e) {
//   e.stopPropagation();
//
//   let this_list_elm = $(this).closest('.list');
//   let section = this_list_elm.attr('section');
//   let row = this_list_elm.attr('row');
//   didSelectCellMenuAtIndexPath(section, row);
// });
// function didSelectCellMenuAtIndexPath(Section, Row) {
//   //MenuUI
//   let list_menu = $(
//     '<div class="dropdown-menu dropdown-menu-right show list_menu">\n' +
//     '    <a class="dropdown-item action-today">优先安排今日学习</a>\n' +
//     '    <a class="dropdown-item action-limit">设置每日学习上限</a>\n' +
//     '    <a class="dropdown-item action-edit">修改名称</a>\n' +
//     '    <a class="dropdown-item action-delete text-dangerr"><span class="text-danger">删除</span></a>\n' +
//     '</div>'
//   );
//   $(".list_menu").remove();
//
//   let this_list_elm = $(".list[section="+Section+"][row="+Row+"]");
//   this_list_elm.append(list_menu);
//
//   //MenuAction
//   let this_list = StudyDataController.getListBy(Section, Row);
//   //.delete
//   list_menu.on('click','.action-delete', function () {
//     Alert.showConfirmAlert(
//       "确认删除 "+this_list.list_name+"?",  // Title,
//       "单词本删除后无法恢复",                                     // HtmlMessage,
//       true,                                                   // isDangerous,
//       "确认删除",                                              // ActionText,
//       function () {                                           // OnConfirm
//         if (this_list != null){
//
//           Alert.showSuccessAlert("已删除"+this_list.list_name,'','',true);
//
//           StudyDataController.archiveListBy(this_list);
//           StudyDataController.regroupStudyData();
//           redisplayListsTableView();
//         }
//       }
//     );
//   });
//   //.edit
//   list_menu.on('click','.action-edit', function () {
//     Alert.showInputAlert("修改单词名称","请尽量与已存在的单词本不要重名哦",this_list.list_name, "确认修改", function (value) {
//       if (Tools.isEmpty(value)==false){
//         Alert.showSuccessAlert("已修改单词本名称",'','',true);
//
//         StudyDataController.modifyListNameBy(this_list, value);
//         StudyDataController.regroupStudyData();
//         redisplayListsTableView();
//       }
//     });
//   });
//   //.today
//   list_menu.on('click','.action-today', function () {
//     Settings.setPrimaryListIndexPath({section:Section, row: Row});
//     refetchAllDataThenRedisplay(true);
//     Alert.showSuccessAlert('今日学习计划已更新',null,null,true);
//   });
//   //.limit
//   list_menu.on('click','.action-limit', function () {
//     Alert.showInputAlert(
//       "设置每日学习量",             // Title,
//       "学习量是指您每天能浏览的单词单词总量<br>普通人每小时能够浏览100-300个单词<br>您可以根据自己的情况设定学习量<br>方格单词会自动为您安排新单词和复习单词" +
//       "<br>(刚开始学习时, 每日学习会低于设置量)",// HtmlMessage,
//       Settings.getUpperLimit(),   // DefaultValue,
//       "确定",                      // ActionText,
//       function (text) {           // OnFinish
//         if (Tools.isEmpty(text)==false){
//           let intValue = parseInt(text);
//           if (isNaN(intValue)==false){
//             Settings.setUpperLimit(intValue);
//             refetchAllDataThenRedisplay(true);
//             Alert.showSuccessAlert('每日学习上限已更新',null,null,true);
//             return;
//           }
//         }
//         Alert.showWarningAlert(
//           "请输入有效的学习量哦",
//           "学习量是指您每天能浏览的单词单词数量<br>普通人每小时能够浏览100-300个单词<br>您可以根据自己的情况设定学习量<br>方格单词会自动为您安排新单词和复习单词",
//           "好的")
//       }
//     )
//   });
// }


//🚫test:
// StudyDataController.refetchAllStudyData(true,function () {
//   redisplayListsTableView();
// });


//🍉init
const ListView = () => {

  useEffect(() => {
    listenLongPress($(contentRef.current).find('.studybook-cover')[0]);
  });

  const contentRef = useRef(this);
  return (
    <div className={"listview-wrapper col-12 "+instance.mMode} ref={contentRef}>

      <div className="section-clear">
        <div className="section-title chapters">
          <span>单词书</span>
          <div className="button switch">
            {/*<IonIcon icon={swapVerticalOutline}/>*/}
            切换词书
          </div>
        </div>

        <div className="studybooks-wrapper">
          <div className="studybook-cover list ripple-parent" section="2" row="0">{/*folded*/}
            <div className="list-content">
              <div className="list-name-group">
                <div className="list_name">单词书</div>
                <span className="badge badge-light">
                  <span className="list_num">0</span>
                </span>
              </div>

              <div className="timestamp">2019-10-31</div>
              <div className="actions-wrapper">
                {/*todo*/}
                {/*<div className="button button-modify">分组设置</div>*/}
                <div className="button button-fold"></div>
                <span className="action action-heart"><IonIcon icon={heart}/></span>
              </div>
              <i className='arrow fas fa-chevron-right'></i>

              {/*<ion-ripple-effect></ion-ripple-effect>*/}
            </div>
          </div>
          <div className="studychapters-wrapper"></div>
        </div>
      </div>

      <div className="section-clear">
        <div className="section-title lists">
          <span>单词本</span>

          {/*<IonButtons className="buttons-group">*/}
          {/*<IonButton className="button add" size="small" shape="round">下载</IonButton>*/}
          {/*<IonButton size="small" shape="round" onClick={()=>{*/}
          {/*StudyDataController.showCreateListAlert(function (inserted_list) {*/}
          {/*regroupStudyDataThenReloadPage();*/}
          {/*})*/}
          {/*}}>新建</IonButton>*/}
          {/*</IonButtons>*/}

        </div>
        <div className="studylists-wrapper"></div>
      </div>
    </div>
  );
};
const ListViewController = (props) => {

  // useIonViewDidEnter(() => {
  //   //console.log("🐞ListViewController.useIonViewDidEnter!")
  //   setTimeout(()=>{
  //     reloadPage();
  //   },500);
  // });

  useEffect(() => {
    //console.log("🐞ListViewController ready!");
    setupBookCover();
    setupSections();
    setupElements();
    reloadPage();
  });

  if (props.fragment){
    return (<ListView/>);
  }else{
    return (
      <IonPage className="ListViewController" mode={instance.mMode}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="" defaultHref="/" onClick={(e)=>{
                //for IonNav
                //为 IonBackButton 添加一个 onClick 事件, 可以防止 IonBackButton 默认的 Router 导航 (e.preventDefault 可选)
                e.preventDefault();
              }}/>
            </IonButtons>
            <IonTitle>{
              {
                "MODE-NORMAL":"我的库存",
                "MODE-CHOOSE-PRIMARY-LIST":"选择优先学习列表"
              }[instance.mMode]
            }</IonTitle>
            <IonButtons slot="end">
              <div className="menu-button color-dark" onClick={()=>{
                //🔐禁止未登录
                if (LimitFunction.isLogin('default') != true){
                  return;
                }

                presentMenuAddList(true);
              }}><span className="text">添加</span></div>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ListViewContent">
          <ListView/>
        </IonContent>

      </IonPage>);
  }
};

export default ListViewController;
