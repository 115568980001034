import $ from "jquery";
import * as Tools from "../../tools/Tools";
import StudyDataController from "../../tools/StudyDataController";

//todo:
// //console.log("🐞mIsFrame:',window.isFrame)
// if (window.isFrame != true){
//   return;
// }

// todo:
// const TTSHelper = window.top.TTSHelper;
// const StudyDataController = window.top.StudyDataController;
// let mTTSHelper = new TTSHelper();

//@Interface
const SearchDetailNavBar = {
  mCurrentResult:null,

}



window.SearchDetailNavBar = SearchDetailNavBar;

export default SearchDetailNavBar;