import {useState,useEffect} from 'react';
import {render} from "react-dom";
import $ from "jquery";
import {IonContent, IonPage, IonRange} from '@ionic/react';
import {IframeNavPresentPage} from '../../../tools/uikit/IframeNav';
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react/dist/index";
import {ellipsisHorizontal, ellipsisVertical, heart} from "ionicons/icons/index";
import * as ColorSuits from "../../../theme/ColorSuits.js";
import '../../../theme/composed-uikit.css';
import StudyDataController from '../../../tools/StudyDataController.js';
import UIPagesController from '../../../tools/uikit/UIPagesController';
import {IframeNavPresentFragment} from '../../../tools/uikit/IframeNav';
import './CardsViewController.css';
import CardsSetting from "./CardsSetting";
import * as Tools from "../../../tools/Tools";
import ResultMaker from "../../../tools/mdict/ResultMaker";
import SearchDetail from "../../dictionary/SearchDetail";
import NavBar from "../../../components/NavBar";
import Alert from "../../../tools/Alert";
import InjectStyle from "../../../components/InjectStyle";
import NativeTools from "../../../tools/NativeTools";

window.$ = $;

var isNewEntering = true;

//🍉instance
var instance = {
  mList:null,
  mWords:[],
  showUP : function (List, Words, Process=0) {

    //words require
    if (Words.length <= 0){
      Alert.showWarningAlert("😨 没有可学习的单词","您可以先添加一些单词哦","好的");
      return;
    }

    //mdxparser required
    if (!window.MdxService){
      Alert.showWarningAlert("词典功能还未就绪哦","稍等几秒钟再试试","好吧");
      return;
    }

    //mdx require
    if (window.top.MdxService.validateDictionarys() != true){
      return;
    }

    //preloader require
    if (!NativeTools.mTTSPreloader){
      Alert.showWarningAlert("发音引擎还未就绪哦","稍等几秒钟再试试","好吧");
      return;
    }


    instance.mList = List;
    instance.mWords = Words;
    mList = List;
    mWords = Words;

    //@Depracated!
    //setProcess(List, Process);

    mCurrentIndex = Process;
    IframeNavPresentPage(CardsViewController, "CardsViewController", false);

    isNewEntering = true;
    setTimeout(()=>{
      //require tts
      NativeTools.tryRequireOfflineTTS();

      //@Depracated: preload by tableview
      //preload voices
      //NativeTools.preloadWordsVoicesBy(Words, false, mCurrentIndex, true, false, ()=>{});

    },2000);
  }
};
window.top.mCardsViewController = instance;


//🍉init
var mCurrentIndex = 0;
var mList;
var mWords = [];
const CardsViewController = () => {

  //🍉display
  const [ionProgress, setIonProgress] = useState(0);
  const [ionTotal, setIonTotal] = useState(0);
  const [mounted, setMounted] = useState(true);

  const CardModels = {
    CardALearnWord   : "pages/CardsVCor/CardA-LearnWord.html",
    CardALearnDetail : "pages/CardsVCor/CardA-LearnDetail.html",
    CardBSpellWord   : "pages/CardsVCor/CardB-SpellWord.html",
    CardCSelectByWord: "pages/CardsVCor/CardC-SelectByWord.html",
    CardCSelectByMean: "pages/CardsVCor/CardC-SelectByMean.html",
  }
  var mCardModel = CardModels.CardALearnWord;

  var mCardsSettingRef, setCardsSettingRef = function(dom){    mCardsSettingRef = dom;};

  function redisplayCards(List, Words) {

    mList = List;
    mWords = Words;

    //setup Mode
    const CardModelsMap = {
      "mode_slide_rate":CardModels.CardALearnWord,
      "mode_slide_detail":CardModels.CardALearnDetail,
      "mode_select_zh":CardModels.CardCSelectByWord,
      "mode_select_en":CardModels.CardCSelectByMean,
      "mode_spell_word":CardModels.CardBSpellWord,
    }

    if (mCardsSettingRef){
      mCardsSettingRef.refetchOptions();
      mCardModel = CardModelsMap[mCardsSettingRef.getOptionMode()];
      //console.log("🐞redisplayCards",mCardModel);
    }

    //setup Process
    setIonTotal(mWords.length);

    //fetch Process
    //refetchProcess();

    //setup UIPagesController
    // $("#cards-slider-warpper").fadeOut(100, function () {
    //   //...init
    //   $("#cards-slider-warpper").fadeIn(200);
    // });
    //init
    $("#cards-slider-warpper").empty();
    UIPagesController.init(
      "cards-slider-warpper",     //ContextID
      mCurrentIndex,              //InitIndex
      UIPagesController.DataSource( //DataSource
        mWords.length,
        viewControllerAtIndex
      ),
      UIPagesController.Delegate(   //Delegate
        didScrollToPageAtIndex
      )
    );
  }
  window.top.redisplayCards = redisplayCards;

  function reloadCards() {
    redisplayCards(mList,mWords);
  }

//🍉DataSource
  function viewControllerAtIndex(Index, Model) {
    let this_model = typeof (Model) != "undefined" ? Model : mCardModel;

    let card_wrapper = $("<div class='card-wrapper position-relative w-75 h-100 bg-white'></div>");
    card_wrapper.attr('src',this_model);
    card_wrapper.addClass("study-card "+CardModels.keyNameOf(this_model));
    card_wrapper.load( this_model, function () {
      setupCard(
        card_wrapper,   // Context,
        Index,          // Index
        mWords[Index],  // Word,
        this_model      // CardModel
      );
    });
    return card_wrapper[0];
  }
  function setupCard(Context, Index, Word, CardModel){

    //console.log("🐞SetupCard:",Index,Word,CardModel,Context);

    //🍓CardA - Learn Word
    if (CardModel == CardModels.CardALearnWord){
      //UI
      Context.find('.word').text(Word.word_string);
      Context.find('.mean').text(Word.word_key);
    }

    //🍓CardA - Learn Detail
    if (CardModel == CardModels.CardALearnDetail){

      IframeNavPresentFragment(
        Context.find(".search-detail-wrapper"),
        SearchDetail, "SearchDetail",
        {Word:Word, WordString:Word.word_string, MeanString:Word.word_key}
      );

      //@Depracated!
      //setup card-detail by $.load()
      //Context.find('.search-detail-wrapper').load('Apps/Dictionary/SearchDetail/SearchDetail.html');

      //@Depracated: setup card-detail by iframe
      // let mSearchDetailModel = NavigationController.init();
      // mSearchDetailModel.putIframe(
      //     Context.find('.search-detail-wrapper'),
      //     null,
      //     null,
      //     'Apps/Dictionary/SearchDetail/SearchDetail.html',
      //     NavigationController.NagigationDelegate(
      //     function onLoad(e) {
      //         //wait iframe js ready
      //         setTimeout(function () {
      //             mSearchDetailModel.getIframeWindow().renderContent([Word.word_string]);
      //         },1000);
      //     },
      //     function pageDidScroll() {...}
      // ));
    }

    //🍓CardB - Spell Word
    if (CardModel == CardModels.CardBSpellWord){
      //UI
      Context.find('.word').text(Word.word_string);
      Context.find('.mean').text(Word.word_key);

      //Actions
      Context.find("input").on("input", function () {
        let answer = Context.find(".word").text().toLowerCase();
        let input_text = $(this).val().toLowerCase();
        if (answer.startsWith(input_text)){
          $(this).removeClass('danger');
          $(this).addClass('safe');
        }else{
          $(this).addClass('danger');
          $(this).removeClass('safe');
        }

        if (answer == input_text){
          setTimeout(function () {
            slideNextAddingMemo();
          },1000);
        }
      });
    }

    //🍓CardC - SelectBy Word / Mean
    if (CardModel == CardModels.CardCSelectByWord || CardModel == CardModels.CardCSelectByMean){

      //UI
      if (CardModel == CardModels.CardCSelectByWord){
        Context.find('.word').text(Word.word_string);
      }else{
        Context.find('.word').text(Word.word_key);
      }

      let option_words = [];
      let words_indexs = Object.keys(mWords);

      //1.
      let get_option_answer = Object.clone(Word);
      get_option_answer.type = "answer";
      option_words.push(get_option_answer);
      words_indexs.splice(words_indexs.indexOf(Index), 1);

      //.234
      for (var i = 0; i<=2; i++){
        if (words_indexs.length > 0){
          let random_position = Math.floor(Math.random()*words_indexs.length);

          let get_option_index = words_indexs[random_position];
          let get_option_word = Object.clone(mWords[get_option_index]);

          if (
            typeof(get_option_word) != "undefined" &&
            get_option_word.word_string != get_option_answer.word_string &&
            get_option_word.word_key != get_option_answer.word_key
          ){
            get_option_word.type = "wrong";
            option_words.push(get_option_word);
            words_indexs.splice(random_position, 1);
          }
        }
      }
      //.shuffle
      option_words = option_words.shuffle();

      //otpions elm
      for (var i=0; i<option_words.length; i++){
        let option_word = option_words[i];

        let option = $("<div class='option'></div>");
        option.addClass(option_word.type);
        if (option_word.type == "answer"){
          option.addClass("slide-next-adding slide-next-wait");
        }

        if (CardModel == CardModels.CardCSelectByWord){
          option.text(option_word.word_key);
        }else{
          option.text(option_word.word_string);
        }

        let indexs = ["1","2","3","4"];
        option.addClass('key key-'+indexs[i]);
        if (i==0){
          option.addClass('key-enter');
        }

        Context.find('.options').append(option);
      }


      //Actions
      Context.on('click','.option',function () {
        $(this).addClass('active');
      });
    }


    //🍓General UI

    //@progress
    let this_word_memo_type = Word.MemoModel.MemoType;
    $("ion-range").attr("memo",this_word_memo_type);

    //@Addition
    if (CardModel != CardModels.CardALearnDetail && CardModel != CardModels.CardALearnWord){
      //.单词详情
      let addition_elm = $("<div class='addition-button'></div>")
      addition_elm.append(
        '<span class="text-sandy detail-button slide-next-detail" onclick="$(this).fadeOut();">单词详情</span>'
      )
      //.memo
      //todo:
      // if(CardsSetting.mOptionShowMemo == true){
      //   let memo_button = $('<span class="memo-button">X</span>');
      //   let this_word_memo_type = Word.MemoModel.MemoType;
      //   memo_button.css({'background-color':ColorSuits['standard-red'][this_word_memo_type]});
      //   addition_elm.append(memo_button);
      // }
      Context.append(addition_elm);
    }

    //🍓General Actions
    //click touchstart
    Context.off('click','.slide-next-ignoring');
    Context.on('click','.slide-next-ignoring',function () {
      console.log("🐞skip!");
      setTimeout(function () {
        slideNextIgnoringMemo();
      },200);
    });

    Context.off('click','.slide-next-adding')
    Context.on('click','.slide-next-adding',function () {
      //console.log("🐞slide-next-adding");

      //show answer
      Context.find(".answer span").click();

      //next
      let delay = $(this).attr('class').includes('slide-next-wait') ? 1000 : 0;
      setTimeout(function () {
        slideNextAddingMemo();
      },delay);
    });

    Context.off('click','.slide-next-detail')
    Context.on('click','.slide-next-detail',function () {
      let page_data = viewControllerAtIndex(mCurrentIndex, CardModels.CardALearnDetail);
      UIPagesController.slideNextWithNewPage(page_data)
    });
  }

// //🍋 mdx service listener
  function startListenMdxServiceEvents() {
    window.top.MdxService.MdxServiceDelegate.OnFetchMainpart = mdxServiceOnFetchMainpart;
  }
  function mdxServiceOnFetchMainpart(Object) {
    let Results = Object;

    //console.log("🐞🍋mainparts:",Results);

    //todo:
    // if (Results.length > 0){
    //
    //   let search_detail_wrapper = $('#cards-slider-warpper').find('.islider-active .search-detail-wrapper');
    //
    //   //display result_index
    //   window.displayResultIndex(search_detail_wrapper, Results[0]);
    //
    //   //display content
    //   let mainparts = [];
    //   $.each(Results, function (i, result) {
    //     let result_mainpart = result.result_mainpart;
    //     if (!Tools.isEmpty(result_mainpart)){
    //       mainparts.push(result_mainpart);
    //     }
    //   });
    //   //console.log("🐞🍋mainparts:",[mainparts]);
    //   window.renderContent(search_detail_wrapper, [mainparts]);
    // }
  }

// //🍋 keyboard listener
// let mHoveringKeyElm;
// function listenKeyboard() {
//   window.focus();
//   let listen_keys = [
//     KeyboardController.KeyCodes[1],
//     KeyboardController.KeyCodes[2],
//     KeyboardController.KeyCodes[3],
//     KeyboardController.KeyCodes[4],
//     KeyboardController.KeyCodes.space,
//     KeyboardController.KeyCodes.enter,
//   ];
//
//   $(document).off('keydown');
//   $(document).off('keyup');
//
//   $(document).keydown(function (e) {
//     if (listen_keys.includes(e.keyCode)){
//       e.preventDefault();
//       let key_name = KeyboardController.KeyCodes.keyNameOf(e.keyCode);
//       $('#cards-slider-warpper').find('.hovering').removeClass('hovering');
//       $('#cards-slider-warpper .islider-active').find(".key-"+key_name).addClass('hovering');
//     }
//   });
//   $(document).keyup(function (e) {
//
//     //space/enter
//     if (listen_keys.includes(e.keyCode)){
//       e.preventDefault();
//       let key_name = KeyboardController.KeyCodes.keyNameOf(e.keyCode);
//       setTimeout(function () {
//         $('#cards-slider-warpper').find('.hovering').removeClass('hovering');
//
//         setTimeout(function () {
//           $('#cards-slider-warpper .islider-active').find(".key-"+key_name).trigger('click')
//         },200);
//       },200);
//     }
//
//     //arrows
//     if (e.keyCode == KeyboardController.KeyCodes.down || e.keyCode == KeyboardController.KeyCodes.right){
//       let next_option = $('#cards-slider-warpper .islider-active').find('.key-enter').next();
//       if (next_option.length > 0){
//         $('#cards-slider-warpper .islider-active').find('.key-enter').removeClass('key-enter');
//         next_option.addClass('key-enter');
//       }
//     }
//     if (e.keyCode == KeyboardController.KeyCodes.up || e.keyCode == KeyboardController.KeyCodes.left){
//       let prev_option = $('#cards-slider-warpper .islider-active').find('.key-enter').prev();
//       if (prev_option.length > 0){
//         $('#cards-slider-warpper .islider-active').find('.key-enter').removeClass('key-enter');
//         prev_option.addClass('key-enter');
//       }
//     }
//   });
// }

//🍉Delegate
  var mLatestSpeakIndex = -1;
  function didScrollToPageAtIndex(Index) {

    mCurrentIndex = Index;
    setIonProgress(mCurrentIndex);

    if (!isNewEntering){
      //preload voices
      NativeTools.preloadWordsVoicesBy(mWords, false, mCurrentIndex, false, false, ()=>{});
    }else{
      isNewEntering = false;
    }

    //@Depracated!
    //saveProcess();

    let this_slide = $("#cards-slider-warpper").find(".islider-active");
    let this_card = this_slide.find('.card-wrapper');
    let this_card_model = this_card.attr('src');

    //console.log("🐞🍋didScrollToPageAtIndex:",this_card_model);

    //🍓setup card at ViewDidAppear

    //@Depracated!
    // //Card LearnDetail
    // if (this_card_model == CardModels.CardALearnDetail){
    //   let this_word = mWords[mCurrentIndex];
    //   let search_detail_wrapper = $('#cards-slider-warpper').find('.islider-active .search-detail-wrapper');
    //   // //display result_index
    //   // window.displayResultIndex(search_detail_wrapper, {result_index:this_word.word_string, result_mean:this_word.word_key});
    //   // window.renderContent(search_detail_wrapper, [ResultMaker.makeHtmlStringForFastRender(this_word)]);
    //
    //   //fetch mainpart
    //   startListenMdxServiceEvents();
    //   window.top.mdxFetchMainpart(this_word.word_string);
    // }

    //Card SpellWord: focus
    if (this_card_model == CardModels.CardBSpellWord){
      setTimeout(function () {
        this_card.find(".inputing")[0].focus();
      },200);
    }

    //todo:
    // //listen keyboard
    // listenKeyboard();

    //🍓auto speak
    if (mCardsSettingRef.getOptionAutoSpeak()){
      //mode limited
      if (
        this_card_model != CardModels.CardBSpellWord &&
        this_card_model != CardModels.CardCSelectByMean
      ){
        //speak once / card
        if (mLatestSpeakIndex != mCurrentIndex){ mLatestSpeakIndex = mCurrentIndex;
          //.speak
          let word = mWords[mCurrentIndex];
          if (word){
            NativeTools.speakWord(word, false);
          }
        }
      }
    }
  }

  //🍉progress
  function setupProgressBar() {
    $("ion-range").off('touchend mouseup');
    $("ion-range").on('touchend mouseup', function () {
      reloadCards();
    });
  }

  // @Depracated!
  // function setProcess(List, Index) {
  //   let key = "STUDY-CARDS-PROCESS-1.0:"+List.unico_id;
  //   localStorage.setItem(key, Index);
  // }
  // window.top.setProcess = setProcess;
  // function refetchProcess() {
  //   mCurrentIndex = 0;
  //   if (mList){
  //     let key = "STUDY-CARDS-PROCESS-1.0:"+mList.unico_id;
  //     let get_process = localStorage.getItem(key);
  //     if (get_process != null){
  //       get_process = parseInt(get_process);
  //       //console.log("🐞mClickingListRow 001:",get_process);
  //       if (get_process < mWords.length){
  //         mCurrentIndex = get_process;
  //       }
  //     }
  //   }
  // }
  // function saveProcess() {
  //   let key = "STUDY-CARDS-PROCESS-1.0:"+mList.unico_id;
  //   localStorage.setItem(key, mCurrentIndex);
  // }
  // window.top.saveProcess = saveProcess;

  //@Depracated!
  // function updateProgressUI() {
  //   setIonProgress(mCurrentIndex);
  //
  //   // let percent = mWords.length > 0 ? (mCurrentIndex/mWords.length)*100 : 0;
  //   // $(".progress-bar").css({'width':percent+"%"},100);
  // }

  //@Depracatd!
  // function setupJumpMenu() {
  //   MenuController.addMenu(
  //     $("#jump-button"),  // Context,
  //     'bottom-right',     // ClassName,
  //     null,               // Style,
  //     function (menu) {   // OnCreatedMenu
  //       for (var word_index=0; word_index<mWords.length; word_index++){
  //         let this_word = mWords[word_index];
  //         let this_index = word_index;
  //
  //         let class_name = mCurrentIndex == word_index ? "disabled":"";
  //         MenuController.addMenuItem(
  //           menu,                   // Menu,
  //           class_name,             // ClassName,
  //           this_word.word_string,  // HtmlContent,
  //           function () {           // OnClick
  //             UIPagesController.skipTo(this_index);
  //           }
  //         )
  //       }
  //     }
  //   )
  // }setupJumpMenu();

//🍉Methods
  function slideNextAddingMemo() {
    StudyDataController.updateWordsMemo([mWords[mCurrentIndex]]);
    UIPagesController.slideNext();
  }
  function slideNextIgnoringMemo() {
    UIPagesController.slideNext();
  }

  useEffect(() => {
    //console.log("🐞CardsViewController ready:", instance.mWords);
    setupProgressBar();

    setTimeout(function () {
      reloadCards();
      window.top.reloadCards = reloadCards;
    },200);

  },[mounted]);

  return (
    <IonPage id="CardsViewController">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="返回" defaultHref="/" onClick={(e)=>{
              //for IonNav
              //为 IonBackButton 添加一个 onClick 事件, 可以防止 IonBackButton 默认的 Router 导航 (e.preventDefault 可选)
              e.preventDefault();
            }}/>
          </IonButtons>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            <IonButton className="menu-button" onClick={(e)=>{
              e.preventDefault();
              e.stopPropagation();

              if (mCardsSettingRef){mCardsSettingRef.toggleSettingsPanel();}
            }}>
              <IonIcon className="color-dark" slot="icon-only" icon={ellipsisHorizontal}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="WordsViewContent">

        <div className="IonRangeWrapper">
          <div className="progress">
            <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>

          <IonRange
            min={0} max={(ionTotal-1)} value={ionProgress}
            color="secondary"
            onIonChange={(event)=>{
              let value = event.detail.value;
              mCurrentIndex = value;
              //@Depracated!
              //saveProcess();
            }}/>
        </div>

        <div id="cards-slider-warpper" className="flex-grow-1">
        </div>
      </IonContent>

      <CardsSetting onRef={setCardsSettingRef}/>
    </IonPage>
  );
};

export default CardsViewController;